import { useTranslation } from 'react-i18next'

import { QuizResultsItem } from '../types'
import * as Styled from './ResultsListItem.styled'

const QuizResultsListItemData = ({
  quizName,
  entriesCount
}: QuizResultsItem) => {
  const { t } = useTranslation()

  return (
    <>
      <Styled.DataValueContainer>
        {quizName}
      </Styled.DataValueContainer>
      <Styled.DataValueContainer>
        {t('results.occurrences', { count: entriesCount })}
      </Styled.DataValueContainer>
      <Styled.DataValueContainer />
    </>
  )
}

export { QuizResultsListItemData }
