import React, { MouseEvent } from 'react'
import { useFormik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'

import { SendResetPasswordEmailSchema } from 'validations/SendResetPasswordEmailSchema'
import { TextField } from 'components/inputs/TextField/TextField'
import { Button } from 'components/inputs/Button/Button'
import { Link } from 'components/inputs/Link/Link'
import * as FormStyled from '../forms.styles'

export interface FormFields {
  emailOrUsername: string
}

interface Props {
  onSubmit: (values: FormFields, formikHelpers: FormikHelpers<FormFields>) => void
  onRedirect: (event: MouseEvent<HTMLButtonElement>) => void
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  emailAddress?: string
}

const SendResetPasswordEmailForm: React.FC<Props> = ({
  onSubmit,
  onRedirect,
  isLoading,
  isSuccess,
  isError,
  emailAddress,
}) => {
  const { t } = useTranslation()
  const { handleSubmit, handleBlur, handleChange, values, errors, touched } = useFormik<FormFields>(
    {
      initialValues: { emailOrUsername: '' },
      validationSchema: SendResetPasswordEmailSchema,
      onSubmit,
    }
  )
  return (
    <FormStyled.Form onSubmit={handleSubmit}>
      <TextField
        label={t('common.emailOrUsername')}
        type='text'
        errorMessage={t(errors.emailOrUsername || '')}
        id='emailOrUsername'
        name='emailOrUsername'
        value={values.emailOrUsername}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.emailOrUsername}
      />
      <Link to='/sign-in'>{t('signUp.backToLogin')}</Link>
      {isError && (
        <FormStyled.ErrorMessage>
          {t('common.errors.generic')}
        </FormStyled.ErrorMessage>
      )}
      <FormStyled.FormFooter>
        {isSuccess ? (
          <>
            <FormStyled.ConfirmationMessage>
              {t('resetPassword.emailSent')} {emailAddress}
            </FormStyled.ConfirmationMessage>
            <Button type='button' onClick={onRedirect}>
              {t('common.signIn')}
            </Button>
          </>
        ) : (
          <Button backgroundGradient disabled={isLoading} isLoading={isLoading}>
            {t('resetPassword.sendResetEmail')}
          </Button>
        )}
      </FormStyled.FormFooter>
    </FormStyled.Form>
  )
}
export default SendResetPasswordEmailForm
