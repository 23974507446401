import { useTranslation } from 'react-i18next'
import { useFormik, FormikHelpers } from 'formik'

import { PasswordField } from 'components/inputs/PasswordField/PasswordField'
import { Button } from 'components/inputs/Button/Button'
import { ResetPasswordSchema } from 'validations/ResetPasswordSchema'
import * as FormStyled from '../forms.styles'

export interface FormFields {
  newPassword: string
  confirmNewPassword: string
}

interface Props {
  onSubmit: (values: FormFields, helpers: FormikHelpers<FormFields>) => void
  isLoading: boolean
  isError: boolean
}

const ResetPasswordForm: React.FC<Props> = ({
  onSubmit,
  isLoading,
  isError,
}) => {
  const { t } = useTranslation()
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } = useFormik<FormFields>(
    {
      initialValues: { newPassword: '', confirmNewPassword: '' },
      validationSchema: ResetPasswordSchema,
      onSubmit,
    }
  )
  return (
    <FormStyled.Form onSubmit={handleSubmit}>
      <PasswordField
        label={t('resetPassword.form.newPassword')}
        errorMessage={t(errors.newPassword || '')}
        id='newPassword'
        name='newPassword'
        value={values.newPassword}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.newPassword}
      />
      <PasswordField
        label={t('resetPassword.form.confirmNewPassword')}
        errorMessage={t(errors.confirmNewPassword || '')}
        id='confirmNewPassword'
        name='confirmNewPassword'
        value={values.confirmNewPassword}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.confirmNewPassword}
      />
      {isError && (
        <FormStyled.ErrorMessage>
          {t('common.errors.generic')}
        </FormStyled.ErrorMessage>
      )}
      <FormStyled.FormFooter>
        <Button backgroundGradient disabled={isLoading} isLoading={isLoading}>
          {t('resetPassword.savePassword')}
        </Button>
      </FormStyled.FormFooter>
    </FormStyled.Form>
  )
}
export default ResetPasswordForm
