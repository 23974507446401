import styled from '@emotion/styled'

import { CompaniesListItem } from './CompaniesListItem/CompaniesListItem'

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledCompaniesListItem = styled(CompaniesListItem)`
  margin-top: 10px;
`

export {
  RootContainer,
  StyledCompaniesListItem as CompaniesListItem
}
