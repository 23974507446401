import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Typography, TypographyProps } from '@material-ui/core'

import { Link } from 'components/inputs/Link/Link'

const CardsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: ${theme.spacing(4)}px;
    ${theme.breakpoints.down('md')} {
      justify-content: center;
    }
  `}
`

const CardText = styled((props: TypographyProps) => (
  <Typography variant='body1' {...props} />
))`
  font-weight: bold;
  svg {
    font-size: 1em;
  }
`

const FAQLink = styled(Link)`
  vertical-align: baseline;
`

export {
  CardsContainer,
  CardText,
  FAQLink,
}
