import { useCreateFileMutation, useCreateLinkMutation } from 'api/mutations/didacticMaterials/didacticMaterials'
import { DidacticMaterial } from 'types/DidacticMaterial'

const useAdditionalMaterialsHandler = () => {
  const { mutateAsync: createFile, isLoading: createFileLoading } = useCreateFileMutation()
  const { mutateAsync: createLink, isLoading: createLinkLoading } = useCreateLinkMutation()

  const isNewMaterialsLoading = createFileLoading || createLinkLoading

  const mapToApiDidacticMaterial = ({ content, isNew, ...material }: DidacticMaterial) => ({
    link: content,
    ...material,
  })

  const handleAdditionalMaterials = async (materials: DidacticMaterial[]) => {
    const newMaterials = await Promise.all(materials.map(
      async (material) => {
        if (material.isNew) {
          if (material.type === 'LINK') {
            const newMaterial = await createLink({
              link: material.content as string,
              name: material.name,
            })
            return newMaterial
          }
          if (material.type === 'FILE') {
            const newMaterial = await createFile({
              file: material.content as File,
              name: material.name,
            })
            return newMaterial
          }
        }
        return mapToApiDidacticMaterial(material)
      }
    ))
    return newMaterials
  }

  return { isNewMaterialsLoading, handleAdditionalMaterials }
}

export { useAdditionalMaterialsHandler }
