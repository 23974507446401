import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { formatISO, addSeconds } from 'date-fns'
import { Save } from '@material-ui/icons'

import { useGetAppointmentQuery } from 'api/queries/appointments/appointments'
import { CreateAppointmentVariables } from 'api/mutations/appointments/types'
import { useUserId } from 'providers/UserContextProvider'
import { useAdditionalMaterialsHandler } from 'hooks/useAdditionalMaterialsHandler'
import { useIsMobile } from 'hooks/useIsMobile'
import { AppointmentHeader } from 'components/data/Appointment/AppointmentHeader/AppointmentHeader'
import { AppointmentForm, AppointmentFormFields } from 'components/forms/AppointmentForm/AppointmentForm'
import { AppointmentVisibilityType } from 'types/AppointmentVisibilityType'
import { AUTHOR, COMPANY } from 'constants/visibilityTypes'
import { mapFromAppointmentResponse } from './utils'
import * as Styled from './details.styles'

interface CreateAppointmentProps {
  isLoading: boolean,
  onCreate: (appointment: CreateAppointmentVariables['appointment']) => void
}

interface StateType {
  appointmentId?: string
}

const CreateAppointment = ({
  isLoading,
  onCreate
}: CreateAppointmentProps) => {
  const { t } = useTranslation()
  const userId = useUserId()
  const isMobile = useIsMobile()
  const { handleAdditionalMaterials, isNewMaterialsLoading } = useAdditionalMaterialsHandler()
  const { state } = useLocation<StateType | undefined>()

  const {
    data: appointmentData,
    isLoading: isLoadingGet,
    isError: isErrorGet,
  } = useGetAppointmentQuery(
    state?.appointmentId ? +state?.appointmentId : 0,
    {
      enabled: !!state?.appointmentId,
      refetchOnWindowFocus: false,
    }
  )

  const initialValues: AppointmentFormFields | undefined = useMemo(() => {
    const appointment = mapFromAppointmentResponse(appointmentData)
    if (appointment) {
      const {
        startDate,
        duration,
        description,
      } = appointment
      const startDateTime = new Date(startDate)
      const endTime = new Date(addSeconds(startDateTime, duration)).toString()

      return {
        ...appointment,
        dateTime: {
          date: new Date().toString(),
          startTime: startDateTime.toString(),
          endTime,
        },
        description: description || '',
      }
    }
    return undefined
  }, [appointmentData])

  const handleCreateAppointment = async ({
    dateTime: {
      date,
      startTime,
      endTime,
    },
    teacher,
    didacticMaterials,
    participants,
    knowledgeBaseScheduleRecords,
    open,
    visibility: appointmentVisibility,
    ...fields
  }: AppointmentFormFields) => {
    const startDate = new Date(date)
    const startTimeDate = new Date(startTime)
    const endTimeDate = new Date(endTime)
    startDate.setHours(startTimeDate.getHours(), startTimeDate.getMinutes(), 0, 0)
    const duration = (endTimeDate.getTime() - startTimeDate.getTime()) / 1000
    const parsedMaterials = await handleAdditionalMaterials(didacticMaterials)

    const visibility = appointmentVisibility === AppointmentVisibilityType.Private
      ? AUTHOR
      : COMPANY

    onCreate({
      ...fields,
      open,
      startDate: formatISO(startDate),
      duration,
      teacherId: teacher ? teacher.id : -1,
      didacticMaterialIds: parsedMaterials.map((material) => material.id),
      languageCode: 'pl',
      knowledgeBaseScheduleRecords: knowledgeBaseScheduleRecords.map((recordId, index) => ({
        knowledgeBaseEntityId: recordId,
        position: index,
      })),
      participantsIds: participants.map((participant) => participant.id),
      visibility
    })
  }

  const renderActionButtons = () => (
    <Styled.ActionButton
      form='appointmentForm'
      startIcon={<Save />}
      $isMobile={isMobile}
    >
      {t('common.save')}
    </Styled.ActionButton>
  )

  return (
    <>
      <AppointmentHeader
        actionButtons={renderActionButtons()}
      />
      <Styled.ContentFooterContainer>
        {isErrorGet ? (
          <Styled.ErrorMessage>
            {t('common.errors.generic')}
          </Styled.ErrorMessage>
        ) : (
          isLoadingGet ? (
            <Styled.CircularProgress />
          ) : (
            <AppointmentForm
              onSubmit={handleCreateAppointment}
              isLoading={isLoading || isNewMaterialsLoading}
              formId='appointmentForm'
              authorId={userId}
              initialValues={initialValues}
            />
          )
        )}
      </Styled.ContentFooterContainer>
    </>
  )
}

export { CreateAppointment }
