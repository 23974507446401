import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

import { useFetch } from 'providers/FetchProvider'
import {
  STATISTICS_QUERY_KEY,
  STATISTICS_ADMIN_QUERY_KEY,
  QUIZ_ANALYSIS_QUERY_KEY
} from 'api/queriesKeys'
import { QuizAnalysisItem, StatisticsResponse } from './types'

const STATISTICS_PAGE_SIZE = 12

const useGetOwnStatisticsQuery = (
  name?: string,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<StatisticsResponse, Error>, 'queryKey'>
) : UseInfiniteQueryResult<StatisticsResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    [STATISTICS_QUERY_KEY, { name }],
    ({ pageParam }) => fetch.get('/statistics', {
      params: {
        name,
        page: pageParam,
        size: STATISTICS_PAGE_SIZE,
      },
    }).then((response) => response.data),
    useQueryOptions,
  )
}

const useGetCompanyStatisticsQuery = (
  companyId: number,
  name?: string,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<StatisticsResponse, Error>, 'queryKey'>
) : UseInfiniteQueryResult<StatisticsResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    [STATISTICS_ADMIN_QUERY_KEY, { companyId, name }],
    ({ pageParam }) => fetch.get(`/statistics/company/${companyId}`, {
      params: {
        name,
        page: pageParam,
        size: STATISTICS_PAGE_SIZE,
      },
    }).then((response) => response.data),
    useQueryOptions,
  )
}

const useGetQuizAnalysisQuery = (
  id: number,
  useQueryOptions?: Omit<UseQueryOptions<QuizAnalysisItem[], Error>, 'queryKey'>
): UseQueryResult<QuizAnalysisItem[], Error> => {
  const { fetch } = useFetch()

  return useQuery(
    [QUIZ_ANALYSIS_QUERY_KEY, { id }],
    () => fetch.get(`statistics/quiz/${id}`).then((response) => response.data),
    useQueryOptions
  )
}

export {
  useGetOwnStatisticsQuery,
  useGetCompanyStatisticsQuery,
  useGetQuizAnalysisQuery
}
