import { ReactNode } from 'react'
import { MenuItem } from '@material-ui/core'

import { Checkbox } from 'components/inputs/Checkbox/Checkbox'
import * as Components from './ManageListItem.components'
import * as Styled from './ManageListItem.styles'

interface ManageListItemProps {
  className?: string
  children: ReactNode
  active?: boolean
  $flexDirection?: string
}

const ManageListItem = ({
  className,
  children,
  active,
  $flexDirection
}: ManageListItemProps): JSX.Element => (
  <Styled.RootContainer $active={active} $flexDirection={$flexDirection} className={className}>
    {children}
  </Styled.RootContainer>
)

ManageListItem.TextField = Styled.TextField
ManageListItem.ActionsContainer = Styled.ActionsContainer
ManageListItem.Button = Styled.Button
ManageListItem.RoleSelect = Styled.Select
ManageListItem.StorageLimitSelect = Styled.StorageLimitSelect
ManageListItem.EducationLevelSelect = Styled.EducationLevelSelect
ManageListItem.MenuIconButton = Components.MenuIconButton
ManageListItem.Menu = Components.Menu
ManageListItem.MenuItem = MenuItem
ManageListItem.IconButton = Components.IconButton
ManageListItem.DeleteMenuItem = Styled.DeleteMenuItem
ManageListItem.EditIconButton = Components.EditIconButton
ManageListItem.ActivateIconButton = Components.ActivateIconButton
ManageListItem.DeleteIconButton = Components.DeleteIconButton
ManageListItem.CancelIconButton = Components.CancelIconButton
ManageListItem.SaveIconButton = Components.SaveIconButton
ManageListItem.HeaderText = Styled.HeaderText
ManageListItem.FieldsContainer = Styled.FieldsContainer
ManageListItem.Checkbox = Checkbox
ManageListItem.Row = Styled.Row
ManageListItem.Column = Styled.Column

export type { ManageListItemProps }
export { ManageListItem }
