import styled from '@emotion/styled'

import { Link } from 'components/inputs/Link/Link'

const AppointmentListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(1, 0)};
`

const SeeAllLink = styled(Link)`
  display: flex;
  font-weight: bold;
  text-decoration: none;
`

export {
  AppointmentListHeader,
  SeeAllLink,
}
