import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentModal } from 'components/modals/ContentModal/ContentModal'

import { KnowledgeBaseSchedule } from 'components/data/KnowledgeBaseSchedule/KnowledgeBaseSchedule'
import * as CommonStyled from '../AppointmentForm.styles'

interface KnowledgeBaseScheduleSectionProps {
  value?: number[]
  onValueChange?: (values: number[]) => void
  touched?: boolean
}

const KnowledgeBaseScheduleSection = ({
  value,
  onValueChange: handleValueChange = () => null,
  touched,
}: KnowledgeBaseScheduleSectionProps): JSX.Element => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const handleModalSave = (newValues: number[]) => {
    handleValueChange(newValues)
    setOpen(false)
  }

  return (
    <>
      <CommonStyled.ItemsContainer $touched={touched}>
        <CommonStyled.ItemsHeaderContainer>
          <CommonStyled.SectionTitle>
            {t('appointment.content')}
          </CommonStyled.SectionTitle>
          <CommonStyled.AddButton
            type='button'
            onClick={() => setOpen(true)}
          >
            {t('appointment.addContent')}
          </CommonStyled.AddButton>
        </CommonStyled.ItemsHeaderContainer>
        <KnowledgeBaseSchedule scheduleIds={value} />
      </CommonStyled.ItemsContainer>
      <ContentModal
        includeFolders
        initialValues={value}
        open={open}
        onClose={() => setOpen(false)}
        onSave={handleModalSave}
      />
    </>
  )
}

export { KnowledgeBaseScheduleSection }
