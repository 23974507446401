import { Typography } from '@material-ui/core'

import { Modal, ModalProps } from 'components/layout/Modal/Modal'
import * as Styled from './ConfirmModal.styles'

interface ConfirmModalProps extends Omit<ModalProps, 'children' | 'onClose'> {
  open: boolean
  title: string
  subText?: string
  errorText?: string
  onClose: () => void
  onSave: () => void
  isLoading?: boolean
  confirmLabel?: string
}

const ConfirmModal = ({
  open,
  title,
  subText,
  errorText = '',
  onClose: handleClose,
  onSave: handleSave,
  isLoading,
  confirmLabel,
}: ConfirmModalProps): JSX.Element => (
  <Styled.Modal
    open={open}
    onClose={handleClose}
  >
    <Modal.Title variant='h3'>
      {title}
    </Modal.Title>
    <Modal.Content>
      <Typography>
        {subText}
      </Typography>
      {errorText && (
        <Styled.ErrorText>
          {errorText}
        </Styled.ErrorText>
      )}
    </Modal.Content>
    <Modal.Actions
      onCancel={handleClose}
      onSave={() => handleSave()}
      isLoading={isLoading}
      confirmLabel={confirmLabel}
    />
  </Styled.Modal>
)

export { ConfirmModal }
export type { ConfirmModalProps }
