import styled from '@emotion/styled'
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@material-ui/core'
import { Check } from '@material-ui/icons'

import { Button } from 'components/inputs/Button/Button'

const Accordion = styled(MuiAccordion)`
  padding: ${({ theme }) => theme.spacing(1)}px;
  box-shadow: none;
  background-color: inherit;
`

const AccordionSummary = styled(MuiAccordionSummary)`
`

const SummaryContainer = styled.div`
  display: flex;
  align-items: center;
`

const AccordionDetails = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
`

const RedirectButton = styled(Button)`
  width: auto;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`

const StyledCheck = styled(Check)`
  margin: ${({ theme }) => theme.spacing(0, 2)};
`

export {
  Accordion,
  AccordionSummary,
  SummaryContainer,
  AccordionDetails,
  RedirectButton,
  StyledCheck as Check,
}
