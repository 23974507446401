import styled from '@emotion/styled'
import { Button } from 'components/inputs/Button/Button'

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(2.5)}px;
`

const CancelButton = styled(Button)`
  min-width: 100px;
  width: auto;
  padding: ${({ theme }) => theme.spacing(1, 3.5)};
`

const SaveButton = styled(Button)`
  min-width: 100px;
  width: auto;
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
`

export {
  ActionsContainer,
  CancelButton,
  SaveButton,
}
