import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

import { ReactComponent as BookSVG } from 'assets/images/address-book.svg'

const AddressBookIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <BookSVG />
  </SvgIcon>
)

export default AddressBookIcon
