import { useState } from 'react'
import constate from 'constate'

const useKnowledgeBaseSchedule = () => {
  const [isLoading, setIsLoading] = useState(false)

  return { isLoading, setIsLoading }
}

const [
  KnowledgeBaseScheduleProvider,
  useIsScheduleLoading,
  useSetScheduleLoading,
] = constate(
  useKnowledgeBaseSchedule,
  (value) => value.isLoading,
  (value) => value.setIsLoading,
)

export {
  KnowledgeBaseScheduleProvider,
  useIsScheduleLoading,
  useSetScheduleLoading,
}
