import { UseQueryResult } from 'react-query'

import {
  useGetQuizEntriesByQuizQuery,
  useGetQuizEntriesByUserQuery,
  useGetQuizzesByAppointmentQuery,
} from 'api/queries/results/results'
import {
  ResultsByQuizItem,
  QuizEntryItem
} from 'api/queries/results/types'
import { ResultsItemType } from 'components/data/ResultsList/types'

const mapResultsData = (
  resultsType: ResultsItemType,
  queryResult: UseQueryResult<QuizEntryItem[] | ResultsByQuizItem[], Error>
) => ({
  ...queryResult,
  data: queryResult.data?.map((item) => {
    switch (resultsType) {
      case ResultsItemType.APPOINTMENT:
        return ({
          type: ResultsItemType.QUIZ as const,
          ...item as ResultsByQuizItem
        })
      default:
        return ({
          type: ResultsItemType.ENTRY as const,
          ...item as QuizEntryItem
        })
    }
  })
})

const useResultsChildData = (
  id: number,
  resultsType: ResultsItemType,
) => {
  const entriesByQuiz = useGetQuizEntriesByQuizQuery(
    id,
    {
      enabled: resultsType === ResultsItemType.QUIZ,
    }
  )

  const entriesByUser = useGetQuizEntriesByUserQuery(
    id,
    {
      enabled: resultsType === ResultsItemType.USER,
    }
  )

  const quizzesByAppointment = useGetQuizzesByAppointmentQuery(
    id,
    {
      enabled: resultsType === ResultsItemType.APPOINTMENT,
    }
  )

  return mapResultsData(resultsType, resultsType === ResultsItemType.QUIZ
    ? entriesByQuiz : resultsType === ResultsItemType.USER
      ? entriesByUser : quizzesByAppointment)
}

export { useResultsChildData }
