import { useUserRole } from 'providers/UserContextProvider'
import { UserRole } from 'types/UserRole'

const useHasRole = (accessRoles: UserRole[] | string | undefined): boolean => {
  const userRole = useUserRole()

  if (!userRole) {
    return false
  }

  return accessRoles?.includes(userRole) || false
}

export { useHasRole }
