import { useMemo } from 'react'
import { useLocation, matchPath } from 'react-router-dom'

const authScreens = [
  '/sign-in',
  '/sign-up',
  '/reset-password',
  '/reset-password/:token',
]

const useIsAuthScreen = (): boolean => {
  const { pathname } = useLocation()
  const isAuthScreen = useMemo(
    () =>
      authScreens.some((screen) =>
        matchPath(pathname, {
          path: screen,
        })),
    [pathname]
  )
  return isAuthScreen
}

export { useIsAuthScreen }
