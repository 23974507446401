import { i18n } from 'i18next'
import { ApiError } from 'api/types'

export const getFieldErrorMessages = (i: i18n, error: ApiError) => {
  const fieldErrorMessages: string[] = []
  const fieldErrors = error.response?.data.fieldErrors || []
  fieldErrors.forEach(({ error: errorName }) => {
    if (i.exists(`apiErrors.${errorName}`)) {
      fieldErrorMessages.push(i.t(`apiErrors.${errorName}`))
    }
  })

  return fieldErrorMessages
}
