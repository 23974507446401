import { useTranslation } from 'react-i18next'
import { MenuItem } from '@material-ui/core'

import { Select, SelectProps } from 'components/inputs/Select/Select'
import { useGetLanguagesQuery } from 'api/queries/languages/languages'

import * as Styled from './LanguagesSelect.styled'

interface LanguagesSelectProps extends SelectProps {
  className?: string,
  without?: string[]
}

const LanguagesSelect = ({
  className,
  without = [],
  ...rest
}: LanguagesSelectProps) => {
  const { t } = useTranslation()

  const {
    data: languages,
    isError,
  } = useGetLanguagesQuery()

  const options = languages?.filter((language) => !without.includes(language.browserCode)) || []

  return (
    <Select
      className={className}
      {...rest}
    >
      {isError && (
        <Styled.ErrorMessage>
          {t('common.error')}
        </Styled.ErrorMessage>
      )}
      {options.map(({ id, browserCode }) => (
        <MenuItem key={id} value={browserCode}>
          {t('language.'.concat(browserCode))}
        </MenuItem>
      ))}
    </Select>
  )
}

export { LanguagesSelect }
