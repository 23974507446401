import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import { Accordion } from 'components/inputs/Accordion/Accordion'
import { useIsMobile } from 'hooks/useIsMobile'
import { ParticipantItem } from './ParticipantItem'
import * as CommonStyled from '../AppointmentContent.styles'

interface Participant {
  id: number
  firstName: string
  lastName: string
  email: string
}
interface ParticipantsSectionProps {
  participants?: Participant[]
}

const ParticipantsSection: React.FC<ParticipantsSectionProps> = ({ participants }) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const participantItems = participants?.map(
    ({ id, firstName, lastName, email }) => (
      <ParticipantItem
        key={id}
        firstName={firstName}
        lastName={lastName}
        email={email}
      />
    )
  )

  return (
    <>
      <CommonStyled.ContentLabel>
        {t('appointment.participants')}
      </CommonStyled.ContentLabel>
      {participants?.length ? (
        isMobile
          ? <Accordion items={participantItems} />
          : participantItems
      ) : (
        <Typography>
          {t('appointment.noParticipants')}
        </Typography>
      )}
    </>
  )
}

export type { Participant }
export { ParticipantsSection }
