/** @jsxImportSource @emotion/react */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Typography } from '@material-ui/core'

import { RestrictedContent } from 'components/auth/RestrictedContent/RestrictedContent'
import { TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE } from 'constants/roleGroups'
import { formatDate } from 'utils/formatDate'
import { formatTime } from 'utils/formatTime'
import { AppointmentsResponseContentItem } from 'api/queries/appointments/types'
import * as Styled from './AppointmentList.styles'

interface AppointmentListItemType extends Omit<AppointmentsResponseContentItem, 'archived'> {}

interface AppointmentListProps {
  appointments: AppointmentListItemType[]
  appointmentsBasePath: string
  isLoading?: boolean
  isArchived?: boolean
  emptyListText?: string
  emptyListSubtext?: string
}

const AppointmentList: React.FC<AppointmentListProps> = ({
  appointments,
  appointmentsBasePath,
  isLoading = false,
  isArchived = false,
  emptyListText = '',
  emptyListSubtext = ''
}) => {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <Styled.NoAppointmentsContainer>
        <CircularProgress />
      </Styled.NoAppointmentsContainer>
    )
  }

  const emptyText = emptyListText
  || (!isArchived ? t('appointments.emptyNext') : t('appointments.emptyArchived'))

  return (
    <>
      {appointments.length ? (
        appointments.map(({
          id,
          name,
          startDate,
          open
        }, index) => (
          <Styled.AppointmentListItem
            key={id}
            redirectPath={`/${appointmentsBasePath}/${id}`}
            title={name}
            date={formatDate(startDate)}
            time={formatTime(startDate)}
            isArchived={isArchived}
            isOpen={open}
            $isFirst={!!index}
          />
        ))) : (
          <Styled.NoAppointmentsContainer>
            <Typography variant='h2' color='primary'>
              {emptyText}
            </Typography>
            <RestrictedContent accessRoles={[TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE]}>
              <Typography variant='subtitle1' color='textSecondary'>
                {emptyListSubtext}
              </Typography>
            </RestrictedContent>
          </Styled.NoAppointmentsContainer>
      )}
    </>
  )
}

export { AppointmentList }
export type { AppointmentListItemType }
