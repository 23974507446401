import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import { DidacticMaterial } from 'types/DidacticMaterial'
import { DidacticMaterialsSectionItem } from './DidacticMaterialsSectionItem'
import * as CommonStyled from '../AppointmentContent.styles'

interface DidacticMaterialsSectionProps {
  didacticMaterials?: DidacticMaterial[]
}

const DidacticMaterialsSection: React.FC<DidacticMaterialsSectionProps> = ({
  didacticMaterials,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <CommonStyled.TopLabel>{t('appointment.additionalMaterials')}</CommonStyled.TopLabel>
      {didacticMaterials?.length ? (
        didacticMaterials.map(({ id, name, content, type }) => (
          <DidacticMaterialsSectionItem
            key={id}
            id={id}
            name={name}
            link={content as string}
            type={type}
          />
        ))
      ) : (
        <Typography>{t('appointment.noAdditionalMaterials')}</Typography>
      )}
    </>
  )
}

export type { DidacticMaterial }
export { DidacticMaterialsSection }
