import React from 'react'
import { useTranslation } from 'react-i18next'

import { KnowledgeBaseSchedule } from 'components/data/KnowledgeBaseSchedule/KnowledgeBaseSchedule'
import * as CommonStyled from '../AppointmentContent.styles'

interface KnowledgeBaseScheduleSectionProps {
  scheduleIds?: number[]
}

const KnowledgeBaseScheduleSection: React.FC<KnowledgeBaseScheduleSectionProps> = ({
  scheduleIds = [],
}) => {
  const { t } = useTranslation()

  return (
    <>
      <CommonStyled.ContentLabel>
        {t('appointment.content')}
      </CommonStyled.ContentLabel>
      <KnowledgeBaseSchedule scheduleIds={scheduleIds} />
    </>
  )
}

export { KnowledgeBaseScheduleSection }
