import React from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem } from '@material-ui/core'
import difference from 'lodash/difference'

import { EducationLevel } from 'types/EducationLevel'
import { Select, SelectProps } from 'components/inputs/Select/Select'

interface EducationLevelSelectProps extends SelectProps {
  without?: EducationLevel[]
}

const EducationLevelSelect: React.FC<EducationLevelSelectProps> = ({
  without = [],
  ...rest
}) => {
  const { t } = useTranslation()

  const educationTypes: EducationLevel[] = difference(Object.values(EducationLevel), without)

  return (
    <Select
      displayEmpty
      {...rest}
    >
      <MenuItem disabled value=''>
        {t('common.educationLevel')}
      </MenuItem>
      {educationTypes.map((level) => (
        <MenuItem key={level} value={level}>
          {t(`educationLevels.${level}`)}
        </MenuItem>
      ))}
    </Select>
  )
}

export { EducationLevelSelect }
