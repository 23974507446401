import * as yup from 'yup'
import { isBefore, isPast, isToday } from 'date-fns'

interface User {
  id: number
  firstName: string
  lastName: string
}

const teacherSchema = yup
  .object().shape({
    id: yup.number(),
    firstName: yup.string(),
    lastName: yup.string(),
  }).test('teachersTest', (value, testCtx) => {
    const { options: { context } } = testCtx
    const participants = context?.participants as User[]
    const open = context?.open as boolean

    if (!value.firstName || !value.id || !value.lastName) {
      return testCtx.createError({ message: 'appointment.errors.lecturers.required' })
    }

    return !open && participants.some((participant) => value.id === participant.id) ? (
      testCtx.createError({ message: 'appointment.errors.lecturers.selectedParticipant' })
    ) : true
  })

const participantsSchema = yup.array().of(
  yup.object().shape({
    id: yup.number(),
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string(),
  }).test('participantsTest', (value, testCtx) => {
    const { options: { context } } = testCtx
    const teacher = context?.teacher as User
    const open = context?.open as boolean

    return !open && teacher?.id === value.id ? (
      testCtx.createError({ message: 'appointment.errors.participants.selectedTeacher' })
    ) : true
  })
)

const CreateOrEditAppointmentSchema = yup.object().shape({
  name: yup.string().required('appointment.errors.name'),
  dateTime: yup.object().shape({
    date: yup
      .string()
      .test('is-past', 'appointment.errors.dateTime.date.invalid', (value) => {
        if (value) {
          const date = new Date(value)
          return isToday(date) || !isPast(date)
        }
        return true
      }),
    startTime: yup
      .string()
      .test('is-before', 'appointment.errors.dateTime.time.invalid', (value, testCtx) => {
        const { options: { context } } = testCtx
        const endTime = context?.dateTime.endTime
        if (value && endTime) {
          const startDate = new Date(value)
          const endDate = new Date(endTime)
          return isBefore(startDate, endDate)
        }
        return true
      }),
    endTime: yup
      .string()
      .test('ends-in-past', 'appointment.errors.dateTime.time.invalid', (value, testCtx) => {
        const { options: { context } } = testCtx
        const endDateValue = context?.dateTime.date
        if (endDateValue && value) {
          const endDateTime = new Date(endDateValue)
          const valueDate = new Date(value)
          endDateTime.setHours(valueDate.getHours(), valueDate.getMinutes(), 0, 0)
          return !isPast(endDateTime)
        }
        return true
      }),
  }),
  teacher: teacherSchema,
  description: yup.string(),
  didacticMaterials: yup.array().of(yup.object()),
  participants: participantsSchema
})

export { CreateOrEditAppointmentSchema }
