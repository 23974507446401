import { ComponentProps, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useTheme } from '@material-ui/core/styles'

import { EducationLevel } from 'types/EducationLevel'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

interface ChartProps {
  title?: string
  label: string
  data: number[]
}

const Chart = ({
  title,
  label,
  data,
}: ChartProps) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const options: ComponentProps<typeof Bar>['options'] = useMemo(() => ({
    responsive: true,
    scales: {
      yAxes: {
        suggestedMin: 0,
        suggestedMax: 100,
      },
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: !!title,
        text: title,
      },
    },
  }), [title])

  const labels = useMemo(() => (
    Object.keys(EducationLevel).map((level) => t(`educationLevels.${level}`))
  ), [t])

  return (
    <Bar
      options={options}
      data={{
        labels,
        datasets: [{
          label,
          data,
          backgroundColor: theme.palette.primary.main,
        }]
      }}
    />
  )
}

export { Chart }
