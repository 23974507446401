import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import Axios from 'axios'

import { REFRESH_TOKEN_QUERY_KEY } from 'api/queriesKeys'
import { RefreshResponse } from './types'

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
})

const useRefreshTokenQuery = (options?: Omit<UseQueryOptions<RefreshResponse, Error>, 'queryKey'>)
: UseQueryResult<RefreshResponse, Error> => (
  useQuery(
    REFRESH_TOKEN_QUERY_KEY,
    () => axios
      .get('/token/refresh')
      .then((response) => response.data),
    options
  )
)

export { useRefreshTokenQuery }
