import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

import { useFetch } from 'providers/FetchProvider'
import {
  COMPANY_RESULTS_BY_APPOINTMENT_QUERY_KEY,
  COMPANY_RESULTS_BY_QUIZ_QUERY_KEY,
  COMPANY_RESULTS_BY_USER_QUERY_KEY,
  QUIZZES_BY_APPOINTMENT_QUERY_KEY,
  QUIZ_ENTRIES_BY_QUIZ_QUERY_KEY,
  QUIZ_ENTRIES_BY_USER_QUERY_KEY,
  RESULTS_BY_APPOINTMENT_QUERY_KEY,
  RESULTS_BY_QUIZ_QUERY_KEY,
  RESULTS_BY_USER_QUERY_KEY,
  HISTORY_QUESTION_QUERY_KEY,
  HISTORY_QUIZ_QUERY_KEY
} from './keys'
import {
  ResultsByQuizItem,
  ResultsByQuizResponse,
  ResultsByUserResponse,
  ResultsByAppointmentResponse,
  QuizEntryItem,
  HistoryQuestion,
  HistoryQuiz
} from './types'

const RESULTS_PAGE_SIZE = 12

const useGetResultsByQuizQuery = (
  nameFilter?: string,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<ResultsByQuizResponse, Error>, 'queryKey'>
) : UseInfiniteQueryResult<ResultsByQuizResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    [RESULTS_BY_QUIZ_QUERY_KEY, { nameFilter }],
    ({ pageParam }) => fetch.get('/results/quiz', {
      params: {
        nameFilter,
        page: pageParam,
        size: RESULTS_PAGE_SIZE,
      },
    }).then((response) => response.data),
    useQueryOptions,
  )
}

const useGetCompanyResultsByQuizQuery = (
  id: number,
  nameFilter?: string,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<ResultsByQuizResponse, Error>, 'queryKey'>
) : UseInfiniteQueryResult<ResultsByQuizResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    [COMPANY_RESULTS_BY_QUIZ_QUERY_KEY, { id, nameFilter }],
    ({ pageParam }) => fetch.get(`/results/quiz/company/${id}`, {
      params: {
        nameFilter,
        page: pageParam,
        size: RESULTS_PAGE_SIZE,
      },
    }).then((response) => response.data),
    useQueryOptions,
  )
}

const useGetQuizEntriesByQuizQuery = (
  id: number,
  useQueryOptions?: Omit<UseQueryOptions<QuizEntryItem[], Error>, 'queryKey'>
): UseQueryResult<QuizEntryItem[], Error> => {
  const { fetch } = useFetch()

  return useQuery(
    [QUIZ_ENTRIES_BY_QUIZ_QUERY_KEY, { id }],
    () => fetch.get(`results/quiz/${id}/quiz-entries`).then((response) => response.data),
    useQueryOptions
  )
}

const useGetResultsByUserQuery = (
  nameFilter?: string,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<ResultsByUserResponse, Error>, 'queryKey'>
) : UseInfiniteQueryResult<ResultsByUserResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    [RESULTS_BY_USER_QUERY_KEY, { nameFilter }],
    ({ pageParam }) => fetch.get('/results/user', {
      params: {
        nameFilter,
        page: pageParam,
        size: RESULTS_PAGE_SIZE,
      },
    }).then((response) => response.data),
    useQueryOptions,
  )
}

const useGetCompanyResultsByUserQuery = (
  id: number,
  nameFilter?: string,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<ResultsByUserResponse, Error>, 'queryKey'>
) : UseInfiniteQueryResult<ResultsByUserResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    [COMPANY_RESULTS_BY_USER_QUERY_KEY, { id, nameFilter }],
    ({ pageParam }) => fetch.get(`/results/user/company/${id}`, {
      params: {
        nameFilter,
        page: pageParam,
        size: RESULTS_PAGE_SIZE,
      },
    }).then((response) => response.data),
    useQueryOptions,
  )
}

const useGetQuizEntriesByUserQuery = (
  id: number,
  useQueryOptions?: Omit<UseQueryOptions<QuizEntryItem[], Error>, 'queryKey'>
): UseQueryResult<QuizEntryItem[], Error> => {
  const { fetch } = useFetch()

  return useQuery(
    [QUIZ_ENTRIES_BY_USER_QUERY_KEY, { id }],
    () => fetch.get(`results/user/${id}/quiz-entries`).then((response) => response.data),
    useQueryOptions
  )
}

const useGetResultsByAppointmentQuery = (
  nameFilter?: string,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<ResultsByAppointmentResponse, Error>, 'queryKey'>
) : UseInfiniteQueryResult<ResultsByAppointmentResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    [RESULTS_BY_APPOINTMENT_QUERY_KEY, { nameFilter }],
    ({ pageParam }) => fetch.get('/results/meeting', {
      params: {
        nameFilter,
        page: pageParam,
        size: RESULTS_PAGE_SIZE,
      },
    }).then((response) => response.data),
    useQueryOptions,
  )
}

const useGetCompanyResultsByAppointmentQuery = (
  id: number,
  nameFilter?: string,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<ResultsByAppointmentResponse, Error>, 'queryKey'>
) : UseInfiniteQueryResult<ResultsByAppointmentResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    [COMPANY_RESULTS_BY_APPOINTMENT_QUERY_KEY, { id, nameFilter }],
    ({ pageParam }) => fetch.get(`/results/meeting/company/${id}`, {
      params: {
        nameFilter,
        page: pageParam,
        size: RESULTS_PAGE_SIZE,
      },
    }).then((response) => response.data),
    useQueryOptions,
  )
}

const useGetQuizzesByAppointmentQuery = (
  id: number,
  useQueryOptions?: Omit<UseQueryOptions<ResultsByQuizItem[], Error>, 'queryKey'>
): UseQueryResult<ResultsByQuizItem[], Error> => {
  const { fetch } = useFetch()

  return useQuery(
    [QUIZZES_BY_APPOINTMENT_QUERY_KEY, { id }],
    () => fetch.get(`results/meeting/${id}/quizzes`).then((response) => response.data),
    useQueryOptions
  )
}

const useGetHistoryQuizQuery = (
  id: number,
  options?: Omit<UseQueryOptions<HistoryQuiz, Error>, 'queryKey'>
): UseQueryResult<HistoryQuiz, Error> => {
  const { fetch } = useFetch()

  return useQuery(
    [HISTORY_QUIZ_QUERY_KEY, { id }],
    () => fetch.get(`/results/quiz-entry/${id}`).then((response) => response.data),
    options,
  )
}

const useGetHistoryQuestionQuery = (
  quizId: number,
  questionId: number,
  options?: Omit<UseQueryOptions<HistoryQuestion, Error>, 'queryKey'>
): UseQueryResult<HistoryQuestion, Error> => {
  const { fetch } = useFetch()

  return useQuery(
    [HISTORY_QUESTION_QUERY_KEY, { quizId, questionId }],
    () => fetch.get(`/results/quiz-entry/${quizId}/question/${questionId}`)
      .then((response) => response.data),
    options,
  )
}

export {
  useGetResultsByQuizQuery,
  useGetCompanyResultsByQuizQuery,
  useGetQuizEntriesByQuizQuery,
  useGetResultsByUserQuery,
  useGetCompanyResultsByUserQuery,
  useGetQuizEntriesByUserQuery,
  useGetResultsByAppointmentQuery,
  useGetCompanyResultsByAppointmentQuery,
  useGetQuizzesByAppointmentQuery,
  useGetHistoryQuizQuery,
  useGetHistoryQuestionQuery
}
