import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
`

const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100vh;
  max-height: -webkit-fill-available;
`

export {
  Container,
  ContentContainer
}
