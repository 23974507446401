import styled from '@emotion/styled'

import { Link } from 'components/inputs/Link/Link'

const DidacticMaterialItem = styled.div`
  display: flex;
  align-items: baseline;
`

const DownloadLink = styled(Link)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`

export {
  DidacticMaterialItem,
  DownloadLink,
}
