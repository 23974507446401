import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { RestrictedRoute } from 'components/auth/RestrictedRoute/RestrictedRoute'
import { useIsAuthenticated } from 'providers/UserContextProvider'
import ResetPasswordScreen from 'screens/auth/ResetPasswordScreen'
import SendResetEmailScreen from 'screens/auth/SendResetEmailScreen'
import SignInScreen from 'screens/auth/SignInScreen'
import AcceptPrivacyPolicyScreen from 'screens/auth/AcceptPrivacyPolicyScreen/AcceptPrivacyPolicyScreen'
import HomeScreen from 'screens/home/HomeScreen'
import KnowledgeBaseScreen from 'screens/KnowledgeBase/KnowledgeBaseScreen/KnowledgeBaseScreen'
import UsersScreen from 'screens/users/UsersScreen'
import AccountScreen from 'screens/account/AccountScreen'
import { PARTICIPANT_ROLE, TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE } from 'constants/roleGroups'
import AppointmentsScreen from 'screens/appointments/AppointmentsScreen'
import PreviewAppointmentScreen from 'screens/appointments/details/PreviewAppointmentScreen'
import EditAppointmentScreen from 'screens/appointments/details/EditAppointmentScreen'
import CreateAppointmentScreen from 'screens/appointments/details/CreateAppointmentScreen'
import { AppointmentDetailedListScreen } from 'screens/appointments/AppointmentsDetailed/AppointmentDetailedListScreen'
import CoursesScreen from 'screens/courses/CoursesScreen'
import { KnowledgeBaseContentScreen } from 'screens/KnowledgeBase/KnowledgeBaseContentScreen/KnowledgeBaseContentScreen'
import { KnowledgeBaseContentEditScreen } from 'screens/KnowledgeBase/KnowledgeBaseContentEditScreen/KnowledgeBaseContentEditScreen'
import { KnowledgeBaseQuestionCreateScreen } from 'screens/KnowledgeBase/KnowledgeBaseQuestionCreateScreen/KnowledgeBaseQuestionCreateScreen'
import { KnowledgeBaseQuestionEditScreen } from 'screens/KnowledgeBase/KnowledgeBaseQuestionEditScreen/KnowledgeBaseQuestionEditScreen'
import { KnowledgeBaseQuestionListScreen } from 'screens/KnowledgeBase/KnowledgeBaseQuestionListScreen/KnowledgeBaseQuestionListScreen'
import { KnowledgeBaseContentCreateScreen } from 'screens/KnowledgeBase/KnowledgeBaseContentCreateScreen/KnowledgeBaseContentCreateScreen'
import { CompaniesScreen } from 'screens/companies/CompaniesScreen'
import { EmailConfirmScreen } from 'screens/account/EmailConfirm/EmailConfirmScreen'
import ResultsScreen from 'screens/results/ResultsScreen'
import QuizHistoryScreen from 'screens/results/QuizHistory/QuizHistoryScreen'
import { RankingScreen } from 'screens/results/RankingScreen/RankingScreen'
import StatisticsScreen from 'screens/statistics/StatisticsScreen'
import StatisticsDetailScreen from 'screens/statistics/StatisticsDetailScreen'
import StatisticsDetailQuestionScreen from 'screens/statistics/StatisticsDetailQuestionScreen'
import AnalysisScreen from 'screens/analysis/AnalysisScreen'
import { KB_CONTENT_ROUTES } from 'constants/knowledgeBase'

const Routes: React.FC = () => {
  const isLoggedIn = useIsAuthenticated()

  return (
    <Switch>
      <Route exact path='/sign-in'>
        {isLoggedIn ? <Redirect to='/' /> : <SignInScreen />}
      </Route>
      <Route exact path='/reset-password'>
        {isLoggedIn ? <Redirect to='/' /> : <SendResetEmailScreen />}
      </Route>
      <Route exact path='/reset-password/:token'>
        <ResetPasswordScreen />
      </Route>
      <Route exact path='/email-confirm/:token'>
        <EmailConfirmScreen />
      </Route>
      <Route exact path='/privacy-policy/'>
        <AcceptPrivacyPolicyScreen />
      </Route>
      <RestrictedRoute
        accessRoles={[PARTICIPANT_ROLE, TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE]}
        exact
        path='/'
      >
        <HomeScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[PARTICIPANT_ROLE, TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE]}
        exact
        path={['/knowledge-base', '/knowledge-base(/group/\\d+)*/group/:id']}
      >
        <KnowledgeBaseScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[PARTICIPANT_ROLE, TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE]}
        exact
        path={`/knowledge-base(/group/\\d+)*/:type${KB_CONTENT_ROUTES}/:id`}
      >
        <KnowledgeBaseContentScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[ADMIN_ROLE]}
        exact
        path={`/knowledge-base(/group/\\d+)*/:type${KB_CONTENT_ROUTES}/:id/edit`}
      >
        <KnowledgeBaseContentEditScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[ADMIN_ROLE]}
        exact
        path='/knowledge-base(/group/\d+)*/quiz/:quizId/edit/questions'
      >
        <KnowledgeBaseQuestionListScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[ADMIN_ROLE]}
        exact
        path='/knowledge-base(/group/\d+)*/quiz/:quizId/edit/questions/create'
      >
        <KnowledgeBaseQuestionCreateScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[ADMIN_ROLE]}
        exact
        path='/knowledge-base(/group/\d+)*/quiz/:quizId/edit/questions/:questionId'
      >
        <KnowledgeBaseQuestionEditScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[ADMIN_ROLE]}
        exact
        path={['/knowledge-base/create', '/knowledge-base(/group/\\d+)*/group/:id/create']}
      >
        <KnowledgeBaseContentCreateScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[COMPANY_ROLE, ADMIN_ROLE]}
        exact
        path='/users'
      >
        <UsersScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE]}
        exact
        path='/courses'
      >
        <CoursesScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE]}
        exact
        path='/appointments/create'
      >
        <CreateAppointmentScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[PARTICIPANT_ROLE, TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE]}
        exact
        path='/appointments/detailed/:type'
      >
        <AppointmentDetailedListScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE]}
        exact
        path='/appointments/:id/edit'
      >
        <EditAppointmentScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[PARTICIPANT_ROLE, TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE]}
        exact
        path='/appointments/:id/:openForum?'
      >
        <PreviewAppointmentScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[PARTICIPANT_ROLE, TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE]}
        exact
        path='/appointments'
      >
        <AppointmentsScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[PARTICIPANT_ROLE, TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE]}
        path='/account'
      >
        <AccountScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[COMPANY_ROLE, ADMIN_ROLE]}
        exact
        path='/results'
      >
        <ResultsScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[COMPANY_ROLE, ADMIN_ROLE]}
        path='/results/quiz/:id'
      >
        <QuizHistoryScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[COMPANY_ROLE, ADMIN_ROLE]}
        path='/results/ranking'
      >
        <RankingScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[COMPANY_ROLE, ADMIN_ROLE]}
        exact
        path='/statistics'
      >
        <StatisticsScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[COMPANY_ROLE, ADMIN_ROLE]}
        exact
        path='/statistics/:quizId'
      >
        <StatisticsDetailScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[COMPANY_ROLE, ADMIN_ROLE]}
        path='/statistics/:quizId/:questionId'
      >
        <StatisticsDetailQuestionScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[COMPANY_ROLE, ADMIN_ROLE]}
        exact
        path='/analysis'
      >
        <AnalysisScreen />
      </RestrictedRoute>
      <RestrictedRoute
        accessRoles={[ADMIN_ROLE]}
        path='/companies'
      >
        <CompaniesScreen />
      </RestrictedRoute>
      <Route path='*'>
        <Redirect to='/sign-in' />
      </Route>
    </Switch>
  )
}

export { Routes }
