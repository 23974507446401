import styled from '@emotion/styled/macro'
import { css } from '@emotion/react'
import { Chip, LinearProgress, Typography } from '@material-ui/core'
import { Link } from 'components/inputs/Link/Link'

interface RootContainerProps {
  $gradientBackground?: boolean
  $isArchived?: boolean
}

const Title = styled(Typography)`
  ${({ theme }) => `
    margin: ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px 0;
  `}
  line-height: 1;
`

const RootLink = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.text.primary};
    text-decoration: none;
    cursor: pointer;
    opacity: 1;

    &:hover, &:focus {
      opacity: 0.75;
      ${Title} {
        text-decoration: underline;
      }
    }
  `}
`

const RootContainer = styled.div<RootContainerProps>`
  ${({ theme, $isArchived, $gradientBackground }) => css`
    border: 3px solid ${theme.palette.primary.main};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    ${$gradientBackground && `
      background: ${theme.palette.gradient.main};
      *, p, a {
        color: ${theme.palette.secondary.contrastText};
      }
    `}

    ${$isArchived && `border-color: ${theme.palette.disabled?.main}`};

    ${theme.breakpoints.down('sm')} {
      align-items: center;
    }
  `}
`

const TopContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing(1.5, 2.5)};
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      flex-direction: column-reverse;
    }
  `}
`

const OpenChip = styled(Chip)`
  ${({ theme }) => css`
    color: #fff;
    background-image: ${theme.palette.gradient.main};
    pointer-events: none;

    ${theme.breakpoints.down('sm')} {
      align-self: flex-start;
    }
  `}
`

const BottomContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `}
`

const DateTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(0, 2.5, 2.5)};
`

const DateText = styled(Typography)`
  ${({ theme }) => css`
    line-height: 1;

    ${theme.breakpoints.down('sm')} {
      margin-left: 0px;
    }
  `}
`

const TimeText = styled(Typography)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing(2.5)}px;
    line-height: 1;
    color: ${theme.palette.primary.main};

    ${theme.breakpoints.down('sm')} {
      margin-right: 0px;
    }
  `}
`

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  width: 100%;
  margin: ${({ theme }) => theme.spacing(0, 1)};
`

const ProgressBar = styled(LinearProgress)`
  ${({ theme }) => css`
    height: 15px;
    width: 100%;
    border: 3px solid  ${theme.palette.secondary.contrastText};
    border-radius: 10px;
    background-color: ${theme.palette.secondary.contrastText};
    box-shadow: 0 0 0 1px ${theme.palette.primary.main};

    & .MuiLinearProgress-bar {
      border-radius: 10px;
    }
  `}
`

const ProgressBarInfoTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
  line-height: 1;
  font-size: 12px;
  font-weight: bold;
`

const RedirectLink = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    height: 20px;
    margin: ${theme.spacing(0, 2.5, 2.5)};
    line-height: 1;
    color: ${theme.palette.text.primary};

    ${theme.breakpoints.down('sm')} {
      margin-top: ${theme.spacing(2.5)}px;
    }
  `}
`

export {
  RootLink,
  RootContainer,
  Title,
  TopContainer,
  OpenChip,
  BottomContainer,
  DateTimeContainer,
  DateText,
  TimeText,
  ProgressBarContainer,
  ProgressBar,
  ProgressBarInfoTextContainer,
  RedirectLink,
}
