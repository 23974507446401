import React from 'react'
import { useTranslation } from 'react-i18next'
import { SelectProps } from '@material-ui/core'

import { SortType } from 'types/SortType'
import { ALPHABETICAL, ALPHABETICAL_REVERSE, NEWEST, NONE, OLDEST } from 'constants/sortTypes'
import { Select } from 'components/inputs/Select/Select'
import * as Styled from './SortSelect.styles'

interface SortSelectProps extends SelectProps {
  className?: string
  withoutNone?: boolean
}

const SortSelect: React.FC<SortSelectProps> = ({
  className,
  withoutNone,
  ...rest
}) => {
  const { t } = useTranslation()

  const SortTypes: SortType[] = [ALPHABETICAL, ALPHABETICAL_REVERSE, NEWEST, OLDEST]
  if (!withoutNone) {
    SortTypes.unshift(NONE)
  }

  return (
    <Select
      label={t('common.sort')}
      className={className}
      {...rest}
    >
      {SortTypes.map((type) => (
        <Styled.MenuItem key={type} value={type}>
          {t(`sort.${[type]}`)}
        </Styled.MenuItem>
      ))}
    </Select>
  )
}

export { SortSelect }
