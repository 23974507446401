import { useCallback } from 'react'

import { CardsListItemType } from 'components/data/CardsList/CardsList'
import { ALPHABETICAL_REVERSE, NEWEST, OLDEST } from 'constants/sortTypes'
import { SortType } from 'types/SortType'

type UseSortCardItemsReturnType = (
  CardItemA: CardsListItemType,
  CardItemB: CardsListItemType,
) => number

const useSortCardItems = (
  sortType: SortType,
): UseSortCardItemsReturnType => {
  const sortTypeFunction = useCallback((CardItemA, CardItemB) => {
    const dateDifference = (
      new Date(CardItemA.creationDateTime).valueOf()
      - new Date(CardItemB.creationDateTime).valueOf()
    )

    switch (sortType) {
      case ALPHABETICAL_REVERSE:
        if (CardItemA.name > CardItemB.name) { return -1 }
        if (CardItemA.name < CardItemB.name) { return 1 }
        return 0
      case NEWEST:
        return -dateDifference
      case OLDEST:
        return dateDifference
      default:
        if (CardItemA.name < CardItemB.name) { return -1 }
        if (CardItemA.name > CardItemB.name) { return 1 }
        return 0
    }
  }, [sortType])

  return sortTypeFunction
}

export { useSortCardItems }
