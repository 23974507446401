import styled from '@emotion/styled'

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export { LoaderContainer }
