import styled from '@emotion/styled'
import { Typography, TypographyProps } from '@material-ui/core'

import { SearchFilterInput } from 'components/inputs/SearchFilterInput/SearchFilterInput'

const SearchFilterContainer = styled.div`
  display: flex;
  align-items: center;
`

const SearchLabel = styled((props: TypographyProps) => (
  <Typography {...props} />
))`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`

const SearchFilter = styled(SearchFilterInput)`
  margin: 0;
`

const LoaderContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 4)};
`

const HelperContainer = styled.div`
  display: flex;
  justify-content: center;
`

export {
  SearchFilterContainer,
  SearchLabel,
  SearchFilter,
  LoaderContainer,
  HelperContainer
}
