import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

import { useFetch } from 'providers/FetchProvider'
import { GET_ANALYSIS_QUERY_KEY } from 'api/queriesKeys'
import { AnalysisItem } from 'api/types'
import { AnalysisScopeType } from 'types/AnalysisScopeType'

const ANALYSIS_PAGE_SIZE = 10

const useGetAnalysisFromOwnCompany = (
  analysisSortBy: AnalysisScopeType,
  useQueryOptions?: Omit<UseQueryOptions<AnalysisItem[], Error>, 'queryKey'>
) : UseQueryResult<AnalysisItem[], Error> => {
  const { fetch } = useFetch()

  return useQuery(
    [GET_ANALYSIS_QUERY_KEY, { analysisSortBy }],
    () => fetch.get('/analysis', {
      params: {
        size: ANALYSIS_PAGE_SIZE,
        analysisSortBy
      },
    }).then((response) => response.data),
    useQueryOptions,
  )
}

const useGetAnalysisFromCompanyId = (
  companyId: number,
  analysisSortBy: AnalysisScopeType,
  useQueryOptions?: Omit<UseQueryOptions<AnalysisItem[], Error>, 'queryKey'>
) : UseQueryResult<AnalysisItem[], Error> => {
  const { fetch } = useFetch()

  return useQuery(
    [GET_ANALYSIS_QUERY_KEY, { analysisSortBy, companyId }],
    () => fetch.get('/analysis/admin', {
      params: {
        size: ANALYSIS_PAGE_SIZE,
        analysisSortBy,
        companyId
      },
    }).then((response) => response.data),
    useQueryOptions,
  )
}

export {
  useGetAnalysisFromOwnCompany,
  useGetAnalysisFromCompanyId,
}
