import { useTranslation } from 'react-i18next'

import * as Styled from './ImagePreview.styles'

interface ImagePreviewProps {
  imageSrc?: string
  className?: string
}

const ImagePreview = ({
  imageSrc,
  className,
}: ImagePreviewProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    imageSrc ? (
      <Styled.ImagePreview
        className={className}
        src={imageSrc}
        alt='preview'
      />
    ) : (
      <Styled.ImagePlaceholder className={className}>
        <Styled.PlaceholderText>
          {t('knowledgeBase.emptyPhoto')}
        </Styled.PlaceholderText>
      </Styled.ImagePlaceholder>
    )
  )
}

export { ImagePreview }
