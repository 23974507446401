import { useEffect, MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik, FormikHelpers } from 'formik'

import { useIsSmallScreen } from 'hooks/useIsSmallScreen'
import { CreateCompanySchema } from 'validations/CreateCompanySchema'
import { ManageListItem } from 'components/inputs/ManageListItem/ManageListItem'
import * as Styled from './CompaniesListItem.styles'

interface CompaniesListItemType {
  id?: number
  name: string
  email: string
  phoneNumber: string
  nip: string
  storageSpaceLimit: number
  active: boolean
}

interface CompaniesListItemProps {
  id?: number
  initialValues?: CompaniesListItemType
  isNew?: boolean
  onSubmit: (
    values: CompaniesListItemType,
    helpers: FormikHelpers<CompaniesListItemType>
  ) => void
  onCancel: () => void
  onActivate?: (id: number, isActive: boolean) => void
  onEdit?: (company: CompaniesListItemType) => void
  className?: string
}

const defaultValues = {
  name: '',
  email: '',
  phoneNumber: '',
  nip: '',
  storageSpaceLimit: -1,
  active: true,
}

enum Mode { DISPLAY, EDIT }

const CompaniesListItem: React.FC<CompaniesListItemProps> = ({
  id,
  initialValues = defaultValues,
  isNew,
  onSubmit,
  onCancel: handleCancel,
  onActivate: handleActivate,
  onEdit: handleEdit,
  className
}) => {
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  const [mode, setMode] = useState<Mode>(isNew ? Mode.EDIT : Mode.DISPLAY)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => setMenuAnchorEl(event.currentTarget)

  const handleMenuClose = () => setMenuAnchorEl(null)

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    values,
    errors,
    touched,
    dirty,
  } = useFormik<CompaniesListItemType>(
    {
      initialValues,
      validationSchema: CreateCompanySchema,
      onSubmit,
      enableReinitialize: true
    }
  )

  useEffect(() => {
    setMode(isNew ? Mode.EDIT : Mode.DISPLAY)
  }, [initialValues, isNew])

  const handleClickSave = () => {
    if (isNew) {
      handleSubmit()
    } else if (id) {
      handleEdit?.({ id, ...values })
    }
    setMode(Mode.DISPLAY)
    handleMenuClose()
  }

  const handleClickCancel = () => {
    handleCancel()
    setMode(Mode.DISPLAY)
    resetForm()
    handleMenuClose()
  }

  const handleClickActivate = () => {
    if (id) {
      handleActivate?.(id, !values.active)
    }
    handleMenuClose()
  }

  return (
    <ManageListItem active={values.active} className={className}>
      <ManageListItem.FieldsContainer $rows={7}>
        {isSmallScreen && (
          <>
            <ManageListItem.HeaderText variant='subtitle1'>
              {t('common.id')}
            </ManageListItem.HeaderText>
            <ManageListItem.HeaderText variant='subtitle1'>
              {t('companies.name')}
            </ManageListItem.HeaderText>
            <ManageListItem.HeaderText variant='subtitle1'>
              {t('common.email')}
            </ManageListItem.HeaderText>
            <ManageListItem.HeaderText variant='subtitle1'>
              {t('common.phoneNumber')}
            </ManageListItem.HeaderText>
            <ManageListItem.HeaderText variant='subtitle1'>
              {t('companies.nip')}
            </ManageListItem.HeaderText>
            <ManageListItem.HeaderText variant='subtitle1'>
              {t('companies.storageSpaceLimit')}
            </ManageListItem.HeaderText>
            <ManageListItem.HeaderText variant='subtitle1'>
              {t('common.actions')}
            </ManageListItem.HeaderText>
          </>
        )}
        <Styled.IdText>
          {id}
        </Styled.IdText>
        <ManageListItem.TextField
          name='name'
          value={values.name}
          placeholder={t('companies.name')}
          disabled={mode === Mode.DISPLAY}
          touched={touched.name}
          errorMessage={t(errors.name || '')}
          onChange={handleChange}
          onBlur={handleBlur}
          $active={values.active}
          $border={mode === Mode.EDIT}
        />
        <ManageListItem.TextField
          name='email'
          value={values.email}
          placeholder={t('common.email')}
          disabled={mode === Mode.DISPLAY}
          touched={touched.email}
          errorMessage={t(errors.email || '')}
          onChange={handleChange}
          onBlur={handleBlur}
          $active={values.active}
          $border={mode === Mode.EDIT}
          $flexValue={1.5}
        />
        <ManageListItem.TextField
          name='phoneNumber'
          value={values.phoneNumber}
          placeholder={t('common.phoneNumber')}
          disabled={mode === Mode.DISPLAY}
          touched={touched.phoneNumber}
          errorMessage={t(errors.phoneNumber || '')}
          onChange={handleChange}
          onBlur={handleBlur}
          $active={values.active}
          $border={mode === Mode.EDIT}
          $flexValue={1.25}
        />
        <ManageListItem.TextField
          name='nip'
          value={values.nip}
          placeholder={t('companies.nip')}
          disabled={mode === Mode.DISPLAY}
          touched={touched.nip}
          errorMessage={t(errors.nip || '')}
          onChange={handleChange}
          onBlur={handleBlur}
          $active={values.active}
          $border={mode === Mode.EDIT}
        />
        <ManageListItem.StorageLimitSelect
          name='storageSpaceLimit'
          errorMessage={t(errors.storageSpaceLimit || '')}
          error={!!errors.storageSpaceLimit}
          value={values.storageSpaceLimit}
          disabled={mode === Mode.DISPLAY}
          onChange={handleChange}
          $active={values.active}
          $border={isNew || mode === Mode.EDIT}
          inputProps={{ style: { textAlign: 'center' } }}
          onBlur={handleBlur}
          touched={touched.storageSpaceLimit}
        />
        <ManageListItem.ActionsContainer>
          {mode === Mode.DISPLAY ? (
            isSmallScreen ? (
              <>
                <ManageListItem.EditIconButton
                  tooltip={t('common.edit')}
                  disabled={!values.active}
                  onClick={() => setMode(Mode.EDIT)}
                />
                <ManageListItem.ActivateIconButton
                  tooltip={values.active ? t('common.deactivate') : t('common.activate')}
                  $active={values.active}
                  onClick={handleClickActivate}
                />
              </>
            ) : (
              <>
                <ManageListItem.MenuIconButton
                  onClick={handleMenuOpen}
                />
                <ManageListItem.Menu
                  open={!!menuAnchorEl}
                  anchorEl={menuAnchorEl}
                  onClose={handleMenuClose}
                >
                  <ManageListItem.MenuItem
                    disabled={!values.active}
                    onClick={() => setMode(Mode.EDIT)}
                  >
                    {t('common.edit')}
                  </ManageListItem.MenuItem>
                  <ManageListItem.MenuItem
                    onClick={handleClickActivate}
                  >
                    {values.active ? t('common.deactivate') : t('common.activate')}
                  </ManageListItem.MenuItem>
                </ManageListItem.Menu>
              </>
            )
          ) : (
            <>
              <ManageListItem.SaveIconButton
                tooltip={t('common.save')}
                disabled={!dirty}
                onClick={handleClickSave}
              />
              <ManageListItem.CancelIconButton
                tooltip={t('common.cancel')}
                onClick={handleClickCancel}
              />
            </>
          )}
        </ManageListItem.ActionsContainer>
      </ManageListItem.FieldsContainer>
    </ManageListItem>
  )
}

export { CompaniesListItem }
export type { CompaniesListItemType }
