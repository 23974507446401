import { useTranslation } from 'react-i18next'
import { getKnowledgeBaseBreadcrumbsList, KnowledgeBaseEntity } from 'utils/getKnowledgeBaseBreadcrumbsList'

interface UseDestinationOptions {
  includeItself?: boolean
  separator?: string
}

const defaultOptions = {
  includeItself: false,
  separator: ' > '
}

const useDestination = (
  knowledgeBaseEntity?: KnowledgeBaseEntity,
  destinationOptions?: UseDestinationOptions,
): string => {
  const options = { ...defaultOptions, ...destinationOptions }
  const { t } = useTranslation()

  const destinationArray = (
    knowledgeBaseEntity ? getKnowledgeBaseBreadcrumbsList(knowledgeBaseEntity) : []
  )

  const destination = [
    t('knowledgeBase.breadcrumbs.root'),
    ...destinationArray.map((e) => e.name)
  ]

  return (!options.includeItself ? destination.slice(0, -1) : destination).join(options.separator)
}

export { useDestination }
