import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Typography, TypographyProps } from '@material-ui/core'

const TimeRangeContainer = styled.div`
  display: flex;
`

const Dash = styled((props: TypographyProps) => (
  <Typography variant='subtitle1' {...props} />
))`
  margin: 12px 6px 0;
`

const ErrorMessage = styled.p`
  ${({ theme }) => css`
    margin-top: 0;
    font-size: 14px;
    color: ${theme.palette.error.main};
  `}
`

export {
  TimeRangeContainer,
  Dash,
  ErrorMessage,
}
