import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import * as CommonStyled from '../Appointment.styles'

const PreviewAppointmentHeader: React.FC = () => {
  const { t } = useTranslation()

  return (
    <CommonStyled.AppointmentHeaderContainer>
      <Typography variant='h2'>
        {t('appointment.title')}
      </Typography>
    </CommonStyled.AppointmentHeaderContainer>
  )
}

export { PreviewAppointmentHeader }
