/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next'
import { FormikTouched, FormikErrors, FormikHandlers } from 'formik'
import { InputAdornment } from '@material-ui/core'

import { Addressable } from 'api/queries/companyAddressables/types'
import { useCompanyId } from 'providers/CompanyProvider'
import { useCompanyAddressablesData } from 'hooks/useCompanyAddressablesData'
import { AddressableSelect } from 'components/data/AddressableSelect/AddressableSelect'
import { DifficultySlider } from 'components/data/DifficultySlider/DifficultySlider'
import { MIN_CONTENT_DURATION } from 'constants/knowledgeBaseContent'
import { KnowledgeBaseContentFormFields, PresentationTestSubForm } from '../types'
import * as Styled from '../KnowledgeBaseContentForm.styles'

interface QuizFormFieldsProps {
  onChange: FormikHandlers['handleChange']
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  onBlur: FormikHandlers['handleBlur']
  values?: Partial<KnowledgeBaseContentFormFields>
  touched: Partial<FormikTouched<KnowledgeBaseContentFormFields>>
  errors: Partial<FormikErrors<KnowledgeBaseContentFormFields>>
  contentValues: PresentationTestSubForm
  contentTouched?: FormikTouched<PresentationTestSubForm>
  contentErrors?: FormikErrors<PresentationTestSubForm>
}

const PresentationTestFormFields = ({
  onChange: handleChange,
  onBlur: handleBlur,
  setFieldValue,
  values,
  touched,
  errors,
  contentValues,
  contentTouched,
  contentErrors,
}: QuizFormFieldsProps) => {
  const { t } = useTranslation()
  const companyId = useCompanyId() || 0

  const { data: addressablesData } = useCompanyAddressablesData(companyId)

  const handleDifficultyChange = (
    _event: any,
    newValue: number | number[]
  ) => {
    setFieldValue('subForm.difficulty', newValue)
  }

  const handleAddressableChange = (
    newValue: Addressable | null,
    field: string
  ) => {
    setFieldValue(field, newValue)
  }

  return (
    <>
      <Styled.InfoSection
        label={t('knowledgeBase.difficulty')}
        editValueComponent={
          <Styled.DifficultySliderContainer>
            <DifficultySlider
              name='subForm.difficulty'
              value={contentValues.difficulty}
              onChange={handleDifficultyChange as any}
              track={false}
            />
          </Styled.DifficultySliderContainer>
        }
      />
      <Styled.InfoSection
        label={t('knowledgeBase.duration')}
        editValueComponent={
          <Styled.TextField
            id='duration'
            name='duration'
            type='number'
            value={values?.duration}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.duration}
            errorMessage={t(errors.duration || '')}
            InputProps={{
              inputProps: {
                min: MIN_CONTENT_DURATION,
              },
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='start'>
                  {t('common.minutes')}
                </InputAdornment>
              )
            }}
          />
        }
      />
      <Styled.InfoSection
        label={t('knowledgeBase.scene')}
        editValueComponent={
          <AddressableSelect
            id='subForm.scene'
            value={contentValues.scene}
            textFieldProps={{
              name: 'subForm.scene',
              touched: !!contentTouched?.scene,
              errorMessage: t(contentErrors?.scene || '')
            }}
            onChange={(_, value) => handleAddressableChange(value, 'subForm.scene')}
            onBlur={handleBlur}
            addressablesData={addressablesData?.scenes || []}
          />
        }
      />
      <Styled.InfoSection
        label={t('knowledgeBase.timeline')}
        editValueComponent={
          <AddressableSelect
            id='subForm.timeline'
            textFieldProps={{
              name: 'subForm.timeline',
              touched: !!contentTouched?.timeline,
              errorMessage: t(contentErrors?.timeline || '')
            }}
            value={contentValues.timeline}
            onChange={(_, value) => handleAddressableChange(value, 'subForm.timeline')}
            onBlur={handleBlur}
            addressablesData={addressablesData?.timelines || []}
          />
        }
      />
    </>
  )
}

export {
  PresentationTestFormFields,
}
