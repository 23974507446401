import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Typography } from '@material-ui/core'

const RootContainer = styled.div`
  display: flex;
`

const TitleText = styled(Typography)`
  font-weight: bold;
`

const AmountText = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    margin-left: 10px;
    font-weight: bold;
  `}
`

export {
  RootContainer,
  TitleText,
  AmountText,
}
