import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  Typography,
  MenuItem,
} from '@material-ui/core'

const StyledMenuItem = styled(MenuItem)`
    display: 'flex';
    align-items: 'center';
    flex-direction: row;
    align-items: center;
  & .MuiListItemIcon-root {
    min-width: 0;
  }
`

const StyledMenuItemNoHover = styled(MenuItem)`
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: center;
  & .MuiListItemIcon-root {
    min-width: 0;
  }
  & .MuiListItem-button:hover {
    background-color: #fff;
    color:red;
    font-size: 20px; 
}
`

const StyledErrorMessage = styled(Typography)`
${({ theme }) => css`
  margin: 0px ${theme.spacing(2)}px;
  color: ${theme.palette.error.main};
  `}
`

export {
  StyledMenuItem as MenuItem,
  StyledErrorMessage as ErrorMessage,
  StyledMenuItemNoHover as MenuItemNoHover
}
