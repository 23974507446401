import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FolderSpecialOutlinedIcon from '@material-ui/icons/FolderSpecialOutlined'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import FolderOpenRoundedIcon from '@material-ui/icons/FolderOpenRounded'
import {
  SelectProps,
  Typography,
  ListItemIcon,
  ListItemText,
  LinearProgress
} from '@material-ui/core'

import { Select } from 'components/inputs/Select/Select'
import { KnowledgeBaseBreadcrumbs } from 'components/navigation/KnowledgeBaseBreadcrumbs/KnowledgeBaseBreadcrumbs'
import { useGetKnowledgeBaseEntityQuery } from 'api/queries/knowledgeBase/knowledgeBase'
import { useCompanyId } from 'providers/CompanyProvider'
import { useKnowledgeBaseContentData } from 'hooks/useKnowledgeBaseContentData'
import * as Styled from './MoveSelect.styles'

export interface Props extends SelectProps {
  errorMessage?: string
  touched?: boolean
  icon?: React.ReactNode
  className?: string
  onSelectedDestinationChange: (id: number | null) => void
  movedElementId: number
}

const MoveSelect: React.FC<Props> = ({
  errorMessage,
  touched,
  icon,
  className,
  onSelectedDestinationChange,
  movedElementId,
  ...rest
}) => {
  const [isRoot, setIsRoot] = useState(true)
  const [currentEntityId, setCurrentEntityId] = useState<number | null>(null)
  const [open, setOpen] = useState(false)
  const [isError, setError] = useState<boolean>(false)
  const { t } = useTranslation()
  const companyId = useCompanyId() || 0

  const {
    data: knowledgeBaseEntityData,
    isLoading: isLoadingEntity,
    isError: isErrorEntity,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = useGetKnowledgeBaseEntityQuery(+currentEntityId!, { enabled: !!currentEntityId })

  const {
    data: knowledgeBaseMovedEntity,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = useGetKnowledgeBaseEntityQuery(+movedElementId!, { enabled: !!movedElementId })

  const {
    data: knowledgeBaseCompanyData,
    isLoading: isLoadingCompany,
    isError: isErrorCompany,
  } = useKnowledgeBaseContentData(companyId)

  const handleShowFirstGeneration = (
    childId: number
  ) => {
    setIsRoot(false)
    setCurrentEntityId(childId)
  }

  const handleBack = (event: { stopPropagation: () => void }) => {
    setError(false)
    event.stopPropagation()
    if (knowledgeBaseEntityData?.parent?.id) {
      setCurrentEntityId(knowledgeBaseEntityData?.parent?.id)
    } else {
      setIsRoot(true)
    }
  }

  const handleSelectedDestinationChange = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    childId: number | null
  ) => {
    event.stopPropagation()
    if (knowledgeBaseMovedEntity?.parent?.id !== childId) {
      onSelectedDestinationChange(childId)
      setError(false)
      setOpen(false)
    } else {
      event.preventDefault()
      setError(true)
      setOpen(true)
    }
  }

  const handleSetCurrentEntityId = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    childId: number
  ) => {
    e.stopPropagation()
    setError(false)
    setCurrentEntityId(childId)
  }

  const handleClickNextItem = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    childId: number
  ) => {
    e.stopPropagation()
    handleShowFirstGeneration(childId)
  }

  return (
    <>
      <Select
        onClick={() => {
          setOpen(!open)
        }}
        open={open}
        defaultValue={t('modals.moveElement.selectValue')}
        renderValue={(value: unknown) => <>{value}</>}
        {...rest}
      >
        {!isRoot
        && (
        <>
          <Styled.MenuItemNoHover onClick={(e) => e.stopPropagation()}>
            <KnowledgeBaseBreadcrumbs
              knowledgeBaseEntity={knowledgeBaseEntityData}
              isError={isErrorEntity}
              isDisplayOnly
            />
          </Styled.MenuItemNoHover>
          <Styled.MenuItem onClick={(e) => handleBack(e)}>
            <ArrowBackRoundedIcon />
          </Styled.MenuItem>
        </>
        )}
        <Styled.MenuItem value={t<string>('modals.moveElement.selectValue')} disabled>
          {t('modals.moveElement.selectValue')}
        </Styled.MenuItem>
        <Styled.MenuItem
          value={t<string>('knowledgeBase.root')}
          onClick={(e) => handleSelectedDestinationChange(e, null)}
        >
          <FolderSpecialOutlinedIcon />
          <Typography>{t('modals.moveElement.defaultValue')}</Typography>
        </Styled.MenuItem>
        {isErrorCompany && (
          <Styled.ErrorMessage>
            {t('common.errors.generic')}
          </Styled.ErrorMessage>
        )}
        {isLoadingCompany ? <LinearProgress /> : (
          knowledgeBaseCompanyData?.pages[0].content?.map((child) => (
            isRoot === true
            && child.dataType === 'GROUP'
            && child.id !== movedElementId && (
              <Styled.MenuItem
                key={child.id}
                value={child.name}
                onClick={(e) => handleSelectedDestinationChange(e, child.id)}
              >
                <>
                  <ListItemIcon>
                    <FolderOpenRoundedIcon />
                  </ListItemIcon>
                  <ListItemText>{child.name}</ListItemText>
                </>
                {child.dataType === 'GROUP' && child.id !== movedElementId && (
                  <ListItemIcon onClick={(e) => {
                    handleClickNextItem(e, child.id)
                  }}
                  >
                    <ArrowForwardIosRoundedIcon />
                  </ListItemIcon>
                )}
              </Styled.MenuItem>
            )
          ))
        )}
        {isErrorEntity && (
          <Styled.ErrorMessage>
            {t('common.errors.generic')}
          </Styled.ErrorMessage>
        )}
        {isLoadingEntity ? <LinearProgress /> : (
          knowledgeBaseEntityData?.children?.map((child) => (
            isRoot === false
            && child.dataType === 'GROUP'
            && child.id !== movedElementId && (
              <Styled.MenuItem
                key={child.id}
                value={child.name}
                onClick={(e) => handleSelectedDestinationChange(e, child.id)}
              >
                <>
                  <ListItemIcon>
                    <FolderOpenRoundedIcon />
                  </ListItemIcon>
                  <ListItemText>{child.name}</ListItemText>
                </>
                {child.hasChildGroups && (
                  <ListItemIcon onClick={(e) => handleSetCurrentEntityId(e, child.id)}>
                    <ArrowForwardIosRoundedIcon />
                  </ListItemIcon>
                )}
              </Styled.MenuItem>
            )
          ))
        )}
        {isError && (
        <Styled.ErrorMessage>
          {t('knowledgeBase.errors.move.location')}
        </Styled.ErrorMessage>)}
      </Select>
    </>
  )
}

export { MoveSelect }
