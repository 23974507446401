import React, { useEffect } from 'react'
import { useFormik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'

import { TextField } from 'components/inputs/TextField/TextField'
import { ChangeEmailSchema } from 'validations/ChangeEmailSchema'
import { FormSaveButton } from 'components/forms/FormSaveButton/FormSaveButton'
import * as FormsStyled from '../forms.styles'

export interface ChangeEmailFormFields {
  email: string
}

export interface ChangeEmailFormProps {
  initialValue?: string
  onSubmit?: (
    values: ChangeEmailFormFields,
    helpers: FormikHelpers<ChangeEmailFormFields>
  ) => void
  isLoadingData?: boolean
  isError?: boolean
  isLoadingUpdate?: boolean
  isSuccessUpdate?: boolean
}

const ChangeEmailForm: React.FC<ChangeEmailFormProps> = ({
  initialValue = '',
  onSubmit = () => {},
  isLoadingData,
  isError,
  isLoadingUpdate,
  isSuccessUpdate,
}) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    dirty,
    resetForm,
  } = useFormik<ChangeEmailFormFields>(
    {
      initialValues: {
        email: initialValue,
      },
      validationSchema: ChangeEmailSchema,
      onSubmit,
      enableReinitialize: true
    }
  )

  useEffect(() => {
    if (isSuccessUpdate) {
      resetForm()
    }
  }, [isSuccessUpdate, resetForm])

  return (
    <FormsStyled.Form onSubmit={handleSubmit}>
      {isError && (
        <FormsStyled.ErrorMessage>
          {t('common.errors.generic')}
        </FormsStyled.ErrorMessage>
      )}
      {isLoadingData ? (
        <FormsStyled.CenteredContainer>
          <CircularProgress />
        </FormsStyled.CenteredContainer>
      ) : (
        <>
          <TextField
            label={t('common.email')}
            type='text'
            errorMessage={t(errors.email || '')}
            id='email'
            name='email'
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.email}
          />
          <FormSaveButton
            isActive={dirty}
            isSaving={isLoadingUpdate}
            isSaved={isSuccessUpdate}
            savedButtonText={t('changeEmail.sent')}
          />
        </>
      )}
    </FormsStyled.Form>
  )
}
export { ChangeEmailForm }
