import React from 'react'

import * as Styled from './ContentCenter.styled'

const ContentCenter: React.FC = ({ children, ...props }) => (
  <Styled.Container {...props}>
    {children}
  </Styled.Container>
)

export { ContentCenter }
