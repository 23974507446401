import {
  NavigationDrawerContainer
} from 'components/navigation/NavigationDrawer/NavigationDrawerContainer'
import { DrawerStateProvider } from 'providers/DrawerStateProvider'
import { useShouldDisplayNavigation } from 'hooks/useShouldDisplayNavigation'
import * as Styled from './AppLayout.styles'

const AppLayout: React.FC = ({ children }) => {
  const shouldDisplayDrawer = useShouldDisplayNavigation()

  return (
    <Styled.Container>
      <DrawerStateProvider>
        {shouldDisplayDrawer && <NavigationDrawerContainer />}
        <Styled.ContentContainer>
          {children}
        </Styled.ContentContainer>
      </DrawerStateProvider>
    </Styled.Container>
  )
}

export { AppLayout }
