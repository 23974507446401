import { useState } from 'react'
import { UseInfiniteQueryResult } from 'react-query'
import { useDebounce } from 'react-use'

import { useGetOwnStatisticsQuery, useGetCompanyStatisticsQuery } from 'api/queries/statistics/statistics'
import { StatisticsResponse } from 'api/queries/statistics/types'
import { Company } from 'api/queries/companies/types'

const useStatisticsData = (
  companyId: Company['id'],
  searchFilter?: string
): UseInfiniteQueryResult<StatisticsResponse, unknown> => {
  const pageOption = (lastPage: StatisticsResponse) => (
    lastPage.number < lastPage.totalPages - 1 ? lastPage.number + 1 : undefined
  )
  const [buffer, setBuffer] = useState(searchFilter)
  useDebounce(() => setBuffer(searchFilter), 500, [searchFilter])

  const ownData = useGetOwnStatisticsQuery(
    buffer,
    {
      enabled: !companyId,
      getNextPageParam: pageOption,
    }
  )

  const idData = useGetCompanyStatisticsQuery(
    companyId,
    buffer,
    {
      enabled: !!companyId,
      getNextPageParam: pageOption,
    }
  )

  return companyId ? idData : ownData
}

export { useStatisticsData }
