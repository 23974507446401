import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalProps } from 'components/layout/Modal/Modal'
import { TextField } from 'components/inputs/TextField/TextField'
import * as Styled from './GroupModal.styles'

interface GroupModalProps extends Omit<ModalProps, 'children' | 'onClose'> {
  open: boolean
  isEdit?: boolean
  defaultName?: string
  onClose: () => void
  onSave: (name: string) => void
  isLoading?: boolean
}

const GroupModal = ({
  open,
  isEdit,
  defaultName = '',
  onClose: handleClose,
  onSave: handleSave,
  isLoading,
}: GroupModalProps): JSX.Element => {
  const { t } = useTranslation()
  const [name, setName] = useState<string>(defaultName)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)

  useEffect(() => {
    setName(defaultName)
  }, [defaultName])

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDisabled(event.target.value.length < 1)
    setName(event.target.value)
  }

  const handleCloseModal = () => {
    handleClose()
    setName(defaultName)
  }

  const handleSaveModal = () => {
    handleSave(name)
    if (!isEdit) {
      setName('')
    }
  }

  return (
    <Styled.Modal
      open={open}
      onClose={handleCloseModal}
    >
      <Modal.Title variant='h3'>
        {t(`knowledgeBase.${isEdit ? 'edit' : 'new'}Group`)}
      </Modal.Title>
      <Modal.Content>
        <TextField
          label={t('common.name')}
          value={name}
          onChange={handleNameChange}
        />
      </Modal.Content>
      <Modal.Actions
        isLoading={isLoading}
        isSaveDisabled={isDisabled}
        onCancel={handleCloseModal}
        onSave={handleSaveModal}
        confirmLabel={t(`common.${isEdit ? 'edit' : 'create'}`)}
      />
    </Styled.Modal>
  )
}

export { GroupModal }
