import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, CircularProgress } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'

import { usePostReplyForAppointmentEntryMutation } from 'api/mutations/forumEntries/forumEntries'
import { ForumEntryItemBase, ForumEntryItemBaseProps } from './ForumEntryItemBase'
import * as Styled from './ForumEntryItem.styles'

interface ForumEntryItemProps extends ForumEntryItemBaseProps {
  disableAddingForumEntries?: boolean
  onGoToThread: () => void
}

const ForumEntryItem = ({
  disableAddingForumEntries,
  onGoToThread,
  ...props
}: ForumEntryItemProps) => {
  const { t } = useTranslation()
  const [replyData, setReplyData] = useState('')

  const {
    mutate: postReplyMutate,
    isLoading: isPostingReply,
  } = usePostReplyForAppointmentEntryMutation({
    onSuccess: () => {
      setReplyData('')
    }
  })

  const {
    id,
    replies
  } = props

  const repliesLength = replies.length

  return (
    <>
      <ForumEntryItemBase {...props} />
      <Styled.RepliesContainer>
        {repliesLength ? (
          <ForumEntryItemBase
            {...replies[0]}
            isViewOnly
            isReply
          />
        ) : null}
        {repliesLength > 1 && (
          <Styled.CenteredContentContainer>
            <Styled.MoreRepliesIndicator />
            <Styled.MoreRepliesIndicator />
            <Styled.MoreRepliesIndicator />
          </Styled.CenteredContentContainer>
        )}
        <Styled.ViewThreadButton
          onClick={onGoToThread}
        >
          {t('appointment.forum.goToThread')}
        </Styled.ViewThreadButton>
        <Styled.Row>
          <Styled.PostReplyInput
            disabled={disableAddingForumEntries}
            multiline
            placeholder={t('appointment.forum.writeReply')}
            value={replyData}
            onChange={({ target: { value } }) => (
              setReplyData(value)
            )}
          />
          <Styled.PostReplyButton
            disabled={disableAddingForumEntries}
            onClick={() => (
              postReplyMutate({ id, content: replyData })
            )}
          >
            {isPostingReply ? (
              <CircularProgress size={24} />
            ) : (
              <SendIcon />
            )}
          </Styled.PostReplyButton>
        </Styled.Row>
      </Styled.RepliesContainer>
      <Divider />
    </>
  )
}

export { ForumEntryItem }
