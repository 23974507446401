import React from 'react'

interface ModalTabPanelProps {
  index: number
  value: number
}

const ModalTabPanel: React.FC<ModalTabPanelProps> = ({
  value,
  index,
  children,
  ...other
}) => (
  <div
    hidden={value !== index}
    {...other}
  >
    {value === index && (
      <>
        {children}
      </>
    )}
  </div>
)

export { ModalTabPanel }
