import { useParams, useHistory } from 'react-router'

import { useResetPasswordMutation } from 'api/mutations/users/users'
import ResetPasswordForm, { FormFields } from 'components/forms/ResetPasswordForm/ResetPasswordForm'
import { AuthLayout } from 'components/layout/AuthLayout/AuthLayout'
import * as Styled from './auth.styles'

interface RouteParams {
  token: string
}

const ResetPasswordScreen: React.FC = () => {
  const { token } = useParams<RouteParams>()
  const history = useHistory()

  const { mutate, isLoading, isError } = useResetPasswordMutation({
    onSuccess: () => history.push('/sign-in')
  })

  return (
    <AuthLayout>
      <Styled.SmallBlueCircle />
      <ResetPasswordForm
        onSubmit={({ newPassword }: FormFields) => mutate({ password: newPassword, token })}
        isLoading={isLoading}
        isError={isError}
      />
    </AuthLayout>
  )
}

export default ResetPasswordScreen
