import { AxiosResponse } from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

import { useFetch } from 'providers/FetchProvider'
import { StorageSpaceLimit } from './types'

const GET_ALL_STORAGE_SPACE_LIMITS_QUERY_KEY = 'storageSpaceLimits'

const useGetStorageSpaceLimitQuery = ()
: UseQueryResult<AxiosResponse<StorageSpaceLimit[]>, Error> => {
  const { fetch } = useFetch()

  return useQuery(
    GET_ALL_STORAGE_SPACE_LIMITS_QUERY_KEY,
    () => fetch.get('/storage-space-limit')
  )
}

export { useGetStorageSpaceLimitQuery }
