import { useQuery, UseQueryResult } from 'react-query'
import { useFetch } from 'providers/FetchProvider'
import { Language } from 'api/types'

const useGetLanguagesQuery = ()
: UseQueryResult<Language[], unknown> => {
  const { fetch } = useFetch()

  return useQuery(
    'languages',
    () => fetch.get('/language').then((response) => response.data)
  )
}

export { useGetLanguagesQuery }
