import { useTranslation } from 'react-i18next'

import { Select, SelectProps } from 'components/inputs/Select/Select'
import { AnalysisScopeType } from 'types/AnalysisScopeType'
import * as Styled from './AnalysisScopeSelect.styles'

interface AnalysisScopeSelectProps extends SelectProps {
  className?: string,
}

const AnalysisScopeSelect = ({
  className,
  ...rest
}: AnalysisScopeSelectProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Select
      className={className}
      {...rest}
    >
      {Object.keys(AnalysisScopeType).map((type) => (
        <Styled.MenuItem key={type} value={type}>
          {t(`analysisScopeType.${[type]}`)}
        </Styled.MenuItem>
      ))}
    </Select>
  )
}

export { AnalysisScopeSelect }
