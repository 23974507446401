import React, { useState } from 'react'

import * as Styled from './Accordion.styles'

interface AccordionProps {
  items?: JSX.Element[]
}

const Accordion: React.FC<AccordionProps> = ({
  items = [],
}) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Styled.Accordion
      expanded={expanded}
      onChange={() => setExpanded((prevState) => !prevState)}
    >
      <Styled.AccordionSummary expandIcon={<Styled.ExpandMore />}>
        {!expanded && items[0]}
      </Styled.AccordionSummary>
      <Styled.AccordionDetails>
        {items}
      </Styled.AccordionDetails>
    </Styled.Accordion>
  )
}

export { Accordion }
