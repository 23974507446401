enum KnowledgeBaseContentType {
  ALL = 'ALL',
  GROUP = 'GROUP',
  PRESENTATION = 'PRESENTATION',
  TEST = 'TEST',
  QUIZ = 'QUIZ',
  SANDBOX_TEST = 'SANDBOX_TEST',
}

export { KnowledgeBaseContentType }
