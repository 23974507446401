import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik, FormikHelpers } from 'formik'
import { CircularProgress } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import FolderIcon from '@material-ui/icons/Folder'
import TodayIcon from '@material-ui/icons/Today'

import { UserDetails } from 'api/types'
import { ManageListItem } from 'components/inputs/ManageListItem/ManageListItem'
import { ClearIconButton, DateTimeTextField } from 'components/inputs/DatePicker/DatePicker.styles'
import { useUserId } from 'providers/UserContextProvider'
import { PARTICIPANT_ROLE } from 'constants/roleGroups'
import { CreateUserSchema } from 'validations/CreateUserSchema'
import { UserRole } from 'types/UserRole'
import { useIsSmallScreen } from 'hooks/useIsSmallScreen'
import { UserListItemRow } from './UserListItemRow'
import * as Styled from './UserListItem.styled'

interface UserListItemType extends Omit<UserDetails,
'defaultLanguage' | 'notificationsOptions' | 'userColor' | 'timeZone'> {
  timeZone?: string
}

type OnEditType = (
  initialValues: UserListItemType,
  values: UserListItemType,
  onSuccess?: () => void
) => void

interface UserListItemProps {
  initialValues?: UserListItemType
  newEmail?: string
  isNew?: boolean
  onSubmit: (values: UserListItemType, helpers: FormikHelpers<UserListItemType>) => void
  onCancel: () => void
  onActivate?: (id: number, isActive: boolean) => void
  className?: string
  onKnowledgeBasePermissionsDialogOpen?: (user: UserListItemType) => void
  onDelete?: (user: UserListItemType) => void
  onEdit?: OnEditType
  isLoading?: boolean
}

const defaultValues = {
  id: 0,
  firstName: '',
  lastName: '',
  username: null,
  email: '',
  phoneNumber: null,
  authorityGroupName: PARTICIPANT_ROLE as UserRole,
  active: true,
  educationLevel: null,
  technicalUser: false,
  expirationDate: null
}

enum Mode { DISPLAY, EDIT }

const UserListItem: React.FC<UserListItemProps> = ({
  initialValues = defaultValues,
  newEmail,
  isNew,
  onSubmit,
  onCancel: handleCancel,
  onActivate: handleActivate,
  onDelete: handleDelete,
  className,
  onKnowledgeBasePermissionsDialogOpen: handleKnowledgeBasePermissionsDialogOpen,
  onEdit: handleEdit,
  isLoading,
}) => {
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  const userId = useUserId()
  const [mode, setMode] = useState<Mode>(isNew ? Mode.EDIT : Mode.DISPLAY)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => setMenuAnchorEl(event.currentTarget)

  const handleMenuClose = () => setMenuAnchorEl(null)

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    resetForm,
    values,
    errors,
    touched,
    dirty
  } = useFormik<UserListItemType>(
    {
      initialValues,
      validationSchema: CreateUserSchema,
      onSubmit,
      enableReinitialize: true
    }
  )

  const handleClickSave = () => {
    if (isNew) {
      handleSubmit()
    } else {
      handleEdit?.(initialValues, values, () => setMode(Mode.DISPLAY))
    }
  }

  const handleClickCancel = () => {
    handleCancel()
    setMode(Mode.DISPLAY)
    handleMenuClose()
    resetForm()
  }

  const handleClickActivate = () => {
    handleActivate?.(values.id, !values.active)
  }

  const handleContentDialogOpen = () => {
    handleKnowledgeBasePermissionsDialogOpen?.(values)
  }

  const handleDeleteDialogOpen = () => {
    handleDelete?.(values)
  }

  const isDisplay = mode === Mode.DISPLAY
  const isInputDisabled = isDisplay && !isNew
  const isEditDisabled = !values.active || userId === values.id
  const shouldDisplayBorder = isNew || !isDisplay

  return (
    <ManageListItem
      active={values.active}
      $flexDirection={isSmallScreen ? 'row' : 'column'}
      className={className}
    >
      {!isSmallScreen ? (
        <ManageListItem.Row $justifyContent='space-between'>
          <Styled.CheckboxWrapper>
            <UserListItemRow
              field={(
                isDisplay ? (
                  values.technicalUser
                    ? <Styled.TechnicalUserWrapper label={t('users.technical')} />
                    : null
                ) : (
                  <ManageListItem.Checkbox
                    errorMessage={t(errors.technicalUser || '')}
                    name='technicalUser'
                    value={values.technicalUser}
                    checked={values.technicalUser}
                    disabled={isInputDisabled}
                    onChange={handleChange}
                    $active={values.active}
                    onBlur={handleBlur}
                    touched={touched.technicalUser}
                    label={t('users.technical')}
                  />
                )
              )}
            />
          </Styled.CheckboxWrapper>
          <Styled.ExpirationDateWrapper>
            <Styled.Text>{t('common.accountExpirationDate')}</Styled.Text>
            <DatePicker
              id='expirationDate'
              name='expirationDate'
              disablePast
              format='dd/MM/yyyy'
              value={values.expirationDate}
              onChange={(date) => setFieldValue('expirationDate', date?.toString())}
              okLabel={t('common.ok')}
              cancelLabel={t('common.cancel')}
              disabled={isInputDisabled}
              TextFieldComponent={(props) => (
                <DateTimeTextField
                  id={props.id}
                  name={props.name}
                  onClick={props.onClick}
                  value={props.value || t('users.accountExpirationDateNotSet')}
                  touched={touched?.expirationDate}
                  disabled={isInputDisabled}
                  endIcon={(
                    mode === Mode.EDIT && props.value ? (
                      <ClearIconButton onClick={() => setFieldValue('expirationDate', null)} />
                    ) : <TodayIcon />
                  )}
                  $active={values.active}
                  $border={shouldDisplayBorder}
                  $maxInputWidth='none'
                  $withHighlight={false}
                />
              )}
            />
          </Styled.ExpirationDateWrapper>
        </ManageListItem.Row>
      ) : null}
      <Styled.FieldsWrapper>
        {isSmallScreen && (!isDisplay || values.technicalUser) ? (
          <UserListItemRow
            field={(
              isDisplay ? (
                values.technicalUser ? (
                  <Styled.TechnicalUserWrapper label={t('users.technical')} />
                ) : null
              ) : (
                <ManageListItem.Checkbox
                  $alignItems='center'
                  errorMessage={t(errors.technicalUser || '')}
                  name='technicalUser'
                  value={values.technicalUser}
                  checked={values.technicalUser}
                  disabled={isInputDisabled}
                  onChange={handleChange}
                  $active={values.active}
                  onBlur={handleBlur}
                  touched={touched.technicalUser}
                  label={t('users.technical')}
                />
              )
            )}
          />
        ) : null}
        <UserListItemRow
          header={(
            <ManageListItem.HeaderText variant='subtitle1'>
              {t('common.firstName')}
            </ManageListItem.HeaderText>
          )}
          field={(
            <ManageListItem.TextField
              errorMessage={t(errors.firstName || '')}
              name='firstName'
              value={values.firstName}
              placeholder={t('common.firstName')}
              disabled={isInputDisabled}
              onChange={handleChange}
              $active={values.active}
              $border={shouldDisplayBorder}
              onBlur={handleBlur}
              touched={touched.firstName}
            />
          )}
        />
        <UserListItemRow
          header={(
            <ManageListItem.HeaderText variant='subtitle1'>
              {t('common.lastName')}
            </ManageListItem.HeaderText>
          )}
          field={(
            <ManageListItem.TextField
              errorMessage={t(errors.lastName || '')}
              name='lastName'
              value={values.lastName}
              placeholder={t('common.lastName')}
              disabled={isInputDisabled}
              onChange={handleChange}
              $active={values.active}
              $border={shouldDisplayBorder}
              onBlur={handleBlur}
              touched={touched.lastName}
            />
          )}
        />
        <UserListItemRow
          $flexValue={1.25}
          header={(
            <ManageListItem.HeaderText variant='subtitle1'>
              {t('common.username')}
            </ManageListItem.HeaderText>
          )}
          field={(
            <ManageListItem.TextField
              name='username'
              value={values.username || undefined}
              placeholder={t('common.username')}
              disabled
              $active={values.active}
            />
          )}
        />
        <UserListItemRow
          $flexValue={1.25}
          header={(
            <ManageListItem.HeaderText variant='subtitle1'>
              {t('common.email')}
            </ManageListItem.HeaderText>
          )}
          field={(
            <ManageListItem.TextField
              errorMessage={t(errors.email || '')}
              name='email'
              value={values.email}
              placeholder={t('common.email')}
              disabled={isInputDisabled}
              onChange={handleChange}
              $active={values.active}
              $border={shouldDisplayBorder}
              onBlur={handleBlur}
              touched={touched.email}
            />
          )}
        />
        <UserListItemRow
          $flexValue={1.25}
          header={(
            <ManageListItem.HeaderText variant='subtitle1'>
              {t('common.phoneNumber')}
            </ManageListItem.HeaderText>
          )}
          field={(
            <ManageListItem.TextField
              errorMessage={t(errors.phoneNumber || '')}
              name='phoneNumber'
              value={values.phoneNumber}
              placeholder={t('common.phoneNumber')}
              disabled={isInputDisabled}
              onChange={handleChange}
              $active={values.active}
              $border={shouldDisplayBorder}
              onBlur={handleBlur}
              touched={touched.phoneNumber}
            />
          )}
        />
        <UserListItemRow
          header={(
            <ManageListItem.HeaderText variant='subtitle1'>
              {t('common.roles')}
            </ManageListItem.HeaderText>
          )}
          field={(
            <ManageListItem.RoleSelect
              errorMessage={t(errors.authorityGroupName || '')}
              error={!!errors.authorityGroupName}
              name='authorityGroupName'
              value={values.authorityGroupName}
              disabled={isInputDisabled}
              onChange={handleChange}
              $active={values.active}
              $border={shouldDisplayBorder}
              inputProps={{ style: { textAlign: 'center' } }}
              onBlur={handleBlur}
              touched={touched.authorityGroupName}
            />
          )}
        />
        <UserListItemRow
          header={(
            <ManageListItem.HeaderText variant='subtitle1'>
              {t('common.educationLevel')}
            </ManageListItem.HeaderText>
          )}
          field={(
            <ManageListItem.EducationLevelSelect
              errorMessage={t(errors.educationLevel || '')}
              error={!!errors.educationLevel}
              name='educationLevel'
              value={values.educationLevel ?? ''}
              disabled={isInputDisabled}
              onChange={handleChange}
              $active={values.active}
              $border={shouldDisplayBorder}
              inputProps={{ style: { textAlign: 'center' } }}
              onBlur={handleBlur}
              touched={touched.educationLevel}
            />
          )}
        />
        {isSmallScreen ? (
          <UserListItemRow
            header={(
              <ManageListItem.HeaderText variant='subtitle1'>
                {t('common.accountExpirationDate')}
              </ManageListItem.HeaderText>
            )}
            field={(
              <DatePicker
                id='expirationDate'
                name='expirationDate'
                disablePast
                format='dd/MM/yyyy'
                value={values.expirationDate}
                onChange={(date) => setFieldValue('expirationDate', date?.toString())}
                okLabel={t('common.ok')}
                cancelLabel={t('common.cancel')}
                disabled={isInputDisabled}
                TextFieldComponent={(props) => (
                  <DateTimeTextField
                    id={props.id}
                    name={props.name}
                    onClick={props.onClick}
                    value={props.value || t('users.accountExpirationDateNotSet')}
                    touched={touched?.expirationDate}
                    disabled={isInputDisabled}
                    endIcon={(
                      mode === Mode.EDIT && props.value ? (
                        <ClearIconButton onClick={() => setFieldValue('expirationDate', null)} />
                      ) : <TodayIcon />
                    )}
                    $active={values.active}
                    $border={shouldDisplayBorder}
                  />
                )}
              />
            )}
          />
        ) : null}
        <UserListItemRow
          header={(
            <ManageListItem.HeaderText variant='subtitle1'>
              {t('common.actions')}
            </ManageListItem.HeaderText>
          )}
          field={(
            <ManageListItem.ActionsContainer>
              {mode === Mode.DISPLAY ? (
                isSmallScreen ? (
                  <>
                    <ManageListItem.EditIconButton
                      tooltip={t('common.edit')}
                      $active={values.active}
                      disabled={isEditDisabled}
                      onClick={() => setMode(Mode.EDIT)}
                    />
                    <ManageListItem.ActivateIconButton
                      tooltip={values.active ? t('common.deactivate') : t('common.activate')}
                      $active={values.active}
                      onClick={handleClickActivate}
                    />
                    <ManageListItem.IconButton
                      tooltip={t('users.knowledgeBasePermissions')}
                      onClick={handleContentDialogOpen}
                    >
                      <FolderIcon />
                    </ManageListItem.IconButton>
                    <ManageListItem.DeleteIconButton
                      tooltip={t('common.delete')}
                      disabled={values.emailConfirmed}
                      onClick={handleDeleteDialogOpen}
                    />
                  </>
                ) : (
                  <>
                    <ManageListItem.MenuIconButton
                      onClick={handleMenuOpen}
                    />
                    <ManageListItem.Menu
                      open={!!menuAnchorEl}
                      anchorEl={menuAnchorEl}
                      onClose={handleMenuClose}
                      onClick={handleMenuClose}
                    >
                      <ManageListItem.MenuItem
                        disabled={isEditDisabled}
                        onClick={() => setMode(Mode.EDIT)}
                      >
                        {t('common.edit')}
                      </ManageListItem.MenuItem>
                      <ManageListItem.MenuItem
                        onClick={handleClickActivate}
                      >
                        {values.active ? t('common.deactivate') : t('common.activate')}
                      </ManageListItem.MenuItem>
                      <ManageListItem.MenuItem
                        onClick={handleContentDialogOpen}
                      >
                        {t('users.knowledgeBasePermissions')}
                      </ManageListItem.MenuItem>
                      <ManageListItem.DeleteMenuItem
                        disabled={values.emailConfirmed}
                        onClick={handleDeleteDialogOpen}
                      >
                        {t('common.delete')}
                      </ManageListItem.DeleteMenuItem>
                    </ManageListItem.Menu>
                  </>
                )
              ) : (
                <Styled.EditButtonContainer>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <ManageListItem.SaveIconButton
                        tooltip={t('common.save')}
                        disabled={!dirty}
                        $active={values.active}
                        onClick={handleClickSave}
                      />
                      <ManageListItem.CancelIconButton
                        tooltip={t('common.cancel')}
                        onClick={handleClickCancel}
                        $active={values.active}
                      />
                    </>
                  )}
                </Styled.EditButtonContainer>
              )}
            </ManageListItem.ActionsContainer>
          )}
        />
      </Styled.FieldsWrapper>
      {newEmail ? (
        <Styled.EmailAwaitsConfirmationText>
          {t('users.emailWaitingForConfirmation', { newEmail })}
        </Styled.EmailAwaitsConfirmationText>
      ) : null}
    </ManageListItem>
  )
}

export { UserListItem }
export type { UserListItemType, OnEditType }
