import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

import { ReactComponent as HomeTitleAccentSVG } from 'assets/images/home-title-accent.svg'

const HomeTitleAccentIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox='0 0 127.561 53.896'>
    <HomeTitleAccentSVG />
  </SvgIcon>
)

export default HomeTitleAccentIcon
