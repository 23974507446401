import { useCallback, useEffect, useState } from 'react'
import constate from 'constate'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import { useSetCompanyId } from 'providers/CompanyProvider'
import { USER_LOGGED_IN } from 'constants/localStorageKeys'
import { UserContext } from 'types/UserContext'

const [
  UserContextProvider,
  useUserRole,
  useUserId,
  useAccessToken,
  useUserLanguage,
  useIsAuthenticated,
  useLogin,
  useLogout,
] = constate(
  () => {
    const { i18n } = useTranslation()
    const [user, setUser] = useState<UserContext | null>(null)
    const [isLoggedIn, setIsLoggedIn] = useState(
      () => localStorage.getItem(USER_LOGGED_IN) === 'true'
    )
    const queryClient = useQueryClient()
    const handleSetCompany = useSetCompanyId()

    const login = useCallback((accessToken, userRole, id, language) => {
      setUser(({
        accessToken,
        role: userRole,
        id,
        defaultLanguage: language,
      }))
      localStorage.setItem(USER_LOGGED_IN, 'true')
      setIsLoggedIn(true)
    }, [])

    const logout = useCallback(() => {
      setUser(null)
      localStorage.setItem(USER_LOGGED_IN, 'false')
      setIsLoggedIn(false)
      queryClient.clear()
      handleSetCompany(null)
    }, [handleSetCompany, queryClient])

    useEffect(() => {
      const { defaultLanguage } = user || {}
      if (defaultLanguage && defaultLanguage.browserCode !== i18n.language) {
        i18n.changeLanguage(defaultLanguage.browserCode)
      }
    }, [i18n, user])

    return { user, isLoggedIn, login, logout }
  },
  (value) => value.user?.role,
  (value) => value.user?.id,
  (value) => value.user?.accessToken,
  (value) => value.user?.defaultLanguage,
  (value) => value.isLoggedIn,
  (value) => value.login,
  (value) => value.logout
)

export {
  UserContextProvider,
  useUserRole,
  useUserId,
  useAccessToken,
  useUserLanguage,
  useIsAuthenticated,
  useLogin,
  useLogout
}
