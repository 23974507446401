import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import TodayIcon from '@material-ui/icons/Today'
import { format } from 'date-fns'

import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import { PageHeader } from 'components/layout/PageHeader/PageHeader'
import { useGetRankingQuery } from 'api/queries/ranking/ranking'
import { RankingList } from 'components/data/RankingList/RankingList'
import { DateTimeTextField } from 'components/inputs/DatePicker/DatePicker.styles'
import * as Styled from './RankingScreen.styles'

const RankingScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const [currentDate, setCurrentDate] = useState<string>(`${new Date().getFullYear()}-01-01`)

  const {
    data: rankingData,
    isLoading,
  } = useGetRankingQuery(currentDate)

  return (
    <RouteContainer>
      <PageHeader
        title={t('results.ranking.title')}
        actions={(
          <Styled.HeaderContainer>
            <Styled.DisplayFromLabel>
              {t('results.ranking.displayFrom')}
            </Styled.DisplayFromLabel>
            <DatePicker
              id='date'
              name='date'
              format='dd/MM/yyyy'
              value={currentDate}
              onChange={(date: MaterialUiPickersDate) => {
                setCurrentDate((date && format(new Date(date), 'yyyy-MM-dd')) || '')
              }}
              disableFuture
              okLabel={t('common.ok')}
              cancelLabel={t('common.cancel')}
              TextFieldComponent={({ id, name, onClick, value }) => (
                <DateTimeTextField
                  id={id}
                  name={name}
                  onClick={onClick}
                  value={value}
                  endIcon={<TodayIcon />}
                  $maxInputWidth='220px'
                  $marginBottom='0px'
                />
              )}
            />
          </Styled.HeaderContainer>
        )}
      />
      <RankingList rankingData={rankingData} isLoading={isLoading} />
    </RouteContainer>
  )
}

export { RankingScreen }
