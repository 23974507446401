import { useTranslation } from 'react-i18next'

import { HistoryQuizQuestionDTO } from 'api/queries/results/types'
import { useIsMobile } from 'hooks/useIsMobile'
import { QuestionAnswerItem } from './QuestionAnswerItem/QuestionAnswerItem'
import * as Styled from './QuestionAnswerList.styles'

interface QuestionAnswerListProps {
  questionsAnswers?: HistoryQuizQuestionDTO[]
}

const QuestionAnswerList = ({
  questionsAnswers,
}: QuestionAnswerListProps): JSX.Element => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    <Styled.Container>
      {!isMobile && (
      <Styled.HeaderContainer>
        <Styled.QuestionHeader>{t('results.content')}</Styled.QuestionHeader>
        <Styled.Header>{t('results.answer')}</Styled.Header>
        <Styled.Header>{t('results.correctAnswer')}</Styled.Header>
        <Styled.Header>{t('results.accuracy')}</Styled.Header>
      </Styled.HeaderContainer>
      )}
      {questionsAnswers?.map((question, index) => (
        <QuestionAnswerItem
          key={question.questionId}
          index={index + 1}
          {...question}
        />
      ))}
    </Styled.Container>
  )
}

export { QuestionAnswerList }
