import { useParams, useHistory } from 'react-router'
import { useQueryClient } from 'react-query'

import { QuizQuestionContentFormFields } from 'components/forms/QuizQuestionForm/types'
import { QuizQuestionForm } from 'components/forms/QuizQuestionForm/QuizQuestionForm'
import { useAddQuestionMutation } from 'api/mutations/quizes/quizes'
import { KNOWLEDGE_BASE_ENTITY_QUERY_KEY } from 'api/queriesKeys'
import * as Styled from '../knowledgeBase.styles'

interface ParamTypes {
  quizId: string
}

const KnowledgeBaseQuestionCreateScreen = (): JSX.Element => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { quizId } = useParams<ParamTypes>()

  const { mutate: addQuestion, isLoading } = useAddQuestionMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(KNOWLEDGE_BASE_ENTITY_QUERY_KEY)
      history.goBack()
    }
  })

  const handleSubmitNewQuestion = ({
    answers,
    presentation,
    ...values
  }: QuizQuestionContentFormFields) => {
    addQuestion({
      id: +quizId,
      dto: {
        ...values,
        answersToAdd: answers,
        presentationId: presentation?.id,
      }
    })
  }

  return (
    <Styled.RootContainer>
      <QuizQuestionForm
        onSubmit={handleSubmitNewQuestion}
        isLoading={isLoading}
      />
    </Styled.RootContainer>
  )
}

export { KnowledgeBaseQuestionCreateScreen }
