import React from 'react'
import { Global, css } from '@emotion/react'

import { CssBaseline } from '@material-ui/core'
import { StylesProvider } from '@material-ui/core/styles'
import { ReactQueryDevtools } from 'react-query/devtools'
import { AppProviders } from './providers/AppProviders'
import Content from './Content'

const App: React.FC = () => (
  <StylesProvider injectFirst>
    <AppProviders>
      <CssBaseline />
      <Global
        styles={css`
          body {
            font-size: 18px;
          }
          button {
            font-size: 14px;
          }
          input {
            font-size: 14px;
          }
        `}
      />
      <Content />
      <ReactQueryDevtools initialIsOpen={false} />
    </AppProviders>
  </StylesProvider>
)

export default App
