import { useState } from 'react'
import { InfiniteQueryObserverResult } from 'react-query'
import { useDebounce } from 'react-use'

import {
  useGetTopLevelOfOwnCompanyQuery,
  useGetTopLevelOfCompanyIdQuery,
  useGetKnowledgeBaseContentQuery
} from 'api/queries/knowledgeBase/knowledgeBase'
import { KnowledgeBaseContentResponse } from 'api/queries/knowledgeBase/types'
import { SortType } from 'types/SortType'
import { KnowledgeBaseContentType } from 'types/KnowledgeBaseContentType'
import { useHasRole } from 'hooks/useHasRoles'
import { ADMIN_ROLE } from 'constants/roleGroups'

const useKnowledgeBaseContentData = (
  companyId: number, filterGroupType: KnowledgeBaseContentType = KnowledgeBaseContentType.ALL,
  sortType: SortType = 'OLDEST', searchFilter?: string, id?: string
): InfiniteQueryObserverResult<KnowledgeBaseContentResponse, Error> => {
  const isAdmin = useHasRole(ADMIN_ROLE)
  const dataType = filterGroupType !== KnowledgeBaseContentType.ALL ? filterGroupType : null
  const sortOption = sortType !== 'NONE' ? sortType : null
  const pageOption = (lastPage: KnowledgeBaseContentResponse) => (
    lastPage.number < lastPage.totalPages - 1 ? lastPage.number + 1 : undefined
  )
  const [buffer, setBuffer] = useState(searchFilter)
  useDebounce(() => setBuffer(searchFilter), 500, [searchFilter])

  const defaultParams = {
    dataType,
    sortOption,
    name: buffer
  }

  const ownTopLevelResult = useGetTopLevelOfOwnCompanyQuery(
    defaultParams,
    {
      enabled: !isAdmin && !id && !companyId,
      getNextPageParam: pageOption,
    }
  )

  const idTopLevelResult = useGetTopLevelOfCompanyIdQuery({
    companyId,
    ...defaultParams
  }, {
    enabled: !id && !!companyId,
    getNextPageParam: pageOption,
  })

  const contentResult = useGetKnowledgeBaseContentQuery(
    id ? +id : 0,
    defaultParams,
    {
      enabled: !!id,
      getNextPageParam: pageOption
    }
  )

  return id ? contentResult : companyId ? idTopLevelResult : ownTopLevelResult
}

export { useKnowledgeBaseContentData }
