import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { StandardTextFieldProps, TextField as MuiTextField } from '@material-ui/core'
import { InputBaseStyle } from 'components/inputs/inputs.styles'

interface StyleProps extends StandardTextFieldProps {
  touched?: boolean
  $withHighlight?: boolean
}

const StyledMuiTextField = styled((props: StyleProps) => (
  <MuiTextField {...props} />
), { shouldForwardProp: (prop) => prop !== '$withHighlight' && prop !== 'touched' })`
  ${({ theme, label, touched, $withHighlight }) => css`
    ${InputBaseStyle(theme, label)}
    .MuiInputBase-root {
      background-color: transparent;
    }
    ${$withHighlight && css`
      .MuiInputBase-root {
        background-color: ${(touched ? 'transparent' : theme.palette.backgroundAccents.blueish)};
      }`}

    & .MuiInputBase-adornedEnd {
      & .MuiButtonBase-root {
        height: 48px;
        width: 48px;
      }
    }

    & .MuiFormHelperText-root {
      margin-left: ${theme.spacing(1)}px;
      font-size: 12px;
    }
  `}
`

export { StyledMuiTextField as TextField }
