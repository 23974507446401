import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useHasRole } from 'hooks/useHasRoles'
import { useIsAuthenticated } from 'providers/UserContextProvider'
import { UserRole } from 'types/UserRole'

interface RestrictedRouteProps {
  accessRoles: UserRole[]
  exact?: boolean
  path: string | string[]
}

const RestrictedRoute: React.FC<RestrictedRouteProps> = ({ accessRoles, children, ...rest }) => {
  const location = useLocation()
  const isAllowed = useHasRole(accessRoles)
  const isLoggedIn = useIsAuthenticated()

  const redirectPath = isLoggedIn && !isAllowed ? {
    pathname: '/',
  } : {
    pathname: '/sign-in',
    state: {
      redirectPath: location.pathname,
    },
  }

  return (
    <Route {...rest}>
      {isLoggedIn && isAllowed
        ? children
        : <Redirect to={redirectPath} />}
    </Route>
  )
}

export { RestrictedRoute }
