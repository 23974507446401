import { useTranslation } from 'react-i18next'

interface DifficultySliderMark {
  value: number
  label: string
}

interface DifficultySliderHelpers {
  marks: DifficultySliderMark[]
  valueLabelFormat: (value: number) => string
}

const useDifficultySlider = (): DifficultySliderHelpers => {
  const { t } = useTranslation()
  const values = [1, 2, 3, 4, 5]
  const marks = values.map((value) => ({
    value,
    label: t(`difficulty.${[value]}`)
  }))

  const valueLabelFormat = (value: number) => (
    marks.findIndex((mark) => mark.value === value) + 1
  ).toString()

  return { marks, valueLabelFormat }
}

export { useDifficultySlider }
export type { DifficultySliderHelpers, DifficultySliderMark }
