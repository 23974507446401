import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import { css } from '@emotion/react'

import { Button } from 'components/inputs/Button/Button'

const StyledButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing(2)}px;
`

const ButtonContainer = styled.div`
  display: flex;
`

const ErrorText = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.error.main}
  `}
`

export {
  StyledButton as Button,
  ButtonContainer,
  ErrorText
}
