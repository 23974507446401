import { StandardTextFieldProps } from '@material-ui/core'
import * as Styled from './TextField.styles'

export interface Props extends StandardTextFieldProps {
  touched?: boolean
  errorMessage?: string
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  className?: string
  hideErrorMessage?: boolean
  withHighlight?: boolean
}

const TextField: React.FC<Props> = ({
  label,
  touched,
  errorMessage,
  startIcon,
  endIcon,
  className,
  hideErrorMessage = false,
  withHighlight = false,
  ...rest
}) => (
  <Styled.TextField
    label={label}
    error={touched && !!errorMessage}
    touched={touched}
    helperText={!hideErrorMessage && touched && errorMessage}
    InputLabelProps={{ shrink: true }}
    InputProps={{ disableUnderline: true, startAdornment: startIcon, endAdornment: endIcon }}
    className={className}
    $withHighlight={withHighlight}
    {...rest}
  />
)

export { TextField }
export type { Props as TextFieldProps }
