import styled from '@emotion/styled'
import { CircularProgress } from '@material-ui/core'

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  z-index: 1;
  align-self: center;
`

export {
  CenteredContainer,
  SelectWrapper,
  StyledCircularProgress as CircularProgress,
}
