import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'

import { useGetOwnNextAppointmentsQuery, useGetOwnArchivedAppointmentsQuery } from 'api/queries/appointments/appointments'
import { WelcomeTitle } from 'components/home/WelcomeTitle/WelcomeTitle'
import { AppointmentsSection } from 'components/home/AppointmentsSection/AppointmentsSection'
import { RedirectCard } from 'components/home/RedirectCard/RedirectCard'
import { NEXT_APPOINTMENTS, ARCHIVED_APPOINTMENTS } from 'constants/appointmentLists'
import * as HomeStyled from '../home.styles'

const ParticipantTeacherHomeScreen: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const {
    data: nextData,
    isLoading: nextLoading,
  } = useGetOwnNextAppointmentsQuery()

  const {
    data: archivedData,
    isLoading: archivedLoading,
  } = useGetOwnArchivedAppointmentsQuery()

  const handleSeeNextAppointment = () => (
    nextData?.pages[0].content.length
      ? history.push(`appointments/${nextData?.pages[0].content[0].id}`)
      : history.push(`appointments/detailed/${NEXT_APPOINTMENTS}`)
  )
  const handleGoToAccount = () => history.push('account')
  const handleSeeArchivedAppointment = () => (
    archivedData?.pages[0].content.length
      ? history.push(`appointments/${archivedData?.pages[0].content[0].id}`)
      : history.push(`appointments/detailed/${ARCHIVED_APPOINTMENTS}`)
  )

  return (
    <>
      <WelcomeTitle />
      <AppointmentsSection />
      <HomeStyled.CardsContainer>
        {nextLoading ? (
          <CircularProgress color='primary' />
        ) : (
          <RedirectCard onClick={handleSeeNextAppointment}>
            <HomeStyled.CardText>
              {t('home.nextAppointment')}
            </HomeStyled.CardText>
          </RedirectCard>
        )}
        <RedirectCard onClick={handleGoToAccount}>
          <HomeStyled.CardText>
            {t('home.account')}
          </HomeStyled.CardText>
        </RedirectCard>
        {archivedLoading ? (
          <CircularProgress color='primary' />
        ) : (
          <RedirectCard onClick={handleSeeArchivedAppointment}>
            <HomeStyled.CardText>
              {t('home.lastAppointment')}
            </HomeStyled.CardText>
          </RedirectCard>
        )}
      </HomeStyled.CardsContainer>
    </>
  )
}

export default ParticipantTeacherHomeScreen
