import { ReactNode, useCallback, useRef } from 'react'
import { SnackbarProvider as MaterialSnackbarProvider } from 'notistack'

import * as Styled from './SnackbarProvider.styles'

interface SnackbarProviderProps {
  children: ReactNode
}

const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const snackbarRef = useRef<MaterialSnackbarProvider>(null)

  const handleDismiss = useCallback((key) => (
    snackbarRef.current?.closeSnackbar(key)
  ), [])

  return (
    <MaterialSnackbarProvider
      disableWindowBlurListener
      autoHideDuration={6000}
      ref={snackbarRef}
      action={(key) => (
        <Styled.CloseButton
          onClick={() => handleDismiss(key)}
        />
      )}
    >
      {children}
    </MaterialSnackbarProvider>
  )
}

export { SnackbarProvider }
