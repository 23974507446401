import * as yup from 'yup'

import { PhoneSchema } from './PhoneSchema'

const usernameRegExp = /^[a-zA-Z0-9]*$/

const userColorRegExp = /^#[0-9a-fA-F]{6}$/

const EditUserDetailsSchema = yup.object().shape({
  firstName: yup.string().required('common.errors.firstName.required'),
  lastName: yup.string().required('common.errors.lastName.required'),
  phoneNumber: PhoneSchema.nullable(),
  username: yup.string().nullable()
    .min(4, 'common.errors.username.min')
    .max(20, 'common.errors.username.max')
    .matches(usernameRegExp, 'common.errors.username.invalid'),
  userColor: yup.string().nullable()
    .matches(userColorRegExp, 'common.errors.userColor.invalid'),
})

export { EditUserDetailsSchema }
