import * as Styled from '../Statistics.styled'

interface StatisticsQuizQuestionListItemProps {
  content: string
  onRedirect: () => void
}

const StatisticsQuizQuestionListItem = ({
  content,
  onRedirect: handleRedirect,
}: StatisticsQuizQuestionListItemProps) => (
  <Styled.ListItem>
    <Styled.ListItemText>
      {content}
    </Styled.ListItemText>
    <Styled.ForwardButton
      onClick={handleRedirect}
    />
  </Styled.ListItem>
)

export { StatisticsQuizQuestionListItem }
