import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'

const UserNameContainer = styled(Typography)`
  ${({ theme }) => css`
    flex-basis: 50%;
    ${theme.breakpoints.down('xs')} {
      font-size: ${theme.typography.body1.fontSize};
      font-weight: ${theme.typography.fontWeightBold};
    }
  `}
`

const ResultContainer = styled(UserNameContainer)`
  ${({ theme }) => css`
    text-align: center;
    ${theme.breakpoints.down('xs')} {
        font-weight: normal;
      }
  `}  
`

const ListContainer = styled.div`
`

const ListHeaderContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: ${theme.spacing(3.5)}px;
  `}
`

const RankingNumber = styled.div`
  ${({ theme }) => css`
  display: flex;
  align-self: center;
  margin-right: ${theme.spacing(0.8)}px;
  ${theme.breakpoints.down('sm')} {
      font-size: ${theme.typography.body1.fontSize};
    }
  `}
`

const CenterElementContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const NoDataContainer = styled(Typography)`
`

export {
  UserNameContainer,
  ResultContainer,
  ListHeaderContainer,
  ListContainer,
  RankingNumber,
  CenterElementContainer,
  NoDataContainer,
}
