import * as yup from 'yup'

import { PhoneSchema } from './PhoneSchema'

const CreateCompanySchema = yup.object().shape({
  name: yup
    .string()
    .required('companies.errors.name.required'),
  email: yup
    .string()
    .email('common.errors.email.invalid')
    .required('common.errors.email.required')
    .nullable(),
  phoneNumber: PhoneSchema
    .required('common.errors.phoneNumber.required'),
  nip: yup
    .string()
    .matches(/^[0-9]+$/, 'companies.errors.nip.length')
    .min(10, 'companies.errors.nip.length')
    .max(10, 'companies.errors.nip.length')
    .required('companies.errors.nip.required'),
  storageSpaceLimit: yup
    .number()
    .min(0, 'companies.errors.storageSpaceLimit.required')
    .required('companies.errors.storageSpaceLimit.required'),
})

export { CreateCompanySchema }
