import { useMemo, useState } from 'react'
import { Grid, Typography, CircularProgress } from '@material-ui/core'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { useGetAllCompaniesQuery } from 'api/queries/companies/companies'
import { GET_ALL_COMPANIES_QUERY_KEY } from 'api/queries/companies/keys'
import {
  useAddCompanyMutation,
  useEditCompanyMutation,
  useSetActiveMutation,
} from 'api/mutations/companies/companies'
import { CompaniesList } from 'components/data/CompaniesList/CompaniesList'
import {
  CompaniesListItem,
  CompaniesListItemType,
} from 'components/data/CompaniesList/CompaniesListItem/CompaniesListItem'
import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import * as LayoutStyled from 'components/layout/Layout.styles'
import * as Styled from './CompaniesScreen.styles'

const CompaniesScreen: React.FC = () => {
  const [isNewCompanyVisible, setIsNewCompanyVisible] = useState<boolean>(false)
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const {
    data: allCompaniesData,
    isLoading,
    isError,
  } = useGetAllCompaniesQuery()

  const companies = useMemo(() => (
    allCompaniesData?.data.map((company) => ({
      nip: '',
      storageSpaceLimit: company.storageSpaceLimitId,
      ...company,
    })) || []
  ), [allCompaniesData?.data])

  const { mutate: addCompany } = useAddCompanyMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(GET_ALL_COMPANIES_QUERY_KEY)
      setIsNewCompanyVisible(false)
    }
  })

  const { mutate: editCompany } = useEditCompanyMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(GET_ALL_COMPANIES_QUERY_KEY)
    }
  })

  const { mutate: setActive } = useSetActiveMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(GET_ALL_COMPANIES_QUERY_KEY)
    }
  })

  const handleSubmitNewCompany = ({
    id,
    storageSpaceLimit,
    ...company
  }: CompaniesListItemType) => {
    addCompany({ ...company, storageSpaceLimitId: storageSpaceLimit })
  }

  const handleCancelNewCompany = () => {
    setIsNewCompanyVisible(false)
  }

  const handleActivate = (id: number, newActive: boolean) => {
    setActive({ id, newActive })
  }

  const handleEdit = ({
    id,
    storageSpaceLimit,
    ...company
  }: CompaniesListItemType) => {
    if (id) {
      editCompany({
        ...company,
        id,
        storageSpaceLimitId: storageSpaceLimit,
      })
    }
  }

  return (
    <RouteContainer>
      <Grid container xs={12} justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Typography variant='h2'>
            {t('companies.title')}
          </Typography>
        </Grid>
        <Grid item>
          <Styled.Button
            backgroundGradient
            onClick={() => setIsNewCompanyVisible(true)}
            endIcon={<AddRoundedIcon />}
          >
            {t('companies.addNew')}
          </Styled.Button>
        </Grid>
        <LayoutStyled.HelperContainer item xs={12}>
          {isError && <Typography color='error'>{t('common.errors.generic')}</Typography>}
          {isLoading && <CircularProgress color='primary' />}
        </LayoutStyled.HelperContainer>
        <LayoutStyled.ListContainer item xs={12}>
          <LayoutStyled.HeadersRow>
            {/* TODO move flex values to constants! */}
            <LayoutStyled.HeaderText variant='subtitle1' $flexValue={0} $minWidth={52}>
              {t('common.id')}
            </LayoutStyled.HeaderText>
            <LayoutStyled.HeaderText variant='subtitle1'>
              {t('companies.name')}
            </LayoutStyled.HeaderText>
            <LayoutStyled.HeaderText variant='subtitle1' $flexValue={1.5}>
              {t('common.email')}
            </LayoutStyled.HeaderText>
            <LayoutStyled.HeaderText variant='subtitle1' $flexValue={1.25}>
              {t('common.phoneNumber')}
            </LayoutStyled.HeaderText>
            <LayoutStyled.HeaderText variant='subtitle1'>
              {t('companies.nip')}
            </LayoutStyled.HeaderText>
            <LayoutStyled.HeaderText variant='subtitle1'>
              {t('companies.storageSpaceLimit')}
            </LayoutStyled.HeaderText>
            <LayoutStyled.HeaderText
              variant='subtitle1'
              $flexValue={0}
              $minWidth={120}
              $textAlign='center'
            >
              {t('common.actions')}
            </LayoutStyled.HeaderText>
          </LayoutStyled.HeadersRow>
          {isNewCompanyVisible && (
            <CompaniesListItem
              isNew
              onSubmit={handleSubmitNewCompany}
              onCancel={handleCancelNewCompany}
            />
          )}
          <CompaniesList companies={companies} onActivate={handleActivate} onEdit={handleEdit} />
        </LayoutStyled.ListContainer>
      </Grid>
    </RouteContainer>
  )
}

export { CompaniesScreen }
