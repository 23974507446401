import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

import { useGetKnowledgeBaseEntityQuery } from 'api/queries/knowledgeBase/knowledgeBase'
import { useAddKnowledgeBaseIdObjectMutation } from 'api/mutations/knowledgeBase/knowledgeBase'
import { KnowledgeBaseContentForm, KnowledgeBaseContentFormFields } from 'components/forms/KnowledgeBaseContentForm/KnowledgeBaseContentForm'
import { parseContentFields } from 'components/forms/KnowledgeBaseContentForm/utils'
import { useDestination } from 'hooks/useDestination'
import { useKnowledgeBaseFormImage } from 'hooks/useKnowledgeBaseFormImage'
import { useLocationReturnFunction } from 'hooks/useLocationReturnFunction'
import { useCompanyId } from 'providers/CompanyProvider'
import * as Styled from '../knowledgeBase.styles'

interface ParamTypes {
  id: string
}

const KnowledgeBaseContentCreateScreen = (): JSX.Element => {
  const { id } = useParams<ParamTypes>()
  const history = useHistory()
  const returnFunction = useLocationReturnFunction()
  const onReturn = returnFunction || (() => history.goBack())

  const companyId = useCompanyId() || 0

  const { data: knowledgeBaseData } = useGetKnowledgeBaseEntityQuery(+id, { enabled: !!id })
  const {
    isAddImageMutationLoading,
    isDeleteImageMutationLoading,
    handleSubmitImage
  } = useKnowledgeBaseFormImage({
    groupId: +id || undefined,
  })

  const {
    mutate: addObjectMutate,
    isLoading: isCreateKnowledgeBaseLoading,
  } = useAddKnowledgeBaseIdObjectMutation()

  const destinationText = useDestination(knowledgeBaseData, { includeItself: true })

  const handleSubmit = async (variables: KnowledgeBaseContentFormFields, languageCode: string) => {
    const { image } = variables
    const values = parseContentFields(variables, languageCode)

    await addObjectMutate({ companyId, ...values }, {
      onSuccess: async (data) => {
        await handleSubmitImage(image, data.id)
        onReturn()
      }
    })
  }

  const isLoading = isCreateKnowledgeBaseLoading
    || isAddImageMutationLoading
    || isDeleteImageMutationLoading

  return (
    <Styled.RootContainer>
      <KnowledgeBaseContentForm
        onSubmit={handleSubmit}
        destinationPlaceholder={destinationText}
        initialDestination={knowledgeBaseData?.id}
        isLoading={isLoading}
      />
    </Styled.RootContainer>
  )
}

export { KnowledgeBaseContentCreateScreen }
