import React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Select as MuiSelect,
  SelectProps,
  InputBase,
  InputLabel,
} from '@material-ui/core'
import * as Styled from './Select.styles'

export interface Props extends SelectProps {
  errorMessage?: string
  touched?: boolean
  icon?: React.ReactNode
  className?: string
}

const Select: React.FC<Props> = ({
  label,
  errorMessage,
  touched,
  icon,
  error,
  className,
  ...rest
}) => (
  <Styled.FormControl error={error && touched} label={label} className={className}>
    {label ? <InputLabel shrink>{label}</InputLabel> : null}
    <MuiSelect
      input={<InputBase startAdornment={icon} />}
      IconComponent={ExpandMoreIcon}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      error={error}
      {...rest}
    />
    {error && touched && <Styled.FormHelperText>{errorMessage}</Styled.FormHelperText>}
  </Styled.FormControl>
)

export { Select }
export type { Props as SelectProps }
