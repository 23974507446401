import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormHelperText,
  Typography,
  FormControlProps
} from '@material-ui/core'

interface StyledFormControlProps extends FormControlProps {
  $active?: boolean
  $alignItems?: string
}

const StyledFormControl = styled(FormControl)<StyledFormControlProps>`
  ${({ theme, error, $active, $alignItems }) => css`
    display: flex;
    pointer-events: none;
    align-items: ${$alignItems || 'stretch'};

    .MuiCheckbox-root {
      color: ${$active
    ? error
      ? theme.palette.error.main
      : theme.palette.primary.main
    : theme.palette.disabled.main}
    }
  `}
`

interface StyledLabelProps {
  error?: boolean
  fontSize?: string
  fontWeight?: string
}

const StyledLabel = styled(Typography,
  { shouldForwardProp: (prop) => prop !== 'error' })<StyledLabelProps>`
  ${({ theme, error, fontSize, fontWeight }) => css`
    font-size: ${fontSize || '14px'};
    font-weight: ${fontWeight || 'normal'};
    color: ${error ? theme.palette.error.main : theme.palette.text.primary}
  `}
`

const StyledCheckbox = styled(MuiCheckbox)`
  pointer-events: auto;
`

const ErrorMessage = styled(FormHelperText)`
  ${({ theme }) => css`
    margin-top: 0;
    font-size: 14px;
    color: ${theme.palette.error.main};
  `}
`

export {
  StyledFormControl as FormControl,
  StyledLabel as Label,
  StyledCheckbox as Checkbox,
  ErrorMessage,
}
