import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, ListItem, ListItemSecondaryAction, Typography } from '@material-ui/core'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

import { Modal, ModalProps } from 'components/layout/Modal/Modal'
import { Checkbox } from 'components/inputs/Checkbox/Checkbox'
import { TextField } from 'components/inputs/TextField/TextField'
import { useUsersData } from 'hooks/useUsersData'
import { Participant } from 'types/Participant'
import { useCompanyId } from 'providers/CompanyProvider'
import * as Styled from './AddParticipantsModal.styles'

interface AddParticipantsModalProps extends Omit<ModalProps, 'children' | 'onClose'> {
  open: boolean
  onClose: () => void
  onSave: (participants: Participant[]) => void
  participants?: Participant[]
  selectedTeacherId?: number
  isLoading?: boolean
}

const AddParticipantsModal = ({
  open,
  onClose: handleClose,
  onSave: handleSave,
  participants = [],
  selectedTeacherId,
  isLoading,
}: AddParticipantsModalProps): JSX.Element => {
  const { t } = useTranslation()
  const [selectedParticipants, setSelectedParticipants] = useState<Participant[]>(participants)
  const [searchFilter, setSearchFilter] = useState<string>('')
  const companyId = useCompanyId() || 0

  const { data: usersResponse, isLoading: isLoadingParticipants, isError } = useUsersData(companyId)

  useEffect(() => {
    setSearchFilter('')
    setSelectedParticipants(participants)
  }, [open, participants])

  const users = useMemo(() => (
    usersResponse?.filter(({ id, active }) => id !== selectedTeacherId && active) || []
  ), [selectedTeacherId, usersResponse])

  const filteredUsers = useMemo(() => (
    users.filter(({ firstName, lastName }) => (
      firstName.toLowerCase().search(searchFilter) !== -1
      || lastName.toLowerCase().search(searchFilter) !== -1
    ))
  ), [users, searchFilter])

  const handleSearchFilterChange = (event: ChangeEvent<HTMLInputElement>) => (
    setSearchFilter(event.target.value.toLowerCase())
  )

  const handleDeleteButton = useCallback((deletedId: number) => {
    const currentIndex = selectedParticipants.findIndex(({ id }) => id === deletedId)

    const newSelectedParticipants = [...selectedParticipants]
    newSelectedParticipants.splice(currentIndex, 1)

    setSelectedParticipants(newSelectedParticipants)
  }, [selectedParticipants])

  const handleCheckboxToggle = useCallback((participant: Participant) => {
    const currentIndex = selectedParticipants.findIndex(({ id }) => id === participant.id)
    const newSelectedParticipants = [...selectedParticipants]

    if (currentIndex === -1) {
      newSelectedParticipants.push(participant)
    } else {
      newSelectedParticipants.splice(currentIndex, 1)
    }

    setSelectedParticipants(newSelectedParticipants)
  }, [selectedParticipants])

  return (
    <Styled.Modal open={open} onClose={handleClose}>
      <Modal.Title variant='h3'>{t('modals.addParticipants.title')}</Modal.Title>
      <Modal.Content>
        <TextField
          placeholder={t('modals.addParticipants.searchPlaceholder')}
          value={searchFilter}
          onChange={handleSearchFilterChange}
        />
        <Styled.HelpersContainer>
          {isError && <Typography color='error'>{t('common.errors.generic')}</Typography>}
          {isLoadingParticipants && <CircularProgress color='primary' />}
        </Styled.HelpersContainer>
        <Styled.List hidden={isError || isLoadingParticipants}>
          {filteredUsers.length ? (
            filteredUsers.map((user) => (
              <ListItem
                key={user.id}
                button
                onClick={() => handleCheckboxToggle(user)}
              >
                <Styled.ListItemText primary={`${user.firstName} ${user.lastName}`} />
                <ListItemSecondaryAction>
                  <Checkbox
                    onChange={() => handleCheckboxToggle(user)}
                    checked={selectedParticipants.some(({ id: selectedParticipantId }) => (
                      selectedParticipantId === user.id
                    ))}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))
          ) : (
            <Typography>
              {t('modals.addParticipants.noUsers')}
            </Typography>
          )}
        </Styled.List>
        <Styled.Divider />
        <Styled.SelectedParticipantsContainer>
          {selectedParticipants.length ? (
            selectedParticipants.map(({ id, firstName, lastName, email }) => (
              <Styled.SelectedParticipantItem key={id}>
                <Styled.SelectedParticipantItemDetailsContainer>
                  <Styled.SelectedParticipantItemDetails>
                    {`${firstName} ${lastName}`}
                  </Styled.SelectedParticipantItemDetails>
                  <Styled.SelectedParticipantItemDetails>
                    {`[${email}]`}
                  </Styled.SelectedParticipantItemDetails>
                </Styled.SelectedParticipantItemDetailsContainer>
                <Styled.DeleteButton
                  size='small'
                  onClick={() => handleDeleteButton(id)}
                >
                  <CloseRoundedIcon />
                </Styled.DeleteButton>
              </Styled.SelectedParticipantItem>
            ))
          ) : (
            <Typography>
              {t('appointment.noParticipants')}
            </Typography>
          )}
        </Styled.SelectedParticipantsContainer>
      </Modal.Content>
      <Modal.Actions
        isLoading={isLoading || isLoadingParticipants}
        onCancel={handleClose}
        onSave={() => handleSave(selectedParticipants)}
        confirmLabel={t('common.add')}
      />
    </Styled.Modal>
  )
}

export { AddParticipantsModal }
