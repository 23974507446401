import React from 'react'
import { useTranslation } from 'react-i18next'

import { QuizQuestionType } from 'types/QuestionType'
import { Select, SelectProps } from 'components/inputs/Select/Select'
import * as Styled from './QuestionTypeSelect.styles'

interface QuestionTypeSelectProps extends SelectProps {
  className?: string,
  label?: string
}

const QuestionTypeSelect: React.FC<QuestionTypeSelectProps> = ({
  className,
  label,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <Select
      label={label ?? t('common.type')}
      className={className}
      {...rest}
    >
      {Object.keys(QuizQuestionType).map((type) => (
        <Styled.MenuItem key={type} value={type}>
          {t(`questionType.${[type]}`)}
        </Styled.MenuItem>
      ))}
    </Select>
  )
}

export { QuestionTypeSelect }
