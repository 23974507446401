import styled from '@emotion/styled'
import { css, SerializedStyles, Theme } from '@emotion/react'
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  lighten,
  darken,
  CircularProgress
} from '@material-ui/core'

interface StyledButtonProps extends MuiButtonProps {
  $styles?: (theme: Theme) => SerializedStyles
}

const basicStyle = (theme: Theme): SerializedStyles => css`
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  padding: ${theme.spacing(1.5, 3.5)};
  margin: auto 0;
  text-transform: none;
  letter-spacing: 0.3px;
  color: white;
  font-size: 16px;
  font-weight: 600;
`

const TextButton = styled((props: StyledButtonProps) => (
  <MuiButton {...props} />
), { shouldForwardProp: (prop) => prop !== '$styles' })`
  ${({ theme, $styles }) => css`
    padding: ${theme.spacing(1.5, 3.5)};
    text-transform: none;
    letter-spacing: 0.3px;
    color: ${theme.palette.primary.main};
    ${$styles?.(theme)};
  `}
`

const ContainedButton = styled((props: StyledButtonProps) => (
  <MuiButton {...props} />
), { shouldForwardProp: (prop) => prop !== '$styles' })`
  ${({ theme, $styles }) => css`
    ${basicStyle(theme)};
    background-color: ${theme.palette.primary.main};
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

    &:hover {
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.16);
      background-color: ${lighten(theme.palette.primary.main, 0.2)};
    }
    &:focus {
      background-color: ${darken(theme.palette.primary.main, 0.2)};
    }
    &:disabled {
      color: white;
      background-color: ${theme.palette.button.disabled};
    }
    ${$styles?.(theme)};
  `}
`

const GradientButton = styled((props: StyledButtonProps) => (
  <MuiButton {...props} />
), { shouldForwardProp: (prop) => prop !== '$styles' })`
  ${({ theme, $styles }) => css`
    ${basicStyle(theme)};
    background-image: ${theme.palette.gradient.main};
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

    &:hover {
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.16);
      background-image: ${theme.palette.gradient.light};
    }
    &:focus {
      background-image: ${theme.palette.gradient.dark};
    }
    &:disabled {
      color: white;
      background-image: ${theme.palette.gradient.disabled};
    }
    ${$styles?.(theme)};
  `}
`

const OutlinedButton = styled((props: StyledButtonProps) => (
  <MuiButton {...props} />
), { shouldForwardProp: (prop) => prop !== '$styles' })`
  ${({ theme, $styles }) => css`
    ${basicStyle(theme)};
    font-weight: bold;
    color: ${theme.palette.text.primary};
    border: 3px solid ${theme.palette.primary.main};

    &:hover {
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.16);
      background-image: ${theme.palette.gradient.main};
      color: ${theme.palette.secondary.contrastText};
    }
    ${$styles?.(theme)};
  `}
`

const LoaderRoot = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.1);
`

const StyledCircularProgress = styled(CircularProgress)`
  color: white;
`

export {
  TextButton,
  ContainedButton,
  GradientButton,
  OutlinedButton,
  LoaderRoot,
  StyledCircularProgress as CircularProgress,
}
