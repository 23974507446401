import React from 'react'
import styled from '@emotion/styled'
import { Typography, TypographyProps } from '@material-ui/core'

import { Button } from 'components/inputs/Button/Button'

const AddAdditionalMaterialsButton = styled(Button)`
  max-width: 350px;
  margin: ${({ theme }) => theme.spacing(1, 0)};
`

const AdditionalMaterialsHelperText = styled((props: TypographyProps) => (
  <Typography variant='subtitle2' color='primary' {...props} />
))`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`

export {
  AddAdditionalMaterialsButton,
  AdditionalMaterialsHelperText,
}
