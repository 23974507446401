import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import { ContentModal } from 'components/modals/ContentModal/ContentModal'
import { DeleteModal } from 'components/modals/DeleteModal/DeleteModal'
import { ConfirmModal } from 'components/modals/ConfirmModal/ConfirmModal'
import { useGetUserKnowledgeBasePermissionsQuery } from 'api/queries/knowledgeBase/knowledgeBase'
import { useChangeKnowledgeBasePermissionsMutation } from 'api/mutations/knowledgeBase/knowledgeBase'
import {
  useDeleteUserMutation,
  useEditUserMutation,
  useRegisterCompanyIdMemberMutation,
  useRegisterOwnCompanyMemberMutation
} from 'api/mutations/users/users'
import { KnowledgeBaseVisibilityType } from 'types/KnowledgeBaseVisibilityType'
import { UserListItem, UserListItemType } from './UserListItem/UserListItem'
import * as Styled from './UserList.styles'

interface UserListProps {
  users: UserListItemType[]
  onSave?: () => void
  onCancel?: () => void
  onActivate: (id: number, isActive: boolean) => void
  className?: string
  userQueryKey: string
}

interface ConfirmModalStateType {
  isOpen: boolean,
  onSave: () => void
}

const UserList: React.FC<UserListProps> = ({
  users,
  onSave: handleSubmit = () => null,
  onCancel: handleCancel = () => null,
  onActivate: handleActivate,
  className,
  userQueryKey,
}) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [userToPerformAction, setUserToPerformAction] = useState<UserListItemType | null>(null)
  const [
    isKnowledgeBasePermissionsDialogOpen,
    setIsKnowledgeBasePermissionsDialogOpen
  ] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalStateType>({
    isOpen: false,
    onSave: () => {},
  })

  const { isLoading: isRegisteringOwnCompanyMember } = useRegisterOwnCompanyMemberMutation()
  const { isLoading: isRegisteringCompanyIdMember } = useRegisterCompanyIdMemberMutation()

  const {
    data: userKnowledgeBasePermissionsList,
    isLoading: isLoadingUserKnowledgeBasePermissionsList
  } = useGetUserKnowledgeBasePermissionsQuery(
    userToPerformAction?.id || null, {
      enabled: !!userToPerformAction
    }
  )

  const {
    mutate: changeKnowledgeBasePermissions,
    isLoading: isSavingNewKnowledgeBasePermissions
  } = useChangeKnowledgeBasePermissionsMutation()

  const handleKnowledgeBasePermissionsDialogOpen = (user: UserListItemType) => {
    setUserToPerformAction(user)
    setIsKnowledgeBasePermissionsDialogOpen(true)
  }

  const handleKnowledgeBasePermissionsDialogClose = () => {
    setIsKnowledgeBasePermissionsDialogOpen(false)
    setUserToPerformAction(null)
  }

  const handleKnowledgeBasePermissionsDialogSave = (knowledgeBaseObjectsIds: number[]) => {
    if (userToPerformAction) {
      changeKnowledgeBasePermissions({
        userId: userToPerformAction?.id,
        knowledgeBaseObjectsIds
      }, {
        onSuccess: () => {
          handleKnowledgeBasePermissionsDialogClose()
        }
      })
    }
  }

  const handleDeleteDialogOpen = (user: UserListItemType) => {
    setUserToPerformAction(user)
    setIsDeleteDialogOpen(true)
  }

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false)
    setUserToPerformAction(null)
  }

  const handleCloseConfirmModal = () => setConfirmModalState({ isOpen: false, onSave: () => {} })

  const {
    mutate: deleteUser,
    isLoading: isDeleteUserLoading,
    isError: isDeleteUserError
  } = useDeleteUserMutation()

  const handleDeleteDialogSave = () => {
    if (userToPerformAction) {
      deleteUser(userToPerformAction.id, {
        onSuccess: () => {
          handleDeleteDialogClose()
        }
      })
    }
  }

  const { mutate: editUser, isLoading: isEditLoading } = useEditUserMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(userQueryKey)
    },
  })

  const handleEdit = (
    initialValues: UserListItemType,
    values: UserListItemType,
    onSuccess?: () => void
  ) => {
    const user = users.find((u) => u.id === values.id)
    const userNewEmail = user?.emailConfirmToken?.newEmail
    const { email, ...rest } = values

    const hasEmailChanged = email !== initialValues.email
    const shouldSendEmail = (!userNewEmail && hasEmailChanged)
    || (userNewEmail && email !== user?.email && email !== userNewEmail)

    const editFunction = () => editUser({
      id: values.id,
      user: {
        ...rest,
        ...(shouldSendEmail) && { email }
      }
    }, {
      onSuccess: () => {
        handleCloseConfirmModal()
        onSuccess?.()
      }
    })
    if (shouldSendEmail && email !== user?.email) {
      setConfirmModalState({
        isOpen: true,
        onSave: editFunction,
      })
    } else {
      editFunction()
    }
  }

  const isLoading = isRegisteringOwnCompanyMember || isRegisteringCompanyIdMember
  || (!confirmModalState.isOpen && isEditLoading)

  return (
    <Styled.RootContainer className={className}>
      {users.map((user) => (
        <UserListItem
          key={user.id}
          initialValues={user}
          newEmail={user.emailConfirmToken?.newEmail}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          onActivate={handleActivate}
          onDelete={handleDeleteDialogOpen}
          onKnowledgeBasePermissionsDialogOpen={handleKnowledgeBasePermissionsDialogOpen}
          onEdit={handleEdit}
          isLoading={isLoading}
        />
      ))}
      <ContentModal
        includeFolders
        knowledgeBaseVisibilityType={KnowledgeBaseVisibilityType.UsersList}
        initialValues={userKnowledgeBasePermissionsList || []}
        isLoadingInitialValues={isLoadingUserKnowledgeBasePermissionsList}
        open={isKnowledgeBasePermissionsDialogOpen}
        onClose={handleKnowledgeBasePermissionsDialogClose}
        onSave={handleKnowledgeBasePermissionsDialogSave}
        isSaving={isSavingNewKnowledgeBasePermissions}
      />
      <DeleteModal
        open={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onSave={handleDeleteDialogSave}
        isLoading={isDeleteUserLoading}
        errorText={isDeleteUserError ? t('users.action.error.delete') : ''}
      />
      <ConfirmModal
        open={confirmModalState.isOpen}
        title={t('users.confirmEmailChangeModal.title')}
        subText={t('users.confirmEmailChangeModal.subText')}
        confirmLabel={t('common.ok')}
        onClose={handleCloseConfirmModal}
        onSave={confirmModalState.onSave}
        isLoading={isEditLoading}
      />
    </Styled.RootContainer>
  )
}

export { UserList }
