import { format } from 'date-fns'

import { EntryResultItem } from '../types'
import * as Styled from './ResultsListItem.styled'

const EntryResultsListItemData = ({
  appointmentTableResponseDTO: {
    name: appointmentName,
  },
  date: startDate,
  name,
}: EntryResultItem) => (
  <>
    <Styled.DataValueContainer>
      {name}
    </Styled.DataValueContainer>
    <Styled.DataValueContainer>
      {format(new Date(startDate), 'dd.MM.yyyy')}
    </Styled.DataValueContainer>
    <Styled.DataValueContainer>
      {appointmentName}
    </Styled.DataValueContainer>
  </>
)

export { EntryResultsListItemData }
