import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  Badge,
  Chip,
  ChipProps,
  Divider as MuiDivider,
  IconButton,
  IconButtonProps,
  Menu,
  MenuProps,
  Typography,
  TypographyProps,
} from '@material-ui/core'
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined'

import { ContentCenter } from 'components/layout/ContentCenter/ContentCenter'
import { Button } from 'components/inputs/Button/Button'
import { iconFontSize } from 'constants/icons'

interface NotificationsButtonProps extends IconButtonProps {
  badgeContent?: number
}

const NotificationsButton = styled(({ badgeContent, ...props }: NotificationsButtonProps) => (
  <IconButton {...props}>
    <Badge badgeContent={badgeContent}>
      <NotificationsOutlinedIcon fontSize='inherit' />
    </Badge>
  </IconButton>
))`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: ${iconFontSize};
  `}
`

const NotificationsMenu = styled((props: MenuProps) => (
  <Menu
    keepMounted
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))`
  & .MuiMenu-paper {
    max-width: 600px;
    max-height: calc(100% - 200px);
    width: calc(100% - 32px);
    min-width: 288px;
    display: flex;
    padding: ${({ theme }) => theme.spacing(1)}px;
  }

  & .MuiList-root {
    flex: 1;
    display: flex;
    overflow: hidden;
  }
`

const Header = styled((props: TypographyProps) => (
  <Typography {...props} variant='h2' />
))`
  ${({ theme }) => `
    margin-left: ${theme.spacing(1)}px;
    margin-bottom: ${theme.spacing(1)}px;
  `}
`

const FiltersContainer = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing(1)}px;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1)}px;
  `}
`

interface FilterChipProps extends ChipProps {
  checked?: boolean
}

const FilterChip = styled(({
  checked,
  ...props
}: FilterChipProps) => (
  <Chip color={checked ? 'primary' : 'default'} {...props} />
))`
  ${({ checked }) => `
    color: ${checked ? 'white' : 'default'};
  `}
`

const Divider = styled(MuiDivider)``

const NotificationMenuContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const NotificationMenuContentListContainer = styled.div`
  flex: 1;
  overflow: auto;
`

const ErrorMessage = styled((props: TypographyProps) => (
  <Typography {...props} color='error' />
))``

const LoaderContainer = styled(ContentCenter)`
  margin: ${({ theme }) => theme.spacing(2)}px 0;
`

const ShowMoreButton = styled(Button)`
  ${({ theme }) => `
    width: auto;
    margin-top: ${theme.spacing(1)}px;
    padding: ${theme.spacing(1)}px;
    color: ${theme.palette.text.primary};
  `}
`

export {
  NotificationsButton,
  NotificationsMenu,
  Header,
  FiltersContainer,
  FilterChip,
  Divider,
  NotificationMenuContentContainer,
  NotificationMenuContentListContainer,
  ErrorMessage,
  LoaderContainer,
  ShowMoreButton,
}
