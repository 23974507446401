import { addSeconds, format } from 'date-fns'

interface ReturnType {
  from: string
  to: string
}

const formatTimeRange = (startDateTimeString: string, duration: number): ReturnType => ({
  from: format(new Date(startDateTimeString), 'kk:mm'),
  to: format(new Date(addSeconds(new Date(startDateTimeString), duration)), 'kk:mm'),
})

export { formatTimeRange }
