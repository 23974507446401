import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  IconButton,
  List,
  ListItemText,
  Typography,
} from '@material-ui/core'

import { Modal } from 'components/layout/Modal/Modal'

const StyledModal = styled(Modal)`
  ${({ theme }) => css`
  & .MuiPaper-root {
      min-width: 650px;
      max-height: 700px;

      ${theme.breakpoints.down('sm')} {
        min-width: 100px;
      }
    }
  `}
`

const HelpersContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledList = styled(List)`
  max-height: 250px;
  overflow-y: auto;
`

const StyledListItemText = styled(ListItemText)`
  .MuiTypography-body1 {
    max-width: 450px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
  }
`

const Divider = styled.hr`
  ${({ theme }) => css`
    width: 100%;
    margin: ${theme.spacing(1.5, 0, 1)};
    height: 0;
    background-color: ${theme.palette.primary.main};
    border: 1px solid ${theme.palette.primary.main};
  `}
`

const SelectedParticipantsContainer = styled.div`
  max-height: 100px;
  overflow-y: auto;
`

const SelectedParticipantItem = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`

const SelectedParticipantItemDetailsContainer = styled.div`
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  p:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
    font-weight: bold;
  }
`

const SelectedParticipantItemDetails = styled(Typography)`
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const DeleteButton = styled(IconButton)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`

export {
  StyledModal as Modal,
  HelpersContainer,
  StyledList as List,
  StyledListItemText as ListItemText,
  Divider,
  SelectedParticipantsContainer,
  SelectedParticipantItem,
  SelectedParticipantItemDetailsContainer,
  SelectedParticipantItemDetails,
  DeleteButton,
}
