import styled from '@emotion/styled'
import { Grid } from '@material-ui/core'
import { Button } from 'components/inputs/Button/Button'

const StyledGrid = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(3)}px;
`

const StyledButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  width: 215px;
  font-size: 15px;
  white-space: nowrap;
`

const CardsListGrid = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(3, 0)};
`

export {
  StyledGrid as Grid,
  StyledButton as Button,
  CardsListGrid,
}
