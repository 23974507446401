import { MouseEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LinearProgress, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { useGetHomeAppointmentsQuery } from 'api/queries/appointments/appointments'
import { useGetTopLevelOfOwnCompanyQuery } from 'api/queries/knowledgeBase/knowledgeBase'
import { useGetUsersFromOwnCompany, useGetTeachersFromOwnCompany } from 'api/queries/users/users'
import * as Styled from './FirstStepsCard.styles'
import { FirstStepsCardAccordion } from './FirstStepsCardAccordion/FirstStepsCardAccordion'

interface Step {
  summary: string
  content: string
  redirectPath?: string
  redirectState?: unknown
  checked?: boolean
}

interface FirstStepsProps {
  className?: string
}

const FirstSteps = ({
  className,
}: FirstStepsProps): JSX.Element => {
  const { t } = useTranslation()

  const [rootAccordionExpanded, setRootAccordionExpanded] = useState<boolean>(false)

  const { data: appointments, isLoading: isAppointmentsLoading } = useGetHomeAppointmentsQuery({
    archivedOptions: 'ALL',
    forPrincipal: false,
    unpaged: true,
  })
  const {
    data: knowledgeBase,
    isLoading: isKnowledgeBaseLoading
  } = useGetTopLevelOfOwnCompanyQuery()
  const { data: teachers, isLoading: isTeachersLoading } = useGetTeachersFromOwnCompany()
  const { data: users, isLoading: isUsersLoading } = useGetUsersFromOwnCompany()

  const isLoading = isKnowledgeBaseLoading || isAppointmentsLoading
    || isUsersLoading || isTeachersLoading

  const steps: Step[] = useMemo(() => [
    {
      summary: t('home.firstSteps.users.title'),
      content: t('home.firstSteps.users.content'),
      redirectPath: '/users',
      redirectState: { newUser: true },
      checked: (users?.length || 0) > 1
    },
    {
      summary: t('home.firstSteps.knowledgeBase.title'),
      content: t('home.firstSteps.knowledgeBase.content'),
      redirectPath: '/knowledge-base/create',
      checked: !!knowledgeBase?.pages[0].content.length && !!teachers?.length
    },
    {
      summary: t('home.firstSteps.appointment.title'),
      content: t('home.firstSteps.appointment.content'),
      redirectPath: '/appointments/create',
      checked: !!appointments?.pages[0].content.length
    },
  ], [
    appointments?.pages,
    knowledgeBase?.pages,
    t,
    teachers?.length,
    users?.length
  ])

  const checkProgress = useMemo(() => (
    steps.filter((step) => !!step.checked).length * (100 / steps.length)
  ), [steps])

  const handleExpandAccordion = (event: MouseEvent) => {
    event.preventDefault()
    setRootAccordionExpanded((prev) => !prev)
  }

  useEffect(() => {
    if (!isLoading && checkProgress !== 100) {
      setRootAccordionExpanded(true)
    }
  }, [checkProgress, isLoading])

  return (
    <Styled.RootContainer className={className}>
      <Styled.Accordion expanded={rootAccordionExpanded}>
        <Styled.AccordionSummary
          onClick={handleExpandAccordion}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant='h3'
            color={!isLoading && checkProgress !== 100 ? 'primary' : 'textPrimary'}
          >
            {t('home.firstSteps.text')}
          </Typography>
        </Styled.AccordionSummary>
        <LinearProgress
          variant={isLoading ? 'indeterminate' : 'determinate'}
          value={checkProgress}
        />
        <Styled.AccordionDetails>
          {steps.map((step, index) => (
            <FirstStepsCardAccordion
              key={step.summary}
              summary={`${index + 1}. ${step.summary}`}
              content={step.content}
              redirectPath={step.redirectPath}
              redirectState={step.redirectState}
              checked={step.checked}
              isLoading={isLoading}
            />
          ))}
        </Styled.AccordionDetails>
      </Styled.Accordion>
    </Styled.RootContainer>
  )
}

export { FirstSteps }
