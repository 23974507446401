import { useTranslation } from 'react-i18next'
import { DatePicker, TimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core'
import TodayIcon from '@material-ui/icons/Today'
import TimerIcon from '@material-ui/icons/Timer'
import { FormikTouched } from 'formik'

import { DateTimeTextField } from 'components/inputs/DatePicker/DatePicker.styles'
import * as Styled from './DateTimeSection.styles'
import * as CommonStyled from '../AppointmentForm.styles'

export interface DateTimeValues {
  date: string
  startTime: string
  endTime: string
}

interface DateTimeSectionProps {
  values?: DateTimeValues
  onValueChange?: (field: string, value: string) => void
  setFieldTouched?: (field: string, value: boolean, validate?: boolean) => void
  validateField?: (field: string) => void
  touched?: FormikTouched<{
    date: boolean
    startTime: boolean
    endTime: boolean
  }>,
  errorMessage?: {
    date?: string
    startTime?: string
    endTime?: string
  }
}

const DateTimeSection: React.FC<DateTimeSectionProps> = ({
  values,
  onValueChange: handleValueChange = () => {},
  setFieldTouched = () => {},
  validateField = () => {},
  touched,
  errorMessage,
}) => {
  const { t } = useTranslation()

  const renderTimeInput = (props: MuiTextFieldProps, isTouched?: boolean) => (
    <DateTimeTextField
      $active
      $border
      id={props.id}
      name={props.name}
      onClick={props.onClick}
      onChange={props.onChange}
      value={props.value}
      touched={isTouched}
      endIcon={<TimerIcon />}
      $maxInputWidth='160px'
    />
  )

  return (
    <>
      <CommonStyled.TopLabel>{t('appointment.date')}</CommonStyled.TopLabel>
      <DatePicker
        id='dateTime.date'
        name='dateTime.date'
        disablePast
        format='dd/MM/yyyy'
        value={values?.date}
        onChange={(date: MaterialUiPickersDate) => (
          handleValueChange('dateTime.date', date?.toString() || '')
        )}
        onClose={() => {
          setFieldTouched('dateTime.date', true, false)
          validateField('dateTime.date')
        }}
        okLabel={t('common.ok')}
        cancelLabel={t('common.cancel')}
        TextFieldComponent={(props) => (
          <DateTimeTextField
            $active
            $border
            id={props.id}
            name={props.name}
            onClick={props.onClick}
            value={props.value}
            touched={touched?.date}
            errorMessage={t(errorMessage?.date || '')}
            endIcon={<TodayIcon />}
            $maxInputWidth='220px'
          />
        )}
      />
      <>
        <CommonStyled.TopLabel>{t('appointment.timeOfAppointment')}</CommonStyled.TopLabel>
        <Styled.TimeRangeContainer>
          <TimePicker
            ampm={false}
            id='dateTime.startTime'
            name='dateTime.startTime'
            value={values?.startTime}
            onChange={(date: MaterialUiPickersDate) => (
              handleValueChange('dateTime.startTime', date?.toString() || '')
            )}
            onClose={() => {
              setFieldTouched('dateTime.startTime', true, false)
              validateField('dateTime.startTime')
              validateField('dateTime.endTime')
            }}
            okLabel={t('common.ok')}
            cancelLabel={t('common.cancel')}
            TextFieldComponent={(props) => (
              renderTimeInput(props, touched?.startTime)
            )}
            minutesStep={5}
          />
          <Styled.Dash><span>&#8212;</span></Styled.Dash>
          <TimePicker
            ampm={false}
            id='dateTime.endTime'
            name='dateTime.endTime'
            value={values?.endTime}
            onChange={(date: MaterialUiPickersDate) => (
              handleValueChange('dateTime.endTime', date?.toString() || '')
            )}
            onClose={() => {
              setFieldTouched('dateTime.endTime', true, false)
              validateField('dateTime.endTime')
            }}
            okLabel={t('common.ok')}
            cancelLabel={t('common.cancel')}
            TextFieldComponent={(props) => (
              renderTimeInput(props, touched?.endTime)
            )}
            minutesStep={5}
          />
        </Styled.TimeRangeContainer>
        {(errorMessage?.startTime) && touched?.startTime ? (
          <Styled.ErrorMessage>
            {t(errorMessage.startTime)}
          </Styled.ErrorMessage>
        ) : errorMessage?.endTime && (touched?.startTime || touched?.endTime) ? (
          <Styled.ErrorMessage>
            {t(errorMessage.endTime)}
          </Styled.ErrorMessage>
        ) : null}
      </>
    </>
  )
}

export { DateTimeSection }
