import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router'

import { AppointmentCalendar } from 'components/data/AppointmentCalendar/AppointmentCalendar'
import { CompanyStatistics } from 'components/data/CompanyStatistics/CompanyStatistics'
import { WelcomeTitle } from 'components/home/WelcomeTitle/WelcomeTitle'
import { useGetHomeAppointmentsQuery } from 'api/queries/appointments/appointments'
import { StatisticsResponse } from 'api/queries/companies/types'
import { useGetCompanyStatistics } from 'api/queries/companies/companies'
import * as Styled from './CompanyHomeScreen.styles'

const statisticsDefaultData: StatisticsResponse = {
  courses: 0,
  groups: 0,
  pastAppointments: 0,
  members: 0,
  teachers: 0,
}

const CompanyHomeScreen: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const {
    data: appointmentsData
  } = useGetHomeAppointmentsQuery({
    archivedOptions: 'ALL',
    forPrincipal: false,
    unpaged: true,
  })
  const { isLoading, data: statisticsData } = useGetCompanyStatistics()

  const statistics = useMemo(() => (
    Object.entries(statisticsData?.data || statisticsDefaultData)
      .map(([key, amount]) => ({
        title: t(`companyStatistics.${[key]}`),
        amount
      })) || []
  ), [statisticsData?.data, t])

  const appointmentsList = useMemo(() => (
    appointmentsData?.pages[0].content || []
  ), [appointmentsData])

  const handleRedirectToAppointment = (id: number) => history.push(`/appointments/${id}`)

  const handleArrangeAppointment = () => history.push('/appointments/create')

  const handleAddUser = () => {
    history.push({ pathname: '/users', state: { newUser: true } })
  }

  return (
    <Styled.RootContainer>
      <WelcomeTitle />
      <Styled.FirstSteps />
      <Styled.UpperContainer>
        <Styled.ArrangementsContainer>
          <Styled.SectionTitle variant='h3'>
            {t('home.arrangeAppointment')}
          </Styled.SectionTitle>
          <Styled.ArrangeButton backgroundGradient onClick={handleArrangeAppointment}>
            {t('common.arrange')}
          </Styled.ArrangeButton>
          <Styled.CourseContainer>
            <Styled.SectionTitle variant='h3'>
              {t('home.addUser')}
            </Styled.SectionTitle>
            <Styled.ArrangeButton backgroundGradient onClick={handleAddUser}>
              {t('common.add')}
            </Styled.ArrangeButton>
          </Styled.CourseContainer>
        </Styled.ArrangementsContainer>
        <Styled.YourCompanyContainer>
          <Styled.SectionTitle variant='h3'>
            {t('home.yourCompany')}
          </Styled.SectionTitle>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <CompanyStatistics
              data={statistics}
            />
          )}
        </Styled.YourCompanyContainer>
      </Styled.UpperContainer>
      <Styled.LowerContainer>
        <Styled.CalendarContainer>
          <Styled.SectionTitle variant='h3'>
            {t('home.yourAppointments')}
          </Styled.SectionTitle>
          <AppointmentCalendar
            data={appointmentsList}
            width={325}
            onAppointmentClick={(appointment) => handleRedirectToAppointment(appointment.id)}
          />
        </Styled.CalendarContainer>
      </Styled.LowerContainer>
    </Styled.RootContainer>
  )
}

export default CompanyHomeScreen
