import { useMemo } from 'react'
import { useLocation, matchPath } from 'react-router-dom'

const screensBlacklist = [
  '/account',
  '/companies'
]

const useShouldDisplayCompanySelect = (): boolean => {
  const { pathname } = useLocation()

  const shouldDisplayCompanySelect = useMemo(
    () => (
      !screensBlacklist.some((screen) =>
        matchPath(pathname, {
          path: screen,
          exact: true,
        }))
    ),
    [pathname]
  )

  return shouldDisplayCompanySelect
}

export { useShouldDisplayCompanySelect }
