import { useMemo } from 'react'
import { useTheme } from '@emotion/react'
import { useViewport } from 'hooks/useViewport'

const useIsMobile = (): boolean => {
  const theme = useTheme()
  const { width } = useViewport()

  const isMobile = useMemo(() => (
    width < theme.breakpoints.values.sm
  ), [theme.breakpoints.values.sm, width])

  return isMobile
}

export { useIsMobile }
