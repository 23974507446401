import { useCallback, useEffect, useMemo, useState } from 'react'
import { Calendar } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Collapse } from 'react-collapse'

import { useIsSmallScreen } from 'hooks/useIsSmallScreen'
import { formatDate } from 'utils/formatDate'
import { AppointmentListItemType } from 'components/data/AppointmentList/AppointmentList'
import { CalendarAppointmentsList } from './CalendarAppointmentsList'
import { useCalendarAnimations } from './useCalendarAnimations'
import * as Styled from './AppointmentCalendar.styles'

type RenderDayType = (
  (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ) => JSX.Element
)

interface IsExpandedType {
  previous: boolean | null
  current: boolean | null
}

enum AnimationTarget {
  Paper,
  Content,
  Text
}

interface AppointmentCalendarProps {
  data: AppointmentListItemType[]
  onAppointmentClick?: (appointment: AppointmentListItemType) => void
  width: number
}

const AppointmentCalendar: React.FC<AppointmentCalendarProps> = ({
  data,
  onAppointmentClick,
  width,
}) => {
  const isSmallScreen = useIsSmallScreen()
  const [date, changeDate] = useState<Date | null>(new Date())
  const [isExpanded, setIsExpanded] = useState<IsExpandedType>({
    previous: null,
    current: null,
  })

  const expandedWidth = width * 2
  const shouldUseXAnimation = !isSmallScreen
  const shouldUseYAnimation = isSmallScreen

  const appointmentList = useMemo(() => (
    data.filter((appointment) => (
      formatDate(appointment.startDate) === formatDate(date?.toDateString() || '')
    ))
  ), [data, date])

  const {
    contentCollapseAnimation,
    contentExpandAnimation,
    contentTextAnimation,
    contentTextBackwardAnimation,
    paperCollapseAnimation,
    paperExpandAnimation
  } = useCalendarAnimations(width, expandedWidth)

  useEffect(() => {
    setIsExpanded((previousIsExpanded) => {
      const newIsExpanded = !!appointmentList.length
      return {
        previous: previousIsExpanded.current,
        current: newIsExpanded
      }
    })
  }, [appointmentList.length])

  const getAppointmentsOnDay = useCallback((day: MaterialUiPickersDate) => (
    data.filter((appointment) => (
      formatDate(appointment.startDate) === formatDate(day?.toDateString() || '')
    )).length
  ), [data])

  const renderDay = useCallback<RenderDayType>(
    (day, selectedDate, dayInCurrentMonth, dayComponent) => (
      dayInCurrentMonth
        ? (
          <Styled.AppointmentsBadge
            badgeContent={getAppointmentsOnDay(day)}
            $isCurrent={
              !!(day && selectedDate)
                && formatDate(day.toDateString()) === formatDate(selectedDate.toDateString())
            }
          >
            {dayComponent}
          </Styled.AppointmentsBadge>
        ) : dayComponent
    ), [getAppointmentsOnDay]
  )

  const getXAnimation = (animationTarget: AnimationTarget) => {
    if (shouldUseXAnimation) {
      if (animationTarget === AnimationTarget.Paper) {
        return isExpanded.current ? paperExpandAnimation : paperCollapseAnimation
      }
      if (animationTarget === AnimationTarget.Content) {
        return isExpanded.current ? contentExpandAnimation : contentCollapseAnimation
      }
      return isExpanded.current ? contentTextAnimation : contentTextBackwardAnimation
    }
    return null
  }

  const getAnimationStyle = () => (
    isExpanded.previous === null ? { animationDuration: '0s' } : undefined
  )

  return (
    <>
      <Styled.Paper
        $animation={getXAnimation(AnimationTarget.Paper)}
        style={getAnimationStyle()}
        $expandedWidth={expandedWidth}
      >
        <Styled.Content
          $animation={getXAnimation(AnimationTarget.Content)}
          style={getAnimationStyle()}
        >
          <Styled.CalendarContainer>
            <Calendar
              date={date}
              onChange={changeDate}
              renderDay={renderDay}
            />
          </Styled.CalendarContainer>
          <Styled.AppointmentsListContainer
            $animation={getXAnimation(AnimationTarget.Text)}
            style={getAnimationStyle()}
          >
            <CalendarAppointmentsList
              appointmentList={appointmentList}
              onAppointmentClick={onAppointmentClick}
            />
          </Styled.AppointmentsListContainer>
          <Collapse isOpened={shouldUseYAnimation && !!isExpanded.current}>
            <Styled.AppointmentsListContainer>
              <CalendarAppointmentsList
                appointmentList={appointmentList}
                onAppointmentClick={onAppointmentClick}
              />
            </Styled.AppointmentsListContainer>
          </Collapse>
        </Styled.Content>
      </Styled.Paper>
    </>
  )
}

export { AppointmentCalendar }
