import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import { useGetMaterialPublicUrl } from 'api/queries/didacticMaterials/didacticMaterials'
import * as Styled from './DidacticMaterialsSectionItem.styles'

interface DidacticMaterialsSectionItemProps {
  id: number
  name: string
  link: string
  type: 'FILE' | 'LINK'
}

const DidacticMaterialsSectionItem = ({
  id,
  name,
  link,
  type
}: DidacticMaterialsSectionItemProps): JSX.Element => {
  const { t } = useTranslation()
  const { data } = useGetMaterialPublicUrl(id)

  return (
    <Styled.DidacticMaterialItem>
      <Typography variant='body1'>{name}</Typography>
      <Styled.DownloadLink
        to={{ pathname: link || data?.data }}
        target='_blank'
      >
        {type === 'FILE' ? t('common.download') : t('common.go')}
      </Styled.DownloadLink>
    </Styled.DidacticMaterialItem>
  )
}

export { DidacticMaterialsSectionItem }
