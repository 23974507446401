import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Button, Typography } from '@material-ui/core'

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledButton = styled(Button)`
  ${({ theme }) => css`
    font-size: 20px;
    color: ${theme.palette.primary.main};
    background-color: ${theme.palette.background.default};
  `}
`

const ErrorText = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.error.main}
  `}
`

export {
  RootContainer,
  StyledButton as Button,
  ErrorText
}
