import { useState, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, MenuItem, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import { PageHeader } from 'components/layout/PageHeader/PageHeader'
import { ResultsGroupingType } from 'constants/resultsGroupingType'
import { ResultsList } from 'components/data/ResultsList/ResultsList'
import { useCompanyId } from 'providers/CompanyProvider'
import { useResultsData } from 'hooks/useResultsData'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import * as Styled from './ResultsScreen.styled'

const ResultsScreen = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const companyId = useCompanyId() || 0
  const [groupBy, setGroupBy] = useState<ResultsGroupingType>(ResultsGroupingType.BY_QUIZZES)
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [loaderElement, setLoaderElement] = useState<HTMLDivElement | null>(null)

  const isIntersecting = useIntersectionObserver(loaderElement)

  const loader = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setLoaderElement(node)
    }
  }, [])

  const {
    data: resultsData,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = useResultsData(companyId, groupBy, searchFilter)

  const resultsItems = useMemo(() => {
    if (resultsData?.pages) {
      return (resultsData.pages).reduce((prev, cur) => [...prev, ...cur])
    }
    return []
  }, [resultsData])

  useEffect(() => {
    if (isIntersecting && hasNextPage) fetchNextPage()
  }, [isIntersecting, hasNextPage, fetchNextPage, resultsData])

  return (
    <RouteContainer>
      <PageHeader
        title={t('results.title')}
        actions={(
          <Styled.RedirectButton
            backgroundGradient
            onClick={() => history.push('results/ranking')}
          >
            {t('results.employeeRanking')}
          </Styled.RedirectButton>
        )}
      />
      <Styled.FiltersContainer>
        <Styled.Select
          label={t('results.groupBy')}
          value={groupBy}
          onChange={(event) => setGroupBy(event.target.value as ResultsGroupingType)}
        >
          {Object.keys(ResultsGroupingType).map((type) => (
            <MenuItem key={type} value={type}>
              {t(`results.grouping.${type}`)}
            </MenuItem>
          ))}
        </Styled.Select>
        <Styled.SearchFilterContainer>
          <Styled.SearchFilter
            placeholder={t('common.startWrite')}
            searchFilterValue={searchFilter}
            onChangeSearchFilter={setSearchFilter}
          />
        </Styled.SearchFilterContainer>
      </Styled.FiltersContainer>
      {isError ? (
        <Styled.HelperContainer>
          <Typography color='error'>
            {t('common.errors.generic')}
          </Typography>
        </Styled.HelperContainer>
      ) : isLoading ? (
        <Styled.HelperContainer>
          <CircularProgress color='primary' />
        </Styled.HelperContainer>
      ) : (
        <>
          <ResultsList results={resultsItems} />
          <Styled.HelperContainer ref={loader}>
            {isFetchingNextPage && <Typography>{t('common.loading')}...</Typography>}
          </Styled.HelperContainer>
        </>
      )}
    </RouteContainer>
  )
}

export default ResultsScreen
