import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

import { Question } from 'api/types'
import { useFetch } from 'providers/FetchProvider'
import { GET_QUIZ_QUESTION_QUERY_KEY } from 'api/queriesKeys'

const useGetQuestionQuery = (
  id: number,
  options?: Omit<UseQueryOptions<Question, Error>, 'queryKey'>
): UseQueryResult<Question, Error> => {
  const { fetch } = useFetch()

  return useQuery(
    [GET_QUIZ_QUESTION_QUERY_KEY, { id }],
    () => fetch.get(`/quiz/question/${id}`).then((response) => response.data),
    options,
  )
}

export {
  useGetQuestionQuery,
}
