import { AppLayout } from 'components/layout/AppLayout/AppLayout'
import { PageLayout } from 'components/layout/PageLayout/PageLayout'
import { AppBar } from 'components/navigation/AppBar/AppBar'
import { Routes } from 'routes/Routes'
import { useShouldDisplayNavigation } from 'hooks/useShouldDisplayNavigation'
import { useFetch } from 'providers/FetchProvider'
import { LoadingScreen } from 'screens/loading/LoadingScreen'
import { useAccessToken, useUserId, useUserRole } from 'providers/UserContextProvider'

const Content: React.FC = () => {
  const shouldDisplayAppBar = useShouldDisplayNavigation()
  const { isRefreshLoading } = useFetch()
  const userRole = useUserRole()
  const accessToken = useAccessToken()
  const userId = useUserId()

  if (isRefreshLoading && !userRole && !accessToken && !userId) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <AppLayout>
      <PageLayout header={shouldDisplayAppBar && <AppBar />}>
        <Routes />
      </PageLayout>
    </AppLayout>
  )
}

export default Content
