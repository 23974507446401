import { CircularProgress, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Accordion } from 'components/inputs/Accordion/Accordion'
import { useIsMobile } from 'hooks/useIsMobile'
import { KnowledgeBaseScheduleItem } from '../KnowledgeBaseScheduleItem/KnowledgeBaseScheduleItem'
import { useIsScheduleLoading } from '../KnowledgeBaseSchedule.context'

interface KnowledgeBaseScheduleListProps {
  scheduleIds: number[]
}

const KnowledgeBaseScheduleList = ({
  scheduleIds
}: KnowledgeBaseScheduleListProps): JSX.Element => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const isLoading = useIsScheduleLoading()

  const renderScheduleItems = () => (
    scheduleIds.map((id) => (
      <KnowledgeBaseScheduleItem key={id} itemId={id} />
    ))
  )

  return (
    <>
      {isLoading && <CircularProgress />}
      <div hidden={isLoading}>
        {scheduleIds?.length ? (
          isMobile
            ? <Accordion items={renderScheduleItems()} />
            : renderScheduleItems()
        ) : (
          <Typography>
            {t('appointment.noKnowledgeBaseSchedule')}
          </Typography>
        )}
      </div>
    </>
  )
}

export { KnowledgeBaseScheduleList }
