import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { CircularProgress as MuiCircularProgress, Typography } from '@material-ui/core'

import { Button, ButtonProps } from 'components/inputs/Button/Button'
import { DeleteButton, DeleteButtonProps } from 'components/inputs/DeleteButton/DeleteButton'

const ContentFooterContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const CircularProgress = styled(MuiCircularProgress)`
  margin: auto;
`

const ErrorMessage = styled(Typography)`
  ${({ theme }) => css`
    margin: auto;
    color: ${theme.palette.error.main};
  `}
`

type ActionButtonProps = (ButtonProps | DeleteButtonProps) & { $isMobile?: boolean }

const ActionButton = styled((props: ActionButtonProps) => (
  <Button {...props} />
), { shouldForwardProp: (prop) => prop !== '$isMobile' })`
  ${({ theme, $isMobile }) => css`
    width: auto;
    margin: 0 ${theme.spacing(1)}px;

    ${$isMobile && css`
      padding: ${theme.spacing(1.5)}px;
      .MuiButton-startIcon {
        margin: 0;
      }
    `}
  `}
`

const StyledDeleteButton = styled((props: ActionButtonProps) => (
  <DeleteButton {...props} />
), { shouldForwardProp: (prop) => prop !== '$isMobile' })`
  ${({ theme, $isMobile }) => css`
    width: auto;
    margin: 0 ${theme.spacing(1)}px;

    ${$isMobile && css`
      padding: ${theme.spacing(1.5)}px;
      .MuiButton-startIcon {
        margin: 0;
      }
    `}
  `}
`

interface RowProps {
  $justifyContent?: 'flex-start' | 'flex-end' | 'center' |
  'space-between' | 'space-around' | 'space-evenly'
  $smallScreenMaxWidth?: string
}

const Row = styled.div<RowProps>`
  ${({ $justifyContent, theme, $smallScreenMaxWidth }) => `
    display: flex;
    justify-content: ${$justifyContent || 'flex-start'};
    ${theme.breakpoints.down('sm')} {
      max-width: ${$smallScreenMaxWidth || 'none'};
    }
  `}
`

export {
  ContentFooterContainer,
  CircularProgress,
  ErrorMessage,
  ActionButton,
  StyledDeleteButton as DeleteButton,
  Row
}
