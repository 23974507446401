import styled from '@emotion/styled'

const AppointmentMobileHeaderContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`

const MobileHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`

export {
  AppointmentMobileHeaderContainer,
  MobileHeaderRow,
}
