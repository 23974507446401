import { useMemo } from 'react'
import { useLocation, matchPath } from 'react-router-dom'

const screensWhitelist = [
  '/sign-in',
  '/sign-up',
  '/reset-password',
  '/reset-password/:token',
  '/email-confirm/:token',
  '/privacy-policy/',
]

const useShouldDisplayNavigation = (): boolean => {
  const { pathname } = useLocation()
  const shouldDisplayNavigation = useMemo(
    () =>
      !screensWhitelist.some((screen) =>
        matchPath(pathname, {
          path: screen,
          exact: true
        })),
    [pathname]
  )
  return shouldDisplayNavigation
}

export { useShouldDisplayNavigation }
