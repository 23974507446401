import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'

const Title = styled(Typography)`
  ${({ theme }) => css`
      font-weight: bold;
      margin-bottom: ${theme.spacing(1)}px;
  `}
`

export {
  Title
}
