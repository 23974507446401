import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { CircularProgress, Typography } from '@material-ui/core'
import { useQueryClient } from 'react-query'

import { useGetKnowledgeBaseEntityQuery } from 'api/queries/knowledgeBase/knowledgeBase'
import { useDeleteQuestionMutation } from 'api/mutations/quizes/quizes'
import { KNOWLEDGE_BASE_ENTITY_QUERY_KEY } from 'api/queriesKeys'
import { DeleteModal } from 'components/modals/DeleteModal/DeleteModal'
import { QuestionList } from 'components/data/QuestionList/QuestionList'
import * as Styled from '../knowledgeBase.styles'

interface ParamTypes {
  quizId: string
}

const KnowledgeBaseQuestionListScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const { quizId } = useParams<ParamTypes>()
  const queryClient = useQueryClient()
  const [deletedQuestionId, setDeletedQuestionId] = useState<number>(0)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

  const {
    data: knowledgeBaseData,
    isLoading,
    isError,
  } = useGetKnowledgeBaseEntityQuery(+quizId, { enabled: !!quizId })

  const {
    mutate: deleteMutate,
    isLoading: isDeleteLoading,
    isError: isDeleteError
  } = useDeleteQuestionMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(KNOWLEDGE_BASE_ENTITY_QUERY_KEY)
      setDeleteModalOpen(false)
    }
  })

  return (
    <Styled.RootContainer>
      <Styled.InfoSection
        label={t('knowledgeBase.questionPool')}
        editValueComponent={
          isError || isLoading ? (
            <Styled.HelpersContainer>
              {isError && <Typography color='error'>{t('common.errors.generic')}</Typography>}
              {isLoading && <CircularProgress color='primary' />}
            </Styled.HelpersContainer>
          ) : (
            <QuestionList
              questions={knowledgeBaseData?.questions}
              onDelete={(questionId: number) => {
                setDeletedQuestionId(questionId)
                setDeleteModalOpen(true)
              }}
            />
          )
        }
      />
      <DeleteModal
        open={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onSave={() => deleteMutate(deletedQuestionId)}
        errorText={isDeleteError ? t('common.errors.generic') : ''}
        isLoading={isDeleteLoading}
      />
    </Styled.RootContainer>
  )
}

export { KnowledgeBaseQuestionListScreen }
