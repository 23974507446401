import { QuizQuestionType } from 'types/QuestionType'
import { QuizQuestionContentFormFields } from './types'

const getDefaultValues = (
): QuizQuestionContentFormFields => (
  {
    questionType: QuizQuestionType.SINGLE_CHOICE,
    question: '',
    stepId: '',
    weight: 1,
    presentation: null,
    answers: [
      {
        id: 0,
        content: '',
        correct: true,
      },
    ],
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isStringArray(value: any): value is string[] {
  if (value instanceof Array) {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of value) {
      if (typeof item !== 'string') {
        return false
      }
    }
    return true
  }
  return false
}

export {
  getDefaultValues,
  isStringArray,
}
