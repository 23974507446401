import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { Typography, TypographyProps } from '@material-ui/core'

interface ContainerProps {
  $horizontal?: boolean
}

const Text = styled((props: TypographyProps) => (
  <Typography variant='subtitle1' {...props} />
))`
  font-weight: lighter;
`

const ContentContainer = styled.div`
  width: 100%;
`

const Container = styled.div<ContainerProps>`
  ${({ theme, $horizontal }) => css`
    display: flex;
    flex-direction: ${$horizontal ? 'row' : 'column'};
    align-items: ${$horizontal ? 'center' : 'flex-start'};

    ${$horizontal && css`
      ${ContentContainer} {
        margin-left: ${theme.spacing(2.5)}px;
      }
    `}
  `}
`

export { Container, Text, ContentContainer }
