import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { useCreateOwnAppointmentMutation } from 'api/mutations/appointments/appointments'
import { CreateAppointment } from './CreateAppointment'

const CompanyCreateAppointmentScreen = () => {
  const history = useHistory()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useCreateOwnAppointmentMutation({
    onSuccess: () => {
      queryClient.invalidateQueries('next-own-appointments')
      history.push('/appointments/detailed/next')
    }
  })

  return (
    <CreateAppointment
      isLoading={isLoading}
      onCreate={(appointment) => mutate({ appointment })}
    />
  )
}

export { CompanyCreateAppointmentScreen }
