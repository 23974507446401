import styled from '@emotion/styled'
import { Grid } from '@material-ui/core'

import { Button } from 'components/inputs/Button/Button'

const StyledButton = styled(Button)`
  max-width: 250px;
  white-space: nowrap;
`

const NoUsersContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
`

export {
  StyledButton as Button,
  NoUsersContainer,
}
