import React from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem } from '@material-ui/core'

import { UserRole } from 'types/UserRole'
import { COMPANY_ROLE, TEACHER_ROLE, PARTICIPANT_ROLE } from 'constants/roleGroups'
import { Select, SelectProps } from 'components/inputs/Select/Select'

const RoleSelect: React.FC<SelectProps> = ({
  ...rest
}) => {
  const { t } = useTranslation()

  const roles: UserRole[] = [PARTICIPANT_ROLE, TEACHER_ROLE, COMPANY_ROLE]

  return (
    <Select
      {...rest}
    >
      <MenuItem disabled value=''>
        {t('common.roles')}
      </MenuItem>
      {roles.map((role) => (
        <MenuItem key={role} value={role}>
          {t(`roles.${role}`)}
        </MenuItem>
      ))}
    </Select>
  )
}

export { RoleSelect }
