interface KnowledgeBaseEntity {
  id: number
  name: string
  parent?: KnowledgeBaseEntity
}

const getKnowledgeBaseBreadcrumbsList = (e: KnowledgeBaseEntity, arr: KnowledgeBaseEntity[] = [])
: KnowledgeBaseEntity[] => (
  !e.parent ? [e] : [...getKnowledgeBaseBreadcrumbsList(e.parent, arr), e]
)

export { getKnowledgeBaseBreadcrumbsList }
export type { KnowledgeBaseEntity }
