/* eslint-disable no-case-declarations */
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { isFuture, differenceInMinutes } from 'date-fns'
import { CircularProgress } from '@material-ui/core'

import { Notification, NotificationType, NotificationReference } from 'types/Notifications'
import { useSetSeenNotificationsMutation } from 'api/mutations/notifications/notifications'
import { formatDateWithTime } from 'utils/formatDateWithTime'
import * as Styled from './NotificationItem.styled'

type NotificationItemProps = Notification & {
  closeMenu: () => void
}

const NotificationItem = ({
  id,
  seen,
  type,
  updateDateTime,
  closeMenu,
  ...props
}: NotificationItemProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const { mutate: setAsSeenMutate, isLoading: isSettingAsSeen } = useSetSeenNotificationsMutation()

  const getNotificationTextPrimaryComponent = () => {
    switch (props.refers) {
      case NotificationReference.STRING:
        const { value } = props
        return t(`notifications.menu.${type}`, { name: value })
      default:
        const { appointment } = props
        if (appointment) {
          const { name, startDate } = appointment
          if (type === NotificationType.APPOINTMENT_SOON) {
            const distance = isFuture(new Date(startDate))
              ? differenceInMinutes(new Date(startDate), new Date(), {
                roundingMethod: 'ceil'
              })
              : 0

            return (
              <Trans
                i18nKey={`notifications.menu.${type}.${distance ? 'future' : 'archived'}`}
                count={distance}
                values={{
                  name,
                  ...(distance
                    ? { x: distance }
                    : { date: formatDateWithTime(startDate, t('common.at')) }
                  )
                }}
                components={{
                  primary: <Styled.PrimarySpan />
                }}
              />
            )
          }
          if (type === NotificationType.APPOINTMENT_DATE_CHANGED) {
            return (
              <Trans
                i18nKey={`notifications.menu.${type}`}
                values={{
                  name,
                  date: formatDateWithTime(startDate)
                }}
                components={{
                  primary: <Styled.PrimarySpan />
                }}
              />
            )
          }

          return (
            <Trans
              i18nKey={`notifications.menu.${type}`}
              values={{ name }}
              components={{ primary: <Styled.PrimarySpan /> }}
            />
          )
        }

        return (
          <Trans
            i18nKey={`notifications.menu.${type}`}
            values={{ name: t('common.noData'), date: t('common.noData') }}
            components={{ primary: <span /> }}
          />
        )
    }
  }

  const handleAppointmentRedirect = () => {
    const { refers } = props
    if (refers === NotificationReference.APPOINTMENT) {
      const { appointment } = props
      if (appointment) {
        const { id: appointmentId } = appointment
        const shouldAppendOpenForumProp = [
          NotificationType.NEW_MSG_ON_APPOINTMENT,
          NotificationType.NEW_REPLY_ON_APPOINTMENT
        ].includes(type)

        history.push(`/appointments/${appointmentId}${shouldAppendOpenForumProp ? `/${true}` : ''}`)
        closeMenu()
      }
    }
  }

  const handleMenuItemClick = () => {
    setAsSeenMutate([id])
    handleAppointmentRedirect()
  }

  return (
    <Styled.MenuItem onClick={handleMenuItemClick}>
      <Styled.NotificationText
        primary={getNotificationTextPrimaryComponent()}
        secondary={formatDateWithTime(updateDateTime)}
      />
      {isSettingAsSeen ? (
        <CircularProgress size={16} />
      ) : (
        <Styled.SeenIndicator seen={seen} />
      )}
    </Styled.MenuItem>
  )
}

export { NotificationItem }
