import { InfiniteQueryObserverResult } from 'react-query'
import {
  useGetOwnNextAppointmentsQuery,
  useGetOwnArchivedAppointmentsQuery,
  useGetCompanyNextAppointmentsQuery,
  useGetCompanyArchivedAppointmentsQuery
} from 'api/queries/appointments/appointments'
import { AppointmentsResponse } from 'api/queries/appointments/types'
import { AppointmentListType } from 'types/AppointmentListType'
import { NEXT_APPOINTMENTS, ARCHIVED_APPOINTMENTS } from 'constants/appointmentLists'
import { SortType } from 'types/SortType'

const useAppointmentsDetailedData = (
  companyId: number,
  listType: AppointmentListType,
  sortType: SortType,
  searchFilter: string,
  forPrincipal = true,
): InfiniteQueryObserverResult<AppointmentsResponse, Error> => {
  const sortOption = sortType !== 'NONE' ? sortType : null
  const pageOption = (lastPage: AppointmentsResponse) => (
    lastPage.number < lastPage.totalPages - 1 ? lastPage.number + 1 : undefined
  )
  const defaultParams = {
    sortOption,
    nameLike: searchFilter,
    forPrincipal,
  }

  const ownNextResult = useGetOwnNextAppointmentsQuery(
    defaultParams,
    {
      enabled: listType === NEXT_APPOINTMENTS && !companyId,
      getNextPageParam: pageOption
    }
  )

  const ownArchivedResult = useGetOwnArchivedAppointmentsQuery(
    defaultParams,
    {
      enabled: listType === ARCHIVED_APPOINTMENTS && !companyId,
      getNextPageParam: pageOption
    }
  )

  const companyNextResult = useGetCompanyNextAppointmentsQuery({
    companyId,
    ...defaultParams
  }, {
    enabled: listType === NEXT_APPOINTMENTS && !!companyId,
    getNextPageParam: pageOption
  })

  const companyArchivedResult = useGetCompanyArchivedAppointmentsQuery({
    companyId,
    ...defaultParams
  }, {
    enabled: listType === ARCHIVED_APPOINTMENTS && !!companyId,
    getNextPageParam: pageOption
  })

  return listType === NEXT_APPOINTMENTS
    ? (companyId ? companyNextResult : ownNextResult)
    : (companyId ? companyArchivedResult : ownArchivedResult)
}

export { useAppointmentsDetailedData }
