import { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Grid } from '@material-ui/core'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import { useTranslation } from 'react-i18next'

import {
  useAddOwnKnowledgeBaseGroupMutation,
  useAddKnowledgeBaseIdGroupMutation,
} from 'api/mutations/knowledgeBase/knowledgeBase'
import { useCompanyId } from 'providers/CompanyProvider'
import { RestrictedContent } from 'components/auth/RestrictedContent/RestrictedContent'
import { GroupModal } from 'components/modals/GroupModal/GroupModal'
import { ADMIN_ROLE } from 'constants/roleGroups'
import * as Styled from 'screens/KnowledgeBase/KnowledgeBaseScreen/KnowledgeBaseScreen.styles'
import { parseGroupFields } from 'screens/KnowledgeBase/KnowledgeBaseScreen/KnowledgeBaseScreen.utils'

interface KnowledgeBaseCreateSectionProps {
  onSuccess: (() => void) | (() => Promise<void>)
  kbEntityId?: number
}

const KnowledgeBaseCreateSection = ({
  onSuccess,
  kbEntityId,
}: KnowledgeBaseCreateSectionProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { pathname } = useLocation()
  const companyId = useCompanyId() || 0

  const [isAddGroupModalOpen, setAddGroupModalOpen] = useState<boolean>(false)

  const handleAddGroupButton = () => setAddGroupModalOpen(true)

  const handleAddContentButton = () => history.push(`${pathname}/create`)

  const {
    mutate: addOwnGroup,
    isLoading: isAddOwnGroupLoading
  } = useAddOwnKnowledgeBaseGroupMutation({
    onSuccess: async () => {
      await onSuccess()
      setAddGroupModalOpen(false)
    }
  })

  const {
    mutate: addIdGroup,
    isLoading: isAddIdGroupLoading
  } = useAddKnowledgeBaseIdGroupMutation({
    onSuccess: async () => {
      await onSuccess()
      setAddGroupModalOpen(false)
    }
  })

  const handleSaveAddOwnGroup = (name: string) => {
    const group = parseGroupFields(name, kbEntityId || null)
    addOwnGroup(group)
  }

  const handleSaveAddIdGroup = (name: string) => {
    const group = parseGroupFields(name, kbEntityId || null)
    addIdGroup({
      companyId,
      ...group
    })
  }

  return (
    <>
      <RestrictedContent accessRoles={[ADMIN_ROLE]}>
        <Grid item>
          <Styled.Button
            backgroundGradient
            onClick={handleAddGroupButton}
            endIcon={<AddRoundedIcon />}
          >
            {t('knowledgeBase.newGroup')}
          </Styled.Button>
          <Styled.Button
            backgroundGradient
            onClick={handleAddContentButton}
            endIcon={<AddRoundedIcon />}
          >
            {t('knowledgeBase.createNew')}
          </Styled.Button>
        </Grid>
      </RestrictedContent>
      <GroupModal
        open={isAddGroupModalOpen}
        onClose={() => setAddGroupModalOpen(false)}
        onSave={companyId ? handleSaveAddIdGroup : handleSaveAddOwnGroup}
        isLoading={companyId ? isAddIdGroupLoading : isAddOwnGroupLoading}
      />
    </>
  )
}

export { KnowledgeBaseCreateSection }
