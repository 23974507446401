import { ThemeOptions } from '@material-ui/core/styles'

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    gradient: GradientPaletteColor
    errorGradient: GradientPaletteColor
    disabled: Palette['primary']
    backgroundAccents: BackgroundAccentsPaletteColor
    button: ButtonPaletteColor
    icon: IconPaletteColor
    textField: TextFieldPaletteColor
    border: BorderPaletteColor
  }
  interface PaletteOptions {
    gradient: GradientPaletteColorOptions
    errorGradient: GradientPaletteColorOptions
    disabled: PaletteOptions['primary']
    backgroundAccents: BackgroundAccentsPaletteColorOptions
    button: ButtonPaletteColorOptions
    icon: IconPaletteColorOptions
    textField: TextFieldPaletteColorOptions
    border: BorderPaletteColorOptions
  }

  interface GradientPaletteColor {
    main: string
    light: string
    dark: string
    disabled: string
  }

  interface ButtonPaletteColor {
    normal: string
    focus: string
    disabled: string
  }

  interface IconPaletteColor {
    expandMore: string
    dark: string
    light: string
  }

  interface BorderPaletteColor {
    light: string
    borderRadius: string
    borderLineWidth: string
  }

  interface GradientPaletteColorOptions {
    main: string
    light?: string
    dark?: string
    disabled?: string
  }

  interface ButtonPaletteColorOptions {
    normal: string
    focus: string
    disabled: string
  }
  interface IconPaletteColorOptions {
    expandMore: string
    dark: string
    light: string
  }
  interface BorderPaletteColorOptions {
    light: string
    borderRadius: string
    borderLineWidth: string
  }

  interface BackgroundAccentsPaletteColor {
    blueish: string
    shadow: string
    shadowBlueish: string
    darkerBlueish: string
  }

  interface BackgroundAccentsPaletteColorOptions {
    blueish: string
    shadow: string
    shadowBlueish: string
    darkerBlueish: string
  }

  interface TextFieldPaletteColorOptions {
    lightGrey: string
    borderRadius: string
  }

  interface TextFieldPaletteColor {
    lightGrey: string
    borderRadius: string
  }

}
export const muiTheme: ThemeOptions = {
  palette: {
    background: {
      default: '#fff',
    },
    backgroundAccents: {
      blueish: '#F9FAFC',
      shadow: '#c8e3e6',
      shadowBlueish: '#2c707630',
      darkerBlueish: '#EEF4F9'
    },
    primary: {
      main: '#2fc5d3',
    },
    secondary: {
      main: '#3da6d5',
      contrastText: '#ffffff'
    },
    gradient: {
      main: 'linear-gradient(-90deg, #57ddda, #39a6d5)',
      light: 'linear-gradient(-90deg, #6cf6f3, #68c3ea);',
      dark: 'linear-gradient(-90deg, #49bab8, #2e8bb3)',
      disabled: 'linear-gradient(-90deg, #cecece, #a1a1a1)',
    },
    errorGradient: {
      main: 'linear-gradient(-90deg, #fc5959, #e10000)'
    },
    text: {
      primary: 'rgba(0,0,0,0.8)',
    },
    error: {
      main: '#e91818',
    },
    disabled: {
      main: '#a1a1a1',
      light: '#dcdcdc'
    },
    button: {
      normal: '#57ddda',
      focus: '#39a6d5',
      disabled: '#a1a1a1',
    },
    icon: {
      expandMore: '#6d7072',
      dark: 'rgba(0,0,0,0.5)',
      light: 'rgba(0,0,0,0.25)'
    },
    textField: {
      lightGrey: '#dededf',
      borderRadius: '6px',
    },
    border: {
      light: 'rgba(0,0,0,0.25)',
      borderRadius: '10px',
      borderLineWidth: '3px',
    },
  },
  typography: {
    fontFamily: 'Quicksand',
    fontSize: 18,
    h1: {
      fontSize: 38,
      fontWeight: 'bold',
    },
    h2: {
      fontSize: 28,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 22,
      fontWeight: 'bold',
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    body1: {
      fontFamily: 'Nunito Sans',
      fontSize: 16,
    },
    body2: {
      fontFamily: 'Nunito Sans',
      fontSize: 14,
    },
  },
  overrides: {
    MuiRadio: {
      root: {
        color: '#2fc5d3',
      },
      colorSecondary: {
        '&$checked': {
          color: '#2fc5d3',
        },
      },
    },
  },
}
