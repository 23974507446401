import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  IconButton,
  IconButtonProps,
  Toolbar,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined'

import { iconFontSize } from 'constants/icons'

const StyledToolbar = styled(Toolbar)`
  display: flex;
`

const MenuButton = styled((props: IconButtonProps) => (
  <IconButton {...props}>
    <MenuIcon fontSize='inherit' />
  </IconButton>
))`
  ${({ theme }) => css`
    margin-right: ${theme.spacing(2)};
    color: ${theme.palette.primary.dark};
    font-size: ${iconFontSize};

    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  `}
`

const AppBarContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const BackButton = styled((props: IconButtonProps) => (
  <IconButton {...props}>
    <ArrowBackIcon fontSize='inherit' />
  </IconButton>
))`
  font-size: ${iconFontSize};
`

const NotificationsButton = styled((props: IconButtonProps) => (
  <IconButton {...props}>
    <NotificationsOutlinedIcon fontSize='inherit' />
  </IconButton>
))`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: ${iconFontSize};
  `}
`

export {
  StyledToolbar as Toolbar,
  AppBarContent,
  MenuButton,
  BackButton,
  NotificationsButton,
}
