import { useGetUsersFromOwnCompany, useGetUsersFromCompanyId } from 'api/queries/users/users'

const useUsersData = (companyId: number) => {
  const ownUsers = useGetUsersFromOwnCompany({
    enabled: !companyId
  })

  const idUsers = useGetUsersFromCompanyId(
    companyId,
    {
      enabled: !!companyId
    }
  )

  return companyId ? idUsers : ownUsers
}

export { useUsersData }
