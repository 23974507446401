import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Typography } from '@material-ui/core'

import { useConfirmEmailMutation } from 'api/mutations/users/users'
import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import { useLogout } from 'providers/UserContextProvider'
import * as Styled from './EmailConfirmScreen.styles'

interface ParamTypes {
  token: string
}

const EmailConfirmScreen = (): JSX.Element => {
  const [isClicked, setIsClicked] = useState<boolean>(false)
  const { t } = useTranslation()
  const { token } = useParams<ParamTypes>()
  const history = useHistory()
  const logout = useLogout()

  const {
    mutateAsync,
    isLoading,
    isSuccess
  } = useConfirmEmailMutation({})

  const handleConfirm = async () => {
    setIsClicked(true)
    await mutateAsync({ value: token })
  }

  useEffect(() => history.listen(() => {
    logout()
  }), [history, logout])

  const handleReturn = () => {
    history.push('/')
  }

  return (
    <RouteContainer>
      <Styled.RootContainer>
        {!isClicked ? (
          <Styled.Button onClick={handleConfirm}>
            {t('confirmEmail.confirm')}
          </Styled.Button>
        ) : (
          isLoading ? (
            <CircularProgress />
          ) : (
            isSuccess ? (
              <>
                <Typography variant='subtitle1'>
                  {t('confirmEmail.success')}
                </Typography>
                <Styled.Button onClick={handleReturn}>
                  {t('confirmEmail.return')}
                </Styled.Button>
              </>
            ) : (
              <Styled.ErrorText variant='subtitle1'>
                {t('common.errors.generic')}
              </Styled.ErrorText>
            )
          )
        )}
      </Styled.RootContainer>
    </RouteContainer>
  )
}

export { EmailConfirmScreen }
