import styled from '@emotion/styled'
import { Chip, darken } from '@material-ui/core'

import { TextField } from 'components/inputs/TextField/TextField'

const AutocompleteTextField = styled(TextField)`
  ${({ theme, touched }) => `
    margin-top: 0;
    .MuiInputBase-root {
      flex-wrap: wrap;
      padding-right: ${theme.spacing(4.5)}px;
      background-color: ${touched ? 'white' : theme.palette.backgroundAccents.blueish};
    }
    .MuiAutocomplete-endAdornment {
      top: 1.5px;
    }
  `}
`

const TeacherChip = styled(Chip)`
  ${({ theme }) => `
    background-color: ${darken(theme.palette.backgroundAccents.blueish, 0.05)};
  `}
`

export { AutocompleteTextField, TeacherChip }
