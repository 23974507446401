import styled from '@emotion/styled'
import {
  Fab,
  FabProps,
  Paper,
  Popper,
} from '@material-ui/core'
import { Close, Forum } from '@material-ui/icons'

const ForumPopper = styled(Popper)`
  ${({ theme }) => `
    max-width: calc(100% - 64px);
    max-height: 80%;
    margin-bottom: ${theme.spacing(1)}px;
    display: flex;
    z-index: ${theme.zIndex.appBar};
  `}
`

const ForumPaper = styled(Paper)`
  flex: 1;
  width: 600px;
  height: 600px;
  display: flex;
  flex-direction: column;
`

const ForumContent = styled.div`
  flex: 1;
`

interface OpenButtonProps extends Omit<FabProps, 'children'> {
  $isOpen?: boolean
  $isMobile?: boolean
}

const OpenButton = styled(({ $isOpen, ...props }: OpenButtonProps) => (
  <Fab {...props}>
    {$isOpen ? (
      <Close />
    ) : (
      <Forum />
    )}
  </Fab>
))`
  ${({ theme, $isOpen }) => `
    margin: ${theme.spacing(1.5)}px 0;
    transition: transform .5s;
    transform: rotate(${$isOpen ? '90deg' : '0'});
    & .MuiSvgIcon-root {
      color: white;
    }
  `}
`

export {
  OpenButton,
  ForumPopper,
  ForumPaper,
  ForumContent,
}
