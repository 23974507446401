import { UseQueryResult } from 'react-query'

import { useGetOwnCompanyAddressables, useGetCompanyIdAddressables } from 'api/queries/companyAddressables/companyAddressables'
import { CompanyAddressables } from 'api/queries/companyAddressables/types'

const useCompanyAddressablesData = (
  companyId: number
): UseQueryResult<CompanyAddressables, Error> => {
  const ownAddressables = useGetOwnCompanyAddressables({
    enabled: !companyId
  })

  const idAddressables = useGetCompanyIdAddressables(
    companyId,
    {
      enabled: !!companyId
    }
  )

  return companyId ? idAddressables : ownAddressables
}

export { useCompanyAddressablesData }
