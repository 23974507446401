import { AppBar as MuiAppBar } from '@material-ui/core'

import { ADMIN_ROLE, COMPANY_ROLE, PARTICIPANT_ROLE, TEACHER_ROLE } from 'constants/roleGroups'
import { useDrawerToggle } from 'providers/DrawerStateProvider'
import { useShouldDisplayCompanySelect } from 'hooks/useShouldDisplayCompanySelect'
import { RestrictedContent } from 'components/auth/RestrictedContent/RestrictedContent'
import { CompanySelect } from 'components/data/CompanySelect/CompanySelect'
import { useLocationReturnFunction } from 'hooks/useLocationReturnFunction'
import { NotificationsMenu } from './NotificationsMenu/NotificationsMenu'
import * as Styled from './AppBar.styles'

const AppBar: React.FC = () => {
  const handleDrawerToggle = useDrawerToggle()
  const shouldDisplayCompanySelect = useShouldDisplayCompanySelect()
  const handleReturn = useLocationReturnFunction()

  return (
    <MuiAppBar elevation={0} position='sticky' color='transparent'>
      <Styled.Toolbar>
        <Styled.MenuButton
          color='inherit'
          edge='start'
          onClick={handleDrawerToggle}
        />
        <Styled.AppBarContent>
          {handleReturn && (
            <Styled.BackButton onClick={handleReturn} />
          )}
          <RestrictedContent accessRoles={[ADMIN_ROLE]}>
            <CompanySelect shouldDisplay={shouldDisplayCompanySelect} />
          </RestrictedContent>
        </Styled.AppBarContent>
        <RestrictedContent accessRoles={[COMPANY_ROLE, TEACHER_ROLE, PARTICIPANT_ROLE]}>
          <NotificationsMenu />
        </RestrictedContent>
      </Styled.Toolbar>
    </MuiAppBar>
  )
}

export { AppBar }
