import { ChangeEvent, useEffect, useMemo, useCallback, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router'
import { Grid, Typography } from '@material-ui/core'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import { useGetKnowledgeBaseEntityQuery } from 'api/queries/knowledgeBase/knowledgeBase'
import { useKnowledgeBaseContentData } from 'hooks/useKnowledgeBaseContentData'
import {
  useMoveKnowledgeBaseEntityMutation,
  useDeleteKnowledgeBaseObjectMutation,
  useEditKnowledgeBaseObjectMutation
} from 'api/mutations/knowledgeBase/knowledgeBase'
import { useCopyQuizMutation } from 'api/mutations/quizes/quizes'
import { KNOWLEDGE_BASE_COMPANY_ID_TOP_QUERY_KEY, KNOWLEDGE_BASE_CONTENT_QUERY_KEY, KNOWLEDGE_BASE_ENTITY_QUERY_KEY, KNOWLEDGE_BASE_OWN_COMPANY_TOP_QUERY_KEY } from 'api/queriesKeys'
import { useCompanyId } from 'providers/CompanyProvider'
import { KnowledgeBaseBreadcrumbs } from 'components/navigation/KnowledgeBaseBreadcrumbs/KnowledgeBaseBreadcrumbs'
import { CardsList } from 'components/data/CardsList/CardsList'
import { SearchFilterInput } from 'components/inputs/SearchFilterInput/SearchFilterInput'
import { SortSelect } from 'components/data/SortSelect/SortSelect'
import { GroupSelect } from 'components/data/GroupSelect/GroupSelect'
import { MoveElementModal } from 'components/modals/MoveElementModal/MoveElementModal'
import { GroupModal } from 'components/modals/GroupModal/GroupModal'
import { ConfirmModal } from 'components/modals/ConfirmModal/ConfirmModal'
import { DeleteModal } from 'components/modals/DeleteModal/DeleteModal'
import { OLDEST } from 'constants/sortTypes'
import { KnowledgeBaseCreateSection } from 'components/data/KnowledgeBase/KnowledgeBaseCreateSection'
import { SortType } from 'types/SortType'
import { KnowledgeBaseContentType } from 'types/KnowledgeBaseContentType'
import * as Styled from './KnowledgeBaseScreen.styles'
import { mapKnowledgeBasePages } from './KnowledgeBaseScreen.utils'

interface RouteParams {
  id: string
}

const KnowledgeBaseScreen = () => {
  const { t } = useTranslation()
  const { id } = useParams<RouteParams>()
  const history = useHistory()
  const queryClient = useQueryClient()
  const companyId = useCompanyId() || 0
  const didMount = useRef(false)

  const [
    filterGroupType,
    setFilterGroupType
  ] = useState<KnowledgeBaseContentType>(KnowledgeBaseContentType.ALL)
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [sortType, setSortType] = useState<SortType>(OLDEST)
  const [isEditGroupModalOpen, setEditGroupModalOpen] = useState<boolean>(false)
  const [isMoveModalOpen, setMoveModalOpen] = useState<boolean>(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [isCopyModalOpen, setCopyModalOpen] = useState<boolean>(false)
  const [changedElementId, setChangedElementId] = useState<number>(0)
  const [changedGroupName, setChangedGroupName] = useState<string>('')
  const [loaderElement, setLoaderElement] = useState<HTMLDivElement | null>(null)

  const loader = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setLoaderElement(node)
    }
  }, [])

  const {
    data: knowledgeBaseEntityData,
    isLoading: isLoadingEntity,
    isError: isErrorEntity,
  } = useGetKnowledgeBaseEntityQuery(+id, { enabled: !!id })

  const {
    data: knowledgeBaseContentData,
    isLoading: isLoadingContent,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useKnowledgeBaseContentData(companyId, filterGroupType, sortType, searchFilter, id)

  const invalidateData = async () => {
    await queryClient.invalidateQueries(KNOWLEDGE_BASE_ENTITY_QUERY_KEY)
    await queryClient.invalidateQueries(KNOWLEDGE_BASE_CONTENT_QUERY_KEY)
    await queryClient.invalidateQueries(
      companyId ? KNOWLEDGE_BASE_COMPANY_ID_TOP_QUERY_KEY : KNOWLEDGE_BASE_OWN_COMPANY_TOP_QUERY_KEY
    )
  }

  const { mutate: moveItem, isLoading: isMoveLoading } = useMoveKnowledgeBaseEntityMutation({
    onSuccess: async () => {
      await invalidateData()
      setMoveModalOpen(false)
    },
  })

  const { mutate: editGroup, isLoading: isEditGroupLoading } = useEditKnowledgeBaseObjectMutation({
    onSuccess: async () => {
      await invalidateData()
      setEditGroupModalOpen(false)
    }
  })

  const {
    mutate: deleteItem,
    isLoading: isDeleteLoading,
    isError: isDeleteError,
    reset: deleteReset
  } = useDeleteKnowledgeBaseObjectMutation({
    onSuccess: async () => {
      setDeleteModalOpen(false)
      await invalidateData()
      setSearchFilter('')
    }
  })

  const {
    mutate: copyQuiz,
    isLoading: isCopyLoading
  } = useCopyQuizMutation({
    onSuccess: async () => {
      setCopyModalOpen(false)
      await invalidateData()
    }
  })

  const isIntersecting = useIntersectionObserver(loaderElement)
  const isLoading = isLoadingEntity || isLoadingContent

  const knowledgeBaseItems = useMemo(() => {
    if (knowledgeBaseContentData?.pages) {
      const { pages } = knowledgeBaseContentData

      return mapKnowledgeBasePages(pages).reduce((prev, cur) => [...prev, ...cur])
    }
    return []
  }, [knowledgeBaseContentData])

  useEffect(() => {
    if (isIntersecting && hasNextPage && !isFetching) {
      fetchNextPage()
    }
  }, [isIntersecting, hasNextPage, fetchNextPage, knowledgeBaseContentData, isFetching])

  useEffect(() => {
    if (didMount.current) {
      history.replace('/knowledge-base')
    } else {
      didMount.current = true
    }
  }, [companyId, history])

  const handleSaveContent = (destinationId?: number | null) => {
    moveItem({
      newParentId: destinationId,
      objectIds: [changedElementId]
    })
  }

  const handleSaveEditGroup = (name: string) => {
    editGroup({
      id: changedElementId,
      dto: {
        dataType: KnowledgeBaseContentType.GROUP,
        name,
        parentId: id,
        languageCode: null
      }
    })
  }

  const handleSortSelectChange = (event: ChangeEvent<{
    name?: string | undefined
    value: unknown
  }>) => {
    setSortType(event.target.value as SortType)
  }

  const handleGroupSelectChange = (event: ChangeEvent<{
    name?: string | undefined
    value: unknown
  }>) => {
    setFilterGroupType(event.target.value as KnowledgeBaseContentType)
  }

  const getItemPath = (elementId: number, dataType?: string) => (
    `${history.location.pathname}/${dataType?.toLowerCase()}/${elementId}`
  )

  const handleEdit = (elementId: number, name: string, dataType?: string) => {
    if (dataType === KnowledgeBaseContentType.GROUP) {
      setChangedGroupName(name)
      setChangedElementId(elementId)
      setEditGroupModalOpen(true)
    } else {
      history.push(`${getItemPath(elementId, dataType)}/edit`, { from: history.location.pathname })
    }
  }

  const handleMove = (elementId: React.SetStateAction<number>) => {
    setMoveModalOpen(true)
    setChangedElementId(elementId)
  }

  const onAddSuccess = async () => {
    await invalidateData()
    setSearchFilter('')
  }

  return (
    <Styled.Container>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={3}>
          <GroupSelect
            value={filterGroupType}
            onChange={handleGroupSelectChange}
            without={[KnowledgeBaseContentType.GROUP]}
          />
        </Grid>
        <Grid item xs={3}>
          <SortSelect
            withoutNone
            value={sortType}
            onChange={handleSortSelectChange}
          />
        </Grid>
        <Grid item xs={6}>
          <SearchFilterInput
            label={t('common.filter')}
            placeholder={t('common.startWrite')}
            searchFilterValue={searchFilter}
            onChangeSearchFilter={setSearchFilter}
          />
        </Grid>
      </Grid>
      {isLoading ? (
        <Styled.CircularProgress color='primary' />
      ) : (
        <>
          <Grid item container xs={12} justifyContent='space-between' alignItems='center'>
            <Grid item>
              <KnowledgeBaseBreadcrumbs
                knowledgeBaseEntity={knowledgeBaseEntityData}
                isError={isErrorEntity}
              />
            </Grid>
            <KnowledgeBaseCreateSection
              onSuccess={onAddSuccess}
              kbEntityId={knowledgeBaseEntityData?.id}
            />
          </Grid>
          <>
            <CardsList
              data={knowledgeBaseItems}
              emptyListInfoText={t('knowledgeBase.empty')}
              onMove={(elementId) => handleMove(elementId)}
              onEdit={(elementId, name, dataType) => handleEdit(elementId, name, dataType)}
              onDelete={(elementId) => {
                setDeleteModalOpen(true)
                setChangedElementId(elementId)
              }}
              onCopy={(elementId) => {
                setCopyModalOpen(true)
                setChangedElementId(elementId)
              }}
            />
            <Styled.LoadMoreContainer ref={loader}>
              {isFetchingNextPage && <Typography>{t('common.loading')}...</Typography>}
            </Styled.LoadMoreContainer>
          </>
        </>
      )}
      <MoveElementModal
        open={isMoveModalOpen}
        movedElementId={changedElementId}
        onClose={() => setMoveModalOpen(false)}
        onSave={(desiredId) => handleSaveContent(desiredId)}
        isLoading={isMoveLoading}
      />
      <GroupModal
        open={isEditGroupModalOpen}
        isEdit
        defaultName={changedGroupName}
        onClose={() => setEditGroupModalOpen(false)}
        onSave={(name: string) => handleSaveEditGroup(name)}
        isLoading={isEditGroupLoading}
      />
      <DeleteModal
        open={isDeleteModalOpen}
        subText={t('knowledgeBase.delete.groupConfirmSubText')}
        onClose={() => {
          setDeleteModalOpen(false)
          deleteReset()
        }}
        onSave={() => deleteItem(changedElementId)}
        errorText={isDeleteError ? t('knowledgeBase.delete.error') : ''}
        isLoading={isDeleteLoading}
      />
      <ConfirmModal
        open={isCopyModalOpen}
        title={t('knowledgeBase.copy.confirmTitle')}
        subText={t('knowledgeBase.copy.quizCopySubText')}
        onClose={() => setCopyModalOpen(false)}
        onSave={() => copyQuiz(changedElementId)}
        isLoading={isCopyLoading}
        confirmLabel={t('common.copy')}
      />
    </Styled.Container>
  )
}

export default KnowledgeBaseScreen
