import { ReactNode, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Collapse } from '@material-ui/core'
import { useResultsChildData } from 'hooks/useResultsChildData'

import * as Styled from './ResultsListItem.styled'
import { ResultsItemsUnion, ResultsItemType } from '../types'

interface ResultsListItemProps {
  id: number
  type: ResultsItemType
  renderChildItem: (item: ResultsItemsUnion) => ReactNode
  children?: ReactNode
}

const ResultsListItem = ({ id, type, renderChildItem, children }: ResultsListItemProps) => {
  const { data: childData, isLoading } = useResultsChildData(id, type)
  const history = useHistory()
  const location = useLocation()
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => childData?.length && setExpanded((prevState) => !prevState)

  const handleRedirect = () => history.push(`${location.pathname}/quiz/${id}`)

  return (
    <>
      <Styled.ListItem $expanded={childData ? expanded : false}>
        <Styled.DataContainer>
          {children}
        </Styled.DataContainer>
        {childData || isLoading ? (
          <Styled.ExpandButton
            $expanded={expanded}
            disabled={!childData?.length}
            onClick={toggleExpanded}
          />
        ) : (
          <Styled.ForwardButton onClick={handleRedirect} />
        )}
      </Styled.ListItem>
      {childData && (
        <Collapse in={expanded} unmountOnExit>
          <Styled.NestedList>
            {childData?.map((child) => (
              renderChildItem(child)
            ))}
          </Styled.NestedList>
        </Collapse>
      )}
    </>
  )
}

export { ResultsListItem }
