import Modal from '@material-ui/core/Modal'
import styled from '@emotion/styled'
import { IconButton, Paper } from '@material-ui/core'

const MuiModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledPaper = styled(Paper)`
  position: relative;
  min-width: 300px;
  max-width: 700px;
  min-height: 65px;
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacing(2.5)}px;
`

export {
  MuiModal,
  StyledPaper as Paper,
  CloseButton,
}
