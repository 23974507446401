import styled from '@emotion/styled'
import { TypographyProps, Typography } from '@material-ui/core'

const ErrorMessage = styled((props: TypographyProps) => (
  <Typography color='error' {...props} />
))`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`

export { ErrorMessage }
