import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useTeachersData } from 'hooks/useTeachersData'
import { useCompanyId } from 'providers/CompanyProvider'
import * as Styled from './LecturersSection.styles'
import * as CommonStyled from '../AppointmentForm.styles'

interface Teacher {
  id: number
  firstName: string
  lastName: string
}

interface LecturerSectionProps {
  value?: Teacher
  setFieldValue: (field: string, value: Teacher) => void
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void
  touched?: boolean
  errorMessage?: string
  selectedParticipantsIds?: number[]
}

const LecturersSection: React.FC<LecturerSectionProps> = ({
  value,
  setFieldValue,
  onBlur: handleBlur,
  touched,
  errorMessage,
  selectedParticipantsIds = [],
}) => {
  const { t } = useTranslation()
  const companyId = useCompanyId() || 0

  const { data: teachersResponse, isLoading, isError } = useTeachersData(companyId)

  const teachers = useMemo(() => (
    teachersResponse?.filter(({ id }) => !selectedParticipantsIds.includes(id)) || []
  ), [selectedParticipantsIds, teachersResponse])

  return (
    <>
      <CommonStyled.TopLabel>{t('appointment.lecturer')}</CommonStyled.TopLabel>
      <Autocomplete
        id='teacher'
        popupIcon={<ExpandMoreIcon />}
        disableClearable
        value={value}
        getOptionSelected={(option, val) => option.id === val.id}
        options={teachers}
        noOptionsText={t('common.noOptions')}
        getOptionLabel={(teacher) => `${teacher.firstName} ${teacher.lastName}`}
        onChange={(_, val) => setFieldValue('teacher', val)}
        onBlur={handleBlur}
        disabled={isLoading || isError}
        loading={isLoading}
        loadingText={`${t('common.loading')}...`}
        renderInput={(params) => (
          <Styled.AutocompleteTextField
            {...params}
            placeholder={t('appointment.lecturerPlaceholder')}
            touched={touched}
            errorMessage={errorMessage}
            InputProps={{
              ...params.InputProps,
              className: '',
              disableUnderline: true,
            }}
          />
        )}
        renderTags={(tagValue, getTagProps) => (
          tagValue.map((option, index) => (
            <Styled.TeacherChip
              key={option.id}
              {...getTagProps({ index })}
              label={`${option.firstName} ${option.lastName}`}
            />
          ))
        )}
      />
    </>
  )
}

export type { Teacher }
export { LecturersSection }
