import { ReactNode } from 'react'

import * as Styled from './PageLayout.styles'

interface Props {
  header?: ReactNode
  footer?: ReactNode
}

const PageLayout: React.FC<Props> = ({ children, header, footer }) => (
  <Styled.Container>
    {header && (
      <Styled.HeaderContainer>
        {header}
      </Styled.HeaderContainer>
    )}
    <Styled.ContentContainer>
      <Styled.ScrollContainer>
        {children}
      </Styled.ScrollContainer>
    </Styled.ContentContainer>
    {footer && (
      <Styled.FooterContainer>
        {footer}
      </Styled.FooterContainer>
    )}
  </Styled.Container>
)

export { PageLayout }
