import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { useFetch } from 'providers/FetchProvider'
import { UserDetails } from 'api/types'
import {
  COMPANY_TEACHERS_QUERY_KEY,
  COMPANY_USERS_QUERY_KEY,
  OWN_COMPANY_TEACHERS_QUERY_KEY,
  OWN_COMPANY_USERS_QUERY_KEY,
  USER_DETAILS_QUERY_KEY
} from 'api/queriesKeys'

const useGetUserDetailsQuery = ()
: UseQueryResult<UserDetails, unknown> => {
  const { fetch } = useFetch()

  return useQuery(
    USER_DETAILS_QUERY_KEY,
    () => fetch.get('/user/current').then((response) => response.data),
  )
}

const useGetUsersFromOwnCompany = (
  options?: Omit<UseQueryOptions<UserDetails[], Error>, 'queryKey'>
) : UseQueryResult<UserDetails[], Error> => {
  const { fetch } = useFetch()

  return useQuery(
    OWN_COMPANY_USERS_QUERY_KEY,
    () => fetch.get('/user/company').then((response) => response.data),
    options
  )
}

const useGetUsersFromCompanyId = (
  id: number,
  options?: Omit<UseQueryOptions<UserDetails[], Error>, 'queryKey'>
): UseQueryResult<UserDetails[], Error> => {
  const { fetch } = useFetch()

  return useQuery(
    [COMPANY_USERS_QUERY_KEY, { id }],
    () => fetch.get(`/user/company/${id}`).then((response) => response.data),
    options
  )
}

const useGetTeachersFromOwnCompany = (
  options?: Omit<UseQueryOptions<UserDetails[], Error>, 'queryKey'>
)
: UseQueryResult<UserDetails[], Error> => {
  const { fetch } = useFetch()

  return useQuery(
    OWN_COMPANY_TEACHERS_QUERY_KEY,
    () => fetch.get('/user/company-teachers').then((response) => response.data),
    options
  )
}

const useGetTeachersFromCompanyId = (
  companyId: number,
  options?: Omit<UseQueryOptions<UserDetails[], Error>, 'queryKey'>
)
: UseQueryResult<UserDetails[]> => {
  const { fetch } = useFetch()

  return useQuery(
    [COMPANY_TEACHERS_QUERY_KEY, companyId],
    () => fetch.get(`/user/company-teachers/${companyId}`).then((response) => response.data),
    options
  )
}

export {
  useGetUserDetailsQuery,
  useGetUsersFromOwnCompany,
  useGetUsersFromCompanyId,
  useGetTeachersFromOwnCompany,
  useGetTeachersFromCompanyId,
}
