import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

import * as Styled from './DeletableItem.styles'

interface DeletableItemProps {
  value: string | number
  label: string
  onDelete: (val: string | number) => void
  className?: string
}

const DeletableItem = ({
  value,
  onDelete: handleDelete,
  label,
  className,
}: DeletableItemProps): JSX.Element => (
  <Styled.Item className={className}>
    <Styled.ItemName variant='body1'>
      {label}
    </Styled.ItemName>
    <Styled.DeleteButton
      size='small'
      onClick={() => handleDelete(value)}
    >
      <CloseRoundedIcon />
    </Styled.DeleteButton>
  </Styled.Item>
)

export { DeletableItem }
