import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import { AppointmentResultsItem } from '../types'
import * as Styled from './ResultsListItem.styled'

const AppointmentResultsListItemData = ({
  entriesCount,
  appointmentTableResponseDTO: {
    name,
    startDate
  }
}: AppointmentResultsItem) => {
  const { t } = useTranslation()

  return (
    <>
      <Styled.DataValueContainer>
        {name}
      </Styled.DataValueContainer>
      <Styled.DataValueContainer>
        {t('results.quizzes', { count: entriesCount })}
      </Styled.DataValueContainer>
      <Styled.DataValueContainer>
        {format(new Date(startDate), 'dd.MM.yyyy')}
      </Styled.DataValueContainer>
    </>
  )
}

export { AppointmentResultsListItemData }
