import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  IconButton,
  IconButtonProps,
  Typography,
  TypographyProps,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

interface QuestionAnswerItemContainerProps
  extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  $correct?: boolean
}

const QuestionAnswerItemContainer = styled.div<QuestionAnswerItemContainerProps>`
  ${({ theme, $correct }) => css`
    display: flex;
    align-items: center;
    border: solid ${$correct ? '#53DD6C' : '#DD1C1C'} 2px;
    border-radius: ${theme.palette.textField.borderRadius};
    margin-bottom: ${theme.spacing(1)}px;
    padding: ${theme.spacing(0, 1)};

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `}  
`

const TextContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: center
    }
  `}
`

const StyledTypography = styled((props: TypographyProps) => (
  <Typography {...props} />
))`
  ${({ theme }) => css`
    flex: 1;
    margin: ${theme.spacing(0, 1)};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    ${theme.breakpoints.down('sm')} {
      white-space: normal;
    }
  `}
`

const QuestionText = styled(StyledTypography)`
  flex: 2;
`

interface ExpandButtonProps extends IconButtonProps {
  $expanded?: boolean
}

const ExpandButton = styled((props: ExpandButtonProps) => (
  <IconButton {...props}>
    <ExpandMoreIcon />
  </IconButton>
), { shouldForwardProp: (prop) => prop !== '$expanded' })`
  ${({ $expanded }) => `
    & .MuiSvgIcon-root {
      transition: transform .2s ease-in-out;
      transform: rotate(${$expanded ? '180' : '0'}deg);
    }
  `}
`

const DetailsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing(2)}px;
    padding: ${theme.spacing(2)}px;
    border: solid ${theme.palette.disabled.light} 2px;
    border-radius: ${theme.palette.textField.borderRadius};
  `}
`

const DetailsRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    margin-bottom: ${theme.spacing(1.5)}px;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: center;
    }
  `}
`

const DetailsText = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`

const DetailsLabel = styled(DetailsText)`
  ${({ theme }) => css`
    color: ${theme.palette.disabled.main};
  
  ${theme.breakpoints.up('sm')} {
    min-width: 200px;
  }
  `}
`

interface AnswerTextProps extends TypographyProps {
  $correct?: boolean
}

const AnswerText = styled(Typography)<AnswerTextProps>`
  ${({ $correct }) => css`
    font-weight: 600;
    color: ${$correct ? '#53DD6C' : '#DD1C1C'};
  `}
`

const HelperContainer = styled.div`
  display: flex;
  justify-content: center;
`

const AnswersList = styled.div`
  display: flex;
  flex-direction: column;
`

const AnswerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export {
  QuestionAnswerItemContainer,
  TextContainer,
  StyledTypography as Typography,
  QuestionText,
  ExpandButton,
  DetailsContainer,
  DetailsRow,
  DetailsLabel,
  DetailsText,
  AnswerText,
  HelperContainer,
  AnswersList,
  AnswerContainer
}
