import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import * as locales from '@material-ui/core/locale'

import { muiTheme } from '../themes'

const theme = createTheme(muiTheme)

const languageMap = new Map([
  ['pl', locales.plPL],
  ['en', locales.enUS],
])

interface ThemeProvidersProps {
  children?: ReactNode
}

const ThemeProviders = ({ children }: ThemeProvidersProps) => {
  const { i18n: { language } } = useTranslation()

  const themeWithLocale = useMemo(
    () => createTheme(muiTheme, languageMap.get(language) || locales.enUS),
    [language],
  )

  return (
    <MuiThemeProvider theme={themeWithLocale}>
      <EmotionThemeProvider theme={theme}>
        {children}
      </EmotionThemeProvider>
    </MuiThemeProvider>
  )
}

export { ThemeProviders }
