import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

import { ReactComponent as QuizSVG } from 'assets/images/quiz-icon.svg'

const QuizIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <QuizSVG />
  </SvgIcon>
)

export default QuizIcon
