import { useEffect, useState } from 'react'
import { useFormik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'
import { HexColorPicker } from 'react-colorful'
import { useDebounce } from 'react-use'

import { UserDetails } from 'api/types'
import { TextField } from 'components/inputs/TextField/TextField'
import { TimezoneSelect } from 'components/data/TimezoneSelect/TimezoneSelect'
import { EditUserDetailsSchema } from 'validations/EditUserDetailsSchema'
import { FormSaveButton } from 'components/forms/FormSaveButton/FormSaveButton'
import * as Styled from './UserDetailsForm.styles'
import * as FormStyled from '../forms.styles'

export type UserDetailsFormFields =
  Pick<UserDetails,
  'firstName' |
  'lastName' |
  'username' |
  'phoneNumber' |
  'userColor' |
  'timeZone'
  >

interface UserDetailsFormProps {
  onSubmit?: (values: UserDetailsFormFields, helpers: FormikHelpers<UserDetailsFormFields>) => void
  isLoadingData?: boolean
  isLoadingUpdate?: boolean
  isSuccessUpdate?: boolean
  initialValues?: UserDetailsFormFields
}

const UserDetailsForm: React.FC<UserDetailsFormProps> = ({
  initialValues = {
    firstName: '',
    lastName: '',
    username: null,
    phoneNumber: null,
    userColor: '',
    timeZone: '',
  },
  onSubmit = () => {},
  isLoadingData,
  isLoadingUpdate,
  isSuccessUpdate,
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [colorValue, setColorValue] = useState(initialValues.userColor || '')

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
    dirty,
  } = useFormik<UserDetailsFormFields>({
    initialValues,
    validationSchema: EditUserDetailsSchema,
    onSubmit,
    enableReinitialize: true,
  })

  const [, cancel] = useDebounce(() => {
    setFieldValue('userColor', colorValue)
  }, 500, [colorValue])

  useEffect(() => {
    if (initialValues.userColor) {
      setColorValue(initialValues.userColor)
      cancel()
    }
  }, [cancel, initialValues])

  return (
    <FormStyled.Form onSubmit={handleSubmit}>
      {isLoadingData ? (
        <Styled.CenteredContainer>
          <CircularProgress />
        </Styled.CenteredContainer>
      ) : (
        <>
          <TextField
            label={t('common.firstName')}
            type='text'
            errorMessage={t(errors.firstName || '')}
            id='firstName'
            name='firstName'
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.firstName}
          />
          <TextField
            label={t('common.lastName')}
            type='text'
            errorMessage={t(errors.lastName || '')}
            id='lastName'
            name='lastName'
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.lastName}
          />
          <TextField
            label={t('common.username')}
            type='text'
            errorMessage={t(errors.username || '')}
            id='username'
            name='username'
            value={values.username || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.username}
          />
          <TextField
            label={t('common.phoneNumber')}
            type='text'
            errorMessage={t(errors.phoneNumber || '')}
            id='phoneNumber'
            name='phoneNumber'
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.phoneNumber}
          />
          <Styled.UserColorTextField
            label={t('common.userColor')}
            errorMessage={t(errors.userColor || '')}
            id='userColor'
            name='userColor'
            value={values.userColor}
            placeholder={t('account.colorNotSet')}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.userColor}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <Styled.UserColorPickerContainer>
                  <Styled.UserColorCircle $backgroundColor={values.userColor || '#ffffff'} />
                  <Styled.UserColorPickerButton
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  />
                </Styled.UserColorPickerContainer>
              )
            }}
          />
          <Styled.UserColorPopover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          >
            <HexColorPicker
              color={values.userColor || '#ffffff'}
              onChange={setColorValue}
            />
          </Styled.UserColorPopover>
          <TimezoneSelect
            label={t('common.timeZone')}
            id='timeZone'
            name='timeZone'
            value={values.timeZone}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.timeZone}
          />
          <FormSaveButton
            isActive={dirty}
            isSaving={isLoadingUpdate}
            isSaved={isSuccessUpdate}
          />
        </>
      )}
    </FormStyled.Form>
  )
}

export { UserDetailsForm }
