import { TypographyProps } from '@material-ui/core'
import * as Styled from './ModalTitle.styles'

const ModalTitle: React.FC<TypographyProps> = ({ children, ...props }) => (
  <Styled.Title variant='subtitle1' {...props}>
    {children}
  </Styled.Title>
)

export { ModalTitle }
