import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'

import { useFetch } from 'providers/FetchProvider'
import { AcceptPolicyVariables } from './types'

const useAcceptPrivacyPolicy = (
  options: UseMutationOptions<AxiosResponse, Error, AcceptPolicyVariables>
) : UseMutationResult<AxiosResponse, Error, AcceptPolicyVariables> => {
  const { fetch } = useFetch()

  return useMutation(
    ({ username, password, id }: AcceptPolicyVariables) =>
      fetch.post(`/privacy/accept/${id}`, {
        username,
        password,
      }),
    options,
  )
}

export { useAcceptPrivacyPolicy }
