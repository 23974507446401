import { useQueryClient } from 'react-query'
import { useAddImageMutation, useDeleteImageMutation } from 'api/mutations/knowledgeBase/knowledgeBase'
import { KnowledgeBaseEntityResponse } from 'api/queries/knowledgeBase/types'
import { KNOWLEDGE_BASE_ENTITY_QUERY_KEY } from 'api/queriesKeys'
import { KnowledgeBaseContentFormFields } from 'components/forms/KnowledgeBaseContentForm/types'

interface UseKnowledgeBaseFormImageProps {
  formInitValues?: KnowledgeBaseContentFormFields
  groupId?: number
}

const useKnowledgeBaseFormImage = ({
  formInitValues,
  groupId,
}: UseKnowledgeBaseFormImageProps = {}) => {
  const queryClient = useQueryClient()

  const {
    mutateAsync: addImageMutate,
    isLoading: isAddImageMutationLoading,
  } = useAddImageMutation({
    onSuccess: (data, { id }) => {
      queryClient.setQueryData<KnowledgeBaseEntityResponse | undefined>(
        [KNOWLEDGE_BASE_ENTITY_QUERY_KEY, { id }],
        (oldData) => (
          oldData && {
            ...oldData,
            image: data.image,
          }
        )
      )
      if (groupId) {
        queryClient.invalidateQueries([KNOWLEDGE_BASE_ENTITY_QUERY_KEY, groupId])
      }
    },
  })

  const {
    mutateAsync: deleteImageMutate,
    isLoading: isDeleteImageMutationLoading,
  } = useDeleteImageMutation({
    onSuccess: (_, id) => {
      queryClient.setQueryData<KnowledgeBaseEntityResponse | undefined>(
        [KNOWLEDGE_BASE_ENTITY_QUERY_KEY, { id }],
        (oldData) => (
          oldData && {
            ...oldData,
            image: null
          }
        )
      )
    },
  })

  const handleSubmitImage = async (image: string | File | null, knowledgeBaseId: number) => {
    if (formInitValues?.image && !image && knowledgeBaseId) {
      await deleteImageMutate(+knowledgeBaseId)
    }

    if (image && typeof image !== 'string' && knowledgeBaseId) {
      await addImageMutate({
        id: +knowledgeBaseId,
        image
      })
    }
  }

  return {
    isAddImageMutationLoading,
    isDeleteImageMutationLoading,
    handleSubmitImage,
  }
}

export { useKnowledgeBaseFormImage }
