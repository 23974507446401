import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { useFetch } from 'providers/FetchProvider'
import { NOTIFICATIONS_QUERY_KEY } from 'api/queriesKeys'
import { KnowledgeBaseEntityResponse } from 'api/queries/knowledgeBase/types'
import {
  AddImageVariables,
  AddKnowledgeBaseObjectVariables,
  EditKnowledgeBaseObjectVariables,
  MoveKnowledgeBaseEntityVariables,
  AddKnowledgeBaseGroupVariables,
  AddImageResponse,
  ChangeUserKnowledgeBasePermissionsVariables,
} from './types'

const useAddKnowledgeBaseIdObjectMutation = (
  options?: UseMutationOptions<KnowledgeBaseEntityResponse, Error, AddKnowledgeBaseObjectVariables>
) : UseMutationResult<KnowledgeBaseEntityResponse, Error, AddKnowledgeBaseObjectVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    (values) => fetch.post(`/knowledge-base/company/${values.companyId}`, values)
      .then((response) => response.data),
    {
      ...options,
      onSuccess: (data, ...rest) => {
        onSuccess?.(data, ...rest)
        enqueueSnackbar(
          t('knowledgeBase.action.success.add', { type: data.dataType, name: data.name }),
          { variant: 'success' }
        )
      },
      onError: () => {
        enqueueSnackbar(t('knowledgeBase.action.error.add'), { variant: 'error' })
      },
    },
  )
}

const useEditKnowledgeBaseObjectMutation = (
  options?: UseMutationOptions<KnowledgeBaseEntityResponse, Error, EditKnowledgeBaseObjectVariables>
) : UseMutationResult<KnowledgeBaseEntityResponse, Error, EditKnowledgeBaseObjectVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ id, dto }: EditKnowledgeBaseObjectVariables) =>
      fetch.patch(`/knowledge-base/${id}`, dto).then((response) => response.data),
    {
      ...options,
      onSuccess: (data, ...rest) => {
        onSuccess?.(data, ...rest)
        queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEY)
        enqueueSnackbar(
          t('knowledgeBase.action.success.edit', { type: data.dataType, name: data.name }),
          { variant: 'success' }
        )
      },
      onError: () => {
        enqueueSnackbar(t('knowledgeBase.action.error.edit'), { variant: 'error' })
      },
    },
  )
}

const useAddImageMutation = (
  options?: UseMutationOptions<AddImageResponse, Error, AddImageVariables>
) : UseMutationResult<AddImageResponse, Error, AddImageVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ id, image }: AddImageVariables) => {
      const formData = new FormData()
      formData.append('image', image)

      return fetch.post(`/knowledge-base/${id}/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => response.data)
    },
    {
      ...options,
      onSuccess: (data, ...rest) => {
        onSuccess?.(data, ...rest)
      },
      onError: () => {
        enqueueSnackbar(t('knowledgeBase.action.error.addImage'), { variant: 'error' })
      },
    },
  )
}

const useDeleteKnowledgeBaseObjectMutation = (
  options?: UseMutationOptions<AxiosResponse<unknown>, Error, number>
) : UseMutationResult<AxiosResponse<unknown>, Error, number> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    (id) => fetch.delete(`/knowledge-base/${id}`),
    {
      ...options,
      onSuccess: (data, ...rest) => {
        onSuccess?.(data, ...rest)
        enqueueSnackbar(t('knowledgeBase.action.success.delete'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('knowledgeBase.action.error.delete'), { variant: 'error' })
      },
    },
  )
}

const useDeleteImageMutation = (
  options: UseMutationOptions<AxiosResponse<unknown>, Error, number>
) : UseMutationResult<AxiosResponse<unknown>, Error, number> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    (id) => fetch.delete(`/knowledge-base/${id}/image`),
    {
      ...options,
      onSuccess: (data, ...rest) => {
        onSuccess?.(data, ...rest)
      },
      onError: () => {
        enqueueSnackbar(t('knowledgeBase.action.error.deleteImage'), { variant: 'error' })
      },
    },
  )
}

const useMoveKnowledgeBaseEntityMutation = (
  options?: UseMutationOptions<AxiosResponse, Error, MoveKnowledgeBaseEntityVariables>
) : UseMutationResult<AxiosResponse, Error, MoveKnowledgeBaseEntityVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    (variables: MoveKnowledgeBaseEntityVariables) =>
      fetch.patch('/knowledge-base/move-multiple', variables),
    {
      ...options,
      onSuccess: (data, ...rest) => {
        onSuccess?.(data, ...rest)
        enqueueSnackbar(t('knowledgeBase.action.success.move'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('knowledgeBase.action.error.move'), { variant: 'error' })
      },
    },
  )
}

const useAddOwnKnowledgeBaseGroupMutation = (
  options?: UseMutationOptions<AxiosResponse, Error, AddKnowledgeBaseGroupVariables>
) : UseMutationResult<AxiosResponse, Error, AddKnowledgeBaseGroupVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    (group: AddKnowledgeBaseGroupVariables) => fetch.post('/knowledge-base', group),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        onSuccess?.(data, variables, context)
        enqueueSnackbar(
          t('knowledgeBase.action.success.add', { type: variables.dataType, name: variables.name }),
          { variant: 'success' }
        )
      },
      onError: () => {
        enqueueSnackbar(t('knowledgeBase.action.error.add'), { variant: 'error' })
      },
    },
  )
}

const useAddKnowledgeBaseIdGroupMutation = (
  options?: UseMutationOptions<AxiosResponse, Error, AddKnowledgeBaseGroupVariables>
) : UseMutationResult<AxiosResponse, Error, AddKnowledgeBaseGroupVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    (group: AddKnowledgeBaseGroupVariables) => (
      fetch.post(`/knowledge-base/company/${group.companyId}`, group)
    ),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        onSuccess?.(data, variables, context)
        enqueueSnackbar(
          t('knowledgeBase.action.success.add', { type: variables.dataType, name: variables.name }),
          { variant: 'success' }
        )
      },
      onError: () => {
        enqueueSnackbar(t('knowledgeBase.action.error.addGroup'), { variant: 'error' })
      },
    },
  )
}

const useChangeKnowledgeBasePermissionsMutation = (
  options?: UseMutationOptions<unknown, Error, ChangeUserKnowledgeBasePermissionsVariables>
): UseMutationResult<unknown, Error, ChangeUserKnowledgeBasePermissionsVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ userId, knowledgeBaseObjectsIds }: ChangeUserKnowledgeBasePermissionsVariables) => (
      fetch.put(`/knowledge-base/user/${userId}/acl`, {
        ids: knowledgeBaseObjectsIds
      })
    ),
    {
      ...options,
      onSuccess: (...args) => {
        onSuccess?.(...args)
        enqueueSnackbar(t('users.action.success.knowledgeBasePermissions'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('users.action.error.knowledgeBasePermissions'), { variant: 'error' })
      },
    },
  )
}

export {
  useAddKnowledgeBaseIdObjectMutation,
  useEditKnowledgeBaseObjectMutation,
  useAddImageMutation,
  useDeleteKnowledgeBaseObjectMutation,
  useDeleteImageMutation,
  useMoveKnowledgeBaseEntityMutation,
  useAddOwnKnowledgeBaseGroupMutation,
  useAddKnowledgeBaseIdGroupMutation,
  useChangeKnowledgeBasePermissionsMutation,
}
