import { useGetAnalysisFromCompanyId, useGetAnalysisFromOwnCompany } from 'api/queries/analysis/analysis'
import { AnalysisScopeType } from 'types/AnalysisScopeType'

const useAnalysisData = (companyId: number, analysisSortBy: AnalysisScopeType) => {
  const ownAnalysis = useGetAnalysisFromOwnCompany(analysisSortBy, {
    enabled: !companyId
  })

  const companyAnalysis = useGetAnalysisFromCompanyId(
    companyId,
    analysisSortBy,
    {
      enabled: !!companyId
    }
  )

  return companyId ? companyAnalysis : ownAnalysis
}

export { useAnalysisData }
