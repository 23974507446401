import { useState, useCallback } from 'react'
import constate from 'constate'

import { Company } from 'api/queries/companies/types'

const [CompanyProvider, useCompanyId, useSetCompanyId] = constate(
  () => {
    const [companyId, setCompanyId] = useState<Company['id'] | null>(null)

    const changeCompanyId = useCallback((id) => {
      setCompanyId(id)
    }, [])

    return { companyId, changeCompanyId }
  },
  (value) => value.companyId,
  (value) => value.changeCompanyId
)

export { CompanyProvider, useCompanyId, useSetCompanyId }
