import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Slider as MuiSlider, SliderProps as MuiSliderProps } from '@material-ui/core'

interface SliderProps extends MuiSliderProps {
  $placeLabelsAlternately?: boolean
}

const Slider = styled(MuiSlider)<SliderProps>`
  ${({ $placeLabelsAlternately }) => css`
    ${$placeLabelsAlternately ? css`
      & .MuiSlider-markLabel[data-index$='1'],
      .MuiSlider-markLabel[data-index$='3'],.MuiSlider-markLabel[data-index$='5'],
      .MuiSlider-markLabel[data-index$='7'],.MuiSlider-markLabel[data-index$='9'] {
        top: -14px;
      }
    ` : null}
  `}
`

export { Slider }
