import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  CircularProgress,
  CircularProgressProps,
  Typography,
  TypographyProps,
} from '@material-ui/core'

import { Select } from 'components/inputs/Select/Select'

const RootContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const StyledCircularProgress = styled((props: CircularProgressProps) => (
  <CircularProgress color='primary' {...props} />
))`
  margin-left: 140px;
`

const ErrorMessage = styled((props: TypographyProps) => (
  <Typography color='error' {...props} />
))`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`

const StyledSelect = styled(Select)`
  ${({ theme }) => css`
    min-width: 180px;
    max-width: 280px;
    margin-left: ${theme.spacing(1)}px;

    & .MuiInputBase-root {
      border-color: ${theme.palette.primary.main};
    }

    & .MuiSelect-root {
      padding: ${theme.spacing(1.5, 2)};
    }
  `}
`

export {
  RootContainer,
  StyledCircularProgress as CircularProgress,
  ErrorMessage,
  StyledSelect as Select,
}
