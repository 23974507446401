import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import { Typography } from '@material-ui/core'

import { DidacticMaterial } from 'types/DidacticMaterial'
import { AddMaterialsModal } from 'components/modals/AddMaterialsModal/AddMaterialsModal'
import * as Styled from './AdditionalMaterialsSection.styled'
import * as CommonStyled from '../AppointmentForm.styles'

interface AdditionalMaterialsSectionProps {
  didacticMaterials?: DidacticMaterial[]
  onMaterialsChange?: (materials: DidacticMaterial[]) => Promise<void>
}

const AdditionalMaterialsSection: React.FC<AdditionalMaterialsSectionProps> = ({
  didacticMaterials = [],
  onMaterialsChange: handleMaterialsChange = () => null
}) => {
  const { t } = useTranslation()
  const [addMaterialsModalOpen, setAddMaterialsModalOpen] = useState<boolean>(false)

  const handleSave = async (materials: DidacticMaterial[]) => {
    await handleMaterialsChange(materials)

    setAddMaterialsModalOpen(false)
  }

  return (
    <>
      <CommonStyled.TopLabel>{t('appointment.additionalMaterials')}</CommonStyled.TopLabel>
      {!!didacticMaterials?.length && (
        didacticMaterials.map(({ id, name: materialName }) => (
          <Typography variant='body1' key={id}>
            {materialName}
          </Typography>
        ))
      )}
      <Styled.AddAdditionalMaterialsButton
        type='button'
        endIcon={<AddRoundedIcon />}
        onClick={() => setAddMaterialsModalOpen(true)}
      >
        {t('appointment.addAdditionalMaterials')}
      </Styled.AddAdditionalMaterialsButton>
      <Styled.AdditionalMaterialsHelperText>
        {t('appointment.additionalMaterialsHelperText')}
      </Styled.AdditionalMaterialsHelperText>
      <AddMaterialsModal
        open={addMaterialsModalOpen}
        didacticMaterials={didacticMaterials}
        onClose={() => setAddMaterialsModalOpen(false)}
        onSave={handleSave}
      />
    </>
  )
}

export type { DidacticMaterial }
export { AdditionalMaterialsSection }
