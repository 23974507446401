export const RESULTS_BY_QUIZ_QUERY_KEY = 'resultsByQuiz'
export const RESULTS_BY_USER_QUERY_KEY = 'resultsByUser'
export const RESULTS_BY_APPOINTMENT_QUERY_KEY = 'resultsByAppointment'
export const COMPANY_RESULTS_BY_QUIZ_QUERY_KEY = 'companyResultsByQuiz'
export const COMPANY_RESULTS_BY_USER_QUERY_KEY = 'companyResultsByUser'
export const COMPANY_RESULTS_BY_APPOINTMENT_QUERY_KEY = 'companyResultsByAppointment'
export const QUIZ_ENTRIES_BY_QUIZ_QUERY_KEY = 'quizEntriesByQuiz'
export const QUIZ_ENTRIES_BY_USER_QUERY_KEY = 'quizEntriesByUser'
export const QUIZZES_BY_APPOINTMENT_QUERY_KEY = 'quizzesByAppointment'
export const HISTORY_QUIZ_QUERY_KEY = 'historyQuiz'
export const HISTORY_QUESTION_QUERY_KEY = 'historyQuestion'
