import styled from '@emotion/styled'

const FloatingRightContainer = styled.div`
  position: absolute;
  right: 50px;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export {
  FloatingRightContainer,
}
