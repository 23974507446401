import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import { ResultsItemsUnion } from './types'
import { renderResultsListItem } from './utils'
import * as Styled from './ResultsList.styled'

interface ResultsListProps {
  results: ResultsItemsUnion[]
}

const ResultsList = ({
  results
}: ResultsListProps) => {
  const { t } = useTranslation()

  return (
    <Styled.List>
      {results.length ? (
        results.map((result) => renderResultsListItem(result))
      ) : (
        <Typography align='center'>
          {t('common.noData')}
        </Typography>
      )}
    </Styled.List>
  )
}

export { ResultsList }
