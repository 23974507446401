import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'

import { useGetNotificationsQuery, useGetNotificationsCountQuery } from 'api/queries/notifications/notifications'
import { Notification } from 'types/Notifications'
import { NotificationItem } from './NotificationItem/NotificationItem'
import * as Styled from './NotificationsMenu.styled'

const NotificationsMenu = () => {
  const { t } = useTranslation()
  const [anchorMenu, setAnchorMenu] = useState<HTMLElement | null>(null)
  const [seenFilterOption, setSeenFilterOption] = useState(false)

  const handleMenuClose = () => setAnchorMenu(null)

  const {
    data: notificationsData,
    isFetching,
    hasNextPage,
    error,
    fetchNextPage,
  } = useGetNotificationsQuery({
    refetchInterval: 1000 * 60,
    getNextPageParam: (lastPage, allPages) => (lastPage.last ? false : allPages?.length || 0),
    keepPreviousData: true,
  })

  const { data: unreadNotificationsData } = useGetNotificationsCountQuery()

  const notifications = useMemo(() => {
    const allNotifications = notificationsData?.pages.reduce(
      (result, page) => (page?.content ? [...result, ...page.content] : result),
      [] as Notification[]
    ) || []

    return seenFilterOption
      ? allNotifications?.filter(({ seen }) => !seen)
      : allNotifications
  }, [notificationsData, seenFilterOption])

  return (
    <>
      <Styled.NotificationsButton
        badgeContent={unreadNotificationsData}
        onClick={(event) => setAnchorMenu(event.currentTarget)}
      />
      <Styled.NotificationsMenu
        open={!!anchorMenu}
        anchorEl={anchorMenu}
        onClose={handleMenuClose}
      >
        <Styled.NotificationMenuContentContainer>
          <Styled.Header>{t('notifications.menu.title')}</Styled.Header>
          <Styled.FiltersContainer>
            <Styled.FilterChip
              checked={!seenFilterOption}
              label={t('notifications.menu.filter.all')}
              onClick={() => setSeenFilterOption(false)}
            />
            <Styled.FilterChip
              checked={seenFilterOption}
              label={t('notifications.menu.filter.notSeen')}
              onClick={() => setSeenFilterOption(true)}
            />
          </Styled.FiltersContainer>
          <Styled.Divider />
          <Styled.NotificationMenuContentListContainer>
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                {...notification}
                closeMenu={handleMenuClose}
              />
            ))}
          </Styled.NotificationMenuContentListContainer>
          {error && (
            <Styled.ErrorMessage>{t('notifications.menu.error')}</Styled.ErrorMessage>
          )}
          {isFetching ? (
            <Styled.LoaderContainer>
              <CircularProgress />
            </Styled.LoaderContainer>
          ) : hasNextPage && (
            <>
              <Styled.Divider />
              <Styled.ShowMoreButton variant='text' onClick={fetchNextPage}>
                {t('notifications.menu.loadMore')}
              </Styled.ShowMoreButton>
            </>
          )}
        </Styled.NotificationMenuContentContainer>
      </Styled.NotificationsMenu>
    </>
  )
}

export { NotificationsMenu }
