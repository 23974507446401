import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { useFetch } from 'providers/FetchProvider'
import {
  Company,
  AddCompanyVariables,
  EditCompanyVariables,
  SetActiveVariables,
} from './types'

const useAddCompanyMutation = (
  options: UseMutationOptions<Company, Error, AddCompanyVariables>
): UseMutationResult<Company, Error, AddCompanyVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    (company: AddCompanyVariables) => (
      fetch.post('/company', company).then((response) => response.data)
    ),
    {
      ...options,
      onSuccess: (data, ...rest) => {
        onSuccess?.(data, ...rest)
        enqueueSnackbar(
          t('companies.action.success.add', { name: data.name }),
          { variant: 'success' }
        )
      },
      onError: (_, { name }) => {
        enqueueSnackbar(t('companies.action.error.add', { name }), { variant: 'error' })
      },
    },
  )
}

const useEditCompanyMutation = (
  options: UseMutationOptions<Company, Error, EditCompanyVariables>
): UseMutationResult<Company, Error, EditCompanyVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ id, ...company }: EditCompanyVariables) => (
      fetch.put(`/company/${id}`, company).then((response) => response.data)
    ),
    {
      ...options,
      onSuccess: (data, ...rest) => {
        onSuccess?.(data, ...rest)
        enqueueSnackbar(
          t('companies.action.success.edit', { name: data.name }),
          { variant: 'success' }
        )
      },
      onError: () => {
        enqueueSnackbar(t('companies.action.error.edit'), { variant: 'error' })
      },
    },
  )
}

const useSetActiveMutation = (
  options?: UseMutationOptions<Company, Error, SetActiveVariables>
): UseMutationResult<Company, Error, SetActiveVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ id, newActive }: SetActiveVariables) => {
      const newFormData = new FormData()
      newFormData.append('newActive', JSON.stringify(newActive))
      return fetch.patch(`/company/${id}/active`, newFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    {
      ...options,
      onSuccess: (...args) => {
        onSuccess?.(...args)
        enqueueSnackbar(t('companies.action.success.setActive'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('companies.action.error.setActive'), { variant: 'error' })
      },
    },
  )
}

export {
  useAddCompanyMutation,
  useEditCompanyMutation,
  useSetActiveMutation
}
