import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { AppointmentListItem, AppointmentListItemProps } from './AppointmentListItem/AppointmentListItem'

const appointmentListItemStyle = css`
  margin-top: 10px;
  margin-bottom: 10px;
`

const firstItemStyle = css`
  ${appointmentListItemStyle};
  margin-top: 0px;
`

interface StyledAppointmentListItemProps extends AppointmentListItemProps {
  $isFirst: boolean
}

const StyledAppointmentListItem = styled(AppointmentListItem)<StyledAppointmentListItemProps>`
  ${({ $isFirst: isFirst }) => (isFirst ? firstItemStyle : appointmentListItemStyle)};
`

const NoAppointmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export {
  StyledAppointmentListItem as AppointmentListItem,
  NoAppointmentsContainer,
}
