import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TextField } from 'components/inputs/TextField/TextField'
import * as Styled from './tabs.styles'

interface Url {
  link: string
  name: string
}

interface UrlTabProps {
  onUrlAdd: (url: Url) => void
}

const UrlTab = ({
  onUrlAdd: handleUrlAdd,
}: UrlTabProps): JSX.Element => {
  const { t } = useTranslation()

  const [urlText, setUrlText] = useState<string>('')
  const [name, setName] = useState<string>('')

  const handleUrlTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUrlText(event.target.value)
  }

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleAddUrlButtonClick = () => {
    handleUrlAdd({ link: urlText, name: name || urlText })
    setUrlText('')
    setName('')
  }

  return (
    <Styled.AddMaterialContainer>
      <Styled.AddText variant='body2'>
        {t('modals.addMaterials.addUrl')}
      </Styled.AddText>
      <Styled.InputContainer>
        <TextField
          label={t('modals.addMaterials.url')}
          value={urlText}
          onChange={handleUrlTextFieldChange}
          placeholder={t('modals.addMaterials.urlPlaceholder')}
        />
        <TextField
          label={t('modals.addMaterials.name')}
          value={name}
          onChange={handleNameChange}
          placeholder={t('modals.addMaterials.namePlaceholder')}
        />
      </Styled.InputContainer>
      <Styled.AddButton
        disabled={!urlText}
        onClick={handleAddUrlButtonClick}
      >
        {t('common.add')}
      </Styled.AddButton>
    </Styled.AddMaterialContainer>
  )
}

export { UrlTab }
export type { Url }
