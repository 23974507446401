import styled from '@emotion/styled'
import { ImagePreview } from 'components/data/ImagePreview/ImagePreview'

import { UploadImageButton } from 'components/inputs/UploadImageButton/UploadImageButton'

const StyledImagePreview = styled(ImagePreview)`
  width: 200px;
  height: 150px;
`

const StyledUploadFileButton = styled(UploadImageButton)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(2.5)}px;
`

export {
  StyledUploadFileButton as UploadFileButton,
  StyledImagePreview as ImagePreview,
}
