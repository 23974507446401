import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import { AccountAccordion } from 'components/inputs/AccountAccordion/AccountAccordion'
import { ChangePasswordForm, ChangePasswordFormFields } from 'components/forms/ChangePasswordForm/ChangePasswordForm'
import { ChangeEmailForm, ChangeEmailFormFields } from 'components/forms/ChangeEmailForm/ChangeEmailForm'
import { UserDetailsForm, UserDetailsFormFields } from 'components/forms/UserDetailsForm/UserDetailsForm'
import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import { ChangeLanguageForm } from 'components/forms/ChangeLanguageForm/ChangeLanguageForm'
import { EmailNotificationsForm } from 'components/forms/NotificationsForm/EmailNotificationsForm'
import { useGetUserDetailsQuery } from 'api/queries/users/users'
import {
  useChangePasswordMutation,
  useEditUserDetailsMutation,
  useEditUserNotificationsMutation,
  useChangeEmailMutation
} from 'api/mutations/users/users'
import { USER_DETAILS_QUERY_KEY } from 'api/queriesKeys'
import * as Styled from './AccountScreen.styles'

const AccountScreen = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const {
    data: userDetails,
    isLoading: isLoadingUserDetails,
    isError: isErrorUserDetails,
  } = useGetUserDetailsQuery()

  const {
    mutate: detailsMutate,
    isLoading: isLoadingUserDetailsEdit,
    isSuccess: isSuccessUserDetailsEdit,
  } = useEditUserDetailsMutation({
    onSuccess: (data) => {
      queryClient.setQueryData(USER_DETAILS_QUERY_KEY, data)
    }
  })

  const {
    mutate: passwordMutate,
    isLoading: isLoadingPasswordEdit,
    isSuccess: isSuccessPasswordEdit,
  } = useChangePasswordMutation()

  const {
    mutate: emailMutate,
    isLoading: isLoadingEmailEdit,
    isSuccess: isSuccessEmailEdit,
  } = useChangeEmailMutation()

  const {
    mutate: notificationsMutate,
  } = useEditUserNotificationsMutation()

  const handleUserDetailsFormSubmit = (values: UserDetailsFormFields) => {
    detailsMutate({
      ...values,
      username: values.username !== '' ? values.username : null,
      phoneNumber: values.phoneNumber !== '' ? values.phoneNumber : null
    })
  }

  const handleChangePasswordFormSubmit = ({
    confirmNewPassword,
    ...values
  }: ChangePasswordFormFields) => {
    passwordMutate(values)
  }

  const handleChangeEmailFormSubmit = ({
    email
  }: ChangeEmailFormFields) => {
    emailMutate({ value: email })
  }

  const handleNotificationsFormChange = (value: boolean) => {
    notificationsMutate({ notificationsByEmail: value })
  }

  return (
    <RouteContainer>
      <Styled.ContentContainer>
        <Styled.ColumnContainer>
          <AccountAccordion
            initialExpanded
            title={t('account.userDetails')}
            details={(
              <UserDetailsForm
                initialValues={userDetails}
                onSubmit={handleUserDetailsFormSubmit}
                isLoadingData={isLoadingUserDetails}
                isLoadingUpdate={isLoadingUserDetailsEdit}
                isSuccessUpdate={isSuccessUserDetailsEdit}
              />
            )}
          />
          <AccountAccordion
            title={t('account.credentials')}
            details={(
              <>
                <ChangeEmailForm
                  initialValue={userDetails?.email}
                  onSubmit={handleChangeEmailFormSubmit}
                  isLoadingData={isLoadingUserDetails}
                  isError={isErrorUserDetails}
                  isLoadingUpdate={isLoadingEmailEdit}
                  isSuccessUpdate={isSuccessEmailEdit}
                />
                <ChangePasswordForm
                  onSubmit={handleChangePasswordFormSubmit}
                  isLoading={isLoadingPasswordEdit}
                  isSuccess={isSuccessPasswordEdit}
                />
              </>
            )}
          />
          <AccountAccordion
            title={t('account.languageAndNotifications')}
            details={(
              <>
                <ChangeLanguageForm />
                <EmailNotificationsForm
                  initialValue={userDetails?.notificationsOptions.notificationsByEmail}
                  onChange={handleNotificationsFormChange}
                  isLoading={isLoadingUserDetails}
                  isError={isErrorUserDetails}
                />
              </>
            )}
          />
        </Styled.ColumnContainer>
      </Styled.ContentContainer>
    </RouteContainer>
  )
}

export default AccountScreen
