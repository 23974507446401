import styled from '@emotion/styled'
import { Button } from 'components/inputs/Button/Button'

const ValuesContainer = styled.div`
  overflow-y: auto;
  max-height: 190px;
`

const InputContainer = styled.div`
  display: flex;
`

const AddButton = styled(Button)`
  width: auto;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`

export {
  InputContainer,
  AddButton,
  ValuesContainer,
}
