import styled from '@emotion/styled'
import { Grid, CircularProgress, Typography, TypographyProps } from '@material-ui/core'

const HelperContainer = styled(Grid)`
  display: flex;
  justify-content: center;
`

const HeadersRow = styled.div`
  display: flex;
  padding: 0 16px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`

interface HeaderTextProps extends TypographyProps {
  $minWidth?: number
  $flexValue?: number
  $textAlign?: string
}

const HeaderText = styled((props: HeaderTextProps) => (
  <Typography noWrap {...props} />
), {
  shouldForwardProp: (prop) => !['$minWidth', '$flexValue', '$textAlign'].includes(prop)
})`
  ${({ $flexValue = 1, $minWidth = 0, $textAlign = 'left' }) => `
    flex: ${$flexValue};
    min-width: ${$minWidth}px;
    margin: 0 5px;
    text-align: ${$textAlign};
  `}
`

const ListContainer = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`

const StyledCircularProgress = styled(CircularProgress)`
  margin: ${({ theme }) => theme.spacing(2)}px auto;
`

export {
  HelperContainer,
  HeadersRow,
  HeaderText,
  ListContainer,
  StyledCircularProgress as CircularProgress,
}
