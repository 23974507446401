import React, { useMemo, forwardRef } from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { ListItemIcon, Divider } from '@material-ui/core'
import { Omit } from '@material-ui/types'

import * as Styled from './NavigationListItem.styles'

interface Props {
  to?: string
  selected?: boolean
  icon?: React.ReactNode
  primary: string
  onClick?: () => void
  className?: string
  divider?: boolean
}

const NavigationListItem: React.FC<Props> = ({
  to,
  selected,
  icon,
  primary,
  onClick,
  className,
  divider,
}) => {
  const renderLink = useMemo(() => (
    to ? (
      forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      ))) : 'div'
  ), [to])

  return (
    <>
      {divider && <Divider />}
      <Styled.ListItem
        button
        selected={selected}
        onClick={onClick}
        component={renderLink}
        className={className}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <Styled.ListItemText primary={primary} />
      </Styled.ListItem>
    </>
  )
}
export { NavigationListItem }
