import React, { useMemo } from 'react'
import { Redirect } from 'react-router-dom'

import { useUserRole } from 'providers/UserContextProvider'
import { ADMIN_ROLE, COMPANY_ROLE, TEACHER_ROLE, PARTICIPANT_ROLE } from 'constants/roleGroups'
import ParticipantPreviewAppointmentScreen from './ParticipantPreviewAppointmentScreen'
import RestrictedPreviewAppointmentScreen from './RestrictedPreviewAppointmentScreen'

const PreviewAppointmentScreen: React.FC = () => {
  const userRole = useUserRole()
  const screenToRender = useMemo(() => {
    switch (userRole) {
      case ADMIN_ROLE:
      case COMPANY_ROLE:
      case TEACHER_ROLE:
      // think of way to distinguish if user is a teacher or participant of specific appointment
      // get by id teachers of chosen appointment and verify teachers presence
      // teacher may potentially remove himself from lecturers
        return <RestrictedPreviewAppointmentScreen />
      case PARTICIPANT_ROLE:
        return <ParticipantPreviewAppointmentScreen />
      default:
        return <Redirect to='/' />
    }
  }, [userRole])

  return <>{screenToRender}</>
}

export default PreviewAppointmentScreen
