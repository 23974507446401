import { ReactNode } from 'react'

import { AppointmentResultsListItemData } from './ResultsListItem/AppointmentResultsListItemData'
import { QuizResultsListItemData } from './ResultsListItem/QuizResultsListItemData'
import { UserResultsListItemData } from './ResultsListItem/UserResultsListItemData'
import { EntryResultsListItemData } from './ResultsListItem/EntryResultListItemData'
import { ResultsListItem } from './ResultsListItem/ResultsListItem'
import {
  ResultsItemType,
  ResultsItemsUnion,
} from './types'

const renderResultsListItem = (item: ResultsItemsUnion): ReactNode => {
  const { id, type } = item

  switch (type) {
    case ResultsItemType.APPOINTMENT:
      return (
        <ResultsListItem
          key={id}
          id={id}
          type={type}
          renderChildItem={renderResultsListItem}
        >
          <AppointmentResultsListItemData {...item} />
        </ResultsListItem>
      )
    case ResultsItemType.QUIZ:
      return (
        <ResultsListItem
          key={id}
          id={id}
          type={type}
          renderChildItem={renderResultsListItem}
        >
          <QuizResultsListItemData {...item} />
        </ResultsListItem>
      )
    case ResultsItemType.USER:
      return (
        <ResultsListItem
          key={id}
          id={id}
          type={type}
          renderChildItem={renderResultsListItem}
        >
          <UserResultsListItemData {...item} />
        </ResultsListItem>
      )
    case ResultsItemType.ENTRY:
      return (
        <ResultsListItem
          key={id}
          id={id}
          type={type}
          renderChildItem={renderResultsListItem}
        >
          <EntryResultsListItemData {...item} />
        </ResultsListItem>
      )
    default:
      return null
  }
}

export { renderResultsListItem }
