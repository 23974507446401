import { css, Theme, SerializedStyles } from '@emotion/react'

const InputBaseStyle = (theme: Theme, label?: React.ReactNode): SerializedStyles => css`
  width: 100%;
  margin: ${theme.spacing(1.5, 0)};
  .MuiInputBase-root {
    border: 1px solid;
    border-radius: 6px;
    border-color: #dededf;
    background-color: white;
    padding: 0;
    > input {
      border-radius: 6px;
    }
  }
  .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.54);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .MuiInputBase-root.Mui-focused {
    border-color: #686868;
  }
  .MuiInputBase-root.Mui-error {
    border-color: ${theme.palette.error.main};
  }
  .MuiInputBase-formControl {
    margin-top: ${label ? '22px' : '0'};
  }
  .MuiFormLabel-root {
    display: inline-block;
    white-space: nowrap;
    color: ${theme.palette.text.primary};
  }
  .MuiFormLabel-root.Mui-error {
    color: ${theme.palette.error.main};
  }
  .MuiInputLabel-shrink {
    transform: translate(0, -6px) scale(0.9);
  }
  .MuiInput-input {
    padding: ${theme.spacing(2)}px;
  }
  .MuiInputBase-inputAdornedStart {
    padding-left: ${theme.spacing(1)}px;
  }
  .MuiFormHelperText-root {
    font-size: 14px;
  }
  .MuiInputBase-input:-webkit-autofill {
    box-shadow: #fff;
    -webkit-box-shadow: 0 0 0 50px #fff inset;
    -webkit-text-fill-color: ${theme.palette.text.primary};
    filter: none;
  }
  & input:placeholder-shown {
    text-overflow: ellipsis;
  }
`

export { InputBaseStyle }
