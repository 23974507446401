import { useParams, useLocation } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'

import { useGetQuizAnalysisQuery } from 'api/queries/statistics/statistics'
import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import { PageHeader } from 'components/layout/PageHeader/PageHeader'
import { StatisticsQuizQuestionList } from 'components/data/Statistics/StatisticsQuizQuestionList/StatisticsQuizQuestionList'
import * as Styled from './Statistics.styled'

interface RouteParams {
  quizId: string
}

interface StateType {
  title?: string
}

const StatisticsDetailScreen = () => {
  const { quizId } = useParams<RouteParams>()
  const { state } = useLocation<StateType | undefined>()

  const {
    data: quizData,
    isLoading,
    isError
  } = useGetQuizAnalysisQuery(+quizId)

  return (
    <RouteContainer>
      {isLoading ? (
        <Styled.LoaderContainer>
          <CircularProgress color='primary' />
        </Styled.LoaderContainer>
      ) : (
        <PageHeader title={state?.title} />
      )}
      <StatisticsQuizQuestionList
        isLoading={isLoading}
        isError={isError}
        items={quizData}
      />
    </RouteContainer>
  )
}

export default StatisticsDetailScreen
