import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Typography as MuiTypography } from '@material-ui/core'

import { Modal } from 'components/layout/Modal/Modal'

const StyledModal = styled(Modal)`
  ${({ theme }) => css`

  & .MuiPaper-root {
      min-width: 650px;
      max-height: 700px;
      
      display: flex;
      flex-direction: column;

      & > div:first-of-type {
        overflow: hidden;
      }

      ${theme.breakpoints.down('sm')} {
        min-width: 100px;
      }
    }
  `}
`

const HelpersContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Typography = styled(MuiTypography)`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const TreeViewContainer = styled.div`
  display: flex;
  overflow: hidden;
`

export {
  StyledModal as Modal,
  HelpersContainer,
  Typography,
  ContentContainer,
  TreeViewContainer,
}
