import * as yup from 'yup'

import { QuizQuestionType } from '../types/QuestionType'

interface Answer {
  id?: number
  content?: string
  correct?: boolean
}

const answersSchema = yup.array().min(1, 'quiz.errors.answers.minFields').of(
  yup.object().shape({
    id: yup.number(),
    content: yup.string().required('quiz.errors.answers.content.required'),
    correct: yup.boolean(),
  })
).test('answersTest', (value, testCtx) => {
  const { parent: { questionType } } = testCtx
  if (questionType === QuizQuestionType.SINGLE_CHOICE) {
    return value?.some(({ correct }: Answer) => correct) ? (
      true
    ) : (
      testCtx.createError({ message: 'quiz.errors.answers.minCorrect' })
    )
  }
  return true
})

const QuizContentSchema = yup.object().shape({
  questionType: yup
    .string()
    .oneOf([QuizQuestionType.SINGLE_CHOICE, QuizQuestionType.MULTIPLE_CHOICE],
      'quiz.errors.type.incorrect')
    .required('quiz.errors.type.required'),
  question: yup
    .string()
    .required('quiz.errors.question.required'),
  weight: yup
    .number()
    .integer('quiz.errors.weight.integer')
    .required('quiz.errors.weight.required')
    .min(1, 'quiz.errors.weight.min')
    .max(5, 'quiz.errors.weight.max')
    .typeError('quiz.errors.weight.invalid'),
  stepId: yup
    .string()
    .matches(/^[0-9]*$/, 'quiz.errors.stepId.invalid'),
  answers: answersSchema
})

export {
  QuizContentSchema
}
