import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded'

import { useGetHomeAppointmentsQuery } from 'api/queries/appointments/appointments'
import { AppointmentList } from 'components/data/AppointmentList/AppointmentList'
import { NEXT_APPOINTMENTS } from 'constants/appointmentLists'
import * as Styled from './AppointmentsSection.styles'

const AppointmentsSection: React.FC = () => {
  const { t } = useTranslation()

  const {
    data: appointmentsData,
    isLoading,
  } = useGetHomeAppointmentsQuery()

  const appointmentsList = appointmentsData?.pages[0].content.slice(0, 2) || []

  return (
    <>
      <Styled.AppointmentListHeader>
        <Typography variant='h3'>
          {t('appointments.nextAppointments')}
        </Typography>
        <Styled.SeeAllLink to={`appointments/detailed/${NEXT_APPOINTMENTS}`}>
          {t('common.seeAll')}
          <KeyboardArrowRightRoundedIcon />
        </Styled.SeeAllLink>
      </Styled.AppointmentListHeader>
      <AppointmentList
        appointments={appointmentsList}
        appointmentsBasePath='appointments'
        isLoading={isLoading}
      />
    </>
  )
}

export { AppointmentsSection }
