import {
  IconButtonProps as MuiIconButtonProps,
  Menu as MuiMenu,
  MenuProps as MuiMenuProps,
  Tooltip,
  TooltipProps,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ToggleOnIcon from '@material-ui/icons/ToggleOn'
import ToggleOffIcon from '@material-ui/icons/ToggleOff'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'

import * as Styled from './ManageListItem.styles'

interface IconButtonProps extends MuiIconButtonProps {
  tooltip?: Pick<TooltipProps, 'title'>
  $active?: boolean
}

const IconButton = ({ tooltip, ...rest }: IconButtonProps) => (
  rest.disabled || !tooltip ? (
    <Styled.IconButton {...rest} />
  ) : (
    <Tooltip title={tooltip}>
      <Styled.IconButton {...rest} />
    </Tooltip>
  )
)

const MenuIconButton = (props: IconButtonProps) => (
  <IconButton {...props}>
    <MoreVertIcon />
  </IconButton>
)

const EditIconButton = (props: IconButtonProps) => (
  <IconButton {...props}>
    <Styled.EditIcon />
  </IconButton>
)

const ActivateIconButton = ({ $active, ...rest }: IconButtonProps) => (
  <IconButton {...rest}>{$active ? <ToggleOnIcon /> : <ToggleOffIcon />}</IconButton>
)

const DeleteIconButton = ({ $active, ...rest }: IconButtonProps) => (
  <IconButton {...rest}>
    <Styled.DeleteIcon />
  </IconButton>
)

const SaveIconButton = (props: IconButtonProps) => (
  <IconButton {...props}>
    <SaveIcon />
  </IconButton>
)

const CancelIconButton = (props: IconButtonProps) => (
  <IconButton {...props}>
    <CancelIcon />
  </IconButton>
)

const Menu = (props: MuiMenuProps) => (
  <MuiMenu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
)

export {
  MenuIconButton,
  Menu,
  IconButton,
  EditIconButton,
  ActivateIconButton,
  DeleteIconButton,
  ToggleOnIcon,
  ToggleOffIcon,
  SaveIconButton,
  CancelIconButton,
}
