import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'

import { Link } from 'components/inputs/Link/Link'

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`

const ListTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(2.5)}px;
`

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SeeAllLink = styled(Link)`
  display: flex;
  font-weight: bold;
  text-decoration: none;
`

export {
  RootContainer,
  UpperContainer,
  LowerContainer,
  ListTitle,
  ListHeader,
  SeeAllLink,
}
