import React, { ReactElement } from 'react'

import { useHasRole } from 'hooks/useHasRoles'
import { UserRole } from 'types/UserRole'

interface RestrictedContentProps {
  accessRoles?: UserRole[]
  fallback?: ReactElement
}

const RestrictedContent: React.FC<RestrictedContentProps> = ({
  accessRoles,
  fallback,
  children
}) => {
  const isAllowed = useHasRole(accessRoles)

  return isAllowed || accessRoles === undefined ? (
    <>{children}</>
  ) : fallback !== undefined ? (
    fallback
  ) : null
}

export { RestrictedContent }
