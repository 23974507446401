import { useTranslation } from 'react-i18next'

import { useIsMobile } from 'hooks/useIsMobile'
import { RankingItem } from 'api/types'
import * as RankingStyled from '../Ranking.styles'
import * as Styled from './RankingListItem.styles'

interface RankingListItemProps extends Omit<RankingItem, 'user'> {
  firstName: RankingItem['user']['firstName']
  lastName: RankingItem['user']['lastName']
}

const RankingListItem = ({
  firstName,
  lastName,
  userManualTestsGrade,
  userQuizGrade,
  userRank
}: RankingListItemProps): JSX.Element => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    <Styled.ListItemContainer $isMobile={isMobile}>
      <RankingStyled.RankingNumber>
        {`${userRank}.`}
      </RankingStyled.RankingNumber>
      <Styled.RankingUserDataContainer $isMobile={isMobile}>
        <RankingStyled.UserNameContainer>
          {`${firstName} ${lastName}`}
        </RankingStyled.UserNameContainer>
        {isMobile ? (
          <>
            <RankingStyled.ResultContainer>
              {`${t('results.ranking.quizMark')}: ${userQuizGrade || '-'}`}
            </RankingStyled.ResultContainer>
            <RankingStyled.ResultContainer>
              {`${t('results.ranking.testMark')}: ${userManualTestsGrade || '-'}`}
            </RankingStyled.ResultContainer>
          </>
        ) : (
          <>
            <RankingStyled.ResultContainer>
              {userQuizGrade || '-'}
            </RankingStyled.ResultContainer>
            <RankingStyled.ResultContainer>
              {userManualTestsGrade || '-'}
            </RankingStyled.ResultContainer>
          </>
        )}
      </Styled.RankingUserDataContainer>
    </Styled.ListItemContainer>
  )
}

export { RankingListItem }
