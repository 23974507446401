import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Typography } from '@material-ui/core'

import { useUserId } from 'providers/UserContextProvider'
import { ForumEntry } from 'api/types'
import { useGetAppointmentQuery } from 'api/queries/appointments/appointments'
import { useGetForumEntriesForAppointmentQuery } from 'api/queries/forumEntries/forumEntries'
import { ForumContentSelectedThreadSection } from './ForumSelectedThreadSection'
import { ForumEntryItem } from './ForumEntryItem/ForumEntryItem'
import { ForumFooter } from './ForumFooter'
import * as Styled from './Forum.styles'

interface ForumProps extends Pick<ForumEntry, 'appointmentId'> {
  onClose: () => void
}

const Forum = ({
  appointmentId,
  onClose,
}: ForumProps) => {
  const { t } = useTranslation()
  const userId = useUserId()
  const [selectedThreadId, setSelectedThreadId] = useState<ForumEntry['id']>()

  const { data: appointmentData } = useGetAppointmentQuery(appointmentId, { enabled: false })

  const {
    data: entries,
    isLoading: isLoadingEntries,
  } = useGetForumEntriesForAppointmentQuery(appointmentId)

  const isAddingDisabled = useMemo(() => {
    if (appointmentData) {
      const {
        appointmentTeacher: { user: { id: teacherId } },
        appointmentParticipants,
      } = appointmentData

      return (
        userId !== teacherId && !appointmentParticipants.find(({ user: { id } }) => id === userId)
      )
    }
    return true
  }, [appointmentData, userId])

  return (
    <Styled.Container>
      <Styled.ForumHeader>
        {selectedThreadId ? (
          <Styled.BackButton
            onClick={() => setSelectedThreadId(undefined)}
          >
            {t('appointment.forum.goBackToList')}
          </Styled.BackButton>
        ) : (
          <Styled.ForumTitle>
            {t('appointment.forum.title')}
          </Styled.ForumTitle>
        )}
        <Styled.CloseButton onClick={onClose} />
      </Styled.ForumHeader>
      {isLoadingEntries ? (
        <Styled.CenteredContentContainer>
          <CircularProgress />
        </Styled.CenteredContentContainer>
      ) : (
        selectedThreadId ? (
          <ForumContentSelectedThreadSection
            forumEntry={entries?.find((entry) => entry.id === selectedThreadId)}
          />
        ) : (
          <Styled.EntriesContainer>
            {entries?.length ? (
              entries?.map((entry) => (
                <ForumEntryItem
                  key={entry.id}
                  {...entry}
                  disableAddingForumEntries={isAddingDisabled}
                  onGoToThread={() => (
                    setSelectedThreadId(entry.id)
                  )}
                />
              ))
            ) : (
              <Styled.CenteredContentContainer>
                <Typography>
                  {t('appointment.forum.noEntries')}
                </Typography>
              </Styled.CenteredContentContainer>
            )}
          </Styled.EntriesContainer>
        )
      )}
      <ForumFooter
        disableAddingForumEntries={isAddingDisabled}
        appointmentId={appointmentId}
        selectedThreadId={selectedThreadId}
      />
    </Styled.Container>
  )
}

export { Forum }
