import { useParams } from 'react-router-dom'
import { CircularProgress, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useGetHistoryQuizQuery } from 'api/queries/results/results'
import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import { QuestionAnswerList } from 'components/data/QuestionAnswerList/QuestionAnswerList'
import { AnswersStatistics } from 'components/data/AnwsersStatistics/AnwsersStatistics'
import * as Styled from './QuizHistoryScreen.styles'

interface RouteParams {
  id: string
}

const QuizHistoryScreen = () => {
  const { id } = useParams<RouteParams>()
  const { t } = useTranslation()

  const { data: quizData, isLoading, isError } = useGetHistoryQuizQuery(+id)

  const {
    userName,
    quizName,
    historyQuizQuestionDTO,
    quizEntryStatisticsDTO
  } = quizData || {}

  return (
    <RouteContainer>
      {isError ? (
        <Styled.HelperContainer>
          <Typography color='error'>
            {t('common.errors.generic')}
          </Typography>
        </Styled.HelperContainer>
      ) : isLoading ? (
        <Styled.HelperContainer>
          <CircularProgress color='primary' />
        </Styled.HelperContainer>
      ) : (
        <>
          <Styled.HeadersContainer>
            <Styled.Header>{userName}</Styled.Header>
            <Styled.Header>{quizName}</Styled.Header>
          </Styled.HeadersContainer>
          <QuestionAnswerList questionsAnswers={historyQuizQuestionDTO} />
          <AnswersStatistics stats={quizEntryStatisticsDTO} />
        </>
      )}
    </RouteContainer>
  )
}

export default QuizHistoryScreen
