import React, { useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import { useUserRole } from 'providers/UserContextProvider'
import { COMPANY_ROLE, ADMIN_ROLE } from 'constants/roleGroups'
import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import { AdminUsersScreen } from './AdminUsersScreen'
import { CompanyUsersScreen } from './CompanyUsersScreen'

const UsersScreen: React.FC = () => {
  const userRole = useUserRole()
  const screenToRender = useMemo(() => {
    switch (userRole) {
      case COMPANY_ROLE:
        return <CompanyUsersScreen />
      case ADMIN_ROLE:
        return <AdminUsersScreen />
      default:
        return <Redirect to='/sign-in' />
    }
  }, [userRole])

  return <RouteContainer>{screenToRender}</RouteContainer>
}

export default UsersScreen
