import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TextField } from 'components/inputs/TextField/TextField'
import { DeletableItem } from 'components/inputs/DeletableItem/DeletableItem'
import * as Styled from './MultipleValuesField.styles'

interface MultipleValuesFieldProps {
  onValuesChange?: (values: string[]) => void
  values?: string[]
  errorMessage?: string
  touched?: boolean
  className?: string
}

const MultipleValuesField = ({
  onValuesChange,
  values,
  errorMessage,
  touched,
  className,
}: MultipleValuesFieldProps): JSX.Element => {
  const { t } = useTranslation()
  const [newValue, setNewValue] = useState<string>('')

  const handleNewValueTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(event.target.value)
  }

  const handleAddButton = (e: MouseEvent) => {
    e.preventDefault()
    if (newValue && values && onValuesChange) {
      onValuesChange([...values, newValue])
    }
    setNewValue('')
  }

  const handleDeleteValue = (valueToDelete: string | number) => {
    if (values && onValuesChange) {
      const newValues = [...values].filter((value) => value !== valueToDelete)
      onValuesChange(newValues)
    }
  }

  return (
    <div className={className}>
      <Styled.ValuesContainer>
        {values?.map((value) => (
          <DeletableItem
            key={value}
            value={value}
            label={value}
            onDelete={handleDeleteValue}
          />
        ))}
      </Styled.ValuesContainer>
      <Styled.InputContainer>
        <TextField
          value={newValue}
          onChange={handleNewValueTextField}
          error={touched && !!errorMessage}
          errorMessage={errorMessage}
          touched={touched}
        />
        <Styled.AddButton onClick={handleAddButton}>
          {t('common.add')}
        </Styled.AddButton>
      </Styled.InputContainer>
    </div>
  )
}

export { MultipleValuesField }
