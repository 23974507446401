import { useTranslation } from 'react-i18next'

import * as LayoutStyled from 'components/layout/Layout.styles'

const UsersTableHeader = () => {
  const { t } = useTranslation()
  return (
    <LayoutStyled.HeadersRow>
      <LayoutStyled.HeaderText variant='subtitle1'>
        {t('common.firstName')}
      </LayoutStyled.HeaderText>
      <LayoutStyled.HeaderText variant='subtitle1'>
        {t('common.lastName')}
      </LayoutStyled.HeaderText>
      <LayoutStyled.HeaderText variant='subtitle1' $flexValue={1.25}>
        {t('common.username')}
      </LayoutStyled.HeaderText>
      <LayoutStyled.HeaderText variant='subtitle1' $flexValue={1.25}>
        {t('common.email')}
      </LayoutStyled.HeaderText>
      <LayoutStyled.HeaderText variant='subtitle1' $flexValue={1.25}>
        {t('common.phoneNumber')}
      </LayoutStyled.HeaderText>
      <LayoutStyled.HeaderText variant='subtitle1'>
        {t('common.roles')}
      </LayoutStyled.HeaderText>
      <LayoutStyled.HeaderText variant='subtitle1'>
        {t('common.educationLevel')}
      </LayoutStyled.HeaderText>
      <LayoutStyled.HeaderText
        variant='subtitle1'
        $flexValue={0}
        $minWidth={120}
        $textAlign='center'
      >
        {t('common.actions')}
      </LayoutStyled.HeaderText>
    </LayoutStyled.HeadersRow>
  )
}

export { UsersTableHeader }
