import { format } from 'date-fns'

import { StatisticsItem } from 'api/queries/statistics/types'
import * as Styled from '../Statistics.styled'

interface StatisticsQuizListItemProps extends StatisticsItem {
  onRedirect: () => void
}

const StatisticsQuizListItem = ({
  name: quizName,
  appointmentTableResponseDTO: {
    name: appointmentName,
    startDate
  },
  onRedirect: handleRedirect,
}: StatisticsQuizListItemProps) => (
  <Styled.ListItem>
    <Styled.ListItemText $flex={2}>
      {quizName}
    </Styled.ListItemText>
    <Styled.ListItemText $flex={2}>
      {format(new Date(startDate), 'dd/MM/yyyy')}
    </Styled.ListItemText>
    <Styled.ListItemText $flex={3}>
      {appointmentName}
    </Styled.ListItemText>
    <Styled.ForwardButton
      onClick={handleRedirect}
    />
  </Styled.ListItem>
)

export { StatisticsQuizListItem }
