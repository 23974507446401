import { useTranslation } from 'react-i18next'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { pl, enGB } from 'date-fns/locale'

const languageMap = new Map([
  ['pl', pl],
  ['en-Gb', enGB],
])

const MuiPickersProvider: React.FC = ({ children }) => {
  const { i18n: { language } } = useTranslation()
  const locale = languageMap.get(language)

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      {children}
    </MuiPickersUtilsProvider>
  )
}

export { MuiPickersProvider }
