import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

interface CircularProgressWithLabelProps extends CircularProgressProps {
  value: number
}

const CircularProgressWithLabel = ({
  value,
  ...props
}: CircularProgressWithLabelProps) => (
  <Box position='relative' display='inline-flex'>
    <CircularProgress variant='determinate' value={value} {...props} />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position='absolute'
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <Typography variant='caption' component='div' color='textSecondary'>
        {`${Math.round(value)}%`}
      </Typography>
    </Box>
  </Box>
)

export { CircularProgressWithLabel }
