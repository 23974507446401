import {
  Typography,
  Menu,
  MenuItem,
  ListItemText,
  IconButton,
} from '@material-ui/core'
import styled from '@emotion/styled/macro'
import { css } from '@emotion/react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const StyledCard = styled.div`
  margin: 10px auto;
  padding: 0 10px;
  box-sizing: border-box;
  min-width: 350px;
  max-width: 450px;
`

const CircleContainer = styled.div`
  position: relative;
`

const Circle = styled.div`
  ${({ theme }) => css`
    position: absolute;
    left: 43%;
    top: -25px;
    height: 50px;
    width: 50px;
    background-color: ${theme.palette.secondary.contrastText};
    box-shadow: 0px 0px 25px 1px rgb(0 0 0 / 28%);
    border-radius: 50%;
    display: inline-block;
  `}
`

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  ${({ theme }) => css`
    fill: ${theme.palette.icon?.expandMore};
    font-size: 50px;
  `}
`

const ContentIconButton = styled(IconButton)`
  & .MuiSvgIcon-root {
    ${({ theme }) => css`
      fill: ${theme.palette.icon?.expandMore};
      font-size: 25px;
    `}
  }
`

const OpenMenuButton = styled(IconButton)`
  padding: 6px;
  margin: 6px;
  background-color: rgba(255, 255, 255, 0.4);
  & .MuiSvgIcon-root {
    border-radius: 50%;
    color: black;
  }
  &:hover {
    & .MuiSvgIcon-root {
      background-color: inherit;
    }
  }
`

const StyledMoreVertIcon = styled(MoreVertIcon)`
  border-radius: 50%;
  color: black;
`

const Title = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(1.5)}px;
  font-weight: bolder;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Data = styled.div`
  ${({ theme }) => css`
    position: absolute;
    background-color: ${theme.palette.secondary.contrastText};
    min-height: 100px;
    bottom: 0;
    width: 100%;
    box-shadow:  0px 0px 20px -9px rgb(0 0 0 / 47%);
    transform: translateY(calc(13px + 1em));
    transition: transform 0.3s;

    &:hover {
      transform: translateY(0);
    }

    &:hover ${StyledExpandMoreIcon} {
      transform: rotate(180deg);
      transition: transform .2s ease-in-out;
    }

    &:hover ${Title} {
      overflow: auto;
      white-space: normal;
      text-overflow: clip;
    }
  `}
`

interface WrapperProps {
  $image?: string
  $isPlaceholder?: boolean
}

const Wrapper = styled.div<WrapperProps>`
  ${({ theme, $image, $isPlaceholder }) => css`
    position: relative;
    min-height: 245px;
    border-radius: 20px;
    background-color: ${theme.palette.primary.main};
    ${$image && css`
      background-image: url(${$image});
      background-position: center ${$isPlaceholder ? '0%' : ''};
      background-repeat: no-repeat;
    `}
    overflow: hidden;
    box-shadow: -3px 9px 25px -8px rgb(0 0 0 / 28%);
  `}
`

const Header = styled.div`
  display: flex;
  flex-direction: row-reverse;
  color: ${({ theme }) => `${theme.palette.secondary.contrastText}`};
`

const CreatedDateText = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    padding: ${theme.spacing(1.5, 2.5)};
    color: ${theme.palette.text.primary};
  `}
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7em 1em;
  padding-bottom: 0;
  position: relative;
  z-index: 1;
  cursor: pointer;
`

const StyledMenu = styled(Menu)`
  border-radius: 20px;
`

const StyledMenuItem = styled(MenuItem)`
  width: 200px;
`

const ListItemTextDelete = styled(ListItemText)`
  ${({ theme }) => css`
    color: ${theme.palette.error.main};
  `}
`

export {
  Data,
  StyledCard as BackCard,
  CircleContainer,
  Circle,
  ContentIconButton,
  OpenMenuButton,
  StyledExpandMoreIcon as ExpandMoreIcon,
  StyledMoreVertIcon as MoreVertIcon,
  Title,
  Wrapper,
  Header,
  CreatedDateText,
  Content,
  StyledMenu as Menu,
  StyledMenuItem as MenuItem,
  ListItemTextDelete,
}
