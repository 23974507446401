import styled from '@emotion/styled'
import { css, Theme } from '@emotion/react'
import {
  Button,
  ButtonProps,
  IconButton as MuiIconButton,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  Typography,
  TypographyProps
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import ToggleOnIcon from '@material-ui/icons/ToggleOn'
import ToggleOffIcon from '@material-ui/icons/ToggleOff'
import DeleteIcon from '@material-ui/icons/Delete'

import { TextField, TextFieldProps } from 'components/inputs/TextField/TextField'
import { SelectProps } from 'components/inputs/Select/Select'
import { RoleSelect } from 'components/data/RoleSelect/RoleSelect'
import { StorageLimitSelect } from 'components/data/StorageLimitSelect/StorageLimitSelect'
import { EducationLevelSelect } from 'components/data/EducationLevelSelect/EducationLevelSelect'

type BaseInputProps = {
  theme?: Theme
  $border?: boolean
  $active?: boolean
}

const inputBase = ({ theme, $active, $border }: BaseInputProps) => css`
  .MuiInputBase-root {
    color: ${$active ? theme?.palette.text.primary : theme?.palette.disabled.main};
    background-color: ${$active
    ? theme?.palette.background.default
    : theme?.palette.disabled.light};
    border-width: ${$border ? '1px' : '0'};
    font-size: 14px;
    > input {
      ${theme?.breakpoints.down('sm')} {
        text-align: center;
      }
    }
  }
`

const selectBase = ({ theme, $active, $border }: BaseInputProps) => css`
  flex: 1;
  justify-content: center;
  margin: 5px 0;

  ${inputBase({ theme, $active, $border })};

  .MuiSelect-icon {
    display: ${$border ? 'block' : 'none'};
  }

  .MuiSelect-root {
    ${theme?.breakpoints.down('sm')} {
      padding: ${theme?.spacing(2)}px;
    }
  }

  ${theme?.breakpoints.down('sm')} {
    text-align: center;
  }
`

interface StyledFormProps
  extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  $active?: boolean
  $flexDirection?: string
}

const RootContainer = styled('div', {
  shouldForwardProp: (prop) => !['$active', '$flexDirection'].includes(prop)
})<StyledFormProps>`
  ${({ theme, $active, $flexDirection }) => css`
    flex: 1;
    display: flex;
    flex-direction: ${$flexDirection || 'row'};
    margin-top: 10px;
    padding: 0 5px;
    border: 3px solid ${$active ? theme.palette.primary.main : theme.palette.disabled.main};
    border-radius: 10px;
    background-color: ${$active ? theme.palette.background.default : theme.palette.disabled.light};

    .MuiFormHelperText-root {
      margin-left: ${theme.spacing(2)}px;
    }

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `}
`

interface FieldsContainerProps {
  $rows?: number
}

const FieldsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== '$rows'
})<FieldsContainerProps>`
  ${({ theme, $rows }) => css`
    flex: 1;
    display: flex;

    ${theme.breakpoints.down('sm')} {
      display: grid;
      grid-template-rows: repeat(${$rows}, 1fr);
      grid-auto-flow: column;
    }
  `}
`

interface RowProps {
  $flexValue?: number;
  $justifyContent?: string;
  $margin?: string;
}

const Row = styled('div', {
  shouldForwardProp: (prop) => !['$flexValue', '$justifyContent', '$margin'].includes(prop)
})<RowProps>`
  ${({ $flexValue, $justifyContent, $margin }) => `
    margin: ${$margin !== undefined ? $margin : 0};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${$justifyContent !== undefined ? $justifyContent : 'flex-start'};
    flex: ${$flexValue !== undefined ? $flexValue : 1};
    width: 100%;
  `}
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

interface StyledTextFieldProps extends BaseInputProps, TextFieldProps {
  $flexValue?: number
}

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => !['$active', '$border', '$flexValue'].includes(prop)
})<StyledTextFieldProps>`
  ${({ theme, $active, $border, $flexValue }) => css`
    flex: ${$flexValue !== undefined ? $flexValue : 1};
    width: auto;
    margin: 5px 0;
    justify-content: center;

    ${inputBase({ theme, $active, $border })};

    .MuiInput-input {
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 16px 0 16px 16px;
    }
  `}
`

type StyledSelectProps = BaseInputProps & SelectProps

const StyledSelect = styled(RoleSelect, {
  shouldForwardProp: (prop) => prop !== '$active' && prop !== '$border',
})<StyledSelectProps>`
  ${({ theme, $active, $border }) => css`
    ${selectBase({ theme, $active, $border })};
  `}
`

const StyledStorageSelect = styled(StorageLimitSelect, {
  shouldForwardProp: (prop) => prop !== '$active' && prop !== '$border',
})<StyledSelectProps>`
  ${({ theme, $active, $border }) => css`
    ${selectBase({ theme, $active, $border })};
  `}
`

const StyledEducationSelect = styled(EducationLevelSelect, {
  shouldForwardProp: (prop) => prop !== '$active' && prop !== '$border',
})<StyledSelectProps>`
  ${({ theme, $active, $border }) => css`
    ${selectBase({ theme, $active, $border })};
  `}
`

const ActionsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    min-width: 120px;
    margin: 5px;
    justify-content: flex-end;

    ${theme.breakpoints.down('sm')} {
      justify-content: center;
    }
  `}
`
interface StyledButtonProps extends ButtonProps {
  $active?: boolean
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== '$active',
})<StyledButtonProps>`
  ${({ theme, $active }) => css`
    width: 50%;
    height: 100%;
    color: ${$active ? theme.palette.text.primary : theme.palette.disabled.main};
    background-color: ${$active ? theme.palette.background.default : theme.palette.disabled.light};
    font-weight: bold;
    font-size: 14px;
  `}
`

interface HeaderTextProps extends TypographyProps {
  $justifyContent?: string
}

const HeaderText = styled((props: HeaderTextProps) => (
  <Typography noWrap {...props} />
), { shouldForwardProp: (prop) => prop !== '$justifyContent' })`
  ${({ $justifyContent }) => `
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: ${$justifyContent || 'flex-start'};
  `}
`

interface MenuItemProps extends Omit<MuiMenuItemProps, 'button'> {
  button?: true
}

const DeleteMenuItem = styled((props: MenuItemProps) => (
  <MuiMenuItem {...props} />
))`
  ${({ theme }) => `
    color: ${theme.palette.error.main};
  `};
`

const IconButton = styled(MuiIconButton)`
  ${({ theme, disabled }) => `
    ${disabled ? `
      & .MuiSvgIcon-root {
        color: ${theme.palette.disabled.main};
      }
    ` : null};
  `}
`

const StyledDeleteIcon = styled(DeleteIcon)`
  ${({ theme }) => `
    color: ${theme.palette.error.main};
  `};
`

export {
  RootContainer,
  FieldsContainer,
  Row,
  Column,
  StyledTextField as TextField,
  StyledSelect as Select,
  StyledStorageSelect as StorageLimitSelect,
  StyledEducationSelect as EducationLevelSelect,
  ActionsContainer,
  StyledButton as Button,
  EditIcon,
  ToggleOnIcon,
  ToggleOffIcon,
  HeaderText,
  DeleteMenuItem,
  StyledDeleteIcon as DeleteIcon,
  IconButton
}
