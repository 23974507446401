import { useIsSmallScreen } from 'hooks/useIsSmallScreen'
import { ManageListItem } from 'components/inputs/ManageListItem/ManageListItem'

interface UserListItemRowProps {
  header?: JSX.Element | null
  field?: JSX.Element | null
  $flexValue?: number
  $justifyContent?: string
}

const UserListItemRow = ({ header, field, $flexValue, $justifyContent }: UserListItemRowProps) => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <ManageListItem.Row $flexValue={$flexValue} $justifyContent={$justifyContent}>
      {isSmallScreen && header ? (
        <ManageListItem.Column>
          { header }
        </ManageListItem.Column>
      ) : null}
      {field ? (
        <ManageListItem.Column>
          { field }
        </ManageListItem.Column>
      ) : null}
    </ManageListItem.Row>
  )
}

export { UserListItemRow }
