import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Typography } from '@material-ui/core'

import { QuizAnalysisItem } from 'api/queries/statistics/types'
import { StatisticsQuizQuestionListItem } from './StatisticsQuizQuestionListItem'
import * as Styled from '../Statistics.styled'

interface StatisticsQuizQuestionListProps {
  isLoading?: boolean
  isError?: boolean
  items?: QuizAnalysisItem[]
}

const StatisticsQuizQuestionList = ({
  isLoading,
  isError,
  items,
}: StatisticsQuizQuestionListProps) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const history = useHistory()

  return (
    <>
      <Styled.HelperContainer>
        {isError && (
          <Typography color='error'>
            {t('common.errors.generic')}
          </Typography>
        )}
      </Styled.HelperContainer>
      <Styled.List>
        {isLoading ? (
          <Styled.HelperContainer>
            <CircularProgress color='primary' />
          </Styled.HelperContainer>
        ) : (items?.length ? (
          items.map(({ questionDTO: { id, question } }, index) => (
            <StatisticsQuizQuestionListItem
              key={id}
              onRedirect={() => history.push(`${pathname}/${id}`)}
              content={`${index + 1}. ${question}`}
            />
          ))
        ) : (
          <Styled.NoItemsContainer>
            <Typography variant='h5' color='primary'>
              {t('common.noData')}
            </Typography>
          </Styled.NoItemsContainer>
        ))}
      </Styled.List>
    </>
  )
}

export { StatisticsQuizQuestionList }
