import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Breadcrumbs as MuiBreadcrumbs, BreadcrumbsProps, Typography } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import { Link } from 'components/inputs/Link/Link'

const Breadcrumbs = styled((props: BreadcrumbsProps) => (
  <MuiBreadcrumbs separator={<NavigateNextIcon fontSize='small' />} {...props} />
))`
  margin: ${({ theme }) => theme.spacing(1, 0)};
`

const BreadcrumbLink = styled(Link)`
  ${({ theme }) => css`
    text-decoration: none;
    color: ${theme.palette.text.primary};
    :hover {
      text-decoration: underline;
    }
  `}
`

const ErrorMessage = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.error.main};
    margin: ${theme.spacing(1)}px 0;
  `}
`

export {
  Breadcrumbs,
  BreadcrumbLink,
  ErrorMessage,
}
