import { CreateKnowledgeBaseContentSchema, EditKnowledgeBaseContentSchema } from 'validations/KnowledgeBaseContentSchema'
import { KnowledgeBaseVisibilityType } from 'types/KnowledgeBaseVisibilityType'
import { KnowledgeBaseContentType } from 'types/KnowledgeBaseContentType'
import { AddKnowledgeBaseObjectVariables, VisibilityType } from 'api/mutations/knowledgeBase/types'
import { KnowledgeBaseContentFormFields } from './types'

const commonDefaultValues = {
  name: '',
  nameTranslations: [],
  description: '',
  descriptionTranslations: [],
  destination: null,
  knowledgeBaseFilesIds: [],
  visibility: KnowledgeBaseVisibilityType.Company,
  image: null,
}

const presentationTestDefaultValues = {
  difficulty: 3,
  timeline: undefined,
  scene: undefined,
}

const getDefaultValues = (
  type: KnowledgeBaseContentType,
  initValues?: KnowledgeBaseContentFormFields
): KnowledgeBaseContentFormFields => {
  switch (type) {
    case KnowledgeBaseContentType.QUIZ:
      return {
        ...commonDefaultValues,
        ...initValues,
        duration: null,
        type: KnowledgeBaseContentType.QUIZ,
        subForm: {
          canGoBack: false,
          quizDuration: false,
          showAnswers: false,
          randomQuestionOrder: false,
          markWeight: 1,
          quantityOfRandomQuestions: null,
          difficulty: 3
        }
      }
    case KnowledgeBaseContentType.PRESENTATION:
      return {
        ...commonDefaultValues,
        ...initValues,
        duration: initValues?.duration || 60,
        type: KnowledgeBaseContentType.PRESENTATION,
        subForm: presentationTestDefaultValues
      }
    case KnowledgeBaseContentType.TEST:
      return {
        ...commonDefaultValues,
        ...initValues,
        duration: initValues?.duration || 60,
        type: KnowledgeBaseContentType.TEST,
        subForm: presentationTestDefaultValues
      }
    case KnowledgeBaseContentType.SANDBOX_TEST:
      return {
        ...commonDefaultValues,
        ...initValues,
        duration: initValues?.duration || 60,
        type: KnowledgeBaseContentType.SANDBOX_TEST,
        subForm: presentationTestDefaultValues
      }
    default:
      return {
        ...commonDefaultValues,
        ...initValues,
        duration: initValues?.duration || 60,
        type: KnowledgeBaseContentType.TEST,
        subForm: presentationTestDefaultValues
      }
  }
}

const getValidationSchema = (isEditing: boolean) => (
  isEditing
    ? EditKnowledgeBaseContentSchema
    : CreateKnowledgeBaseContentSchema
)

const parseContentFields = (
  variables: KnowledgeBaseContentFormFields,
  languageCode: string
): AddKnowledgeBaseObjectVariables => {
  const {
    destination,
    type,
    visibility,
    duration,
    knowledgeBaseFilesIds,
    subForm,
    image,
    ...values
  } = variables

  return {
    ...values,
    languageCode,
    knowledgeBaseFileIds: knowledgeBaseFilesIds,
    parentId: destination !== null ? destination.toString() : destination,
    dataType: type,
    visibility: visibility as VisibilityType,
    duration: duration && duration * 60,
    ...('scene' in subForm
      ? {
        sceneId: subForm.scene?.id,
        timelineId: subForm.timeline?.id,
        difficulty: subForm.difficulty
      }
      : {
        ...Object.fromEntries(
          Object.entries(subForm).filter(([key]) => !['timeline', 'scene'].includes(key))
        )
      }
    ),
  }
}

export {
  getDefaultValues,
  getValidationSchema,
  parseContentFields,
}
