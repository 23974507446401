import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { MenuItem, Radio, Typography, TypographyProps } from '@material-ui/core'
import { Clear } from '@material-ui/icons'

import { Button, ButtonProps } from 'components/inputs/Button/Button'
import { TextField } from 'components/inputs/TextField/TextField'
import { QuestionTypeSelect } from 'components/data/QuestionTypeSelect/QuestionTypeSelect'
import { ContentSection } from 'components/data/ContentSection/ContentSection'

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const InfoSection = styled(ContentSection)`
  flex: 1;
  margin-top: ${({ theme }) => theme.spacing(2.5)}px;
  min-width: 315px;
`

const StyledQuestionSelect = styled(QuestionTypeSelect)`
  max-width: 500px;
`

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiFormHelperText-root {
    margin-top: 0px;
    height: 0;
  }
`

const StyledTextFieldShort = styled(TextField)`
  max-width: 200px;
`

const StyledTextFieldMedium = styled(TextField)`
  max-width: 500px;
  .MuiFormHelperText-root {
    margin-top: 0px;
    height: 0;
  }
`

const RowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(315px, 1fr));
  grid-column-gap: 20px;
`

interface SaveButtonProps extends ButtonProps {
  $isMobile?: boolean
}

const SaveButton = styled((props: SaveButtonProps) => (
  <Button {...props} />
), { shouldForwardProp: (prop) => prop !== '$isMobile' })`
  ${({ $isMobile }) => css`
    width: auto;
    z-index: 999;
    ${$isMobile ? css`
    ` : css`
      position: absolute;
      bottom: 50px;
      right: 50px;
    `}
  `}
`

const ClearIcon = styled(Clear)`
  ${({ theme }) => css`
    color: ${theme.palette.icon.dark};
  `}
`

const RadioButton = styled(Radio)`
  margin-left: 10px;
`

const AddAnswerButton = styled(Button)`
  max-width: 500px;
  margin-top: 10px;
`

const AnswerRowContainer = styled.div`
  display: flex;
  align-items: center;
`

const AnswerLabel = styled((props: TypographyProps) => (
  <Typography variant='subtitle1' {...props} />
))`
  margin-top: ${({ theme }) => theme.spacing(2.5)}px;
`
const ErrorMessage = styled((props: TypographyProps) => (
  <Typography variant='body2' color='error' {...props} />
))`
  margin-top: ${({ theme }) => theme.spacing(2.5)}px;
`

const StyledMenuItem = styled(MenuItem)`
  ${({ theme }) => css`
  :hover, :focus {
    background: ${theme.palette.backgroundAccents.darkerBlueish};
  }
  `}
`

const ModalButton = styled(Button)`
  width: auto;
`

const PresentationLabel = styled(Typography)`
  margin: 10px;
`

export {
  Form,
  InfoSection,
  SaveButton,
  StyledQuestionSelect as QuestionTypeSelect,
  StyledTextField as TextField,
  RowContainer,
  HeaderContainer,
  StyledTextFieldShort as TextFieldShort,
  ClearIcon,
  AddAnswerButton,
  AnswerRowContainer,
  StyledTextFieldMedium as TextFieldMedium,
  AnswerLabel,
  RadioButton,
  StyledMenuItem as MenuItem,
  ErrorMessage,
  ModalButton,
  PresentationLabel,
}
