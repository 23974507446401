import { ForumEntry } from 'api/types'
import { ForumEntryItemBase } from './ForumEntryItem/ForumEntryItemBase'
import * as Styled from './Forum.styles'

interface ForumContentSelectedThreadSectionProps {
  forumEntry?: ForumEntry
}

const ForumContentSelectedThreadSection = ({
  forumEntry,
}: ForumContentSelectedThreadSectionProps) => {
  if (!forumEntry) {
    return null
  }

  const {
    replies,
  } = forumEntry

  return (
    <Styled.EntriesContainer>
      <ForumEntryItemBase
        {...forumEntry}
      />
      <Styled.SelectedThreadRepliesContainer>
        {replies.map((replyEntry) => (
          <ForumEntryItemBase
            key={replyEntry.id}
            {...replyEntry}
            isReply
          />
        ))}
      </Styled.SelectedThreadRepliesContainer>
    </Styled.EntriesContainer>
  )
}

export { ForumContentSelectedThreadSection }
