import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import { Accordion } from 'components/inputs/Accordion/Accordion'
import { AddParticipantsModal } from 'components/modals/AddParticipantsModal/AddParticipantsModal'
import { useIsMobile } from 'hooks/useIsMobile'
import { Participant } from 'types/Participant'
import { ParticipantItem } from './ParticipantItem'
import * as CommonStyled from '../AppointmentForm.styles'

interface ParticipantsSectionProps {
  participants?: Participant[]
  selectedTeacherId?: number
  setFieldValue?: (field: string, value: Participant[], shouldValidate?: boolean) => void
  setFieldTouched?: (field: string, value: boolean, shouldValidate?: boolean) => void
  touched?: boolean
  errors?: string[]
}

const ParticipantsSection: React.FC<ParticipantsSectionProps> = ({
  participants = [],
  selectedTeacherId,
  setFieldValue = () => {},
  setFieldTouched = () => {},
  touched,
  errors = [],
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const [open, setOpen] = useState<boolean>(false)

  const participantItems = participants.map(({ id, ...rest }, index) => (
    <ParticipantItem
      key={id}
      {...rest}
      onRemove={() => {
        setFieldTouched('participants', true, false)
        setFieldValue('participants', participants.filter((p) => p.id !== id), true)
      }}
      errorMessage={t(errors[index])}
    />
  ))

  const handleModalSave = (newParticipants: Participant[]) => {
    setFieldTouched('participants', true, false)
    setFieldValue('participants', newParticipants, true)
    setOpen(false)
  }

  return (
    <CommonStyled.ItemsContainer $touched={touched}>
      <CommonStyled.ItemsHeaderContainer>
        <CommonStyled.SectionTitle>
          {t('appointment.participants')}
        </CommonStyled.SectionTitle>
        <CommonStyled.AddButton
          type='button'
          onClick={() => setOpen(true)}
        >
          {t('appointment.addParticipants')}
        </CommonStyled.AddButton>
      </CommonStyled.ItemsHeaderContainer>
      <CommonStyled.ItemsListContainer>
        {participants.length ? (
          isMobile
            ? <Accordion items={participantItems} />
            : participantItems
        ) : (
          <Typography>{t('appointment.noParticipants')}</Typography>
        )}
      </CommonStyled.ItemsListContainer>
      <AddParticipantsModal
        open={open}
        onClose={() => setOpen(false)}
        onSave={handleModalSave}
        participants={participants}
        selectedTeacherId={selectedTeacherId}
      />
    </CommonStyled.ItemsContainer>
  )
}

export type { Participant }
export { ParticipantsSection }
