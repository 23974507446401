import { ChangeEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TextField } from 'components/inputs/TextField/TextField'
import * as Styled from './tabs.styles'

interface PdfFile {
  file: File,
  name: string
}

interface OwnMaterialsTabProps {
  onFileAdd: (file: PdfFile) => void
}

const PdfTab: React.FC<OwnMaterialsTabProps> = ({
  onFileAdd: handleFileAdd,
}) => {
  const { t } = useTranslation()

  const [pdfFile, setPdfFile] = useState<File | null>(null)
  const [name, setName] = useState<string>('')
  const fileInput = useRef<HTMLInputElement>(null)

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPdfFile((event.target.files as FileList)[0])
  }

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleAddFileButtonClick = () => {
    if (pdfFile) {
      handleFileAdd({ file: pdfFile as File, name: name || pdfFile.name })
      setPdfFile(null)
      setName('')
      if (fileInput.current) {
        fileInput.current.value = ''
      }
    }
  }

  return (
    <Styled.AddMaterialContainer>
      <Styled.AddText variant='body2'>
        {t('modals.addMaterials.addPdf')}
      </Styled.AddText>
      <Styled.InputContainer>
        <input
          id='raised-button-file'
          ref={fileInput}
          style={{ display: 'none' }}
          type='file'
          accept='.pdf'
          onChange={handleFileChange}
        />
        <Styled.InputLabel htmlFor='raised-button-file'>
          <Styled.SelectFileButton variant='outlined' component='span'>
            {t('common.chooseFile')}
          </Styled.SelectFileButton>
          <Styled.FileName color='primary'>
            {pdfFile?.name || t('common.noFileSelected')}
          </Styled.FileName>
        </Styled.InputLabel>
        <TextField
          label={t('modals.addMaterials.name')}
          value={name}
          onChange={handleNameChange}
          placeholder={t('modals.addMaterials.namePlaceholder')}
        />
      </Styled.InputContainer>
      <Styled.AddButton
        disabled={!pdfFile}
        onClick={handleAddFileButtonClick}
      >
        {t('common.add')}
      </Styled.AddButton>
    </Styled.AddMaterialContainer>
  )
}

export { PdfTab }
export type { PdfFile }
