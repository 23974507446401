import * as yup from 'yup'

import { PhoneSchema } from './PhoneSchema'

const CreateUserSchema = yup.object().shape({
  firstName: yup.string().required('common.errors.firstName.required'),
  lastName: yup.string().required('common.errors.lastName.required'),
  email: yup.string().email('common.errors.email.invalid').required('common.errors.email.required'),
  username: yup.string().nullable(),
  phoneNumber: PhoneSchema.nullable(),
  authorityGroupName: yup.string().required('common.errors.roles.required')
})

export { CreateUserSchema }
