import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Chip } from '@material-ui/core'

import { Modal } from 'components/layout/Modal/Modal'

const ModalContent = styled(Modal.Content)`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    min-width: 500px;
    max-width: 500px;
    min-height: 450px;
    max-height: 700px;

    ${theme.breakpoints.down('sm')} {
      min-width: 100px;
    }
  `}
`

const DidacticMaterialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 20vh;
  max-width: 100%;
  overflow-y: auto;
  margin-top: ${({ theme }) => theme.spacing(2.5)}px;
`

const StyledChip = styled(Chip)`
  margin: 5px;
  max-width: 95%;
  text-overflow: ellipsis;
`

const TabPanelsContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(2.5, 0)};
`

export {
  ModalContent,
  TabPanelsContainer,
  DidacticMaterialsContainer,
  StyledChip as Chip,
}
