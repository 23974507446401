import styled from '@emotion/styled'
import { CompanyStatisticsItem } from './CompanyStatiscticsItem/CompanyStatisticsItem'

const RootContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
`

const StyledStatisticsItem = styled(CompanyStatisticsItem)`
  min-width: 175px;
  margin: ${({ theme }) => theme.spacing(0, 3.5, 3, 0)};
  white-space: nowrap;
`

export {
  RootContainer,
  StyledStatisticsItem as StatisticsItem,
}
