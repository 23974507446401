import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import {
  usePostEntryForAppointmentMutation,
  usePostReplyForAppointmentEntryMutation,
} from 'api/mutations/forumEntries/forumEntries'
import { CircularProgressWithLabel } from './CircularProgressWithLabel'
import { FORUM_ENTRY_MAX_LENGTH } from './constants'
import * as Styled from './Forum.styles'

interface ForumFooterProps {
  appointmentId: number
  selectedThreadId?: number
  disableAddingForumEntries?: boolean
}

const ForumFooter = ({
  appointmentId,
  selectedThreadId,
  disableAddingForumEntries,
}: ForumFooterProps) => {
  const { t } = useTranslation()
  const [entryContent, setEntryContent] = useState('')

  const {
    mutate: postNewEntryMutate,
    isLoading: isPostingNewEntry,
  } = usePostEntryForAppointmentMutation({
    onSuccess: () => {
      setEntryContent('')
    }
  })

  const {
    mutate: postReplyEntryMutate,
    isLoading: isPostingReplyEntry,
  } = usePostReplyForAppointmentEntryMutation({
    onSuccess: () => {
      setEntryContent('')
    }
  })

  const handlePostEntry = () => {
    if (selectedThreadId) {
      postReplyEntryMutate({ id: selectedThreadId, content: entryContent })
    } else {
      postNewEntryMutate({ appointmentId, content: entryContent })
    }
  }

  const isUploading = isPostingNewEntry || isPostingReplyEntry

  return (
    <Styled.ForumFooter>
      <Styled.ForumFooterTitleContainer>
        <Typography variant='subtitle1'>
          {t(`appointment.forum.${selectedThreadId ? 'continueThread' : 'addNewEntry'}`)}
        </Typography>
        {entryContent && (
          <CircularProgressWithLabel
            value={(entryContent.length / FORUM_ENTRY_MAX_LENGTH) * 100}
          />
        )}
      </Styled.ForumFooterTitleContainer>
      <Styled.ForumFooterFormContainer>
        <Styled.ForumEntryInput
          disabled={disableAddingForumEntries}
          multiline
          minRows={2}
          maxRows={2}
          placeholder={t(`appointment.forum.${selectedThreadId ? 'writeReply' : 'writeMessage'}`)}
          value={entryContent}
          onChange={({ target: { value } }) => setEntryContent(value)}
          inputProps={{
            maxLength: FORUM_ENTRY_MAX_LENGTH,
          }}
        />
        <Styled.PostEntryButton
          disabled={disableAddingForumEntries}
          onClick={handlePostEntry}
          isLoading={isUploading}
        >
          <Styled.SendIcon />
          {t('appointment.forum.send')}
        </Styled.PostEntryButton>
      </Styled.ForumFooterFormContainer>
    </Styled.ForumFooter>
  )
}

export { ForumFooter }
