import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Button } from 'components/inputs/Button/Button'

const Card = styled.div`
  ${({ theme }) => css`
    max-width: 280px;
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing(3, 1.5)};
    box-shadow: 0px 6px 12px ${theme.palette.backgroundAccents.shadowBlueish};
    border-radius: 10px;
    ${theme.breakpoints.down('sm')} {
      max-width: 350px;
      margin: ${theme.spacing(3)}px 0;
    }
  `}
`

const CardContent = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.spacing(3, 3, 2)};
`

const CardActions = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;
`

const StyledButton = styled(Button)`
  width: auto;
  padding: ${({ theme }) => theme.spacing(1, 2)};
`

export {
  Card,
  CardContent,
  CardActions,
  StyledButton as Button,
}
