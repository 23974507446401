import { MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Select, SelectProps } from 'components/inputs/Select/Select'
import { useGetStorageSpaceLimitQuery } from 'api/queries/storageSpaceLimit/storageSpaceLimit'

interface StorageLimitSelectProps extends SelectProps {
  className?: string
  withoutNone?: boolean
}

const StorageLimitSelect = ({
  className,
  withoutNone,
  ...rest
}: StorageLimitSelectProps): JSX.Element => {
  const { t } = useTranslation()
  const { data } = useGetStorageSpaceLimitQuery()

  return (
    <Select
      className={className}
      defaultValue={-1}
      {...rest}
    >
      <MenuItem disabled value={-1}>
        {t('companies.storageSpaceLimit')}
      </MenuItem>
      {data?.data.map((limit) => (
        <MenuItem key={limit.id} value={limit.id}>
          {limit.name}
        </MenuItem>
      ))}
    </Select>
  )
}

export { StorageLimitSelect }
