import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { useHistory, useLocation } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { KNOWLEDGE_BASE_ENTITY_QUERY_KEY } from 'api/queriesKeys'
import { useMoveQuestionMutation } from 'api/mutations/quizes/quizes'
import { Question } from 'api/types'
import { QuestionListItem } from './QuestionListItem/QuestionListItem'
import * as Styled from './QuestionList.styles'

interface QuestionListProps {
  questions?: Question[]
  isDisplayOnly?: boolean
  onDelete?: (id: number) => void
}

const QuestionList = ({
  questions,
  isDisplayOnly = false,
  onDelete = () => null,
}: QuestionListProps): JSX.Element => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const [items, setItems] = useState(questions)
  const queryClient = useQueryClient()

  const {
    mutate: moveQuestionMutate,
  } = useMoveQuestionMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(KNOWLEDGE_BASE_ENTITY_QUERY_KEY)
    },
  })

  useEffect(() => {
    setItems(questions)
  }, [questions])

  const handleRedirectToQuestionCreation = () => (
    history.push(`${location.pathname}/create`)
  )

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return
    if (Array.isArray(items)) {
      const newItems = Array.from(items)
      const [removed] = newItems.splice(result.source.index, 1)
      newItems.splice(result.destination.index, 0, removed)
      setItems(newItems)
      moveQuestionMutate(
        { id: +result.draggableId, position: result.destination.index + 1 },
        { onError: () => setItems(items) }
      )
    }
  }

  return (
    <Styled.Container>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='items'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items && items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id.toString()}
                  index={index}
                  isDragDisabled={isDisplayOnly}
                >
                  {(draggableProvided) => (
                    <QuestionListItem
                      index={index}
                      provided={draggableProvided}
                      data={item}
                      onDelete={onDelete}
                      isDisplayOnly={isDisplayOnly}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!isDisplayOnly && (
        <Styled.AddQuestionButton onClick={handleRedirectToQuestionCreation}>
          {t('knowledgeBase.newQuestion')}
        </Styled.AddQuestionButton>
      )}
    </Styled.Container>
  )
}

export { QuestionList }
