import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { SliderProps } from '@material-ui/core'

import { useDifficultySlider } from './useDifficultySlider'
import * as Styled from './DifficultySlider.styles'

const SLIDER_LABEL_POSITION_CHANGE_MIN_WIDTH = 300

const DifficultySlider = (props: SliderProps): JSX.Element => {
  const [placeLabelsAlternately, setPlaceLabelsAlternately] = useState(false)
  const ref = useRef<HTMLSpanElement>(null)
  const { marks } = useDifficultySlider()

  const handleSliderWidthChange = () => {
    if (ref.current && ref.current.offsetWidth <= SLIDER_LABEL_POSITION_CHANGE_MIN_WIDTH) {
      setPlaceLabelsAlternately(true)
    } else {
      setPlaceLabelsAlternately(false)
    }
  }

  useLayoutEffect(() => {
    handleSliderWidthChange()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleSliderWidthChange)
    return () => window.removeEventListener('resize', handleSliderWidthChange)
  }, [])

  return (
    <Styled.Slider
      ref={ref}
      $placeLabelsAlternately={placeLabelsAlternately}
      defaultValue={3}
      step={1}
      valueLabelDisplay='off'
      marks={marks}
      min={1}
      max={marks.length}
      {...props}
    />
  )
}

export { DifficultySlider }
