import styled from '@emotion/styled'

const BackgroundCircle = styled.div`
  width: 80%;
  position: absolute;
  right: -35%;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  z-index: -1;
  padding-bottom: 80%;
  background: #f6f9fa;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 70%;
    padding-bottom: 70%;
  }
`

const BlueCircle = styled.div`
  width: 30%;
  position: absolute;
  left: 5%;
  bottom: 5%;
  border-radius: 50%;
  z-index: -1;
  padding-bottom: 30%;
  background: #eef4f9;
`

const SmallBlueCircle = styled.div`
  width: 17%;
  position: absolute;
  right: 10%;
  top: 5%;
  border-radius: 50%;
  z-index: -1;
  padding-bottom: 17%;
  background: #f6f9fa;
`

const WhiteCircle = styled.div`
  width: 15%;
  position: absolute;
  left: 5%;
  bottom: 5%;
  transform: translate(50%, -50%);
  border-radius: 50%;
  z-index: -1;
  padding-bottom: 15%;
  background: #fff;
`

export {
  BackgroundCircle,
  BlueCircle,
  SmallBlueCircle,
  WhiteCircle,
}
