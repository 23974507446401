import { CircularProgress } from '@material-ui/core'
import * as Styled from './LoadingScreen.styles'

const LoadingScreen = (): JSX.Element => (
  <Styled.LoaderContainer>
    <CircularProgress size={60} />
  </Styled.LoaderContainer>
)

export { LoadingScreen }
