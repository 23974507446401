import React from 'react'
import * as Styled from './CompanyStatisticsItem.styles'

interface CompanyStatisticsItemProps {
  title: string
  amount: number
  className?: string
}

const CompanyStatisticsItem: React.FC<CompanyStatisticsItemProps> = ({
  title,
  amount,
  className
}) => (
  <Styled.RootContainer className={className}>
    <Styled.TitleText>
      {`${title}:`}
    </Styled.TitleText>
    <Styled.AmountText>
      {amount}
    </Styled.AmountText>
  </Styled.RootContainer>
)

export { CompanyStatisticsItem }
