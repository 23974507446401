import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const HeaderContainer = styled.div`
  flex: 0;
`

const ContentContainer = styled.div`
  flex: 1 1 0;
  overflow: hidden;
`

const ScrollContainer = styled.div`
  height: 100%;
  overflow: auto;
`

const FooterContainer = styled.div`
  flex: 0;
`

export {
  Container,
  HeaderContainer,
  ContentContainer,
  ScrollContainer,
  FooterContainer
}
