import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Typography } from '@material-ui/core'

const DisplayFromLabel = styled(Typography)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing(1)}px;
  `}
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

export {
  DisplayFromLabel,
  HeaderContainer,
}
