import { MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { timeZones } from 'constants/timeZones'
import { Select, SelectProps } from 'components/inputs/Select/Select'

const TimezoneSelect = (props: SelectProps) => {
  const { t } = useTranslation()

  return (
    <Select
      {...props}
    >
      {timeZones.map((timeZone) => (
        <MenuItem key={timeZone.tzCode} value={timeZone.offset}>
          {t(`timeZones.${timeZone.labelCode}`)}
        </MenuItem>
      ))}
    </Select>
  )
}

export { TimezoneSelect }
