import { useTranslation } from 'react-i18next'

import { QuizEvaluationData } from 'api/queries/statistics/types'
import { formatSeconds } from 'utils/formatSeconds'
import * as Styled from './StatisticsDetails.styled'

interface StatisticsQuestionDetailsProps {
  averageTime?: QuizEvaluationData['navgTime']
  standardDeviation?: QuizEvaluationData['nstdDevTime']
  averageCorrectness?: QuizEvaluationData['navgCorrect']
  averageIncorrect?: QuizEvaluationData['navgIncorrect']
}

const StatisticsQuestionDetails = ({
  averageTime,
  standardDeviation,
  averageCorrectness,
  averageIncorrect,
}: StatisticsQuestionDetailsProps) => {
  const { t } = useTranslation()

  return (
    <Styled.Container>
      <Styled.RowsContainer>
        {averageTime && (
          <Styled.Row>
            <Styled.Label>
              {t('statistics.details.averageResponseTime')}
            </Styled.Label>
            <Styled.Value>
              {`${formatSeconds(Number((averageTime / 1000000000).toFixed(0)))} min`}
            </Styled.Value>
          </Styled.Row>
        )}
        {standardDeviation && (
          <Styled.Row>
            <Styled.Label>
              {t('statistics.details.standardDeviation')}
            </Styled.Label>
            <Styled.Value>
              {`${formatSeconds(Number((standardDeviation / 1000000000).toFixed(0)))} min`}
            </Styled.Value>
          </Styled.Row>
        )}
        {averageCorrectness !== undefined && (
          <Styled.Row>
            <Styled.Label>
              {t('statistics.details.averageCorrectness')}
            </Styled.Label>
            <Styled.Value>
              {`${averageCorrectness * 100} %`}
            </Styled.Value>
          </Styled.Row>
        )}
        {averageIncorrect !== undefined && (
          <Styled.Row>
            <Styled.Label>
              {t('statistics.details.averageIncorrectAnswers')}
            </Styled.Label>
            <Styled.Value>
              {`${averageIncorrect * 100} %`}
            </Styled.Value>
          </Styled.Row>
        )}
      </Styled.RowsContainer>
    </Styled.Container>
  )
}

export { StatisticsQuestionDetails }
