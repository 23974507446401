import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Typography, TypographyProps } from '@material-ui/core'

const HeadersContainer = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `}
`

const Header = styled((props: TypographyProps) => (
  <Typography variant='h2' {...props} />
))``

const HelperContainer = styled.div`
  display: flex;
  justify-content: center;
`

export {
  HeadersContainer,
  Header,
  HelperContainer,
}
