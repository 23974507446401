import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { MenuItem } from '@material-ui/core'

import { TextField } from 'components/inputs/TextField/TextField'

const StyledMenuItem = styled(MenuItem)`
  ${({ theme }) => css`
  :hover, :focus {
    background: ${theme.palette.backgroundAccents.darkerBlueish};
  }
  `}
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const AutocompleteTextField = styled(TextField)`
  ${({ theme }) => `
    margin-top: 0;
    .MuiInputBase-root {
      flex-wrap: wrap;
      padding-right: ${theme.spacing(4.5)}px;
    }
    .MuiAutocomplete-endAdornment {
      top: 1.5px;
    }
  `}
`

export { StyledMenuItem as MenuItem, TextContainer, AutocompleteTextField }
