import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Typography } from '@material-ui/core'

const TitleContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: ${theme.spacing(4)}px;
    color: ${theme.palette.primary.main};
    overflow-x: hidden;
  `}
`

const Title = styled(Typography)`
  > svg {
    position: absolute;
    width: 116px;
    height: 48px;
    transform: translate(-55%, 15%);
  }
`

export {
  TitleContainer,
  Title,
}
