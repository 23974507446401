import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import { CheckboxProps, FormControlLabel } from '@material-ui/core'

import * as Styled from './Checkbox.styles'

interface Props extends CheckboxProps {
  label?: string
  touched?: boolean
  error?: boolean
  errorMessage?: string
  labelFontSize?: string
  labelFontWeight?: string
  $active?: boolean
  $alignItems?: string
}

const Checkbox: React.FC<Props> = ({
  label,
  touched,
  error,
  errorMessage,
  labelFontSize,
  labelFontWeight,
  $active,
  $alignItems,
  ...rest
}) => (
  <Styled.FormControl error={touched && error} $active={$active} $alignItems={$alignItems}>
    {label ? (
      <FormControlLabel
        control={
          <Styled.Checkbox
            icon={<CheckBoxOutlineBlankOutlinedIcon fontSize='large' />}
            checkedIcon={<CheckBoxOutlinedIcon fontSize='large' />}
            color='primary'
            {...rest}
          />
        }
        label={
          <Styled.Label
            fontWeight={labelFontWeight}
            fontSize={labelFontSize}
            error={touched && error}
          >
            {label}
          </Styled.Label>
        }
      />
    ) : (
      <Styled.Checkbox
        icon={<CheckBoxOutlineBlankOutlinedIcon fontSize='large' />}
        checkedIcon={<CheckBoxOutlinedIcon fontSize='large' />}
        color='primary'
        {...rest}
      />
    )}
    {touched && error && errorMessage && (
      <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>
    )}
  </Styled.FormControl>
)

export { Checkbox }
