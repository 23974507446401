import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Typography } from '@material-ui/core'

import { Button } from 'components/inputs/Button/Button'
import { FirstSteps } from 'components/home/FirstStepsCard/FirstStepsCard'

const leftContainers = css`
  flex: 2;
  margin-right: 50px;
`

const rightContainers = css`
  flex: 3;
  margin-left: 50px;
`

const RootContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
`

const UpperContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    flex: 1;
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: ${theme.spacing(2.5)}px;
      max-width: 100%;
    }
  `}
`

const ArrangementsContainer = styled.div`
  ${leftContainers}
`

const CourseContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(5)}px;
`

const ArrangeButton = styled(Button)`
  width: auto;
  max-width: 300px;
  font-weight: bold;
`

const CalendarContainer = styled.div`
  flex: 1;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: 0px;
    margin-top: ${({ theme }) => theme.spacing(2.5)}px;
  }
`

const LowerContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 100px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: ${({ theme }) => theme.spacing(2.5)}px;
    max-width: 100%;
  }
`

const YourCompanyContainer = styled.div`
  ${rightContainers};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: ${({ theme }) => theme.spacing(2.5)}px;
    margin-left: 0;
  }
`

const SectionTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

const StyledFirstSteps = styled(FirstSteps)`
  margin: ${({ theme }) => theme.spacing(2, 0, 4.5, 0)};
`

export {
  RootContainer,
  UpperContainer,
  ArrangementsContainer,
  CourseContainer,
  ArrangeButton,
  LowerContainer,
  YourCompanyContainer,
  SectionTitle,
  StyledFirstSteps as FirstSteps,
  CalendarContainer
}
