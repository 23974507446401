import React from 'react'
import { useTranslation } from 'react-i18next'
import { DrawerProps } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'

import { useLogout } from 'providers/UserContextProvider'
import { useSignOutMutation } from 'api/mutations/authentication/authentication'
import BellVRLogo from 'assets/images/logo.png'
import { NavigationList } from './NavigationList/NavigationList'
import * as Styled from './NavigationDrawer.styles'

const NavigationDrawer: React.FC<DrawerProps> = (props) => {
  const { t } = useTranslation()
  const logout = useLogout()
  const { mutate: logoutMutate } = useSignOutMutation({
    onSettled: () => logout()
  })

  return (
    <Styled.Drawer {...props}>
      <Styled.Toolbar to='/'>
        <Styled.LogoImage src={BellVRLogo} alt='BellVR Logo' />
      </Styled.Toolbar>
      <Styled.ListContainer>
        <NavigationList />
        <Styled.NavigationListItem
          primary={t('common.signOut')}
          icon={<ExitToApp />}
          onClick={() => logoutMutate(undefined)}
        />
      </Styled.ListContainer>
    </Styled.Drawer>
  )
}

export { NavigationDrawer }
