import styled from '@emotion/styled'
import { IconButton, IconButtonProps } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const AppointmentHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

interface ExitButtonProps extends IconButtonProps {
  $isMobile?: boolean
}

const ExitButton = styled((props: ExitButtonProps) => (
  <IconButton {...props}>
    <CloseIcon />
  </IconButton>
), { shouldForwardProp: (prop) => prop !== '$isMobile' })`
  ${({ theme, $isMobile }) => `
    ${$isMobile && `margin-left: -${theme.spacing(1.5)}px`};
    color: ${theme.palette.text.primary};
  `}
`

interface RowProps {
  $justifyContent?: 'flex-start' | 'flex-end' | 'center' |
  'space-between' | 'space-around' | 'space-evenly'
  $smallScreenMaxWidth?: string
}

const Row = styled.div<RowProps>`
  ${({ $justifyContent, theme, $smallScreenMaxWidth }) => `
    display: flex;
    justify-content: ${$justifyContent || 'flex-start'};
    ${theme.breakpoints.down('sm')} {
      max-width: ${$smallScreenMaxWidth || 'none'};
    }
  `}
`

export {
  AppointmentHeaderContainer,
  Row,
  ExitButton,
}
