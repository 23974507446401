import styled from '@emotion/styled'
import { css, SerializedStyles, Theme } from '@emotion/react'
import {
  IconButton,
  IconButtonProps,
  Typography,
  TypographyProps,
} from '@material-ui/core'
import {
  ArrowForward,
  CheckBox,
  Delete,
  RadioButtonUnchecked,
  DragIndicator,
} from '@material-ui/icons'

const QuestionItemContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin: 10px 0px;
    border: solid ${theme.palette.textField.lightGrey} 1px;
    border-radius: ${theme.palette.textField.borderRadius};
    min-height: 60px;
  `}  
`

const QuestionContainer = styled((props: TypographyProps) => (
  <Typography {...props} />
))`
  flex: 1;
  margin: 0 ${({ theme }) => theme.spacing(1)}px;
`

const ActionsContainer = styled.div`
  display: flex;
`

const DarkIconCommonStyles = (theme: Theme): SerializedStyles => css`
  color: ${theme.palette.icon.dark}; 
`

const IconCommonStyles = (theme: Theme): SerializedStyles => css`
  color: ${theme.palette.icon.light}; 
  padding-left: 10px;
  font-size: 28px;
`

const RedirectButton = styled((props: IconButtonProps) => (
  <IconButton {...props}>
    <ArrowForward />
  </IconButton>
))`
  & .MuiSvgIcon-root {
    ${({ theme }) => DarkIconCommonStyles(theme)}
  }
`

const DeleteButton = styled((props: IconButtonProps) => (
  <IconButton {...props}>
    <Delete />
  </IconButton>
))`
  & .MuiSvgIcon-root {
    ${({ theme }) => DarkIconCommonStyles(theme)}
  }
`

const CheckBoxIcon = styled(CheckBox)`
  ${({ theme }) => IconCommonStyles(theme)};
`

const RadioButtonIcon = styled(RadioButtonUnchecked)`
  ${({ theme }) => IconCommonStyles(theme)};
`

const DragIndicatorIcon = styled(DragIndicator)`
  ${({ theme }) => DarkIconCommonStyles(theme)}; 
`

export {
  QuestionItemContainer,
  QuestionContainer,
  ActionsContainer,
  RedirectButton,
  DeleteButton,
  CheckBoxIcon,
  RadioButtonIcon,
  DragIndicatorIcon,
}
