import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { useCreateCompanyAppointmentMutation } from 'api/mutations/appointments/appointments'
import { useCompanyId } from 'providers/CompanyProvider'
import { CreateAppointment } from './CreateAppointment'

const AdminCreateAppointmentScreen = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const companyId = useCompanyId() || -1

  const { mutate, isLoading } = useCreateCompanyAppointmentMutation({
    onSuccess: () => {
      queryClient.invalidateQueries('next-company-appointments')
      history.push('/appointments/detailed/next')
    }
  })

  return (
    <CreateAppointment
      isLoading={isLoading}
      onCreate={(appointment) => mutate({ companyId, appointment })}
    />
  )
}

export { AdminCreateAppointmentScreen }
