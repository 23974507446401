import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { combineProviders } from 'react-combine-providers'
import { QueryClient, QueryClientProvider } from 'react-query'

import { ApiError } from 'api/types'
import { TOKEN_EXPIRED } from 'constants/errorCodes'
import { I18nProvider } from './I18nProvider'
import { ThemeProviders } from './ThemeProviders'
import { UserContextProvider } from './UserContextProvider'
import { FetchProvider } from './FetchProvider'
import { MuiPickersProvider } from './MuiPickersProvider'
import { CompanyProvider } from './CompanyProvider'
import { SnackbarProvider } from './SnackbarProvider/SnackbarProvider'
import { ApiErrorHandlerProvider } from './ApiErrorHandlerProvider'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failedCount, error) => {
        const { messageCode, status } = (error as ApiError<unknown>).response?.data || {}
        if (status === 401 && messageCode === TOKEN_EXPIRED) {
          return true
        }
        return failedCount !== 4
      }
    },
    mutations: {
      retry: (_, error) => {
        const { messageCode, status } = (error as ApiError<unknown>).response?.data || {}
        if (status === 401 && messageCode === TOKEN_EXPIRED) {
          return true
        }
        return false
      }
    },
  }
})

const AppProviders: React.FC = ({ children }) => {
  const providers = combineProviders()

  providers.push(BrowserRouter)
  providers.push(I18nProvider)
  providers.push(ThemeProviders, { children })
  providers.push(QueryClientProvider, { client: queryClient })
  providers.push(MuiPickersProvider)
  providers.push(SnackbarProvider, { children })

  providers.push(CompanyProvider)
  providers.push(UserContextProvider)
  providers.push(FetchProvider)
  providers.push(ApiErrorHandlerProvider)

  const MasterProvider = providers.master()

  return <MasterProvider>{children}</MasterProvider>
}

export { AppProviders }
