import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'

import { AnalysisScopeSelect } from 'components/data/AnalysisScopeSelect/AnalysisScopeSelect'

const Select = styled(AnalysisScopeSelect)`
  width: 270px;
  margin: 0;
`

const AnalysisScopeSelectLabel = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  white-space: nowrap;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

export {
  Select,
  AnalysisScopeSelectLabel,
  HeaderContainer,
}
