import { useTranslation } from 'react-i18next'

import { HistoryQuestion } from 'api/queries/results/types'
import { QuizQuestionType } from 'types/QuestionType'
import { formatSeconds } from 'utils/formatSeconds'
import * as Styled from '../QuestionAnswerItem.styles'

interface QuestionAnswerDetailsProps {
  questionData?: HistoryQuestion
}

const QuestionAnswerDetails = ({
  questionData
}: QuestionAnswerDetailsProps) => {
  const { t } = useTranslation()

  const {
    question,
    questionType,
    time,
    answers,
    userAnswers,
  } = questionData || {}

  return (
    <Styled.DetailsContainer>
      <Styled.DetailsRow>
        <Styled.DetailsLabel>{t('results.questionType')}</Styled.DetailsLabel>
        <Styled.DetailsText>{t(`questionType.${questionType}`)}</Styled.DetailsText>
      </Styled.DetailsRow>
      <Styled.DetailsRow>
        <Styled.DetailsLabel>{t('results.content')}</Styled.DetailsLabel>
        <Styled.DetailsText>{question}</Styled.DetailsText>
      </Styled.DetailsRow>
      {time && (
        <Styled.DetailsRow>
          <Styled.DetailsLabel>{t('results.answerTime')}</Styled.DetailsLabel>
          <Styled.DetailsText>
            {`${formatSeconds(time)} min`}
          </Styled.DetailsText>
        </Styled.DetailsRow>
      )}
      <Styled.DetailsRow>
        <Styled.DetailsLabel>{t('quiz.labelAnswers')}</Styled.DetailsLabel>
        <Styled.AnswersList>
          {questionType === QuizQuestionType.MULTIPLE_CHOICE
            ? (
              answers?.map((answer) => (
                <Styled.AnswerContainer key={answer.content}>
                  <Styled.DetailsText>{`${answer.content}:`}</Styled.DetailsText>
                  <Styled.AnswerText
                    $correct={
                      userAnswers?.includes(answer.content) === answer.correct
                    }
                  >
                    {t(`common.${
                      userAnswers?.includes(answer.content) ? 'yes' : 'no'
                    }`).toUpperCase()}
                  </Styled.AnswerText>
                </Styled.AnswerContainer>
              ))
            ) : (
              userAnswers?.map((userAnswer) => (
                <Styled.AnswerText
                  $correct={
                    answers?.filter((answer) => answer.correct)
                      .some((answer) => answer.content === userAnswer)
                  }
                  key={userAnswer}
                >
                  {userAnswer}
                </Styled.AnswerText>
              ))
            )}
        </Styled.AnswersList>
      </Styled.DetailsRow>
      <Styled.DetailsRow>
        <Styled.DetailsLabel>{t('results.correctAnswers')}</Styled.DetailsLabel>
        <Styled.AnswersList>
          {answers?.map((answer) => (
            questionType === QuizQuestionType.MULTIPLE_CHOICE
              ? (
                <Styled.AnswerContainer key={answer.content}>
                  <Styled.DetailsText>{`${answer.content}:`}</Styled.DetailsText>
                  <Styled.AnswerText $correct>
                    {t(`common.${answer.correct ? 'yes' : 'no'}`).toUpperCase()}
                  </Styled.AnswerText>
                </Styled.AnswerContainer>
              )
              : (answer.correct ? (
                <Styled.AnswerText
                  key={answer.content}
                  $correct
                >
                  {answer.content}
                </Styled.AnswerText>
              ) : (
                <Styled.DetailsText key={answer.content}>{answer.content}</Styled.DetailsText>
              ))
          ))}
        </Styled.AnswersList>
      </Styled.DetailsRow>
    </Styled.DetailsContainer>
  )
}

export { QuestionAnswerDetails }
