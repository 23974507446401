import styled from '@emotion/styled'
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@material-ui/core'

import ExpandIcon from 'assets/icons/ExpandIcon'

const Accordion = styled(MuiAccordion)`
  box-shadow: none;
  background-color: inherit;
  ::before {
    height: 0;
  }
  &.Mui-expanded {
    margin: 0;
  }
`

const AccordionSummary = styled(MuiAccordionSummary)`
  flex-direction: column;
  padding: 0;
  .MuiAccordionSummary-content {
    margin: 0;
    width: 100%;
    .Mui-expanded {
      margin: 0;
    }
  }
  &.Mui-expanded {
    min-height: 0;
  }
  .MuiButtonBase-root {
    margin: 0;
    padding: ${({ theme }) => theme.spacing(0.5)}px;
  }
`

const AccordionDetails = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
`

const ExpandMore = styled(ExpandIcon)``

export {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ExpandMore,
}
