import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'

const TrainingIdContainer = styled(Typography)`
  ${({ theme }) => css`
    flex-basis: 50%;
    padding-top: ${theme.spacing(1)}px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: wrap;
  `}
`

const ResultContainer = styled(TrainingIdContainer)`
  ${({ theme }) => css`
    text-align: center;
    ${theme.breakpoints.down('sm')} {
      font-weight: normal;
    }
  `}  
`

const StepIdContainer = styled(ResultContainer)`
  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      padding-left: ${theme.spacing(2)}px;
    }
  `}    
`

const ListContainer = styled.div`
`

const ListHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `}
`

const CenterElementContainer = styled.div`
  display: flex;
  justify-content: center;
`

export {
  TrainingIdContainer,
  ResultContainer,
  ListHeaderContainer,
  ListContainer,
  CenterElementContainer,
  StepIdContainer,
}
