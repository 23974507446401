import { useTranslation } from 'react-i18next'
import difference from 'lodash/difference'

import { KnowledgeBaseVisibilityType } from 'types/KnowledgeBaseVisibilityType'
import { Select, SelectProps } from 'components/inputs/Select/Select'
import * as Styled from '../VisibilityTypeSelect.styles'

interface KnowledgeBaseVisibilitySelectProps extends SelectProps {
  className?: string,
  without?: KnowledgeBaseVisibilityType[]
  label?: string
}

const KnowledgeBaseVisibilitySelect = ({
  className,
  without = [],
  label,
  ...rest
}: KnowledgeBaseVisibilitySelectProps) => {
  const { t } = useTranslation()

  const visibilityTypes: KnowledgeBaseVisibilityType[] = difference([
    KnowledgeBaseVisibilityType.Author,
    KnowledgeBaseVisibilityType.Company,
    KnowledgeBaseVisibilityType.UsersList,
  ], without)

  return (
    <Select
      label={label ?? t('common.visibility')}
      className={className}
      {...rest}
    >
      {visibilityTypes.map((type) => (
        <Styled.MenuItem key={type} value={type}>
          {t(`knowledgeBase.visibility.${[type]}`)}
        </Styled.MenuItem>
      ))}
    </Select>
  )
}

export { KnowledgeBaseVisibilitySelect }
