import { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './FormSaveButton.styles'

interface FormSaveButtonProps {
  isActive?: boolean
  isSaving?: boolean
  isSaved?: boolean
  savedButtonText?: string
}

const FormSaveButton = ({
  isActive,
  isSaving,
  isSaved,
  savedButtonText,
}: FormSaveButtonProps) => {
  const { t } = useTranslation()
  const [wasUsed, setWasUsed] = useState(false)

  const buttonText = useMemo(() => {
    if (isSaving) return t('common.saving')
    if (isSaved && !isActive) return savedButtonText || t('common.saved')
    return t('common.save')
  }, [isActive, isSaving, isSaved, savedButtonText, t])

  useEffect(() => {
    if (isSaved && !wasUsed) {
      setWasUsed(true)
    }
  }, [isSaved, wasUsed])

  const disabled = wasUsed ? false : !isActive

  return (
    <Styled.ButtonContainer>
      <Styled.Button
        disabled={disabled || isSaving}
        isLoading={isSaving}
      >
        {buttonText}
      </Styled.Button>
    </Styled.ButtonContainer>
  )
}

export { FormSaveButton }
