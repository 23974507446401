import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

import { useFetch } from 'providers/FetchProvider'
import { ForumEntry } from 'api/types'
import { FORUM_ENTRIES_QUERY_KEY } from 'api/queriesKeys'

const useGetForumEntriesForAppointmentQuery = (
  id: number,
  useQueryOptions?: Omit<UseQueryOptions<ForumEntry[], Error>, 'queryKey'>
)
: UseQueryResult<ForumEntry[], Error> => {
  const { fetch } = useFetch()

  return useQuery(
    [FORUM_ENTRIES_QUERY_KEY, { id }],
    () => fetch.get(`/forum-entry/appointment/${id}`).then((response) => response.data),
    useQueryOptions,
  )
}

export { useGetForumEntriesForAppointmentQuery }
