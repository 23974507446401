import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Button } from 'components/inputs/Button/Button'
import { Select } from 'components/inputs/Select/Select'
import { SearchFilterInput } from 'components/inputs/SearchFilterInput/SearchFilterInput'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

const RedirectButton = styled(Button)`
  width: auto;
`

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing(2.5)}px;
`

const StyledSelect = styled(Select)`
  ${({ theme }) => css`
    flex: 1;
    min-width: 200px;
    margin-right: ${theme.spacing(2.5)}px;

    ${theme.breakpoints.down('sm')} {
      margin-right: 0;
    }
  `}
`

const SearchFilterContainer = styled.div`
  ${({ theme }) => css`
    flex: 3;
    min-width: 200px;
    margin-left: ${theme.spacing(2.5)}px;

    ${theme.breakpoints.down('sm')} {
      margin-left: 0;
    }
  `}
`

const SearchFilter = styled(SearchFilterInput)``

const HelperContainer = styled.div`
  display: flex;
  justify-content: center;
`

export {
  Header,
  RedirectButton,
  FiltersContainer,
  StyledSelect as Select,
  SearchFilterContainer,
  SearchFilter,
  HelperContainer,
}
