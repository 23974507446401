import styled from '@emotion/styled'
import { Chip, Typography, TypographyProps } from '@material-ui/core'

const CheckboxWrapper = styled.div`
  ${({ theme }) => `
    padding-left: 10px;
  
    ${theme.breakpoints.down('sm')} {
      padding: 0;
      display: flex;
      justify-content: center;
    }
  `}
`

const FieldsWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: flex-start;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `}
`

const Text = styled(Typography)`
  ${({ theme }) => `
    margin-left: ${theme.spacing(0.5)}px;
    margin-right: ${theme.spacing(1)}px;
    font-weight: bold;
  `}
`

const ExpirationDateWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  align-items: center;
  & .MuiFormControl-root {
    margin-bottom: 0;
  }
`

const EditButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`

const TechnicalUserWrapper = styled(Chip)`
  ${({ theme }) => `
    color: #fff;
    background-image: ${theme.palette.gradient.main};
    pointer-events: none;

    ${theme.breakpoints.down('sm')} {
      margin: ${theme.spacing(1)}px;
    }
  `}
`

const EmailAwaitsConfirmationText = styled((props: TypographyProps) => (
  <Typography variant='body2' {...props} />
))`
  ${({ theme }) => `
    padding: ${theme.spacing(1)}px 10px;
    color: rgba(0, 0, 0, 0.38);
  `}
`

export {
  CheckboxWrapper,
  FieldsWrapper,
  Text,
  ExpirationDateWrapper,
  EditButtonContainer,
  TechnicalUserWrapper,
  EmailAwaitsConfirmationText
}
