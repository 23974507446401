import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { IconButton, IconButtonProps, Typography, TypographyProps } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

const List = styled.div`
  display: flex;
  flex-direction: column;
`

const HelperContainer = styled.div`
  display: flex;
  justify-content: center;
`

const NoItemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
`

const ListItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: ${theme.spacing(1)}px 0;
    padding: ${theme.spacing(1)}px;
    border: ${theme.palette.border.borderLineWidth} solid ${theme.palette.border.light};
    border-radius: ${theme.palette.border.borderRadius};

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: center;
      padding: ${theme.spacing(0.5)}px;
    }
  `}
`

interface ListItemTextProps extends TypographyProps {
  $flex?: number
}

const ListItemText = styled((props: ListItemTextProps) => (
  <Typography noWrap {...props} />
), { shouldForwardProp: (prop) => prop !== '$flex' })`
  ${({ theme, $flex }) => `
    flex: ${$flex || 1};
    margin: 0 ${theme.spacing(1)}px;
  `}
`

const ForwardButton = styled((props: IconButtonProps) => (
  <IconButton {...props}>
    <ArrowForwardIcon />
  </IconButton>
))`
  margin: 0 ${({ theme }) => theme.spacing(1)}px;
`

export {
  List,
  HelperContainer,
  NoItemsContainer,
  ListItem,
  ListItemText,
  ForwardButton,
}
