import { QuizEvaluationData } from 'api/queries/statistics/types'
import { EducationLevel } from 'types/EducationLevel'

export const parseData = (data?: QuizEvaluationData) => {
  if (!data) {
    return {
      time: [],
      correct: [],
    }
  }

  const time = Object.keys(EducationLevel).map((level) => {
    const timeKey = Object.keys(data).find((key) => {
      const lowerCaseKey = key.toLowerCase()
      const levelToLowerCase = level.toLowerCase()
      return lowerCaseKey.includes(`time${levelToLowerCase}`)
    })
    return data[timeKey as keyof QuizEvaluationData] as number * 100
  })
  const correct = Object.keys(EducationLevel).map((level) => {
    const correctKey = Object.keys(data).find((key) => {
      const lowerCaseKey = key.toLowerCase()
      const levelToLowerCase = level.toLowerCase()
      return lowerCaseKey.includes(`correct${levelToLowerCase}`)
    })
    return data[correctKey as keyof QuizEvaluationData] as number * 100
  })

  return {
    time,
    correct,
  }
}
