import { Typography } from '@material-ui/core'
import React from 'react'

import * as CommonStyled from '../AppointmentForm.styles'
import * as Styled from './ParticipantItem.styles'

interface ParticipantItemProps {
  firstName: string
  lastName: string
  email: string
  onRemove: () => void
  errorMessage?: string
}

const ParticipantItem: React.FC<ParticipantItemProps> = ({
  firstName,
  lastName,
  email,
  onRemove: handleRemove,
  errorMessage = '',
}) => (
  <CommonStyled.ItemsListRow>
    <Styled.Container>
      <Styled.ContentContainer>
        <Styled.TextContainer>
          <Styled.Text $bold>
            {`${firstName} ${lastName}`}
          </Styled.Text>
          <Styled.Text>
            {`[${email}]`}
          </Styled.Text>
        </Styled.TextContainer>
        <CommonStyled.RemoveButton
          onClick={handleRemove}
        />
      </Styled.ContentContainer>
      {errorMessage && (
        <Typography variant='body2' color='error'>{errorMessage}</Typography>
      )}
    </Styled.Container>
  </CommonStyled.ItemsListRow>
)

export { ParticipantItem }
