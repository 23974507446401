import React from 'react'
import { useHistory } from 'react-router-dom'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import { useTranslation } from 'react-i18next'

import { RestrictedContent } from 'components/auth/RestrictedContent/RestrictedContent'
import { TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE } from 'constants/roleGroups'
import { Appointments } from 'components/data/Appointments/Appointments'
import type { AppointmentListItemType } from 'components/data/AppointmentList/AppointmentList'
import { useIsMobile } from 'hooks/useIsMobile'
import * as Styled from './AppointmentsBase.styles'

interface AppointmentsBaseProps {
  nextAppointmentsList: AppointmentListItemType[]
  archivedAppointmentsList: AppointmentListItemType[]
  isLoading: boolean
}

const AppointmentsBase: React.FC<AppointmentsBaseProps> = ({
  nextAppointmentsList,
  archivedAppointmentsList,
  isLoading
}) => {
  const history = useHistory()
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const handleAddNewAppointmentButton = () => history.push('/appointments/create')

  return (
    <Styled.RootContainer>
      <RestrictedContent accessRoles={[TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE]}>
        <Styled.ActionsContainer>
          {!isMobile ? (
            <Styled.AddAppointmentButton
              backgroundGradient
              endIcon={<AddRoundedIcon />}
              onClick={handleAddNewAppointmentButton}
            >
              {t('appointments.add')}
            </Styled.AddAppointmentButton>) : (
              <Styled.AddAppointmentFab
                variant='extended'
                color='primary'
                onClick={handleAddNewAppointmentButton}
              >
                {t('appointments.add')}
                <AddRoundedIcon />
              </Styled.AddAppointmentFab>
          )}
        </Styled.ActionsContainer>
      </RestrictedContent>
      <Appointments
        nextAppointmentsList={nextAppointmentsList}
        archivedAppointmentsList={archivedAppointmentsList}
        isLoading={isLoading}
      />
    </Styled.RootContainer>
  )
}

export { AppointmentsBase }
