import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'

import { RankingItem } from 'api/types'
import { useIsMobile } from 'hooks/useIsMobile'
import { RankingListItem } from './RankingListItem/RankingListItem'
import * as Styled from './Ranking.styles'

interface RankingListProps {
  isLoading: boolean
  rankingData?: RankingItem[]
}

const RankingList = ({ rankingData, isLoading }: RankingListProps): JSX.Element => {
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <Styled.CenterElementContainer>
        <CircularProgress />
      </Styled.CenterElementContainer>
    )
  }

  if (!rankingData?.length) {
    return (
      <Styled.CenterElementContainer>
        <Styled.NoDataContainer>
          {t('results.ranking.noData')}
        </Styled.NoDataContainer>
      </Styled.CenterElementContainer>
    )
  }

  return (
    <Styled.ListContainer>
      {!isMobile && (
      <Styled.ListHeaderContainer>
        <Styled.UserNameContainer>
          {t('results.ranking.name')}
        </Styled.UserNameContainer>
        <Styled.ResultContainer>
          {t('results.ranking.quizMark')}
        </Styled.ResultContainer>
        <Styled.ResultContainer>
          {t('results.ranking.testMark')}
        </Styled.ResultContainer>
      </Styled.ListHeaderContainer>
      )}
      {
        rankingData && rankingData.map(({ user, ...userRankingData }) => (
          <RankingListItem
            key={user.id}
            firstName={user.firstName}
            lastName={user.lastName}
            {...userRankingData}
          />
        ))
      }
    </Styled.ListContainer>
  )
}

export { RankingList }
