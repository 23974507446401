import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

import { ReactComponent as ExpandSVG } from 'assets/images/expand.svg'

const ExpandIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox='0 0 45.07 15.985'>
    <ExpandSVG />
  </SvgIcon>
)

export default ExpandIcon
