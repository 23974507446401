import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik, FormikHelpers } from 'formik'
import { Grid } from '@material-ui/core'

import { SignInSchema } from 'validations/SignInSchema'
import { Button } from 'components/inputs/Button/Button'
import { TextField } from 'components/inputs/TextField/TextField'
import { PasswordField } from 'components/inputs/PasswordField/PasswordField'
import { Link } from 'components/inputs/Link/Link'
import * as FormStyled from '../forms.styles'

export interface FormFields {
  emailOrUsername: string
  password: string
}

interface Props {
  onSubmit: (values: FormFields, helpers: FormikHelpers<FormFields>) => void
  isLoading: boolean
  isError: boolean
  errorMessage?: string
}

const SignInForm: React.FC<Props> = ({
  onSubmit,
  isLoading,
  isError,
  errorMessage
}) => {
  const { t } = useTranslation()

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched
  } = useFormik<FormFields>({
    initialValues: { emailOrUsername: '', password: '' },
    validationSchema: SignInSchema,
    onSubmit,
  })

  return (
    <FormStyled.Form onSubmit={handleSubmit}>
      <TextField
        label={t('common.emailOrUsername')}
        type='text'
        errorMessage={t(errors.emailOrUsername || '')}
        id='emailOrUsername'
        name='emailOrUsername'
        value={values.emailOrUsername}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.emailOrUsername}
      />
      <PasswordField
        label={t('common.password')}
        errorMessage={t(errors.password || '')}
        id='password'
        name='password'
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.password}
      />
      {isError && (
        <FormStyled.ErrorMessage>
          {errorMessage || t('signIn.errors.wrongCredentials')}
        </FormStyled.ErrorMessage>
      )}
      <FormStyled.FormFooter>
        <Grid container justifyContent='space-between'>
          <Grid container item xs={12} sm={8} alignContent='center'>
            <Link to='/reset-password'>{t('signIn.forgotPassword')}</Link>
          </Grid>
          <Grid container item xs={12} sm={4}>
            <Button backgroundGradient disabled={isLoading} isLoading={isLoading}>
              {t('common.signIn')}
            </Button>
          </Grid>
        </Grid>
      </FormStyled.FormFooter>
    </FormStyled.Form>
  )
}
export default SignInForm
