import { useQuery, UseQueryResult } from 'react-query'
import { AxiosResponse } from 'axios'

import { useFetch } from 'providers/FetchProvider'
import { Company, StatisticsResponse } from './types'
import { GET_ALL_COMPANIES_QUERY_KEY, GET_COMPANY_STATISTICS_QUERY_KEY } from './keys'

const useGetAllCompaniesQuery = ()
: UseQueryResult<AxiosResponse<Company[]>, unknown> => {
  const { fetch } = useFetch()

  return useQuery(
    GET_ALL_COMPANIES_QUERY_KEY,
    () => fetch.get('/company')
  )
}

const useGetCompanyStatistics = ()
: UseQueryResult<AxiosResponse<StatisticsResponse>, unknown> => {
  const { fetch } = useFetch()

  return useQuery(
    GET_COMPANY_STATISTICS_QUERY_KEY,
    () => fetch.get('/company/statistics')
  )
}

export {
  useGetCompanyStatistics,
  useGetAllCompaniesQuery,
}
