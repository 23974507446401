import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import { PageHeader } from 'components/layout/PageHeader/PageHeader'
import { StatisticsQuizList } from 'components/data/Statistics/StatisticsQuizList/StatisticsQuizList'
import { useCompanyId } from 'providers/CompanyProvider'
import { useStatisticsData } from 'hooks/useStatisticsData'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import * as Styled from './Statistics.styled'

const StatisticsScreen = () => {
  const { t } = useTranslation()
  const companyId = useCompanyId() || 0
  const [searchFilter, setSearchFilter] = useState('')
  const [loaderElement, setLoaderElement] = useState<HTMLDivElement | null>(null)

  const isIntersecting = useIntersectionObserver(loaderElement)

  const loader = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setLoaderElement(node)
    }
  }, [])

  const {
    data: quizzesData,
    isLoading: isLoadingQuizzes,
    isError: isErrorQuizzes,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = useStatisticsData(companyId, searchFilter)

  const quizzesItems = useMemo(() => {
    if (quizzesData?.pages) {
      return quizzesData.pages.map((page) => page.content).reduce((prev, cur) => [...prev, ...cur])
    }
    return []
  }, [quizzesData])

  useEffect(() => {
    if (isIntersecting && hasNextPage) fetchNextPage()
  }, [isIntersecting, hasNextPage, fetchNextPage, quizzesData])

  return (
    <RouteContainer>
      <PageHeader
        title={t('statistics.title')}
        actions={(
          <Styled.SearchFilterContainer>
            <Styled.SearchLabel>
              {t('common.search')}
            </Styled.SearchLabel>
            <Styled.SearchFilter
              label=''
              searchFilterValue={searchFilter}
              onChangeSearchFilter={setSearchFilter}
            />
          </Styled.SearchFilterContainer>
        )}
      />
      <StatisticsQuizList
        isLoading={isLoadingQuizzes}
        isError={isErrorQuizzes}
        items={quizzesItems}
      />
      {(!isLoadingQuizzes || !isErrorQuizzes) && (
        <Styled.HelperContainer ref={loader}>
          {isFetchingNextPage && <Typography>{t('common.loading')}...</Typography>}
        </Styled.HelperContainer>
      )}
    </RouteContainer>
  )
}

export default StatisticsScreen
