/* eslint-disable max-len */
import {
  ALPHABETICAL, ALPHABETICAL_REVERSE, NEWEST, NONE as SORT_NONE, OLDEST
} from 'constants/sortTypes'
import { ADMIN_ROLE, COMPANY_ROLE, TEACHER_ROLE, PARTICIPANT_ROLE } from 'constants/roleGroups'
import { EMAIL_AWAIT_CONFIRMATION } from 'constants/errorCodes'
import { ResultsGroupingType } from 'constants/resultsGroupingType'
import { NotificationType } from 'types/Notifications'
import { EducationLevel } from 'types/EducationLevel'
import { QuizQuestionType } from 'types/QuestionType'
import { RankingScopeType } from 'types/RankingScopeType'
import { KnowledgeBaseContentType } from 'types/KnowledgeBaseContentType'
import { AnalysisScopeType } from 'types/AnalysisScopeType'
import { AppointmentVisibilityType } from 'types/AppointmentVisibilityType'
import { KnowledgeBaseVisibilityType } from 'types/KnowledgeBaseVisibilityType'

export default {
  words: {
    quiz: 'Quiz',
    quiz_plural: 'Quizzes',
    occurrence: 'Occurrence',
    occurrence_plural: 'Occurrences',
  },
  translation: {
    common: {
      id: 'Id',
      emailOrUsername: 'Email or username',
      username: 'Username',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm password',
      newPassword: 'New password',
      confirmNewPassword: 'Confirm new password',
      oldPassword: 'Old password',
      comingSoon: 'Coming soon',
      firstName: 'First name',
      lastName: 'Last name',
      phoneNumber: 'Phone number',
      userColor: 'User color',
      educationLevel: 'Education level',
      timeZone: 'Time zone',
      signIn: 'Sign in',
      signUp: 'Sign up',
      signOut: 'Sign out',
      save: 'Save',
      saveChanges: 'Save changes',
      saving: 'Saving',
      saved: 'Saved',
      created: 'Created',
      loading: 'Loading',
      seeAll: 'See all',
      go: 'Go',
      details: 'Details',
      language: 'Language',
      edit: 'Edit',
      move: 'Move',
      delete: 'Delete',
      copy: 'Copy',
      group: 'Group',
      sort: 'Sort',
      search: 'Search',
      arrange: 'Arrange',
      create: 'Create',
      createNew: 'Create new',
      choose: 'Choose',
      ok: 'Ok',
      cancel: 'Cancel',
      type: 'Type',
      download: 'Download',
      name: 'Name',
      description: 'Description',
      visibility: 'Visibility',
      chooseImage: 'Choose image',
      chooseFile: 'Choose file',
      noFileSelected: 'No file selected',
      add: 'Add',
      minutes: 'Minutes',
      roles: 'Roles',
      start: 'Start',
      actions: 'Actions',
      emptySubtext: 'To create new, use the buttons above',
      deactivate: 'Deactivate',
      activate: 'Activate',
      companies: 'Companies',
      agree: 'I agree',
      decline: 'Decline',
      users: 'Users',
      startWrite: 'Start typing the name...',
      notAssigned: 'Not assigned',
      at: 'at',
      noData: 'No data',
      yes: 'Yes',
      no: 'No',
      accountExpirationDate: 'Account expiration date',
      filter: 'Filter',
      iUnderstand: 'I understand',
      noOptions: 'No options',
      couldNotFetchCompaniesData: 'Could not fetch companies data',
      errors: {
        generic: 'An error occurred.',
        firstName: {
          required: 'First name is required',
        },
        lastName: {
          required: 'Last name is required',
        },
        username: {
          taken: 'Username is already taken',
          min: 'Username must be at least 4 characters long',
          max: 'Username can\'t be longer than 20 characters',
          invalid: 'No special characters allowed',
        },
        emailOrUsername: {
          required: 'Email or username is required',
          invalid: 'Please enter a valid email or username',
        },
        email: {
          required: 'Email is required',
          invalid: 'Please enter a valid email',
        },
        password: {
          min: 'Password must be at least 8 characters long',
          required: 'Password is required',
        },
        confirmPassword: {
          dontMatch: 'Passwords do not match',
          required: 'Password confirmation is required',
        },
        phoneNumber: {
          required: 'Phone number is required',
          invalid: 'Please enter a valid phone number',
          min: 'Phone number must be at least 9 characters long',
        },
        userColor: {
          invalid: 'Invalid color format',
        },
        roles: {
          required: 'A role is required'
        },
        imageSize: 'File is too large, maximum file size is 2MB',
        fileType: 'Unsupported file format',
        imageDimensions: 'Incorrect image size, minimum size is 500px x 300px'
      },
    },
    signIn: {
      errors: {
        wrongCredentials: 'The username or password is incorrect',
      },
      forgotPassword: 'Forgot password?',
      dontHaveAccount: "Don't have an account yet? Sign up",
    },
    signUp: {
      form: {
        username: 'Username(optional)',
        privacyPolicy: 'I accept the terms of service',
      },
      errors: {
        privacyPolicy: {
          required: 'You need to accept the terms of service',
        },
      },
      haveAccount: 'Already have an account? Sign in',
      backToLogin: 'Return to login screen',
    },
    resetPassword: {
      form: {
        newPassword: 'New password',
        confirmNewPassword: 'Confirm new password',
      },
      sendResetEmail: 'Send password reset email',
      emailSent: 'Sent password reset email on address ',
      savePassword: 'Save password',
      savedPassword: 'New password was saved',
    },
    changeEmail: {
      title: 'Change email address',
      sent: 'Confirmation mail sent',
      action: {
        success: 'E-mail has been updated',
        error: 'An error occurred while trying to update e-mail',
      },
    },
    confirmEmail: {
      confirm: 'Confirm new e-mail',
      success: 'E-mail address changed successfully',
      return: 'Return to home screen',
    },
    changePassword: {
      title: 'Change password',
      form: {
        newPassword: 'New password',
        confirmNewPassword: 'Confirm new password',
        oldPassword: 'Confirm change with current password',
      },
      errors: {
        oldPassword: {
          required: 'Old password is required'
        },
        newPassword: {
          min: 'Password must be at least 8 characters long',
          required: 'New password is required',
        },
        confirmNewPassword: {
          required: 'Confirmation of new password is required',
        },
      },
      action: {
        success: 'Password has been updated',
        error: 'An error occurred while trying to update password',
      },
    },
    changeUserDetails: {
      action: {
        success: 'User details have been updated',
        error: 'An error occurred while trying to update user details',
      },
    },
    drawer: {
      navigation: {
        home: 'Home',
        base: 'Base',
        companies: 'Companies',
        users: 'Users',
        knowledgeBase: 'Knowledge base',
        appointments: 'Appointments',
        results: 'Results',
        statistics: 'Statistics',
        analysis: 'Analysis',
        courses: 'Courses',
        account: 'Account',
        faq: 'FAQ',
      },
    },
    account: {
      colorNotSet: 'None',
      userDetails: 'User details',
      credentials: 'Credentials',
      languageAndNotifications: 'Language and notifications',
    },
    appointments: {
      add: 'Add new appointment',
      nextAppointments: 'Next appointments',
      archivedAppointments: 'Past appointments',
      emptyNext: 'There are no upcoming appointments',
      emptyArchived: 'There are no past appointments',
      searchPlaceholder: 'Enter a name to search...',
    },
    appointmentList: {
      seeDetails: 'See more details',
      contentDownloadedTooltip: 'Content can be downloaded in appointment details',
      contentDownloaded: 'You are up to date',
      contentNotDownloaded: 'Download your content',
    },
    appointment: {
      title: 'Appointment',
      addDescriptionPlaceholder: 'Add description',
      lecturer: 'Lecturer',
      lecturerPlaceholder: 'Add appointment lecturers',
      duration: 'Duration',
      content: 'Content',
      addContent: 'Add content',
      participants: 'Participants',
      addParticipants: 'Add participants',
      date: 'Date',
      timeOfAppointment: 'Time of appointment',
      additionalMaterials: 'Additional materials',
      addAdditionalMaterials: 'Add/Edit additional materials',
      additionalMaterialsHelperText: 'You can add pdf or video only',
      noAdditionalMaterials: 'There are no additional materials provided',
      sendNotifications: 'Send notifications about new appointment to participants',
      noParticipants: 'There are no participants assigned to this appointment',
      noKnowledgeBaseSchedule: 'There is no knowledge base content assigned to this appointment',
      progressDownloadingContent: 'Content download progress',
      addChosen: 'Add chosen',
      open: 'Open',
      isOpen: 'The appointment is open',
      isNotOpen: 'The appointment is not open',
      visibility: {
        [AppointmentVisibilityType.Private]: 'Private',
        [AppointmentVisibilityType.Public]: 'Open',
        [AppointmentVisibilityType.Invitational]: 'Invitational',
        info: {
          [AppointmentVisibilityType.Private]: 'The appointment is visible only for author',
          [AppointmentVisibilityType.Invitational]: 'The appointment is visible only to users specified in the list of participants',
          [AppointmentVisibilityType.Public]: 'The meeting is visible to both the users specified in the list of participants and the other members of the company.',
        }
      },
      errors: {
        name: 'Name of the appointment is required',
        duration: 'The finish time of appointment can\'t be before it\'s start',
        dateTime: {
          date: {
            invalid: 'Invalid date of appointment',
          },
          time: {
            invalid: 'Invalid time of appointment',
          },
        },
        participants: {
          selectedTeacher: 'You can\'t assign selected lecturer as a participant',
        },
        lecturers: {
          required: 'Providing a teacher is required',
          selectedParticipant: 'User is already assigned as a participant for this appointment',
        }
      },
      action: {
        success: {
          add: 'Appointment "{{ name }}" has been added',
          edit: 'Appointment "{{ name }}" has been updated',
          createFile: 'File "{{ name }}" has been created',
          createLink: 'Link "{{ name }}" has been created',
          delete: 'Appointment has been deleted',
        },
        error: {
          add: 'An error occurred while trying to add appointment "{{ name }}"',
          edit: 'An error occurred while trying to update appointment "{{ name }}"',
          createFile: 'An error occurred while trying to create the file',
          createLink: 'An error occurred while trying to create the link',
          delete: 'An error occurred while trying to delete the appointment',
        },
      },
      forum: {
        title: 'Forum',
        open: 'Open',
        send: 'Send',
        addNewEntry: 'Add new entry',
        continueThread: 'Continue thread',
        goToThread: 'Go to thread',
        goBackToList: 'Go back to list',
        replies: 'Replies',
        writeMessage: 'Write a message...',
        writeReply: 'Write a reply...',
        noEntries: 'No entries',
        action: {
          success: {
            add: 'Forum entry has been added',
            edit: 'Forum entry has been modified',
          },
          error: {
            add: 'An error occurred while trying to add forum entry',
            edit: 'An error occurred while trying to modify forum entry',
          },
        },
      },
    },
    knowledgeBase: {
      root: 'Knowledge base (general)',
      group: 'Group',
      presentation: 'Presentation',
      content: 'Content',
      duration: 'Approximate duration',
      difficulty: 'Approximate difficulty',
      timeline: 'Timeline',
      scene: 'Scene',
      createNew: 'Create new content',
      newGroup: 'Create new group',
      editGroup: 'Edit group',
      empty: 'There are no knowledge base elements',
      breadcrumbs: {
        root: 'Knowledge Base',
        error: 'Could not load breadcrumbs, please use browser navigation',
      },
      files: 'Files',
      destination: 'Content destination',
      photo: 'Photo',
      canGoBack: 'Possibility to go backward',
      quizDuration: 'Duration',
      showAnswers: 'Show answers after approval',
      randomQuestionOrder: 'Random order of questions',
      markWeight: 'Mark weight',
      randomQuestions: 'Number of questions selected from the pool',
      questionPool: 'Question pool',
      noQuestions: 'No questions have been added to this quiz',
      newQuestion: 'Add new question',
      emptyPhoto: 'No photo',
      translations: 'Translations',
      delete: {
        groupConfirmSubText: 'By deleting this element you will also remove everything inside it.',
        error: 'Error: This element or element inside may be use',
      },
      copy: {
        confirmTitle: 'Confirm copying',
        quizCopySubText: 'This will create a second, identical quiz in this location',
      },
      visibility: {
        [KnowledgeBaseVisibilityType.Author]: 'Author',
        [KnowledgeBaseVisibilityType.Company]: 'Company',
        [KnowledgeBaseVisibilityType.UsersList]: 'Entitled users',
      },
      errors: {
        name: {
          required: 'Name is required',
        },
        description: {
          required: 'Description is required',
          maxLength: 'Description is too long'
        },
        type: {
          required: 'Type is required',
          incorrect: 'Incorrect type',
        },
        files: {
          min: 'At least one file is required',
          size: 'File is too large, maximum file size is 10MB',
        },
        destination: {
          required: 'Destination is required',
        },
        visibility: {
          required: 'Visibility is required',
          incorrect: 'Incorrect visibility type',
        },
        move: {
          location: 'Choose other location'
        },
        duration: {
          integer: 'Duration must be an integer',
          required: 'Duration is required',
          min: 'Duration can\'t be shorter than 1',
          max: 'Duration can\'t be longer than a day'
        },
        markWeight: {
          integer: 'Rating weight must be an integer',
          required: 'Rating weight is required',
          min: 'Rating weight cannot be less than 1',
          max: 'Rating weight cannot be higher than 3'
        },
        quantityOfRandomQuestions: 'The number of questions selected from the pool cannot exceed the total number of questions in the pool ({{ count }})',
      },
      action: {
        success: {
          add: 'Content "$t(group.{{ type }}) - {{ name }}" has been added to knowledge base',
          edit: 'Content "$t(group.{{ type }}) - {{ name }}" has been updated',
          delete: 'Knowledge base content has been deleted',
          move: 'Content has been moved',
        },
        error: {
          add: 'An error occurred while trying to add content to knowledge base',
          edit: 'An error occurred while trying to update content',
          delete: 'An error occurred while trying to delete content',
          addImage: 'An error occurred while trying to add image to knowledge base content',
          move: 'An error occurred while trying to move content',
        },
      },
    },
    quiz: {
      question: 'Content of question',
      stepId: 'Step Id',
      weight: 'Question weight',
      addAnswer: 'Add answer',
      labelAnswers: 'Answers',
      addQuestion: 'Add question',
      presentation: 'The presentation to which the question refers',
      selectPresentation: 'Select presentation',
      manageQuestions: 'Manage questions',
      action: {
        success: {
          copy: 'Quiz has been copied',
          add: 'Question has been added',
          edit: 'The question has been updated',
          delete: 'Question has been deleted',
          moveQuestion: 'The order of the questions has been updated',
        },
        error: {
          copy: 'An error occurred while trying to copy the quiz',
          add: 'An error occurred while trying to add question',
          edit: 'An error occurred while trying to update a question',
          delete: 'An error occurred while trying to delete the question',
          moveQuestion: 'An error occurred while trying to change the order of questions',
        },
      },
      errors: {
        type: {
          required: 'Type is required',
          incorrect: 'Incorrect type',
        },
        question: {
          required: 'Question content is required',
        },
        weight: {
          integer: 'Question weight must be an integer',
          required: 'Question weight is required',
          min: 'Question weight cannot be less than 1',
          max: 'Question weight cannot be higher than 5',
          invalid: 'Weight must be a number between 1 and 5',
        },
        answers: {
          minFields: 'You cannot save a quiz without an answer',
          minCorrect: 'You must mark the correct answer before saving',
          content: {
            required: 'Answer content is required',
          }
        },
        stepId: {
          invalid: 'Invalid step id'
        },
      }
    },
    users: {
      addNew: 'Add new user',
      cantEdit: 'You can\'t edit role of your own account',
      noUsers: 'There are no users',
      knowledgeBasePermissions: 'Knowledge base permissions',
      technical: 'Technical user',
      accountExpirationDateNotSet: 'Not set',
      emailWaitingForConfirmation: 'Waiting for new e-mail confirmation: {{ newEmail }}',
      confirmEmailChangeModal: {
        title: 'Confirm change of e-mail address',
        subText: 'Changing e-mail address requires confirmation of new address via e-mail sent to provided address',
      },
      action: {
        success: {
          setActive: 'Status of the user has been updated',
          editRole: 'Role of the user has been updated',
          registerCompanyMember: 'User has been registered to company',
          educationLevel: 'Education level of the user has been changed',
          knowledgeBasePermissions: 'Knowledge base permissions have been changed',
          delete: 'The user has been deleted',
          edit: 'The user has been updated',
        },
        error: {
          setActive: 'An error occurred while trying to update user status',
          editRole: 'An error occurred while trying to update user role',
          registerCompanyMember: 'An error occurred while trying to register the user',
          educationLevel: 'An error occurred while trying to change user education level',
          knowledgeBasePermissions: 'An error occurred while trying to change knowledge base permissions',
          delete: 'An error occurred while trying to delete the user',
          edit: 'An error occurred while trying to update the user'
        },
      },
    },
    results: {
      title: 'Results',
      employeeRanking: 'Employee ranking',
      groupBy: 'Group by',
      grouping: {
        [ResultsGroupingType.BY_APPOINTMENTS]: 'appointments',
        [ResultsGroupingType.BY_QUIZZES]: 'quizzes',
        [ResultsGroupingType.BY_USERS]: 'users',
      },
      content: 'Question Content',
      answer: 'Answer',
      correctAnswer: 'Correct answer',
      accuracy: 'Accuracy',
      receivedAnswers: 'Received answers',
      answeringAccuracy: 'Answering accuracy',
      gradeAverage: 'Average grade',
      timeUsed: 'Time used',
      answerTime: 'Answer time',
      correctAnswers: 'Correct answers',
      wrongAnswers: 'Wrong answers',
      algorithmRating: 'Algorithm rating',
      questionType: 'Question type',
      quizzes: '{{ count }} $t(words:quiz, { "count": {{ count }} })',
      occurrences: '{{ count }} $t(words:occurrence, { "count": {{ count }} })',
      ranking: {
        name: 'Full name',
        title: 'Employee ranking',
        displayFrom: 'Display from',
        quizMark: 'Quiz mark',
        testMark: 'Manual test evaluation',
        noData: 'No data to display in the ranking',
      }
    },
    statistics: {
      title: 'Statistics',
      details: {
        averageResponseTime: 'Average response time',
        standardDeviation: 'Standard deviation',
        averageCorrectness: 'Average correctness',
        averageIncorrectAnswers: 'Average of incorrect answers',
        averageTimeOfTaskSolution: 'Average time needed to solve the task',
        averageTimeInPercentage: 'Time on percentage scale',
        averageAnswersCorrectness: 'Average correctness of answers',
        averageCorrectnessInPercentage: 'Correctness on percentage scale',
      },
    },
    analysis: {
      title: 'Analysis',
      sortBy: 'Sort by',
      trainingId: 'Training id',
      stepId: 'Step id',
      averageCorrectAnswers: 'Average number of correct answers',
      averageUsedTime: 'Average used time',
    },
    courses: {
      createNew: 'Create new course',
      empty: 'There are no courses',
    },
    companies: {
      title: 'Companies',
      name: 'Company name',
      addNew: 'Add new company',
      nip: 'NIP number',
      storageSpaceLimit: 'Storage space limit',
      errors: {
        name: {
          required: 'Company name is required',
        },
        nip: {
          required: 'NIP number is required',
          length: 'NIP number should be 10 digits long',
        },
        storageSpaceLimit: {
          required: 'Storage space limit is required',
        },
      },
      action: {
        success: {
          add: 'Company "{{ name }}" has been added',
          edit: 'Company "{{ name }}" has been updated',
          setActive: 'Company status has been updated',
        },
        error: {
          add: 'An error occurred while trying to add company "{{ name }}"',
          edit: 'An error occurred while trying to update an appointment "{{ name }}"',
          setActive: 'An error occurred while trying to update company status',
        },
      },
    },
    home: {
      title: 'Welcome in Bell VR web application!',
      nextAppointment: 'Check details of your next appointment',
      lastAppointment: 'Check details of your last appointment',
      account: 'Change your account details and settings, such as username or language',
      teacherApp: 'Teacher app',
      desktopApp: 'Desktop app',
      downloadHelper: 'How to download VR app?',
      seeInstruction: 'Instruction',
      arrangeAppointment: 'Arrange the appointment',
      addUser: 'Add new user',
      yourCompany: 'Your company',
      subscriptionType: 'Your plan of subscription',
      yourAppointments: 'Your appointments',
      changeNow: 'Change now',
      firstSteps: {
        text: 'First steps',
        users: {
          title: 'Create user accounts',
          content: 'Enter users into the system. To work properly there should be at least one teacher in the system. Each newly added user will receive two emails: account confirmation and a link to an account password setting form.',
        },
        knowledgeBase: {
          title: 'Add materials to the knowledge base',
          content: 'The received presentation or test files should be inserted into the knowledge base. For each set of files enter a name, description, approximate duration and approximate difficulty. If you don\'t want the added content to be visible to everyone for now, set the visibility to "Author". You can optionally add a photo as well. Added presentations and tests can later be grouped in the "Knowledge Base" tab',
        },
        appointment: {
          title: 'Create first appointment',
          content: 'Create your first appointment. You will need to provide a title, description, meeting time, and a teacher. Add participants to the meeting using the "Participants" section. In the "Content" section, place all the presentations and tests from the knowledge base that will be discussed during the meeting. If you don\'t want to do this at this time, you can do it at any time later when editing the meeting. You can also optionally add supporting materials that users can download or view to be prepared better for the meeting',
        },
      }
    },
    companyStatistics: {
      members: 'Members',
      courses: 'Courses',
      groups: 'Groups',
      teachers: 'Teachers',
      pastAppointments: 'Past appointments',
    },
    language: {
      pl: 'Polski',
      en: 'English',
      de: 'Deutsch',
      nl: 'Nederlands',
      da: 'Dansk',
      cs: 'Čeština',
      zh: '中文',
      ar: 'العربية',
      fi: 'Suomi',
      fr: 'Français',
      hu: 'Magyar',
      it: 'Italiano',
      no: 'Norsk',
      ru: 'Русский язык',
      es: 'Español',
      sv: 'Svenska',
      action: {
        success: {
          change: 'Language changed to $t(language.{{browserCode}})',
        },
        error: {
          change: 'An error occurred while trying to change language',
        },
      },
    },
    notifications: {
      title: 'Notification settings',
      redirectMessage: 'Notification settings',
      labels: {
        email: 'Send notifications also to your e-mail',
      },
      menu: {
        title: 'Notifications',
        loadMore: 'Load more',
        error: 'An error occurred while trying to fetch notifications',
        filter: {
          all: 'All',
          notSeen: 'Unread',
        },
        [NotificationType.APPOINTMENT_DATE_CHANGED]: 'New meeting date for <primary>{{ name }}</primary>: <primary>{{ date }}</primary>',
        [NotificationType.APPOINTMENT_REMOVED]: 'Deleted meeting {{ name }}',
        [NotificationType.APPOINTMENT_SOON]: {
          future: 'Meeting: <primary>{{ name }}</primary> starts in <primary>{{ x }}</primary> $t(words:minutes, { "count": {{ count }} })',
          archived: 'Meeting <primary>{{ name }}</primary> has started on <primary>{{ date }}</primary>',
        },
        [NotificationType.CONTENT_UPDATED_ON_APPOINTMENT]: 'Materials from the <primary>{{ name }}</primary> meeting have been updated',
        [NotificationType.NEW_CONTENT_ON_APPOINTMENT]: 'New materials have been added to <primary>{{ name }}</primary>',
        [NotificationType.NEW_MSG_ON_APPOINTMENT]: 'New message in <primary>{{ name }}</primary>',
        [NotificationType.NEW_QUIZ_ON_APPOINTMENT]: 'You have a new quiz to complete in <primary>{{ name }}</primary>',
        [NotificationType.NEW_REPLY_ON_APPOINTMENT]: 'New reply in <primary>{{ name }}</primary>',
        [NotificationType.NEW_TEST_ON_APPOINTMENT]: 'You have a new test to complete in <primary>{{ name }}</primary>',
        [NotificationType.QUIZ_UPDATED_ON_APPOINTMENT]: 'The Quiz for <primary>{{ name }}</primary> has been updated',
        [NotificationType.TEST_UPDATED_ON_APPOINTMENT]: 'The Test for <primary>{{ name }}</primary> has been updated',
        [NotificationType.USER_ADDED_TO_APPOINTMENT]: 'You have a new meeting <primary>{{ name }}</primary>',
        [NotificationType.USER_REMOVED_FROM_APPOINTMENT]: 'You were removed from meeting {{ name }}',
      },
      action: {
        success: 'Notification settings have been updated',
        error: {
          fetch: 'An error occurred while trying to update notification settings',
          setSeen: 'An error occurred while trying to set notification as seen',
        },
      },
    },
    modals: {
      confirmDeletion: 'Confirm deletion',
      addMaterials: {
        title: 'Add Materials',
        tabs: {
          pdf: 'PDF',
          url: 'Links',
        },
        addPdf: 'Add your own materials',
        addUrl: 'Add URL (e.g. to video on YouTube)',
        emptySelected: 'There are no additional materials to add',
        urlPlaceholder: 'Enter url...',
        noMaterialDatabase: 'There are no materials in the database',
        name: 'Name shown to participants (optional)',
        namePlaceholder: 'Enter name...',
        url: 'Url',
      },
      addKnowledgeBaseFiles: {
        title: 'Add files',
        tabs: {
          database: 'Search in database',
          own: 'Add own files',
        },
        searchPlaceholder: 'Enter a name to search...',
        emptySelected: 'There are no files to add',
        urlPlaceholder: 'Enter url...',
        noMaterialDatabase: 'There are no files in the database',
        delete: {
          confirmTitle: 'Confirm file deletion',
          confirmSubtext: 'Only unused files can be deleted. Deleting a file from the database is irreversible!',
          error: 'Error: This file may be in use',
        },
        upload: 'Uploading',
      },
      addParticipants: {
        title: 'Find participants',
        searchPlaceholder: 'Enter a name to search...',
        noUsers: 'There are no users available to add',
      },
      moveElement: {
        title: 'Move element',
        selectValue: 'Select new location of element...',
        defaultValue: 'Knowledge base (general)'
      },
      content: {
        title: 'Select content',
        select: 'Select all group or individual elements by clicking on the checkboxes at the right',
      },
      presentation: {
        title: 'Select presentation',
      },
    },
    sort: {
      [SORT_NONE]: '-',
      [ALPHABETICAL]: 'Alphabetical',
      [ALPHABETICAL_REVERSE]: 'Alphabetical reverse',
      [NEWEST]: 'Newest',
      [OLDEST]: 'Oldest',
    },
    group: {
      [KnowledgeBaseContentType.ALL]: 'All',
      [KnowledgeBaseContentType.GROUP]: 'Group',
      [KnowledgeBaseContentType.PRESENTATION]: 'Presentation',
      [KnowledgeBaseContentType.TEST]: 'Interactive test',
      [KnowledgeBaseContentType.QUIZ]: 'Quiz',
      [KnowledgeBaseContentType.SANDBOX_TEST]: 'Sandbox test',
    },
    questionType: {
      [QuizQuestionType.SINGLE_CHOICE]: 'Single choice',
      [QuizQuestionType.MULTIPLE_CHOICE]: 'Multiple choice',
    },
    difficulty: {
      1: 'Very easy',
      2: 'Easy',
      3: 'Medium',
      4: 'Hard',
      5: 'Very hard',
    },
    roles: {
      [ADMIN_ROLE]: 'Admin',
      [COMPANY_ROLE]: 'Company account',
      [TEACHER_ROLE]: 'Teacher',
      [PARTICIPANT_ROLE]: 'Participant'
    },
    rankingScopeType: {
      [RankingScopeType.YEAR]: 'Rok',
      [RankingScopeType.MONTH]: 'Miesiąc',
      [RankingScopeType.WEEK]: 'Tydzień'
    },
    analysisScopeType: {
      [AnalysisScopeType.CORRECTNESS]: 'Correct answers',
      [AnalysisScopeType.TIME]: 'Time used',
    },
    educationLevels: {
      [EducationLevel.HIGHER]: 'Higher',
      [EducationLevel.PRIMARY]: 'Primary',
      [EducationLevel.SECONDARY]: 'Secondary',
      [EducationLevel.LOWER_SECONDARY]: 'Lower secondary',
      [EducationLevel.VOCATIONAL]: 'Vocational',
      [EducationLevel.UNKNOWN]: 'Unknown',
    },
    apiErrors: {
      [EMAIL_AWAIT_CONFIRMATION]: 'Account has not been activated',
      EmailAvailable: 'E-mail is already taken',
    },
    timeZones: {
      pagoPago: '(GMT-11:00) Pago Pago',
      aleutian: '(GMT-10:00) Aleutian Islands',
      alaska: '(GMT-09:00) Alaska Time',
      pacific: '(GMT-08:00) Pacific Time',
      mountain: '(GMT-07:00) Mountain Time',
      central: '(GMT-06:00) Central Time',
      eastern: '(GMT-05:00) Eastern Time',
      halifax: '(GMT-04:00) Atlantic Time - Halifax',
      buenosAires: '(GMT-03:00) Buenos Aires',
      saoPaulo: '(GMT-02:00) Sao Paulo',
      capeVerde: '(GMT-01:00) Cape Verde',
      london: '(GMT+00:00) London',
      belgrade: '(GMT+01:00) Belgrade',
      jerusalem: '(GMT+02:00) Jerusalem',
      minsk: '(GMT+03:00) Minsk',
      dubai: '(GMT+04:00) Dubai',
      kabul: '(GMT+04:30) Kabul',
      uzbekistan: '(GMT+05:00) Uzbekistan',
      india: '(GMT+05:30) India Standard Time',
      kathmandu: '(GMT+05:45) Kathmandu',
      dhaka: '(GMT+06:00) Dhaka',
      myanmar: '(GMT+06:30) Myanmar Standard Time',
      bangkok: '(GMT+07:00) Bangkok',
      hongkong: '(GMT+08:00) Hong Kong',
      eucla: '(GMT+08:45) Western Australia (Eucla)',
      tokyo: '(GMT+09:00) Tokyo',
      darwin: '(GMT+09:30) Central Time - Darwin',
      brisbane: '(GMT+10:00) Eastern Time - Brisbane',
      adelaide: '(GMT+10:30) Central Time - Adelaide',
      vladivostok: '(GMT+11:00) Vladivostok',
      newZealand: '(GMT+12:00) New Zealand Time',
      tonga: '(GMT+13:00) Tonga Time',
      kiritimati: '(GMT+14:00) Kiritimati',
    },
  },
}
