/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next'
import { FormikTouched, FormikErrors, FormikHandlers } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'

import { Checkbox } from 'components/inputs/Checkbox/Checkbox'
import { DifficultySlider } from 'components/data/DifficultySlider/DifficultySlider'
import { MIN_MARK_WEIGHT, MAX_MARK_WEIGHT, MIN_CONTENT_DURATION } from 'constants/knowledgeBaseContent'
import { KnowledgeBaseContentFormFields, QuizSubForm } from '../types'
import * as Styled from '../KnowledgeBaseContentForm.styles'

interface QuizFormFieldsProps {
  isEditing?: boolean
  onChange: FormikHandlers['handleChange']
  onBlur: FormikHandlers['handleBlur']
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  values?: Partial<KnowledgeBaseContentFormFields>
  touched: Partial<FormikTouched<KnowledgeBaseContentFormFields>>
  errors: Partial<FormikErrors<KnowledgeBaseContentFormFields>>
  quizValues: QuizSubForm
  quizTouched?: FormikTouched<QuizSubForm>
  quizErrors?: FormikErrors<QuizSubForm>
}

const QuizFormFields = ({
  isEditing = false,
  onChange: handleChange,
  onBlur: handleBlur,
  setFieldValue,
  values,
  touched,
  errors,
  quizValues,
  quizTouched,
  quizErrors,
}: QuizFormFieldsProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const handleDifficultyChange = (
    _event: any,
    newValue: number | number[]
  ) => {
    setFieldValue('subForm.difficulty', newValue)
  }

  const handleButtonClick = () => {
    history.push(`${location.pathname}/questions`)
  }

  return (
    <>
      <Styled.InfoSection
        label={t('knowledgeBase.difficulty')}
        editValueComponent={
          <Styled.DifficultySliderContainer>
            <DifficultySlider
              name='subForm.difficulty'
              value={quizValues.difficulty}
              onChange={handleDifficultyChange as any}
              track={false}
            />
          </Styled.DifficultySliderContainer>
        }
      />
      <Styled.InfoSection
        label=''
        editValueComponent={
          <Checkbox
            id='subForm.quizDuration'
            name='subForm.quizDuration'
            label={t('knowledgeBase.duration')}
            labelFontSize='18px'
            labelFontWeight='bold'
            checked={quizValues.quizDuration}
            value={quizValues.quizDuration}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={quizTouched?.quizDuration}
            errorMessage={t(quizErrors?.quizDuration || '')}
          />
        }
      >
        <Styled.TextField
          id='duration'
          name='duration'
          type='number'
          disabled={!quizValues.quizDuration}
          value={values?.duration}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.duration}
          errorMessage={t(errors.duration || '')}
          InputProps={{
            inputProps: {
              min: MIN_CONTENT_DURATION,
            },
            disableUnderline: true,
            endAdornment: (
              <Styled.InputAdornment position='start'>
                {t('common.minutes')}
              </Styled.InputAdornment>
            )
          }}
        />
      </Styled.InfoSection>
      <Styled.InfoSection
        label={t('knowledgeBase.markWeight')}
        editValueComponent={
          <Styled.TextFieldShort
            id='subForm.markWeight'
            name='subForm.markWeight'
            type='number'
            inputProps={{
              min: MIN_MARK_WEIGHT,
              max: MAX_MARK_WEIGHT,
            }}
            value={quizValues.markWeight}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={quizTouched?.markWeight}
            errorMessage={t(quizErrors?.markWeight || '')}
          />
        }
      />
      <div>
        <Styled.InfoSection
          label=''
          editValueComponent={
            <Checkbox
              id='subForm.canGoBack'
              name='subForm.canGoBack'
              label={t('knowledgeBase.canGoBack')}
              labelFontSize='18px'
              labelFontWeight='bold'
              checked={quizValues.canGoBack}
              value={quizValues.canGoBack}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={quizTouched?.canGoBack}
              errorMessage={t(quizErrors?.canGoBack || '')}
            />
          }
        />
        <Styled.InfoSection
          label=''
          editValueComponent={
            <Checkbox
              id='subForm.showAnswers'
              name='subForm.showAnswers'
              label={t('knowledgeBase.showAnswers')}
              labelFontSize='18px'
              labelFontWeight='bold'
              checked={quizValues.showAnswers}
              value={quizValues.showAnswers}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={quizTouched?.showAnswers}
              errorMessage={t(quizErrors?.showAnswers || '')}
            />
          }
        />
        <Styled.InfoSection
          label=''
          editValueComponent={
            <Checkbox
              id='subForm.randomQuestionOrder'
              name='subForm.randomQuestionOrder'
              label={t('knowledgeBase.randomQuestionOrder')}
              labelFontSize='18px'
              labelFontWeight='bold'
              checked={quizValues.randomQuestionOrder}
              value={quizValues.randomQuestionOrder}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={quizTouched?.randomQuestionOrder}
              errorMessage={t(quizErrors?.randomQuestionOrder || '')}
            />
          }
        />
      </div>
      {isEditing && (
        <>
          <Styled.InfoSection
            label={t('knowledgeBase.randomQuestions')}
            editValueComponent={
              <Styled.TextFieldShort
                id='subForm.quantityOfRandomQuestions'
                name='subForm.quantityOfRandomQuestions'
                type='number'
                value={quizValues.quantityOfRandomQuestions}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={quizTouched?.quantityOfRandomQuestions}
                errorMessage={
                  t(
                    quizErrors?.quantityOfRandomQuestions || '',
                    { count: quizValues.questions?.length },
                  )
                }
              />
            }
          />
          <Styled.InfoSection
            label={t('knowledgeBase.questionPool')}
            editValueComponent={
              <Styled.QuestionsListButton onClick={handleButtonClick}>
                {t('quiz.manageQuestions')}
              </Styled.QuestionsListButton>
            }
          />
        </>
      )}
    </>
  )
}

export {
  QuizFormFields,
}
