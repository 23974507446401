import { useHistory, useLocation } from 'react-router-dom'
import { DraggableProvided } from 'react-beautiful-dnd'

import { Question } from 'api/types'
import { QuizQuestionType } from 'types/QuestionType'
import * as Styled from './QuestionListItem.styles'

interface QuestionListItemProps {
  onDelete: (itemToDelete: number) => void
  data: Question
  provided: DraggableProvided
  index: number
  isDisplayOnly?: boolean
}

const QuestionListItem = ({
  onDelete,
  data,
  provided,
  index,
  isDisplayOnly = false,
}: QuestionListItemProps) => {
  const { id, question, questionType } = data
  const history = useHistory()
  const location = useLocation()

  const handleRedirectToQuiz = (questionId: number) => (
    history.push(`${location.pathname}/${questionId}`)
  )

  return (
    <Styled.QuestionItemContainer
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {!isDisplayOnly && <Styled.DragIndicatorIcon />}
      {questionType === QuizQuestionType.MULTIPLE_CHOICE ? (
        <Styled.CheckBoxIcon />
      ) : (
        <Styled.RadioButtonIcon />
      )}
      <Styled.QuestionContainer>
        {`${index + 1}. ${question}`}
      </Styled.QuestionContainer>
      {!isDisplayOnly && (
        <Styled.ActionsContainer>
          <Styled.RedirectButton onClick={() => handleRedirectToQuiz(id)} />
          <Styled.DeleteButton onClick={() => onDelete(id)} />
        </Styled.ActionsContainer>
      )}
    </Styled.QuestionItemContainer>
  )
}

export { QuestionListItem }
