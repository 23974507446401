import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'

const FormSectionWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(1, 0)};
  * {
    line-height: normal;
  }
`

const Subtitle = styled(Typography)`
  margin-bottom: 6px;
`

const HintText = styled(Typography)`
  font-weight: bold;
  margin: ${({ theme }) => theme.spacing(0.5, 0)};
`

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
`

export {
  FormSectionWrapper,
  Subtitle,
  HintText,
  CenteredContainer,
}
