import styled from '@emotion/styled'
import { Typography, TypographyProps } from '@material-ui/core'

const ParticipantItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ theme }) => theme.spacing(0.5)}px 0;
`

interface TextProps extends TypographyProps {
  $bold?: boolean
}

const ParticipantText = styled((props: TextProps) => (
  <Typography variant='body1' noWrap {...props} />
), { shouldForwardProp: (prop) => prop !== '$bold' })`
  ${({ theme, $bold }) => `
    margin-right: ${theme.spacing(1)}px;
    line-height: 1.5; 
    font-weight: ${$bold ? 'bold' : 'normal'};
  `}
`

export {
  ParticipantItem,
  ParticipantText,
}
