import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { MenuItem } from '@material-ui/core'

const StyledMenuItem = styled(MenuItem)`
  ${({ theme }) => css`
  :hover, :focus {
    background: ${theme.palette.backgroundAccents.darkerBlueish};
  }
  `}
`

export { StyledMenuItem as MenuItem }
