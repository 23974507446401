import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Typography, TypographyProps } from '@material-ui/core'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`

const TextContainer = styled.div`
  h6:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`

interface ItemsListRowTextProps extends TypographyProps {
  $bold?: boolean
}

const Text = styled(({ $bold, ...rest }: ItemsListRowTextProps) => (
  <Typography variant='body1' {...rest} />
))`
  ${({ $bold }) => css`
    display: inline-flex;
    font-weight: ${$bold ? 'bold' : 'normal'};
  `}
`

export {
  Container,
  ContentContainer,
  TextContainer,
  Text,
}
