import React from 'react'
import { useTranslation } from 'react-i18next'
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded'

import * as Styled from './RedirectCard.styles'

interface RedirectCardProps {
  buttonText?: string
  onClick?: () => void
}

const RedirectCard: React.FC<RedirectCardProps> = ({
  children,
  buttonText,
  onClick: handleClick = () => {},
}) => {
  const { t } = useTranslation()

  return (
    <Styled.Card>
      <Styled.CardContent>
        {children}
      </Styled.CardContent>
      <Styled.CardActions>
        <Styled.Button
          type='button'
          variant='outlined'
          onClick={handleClick}
          endIcon={<KeyboardArrowRightRoundedIcon />}
        >
          {buttonText || t('common.go')}
        </Styled.Button>
      </Styled.CardActions>
    </Styled.Card>
  )
}

export { RedirectCard }
