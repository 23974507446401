import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Typography } from '@material-ui/core'

import { StatisticsItem } from 'api/queries/statistics/types'
import { StatisticsQuizListItem } from './StatisticsQuizListItem'
import * as Styled from '../Statistics.styled'

interface StatisticsQuizListProps {
  isLoading?: boolean
  isError?: boolean
  items?: StatisticsItem[]
}

const StatisticsQuizList = ({
  isLoading,
  isError,
  items,
}: StatisticsQuizListProps) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const history = useHistory()

  return (
    <>
      <Styled.HelperContainer>
        {isError && (
          <Typography color='error'>
            {t('common.errors.generic')}
          </Typography>
        )}
      </Styled.HelperContainer>
      <Styled.List>
        {isLoading ? (
          <Styled.HelperContainer>
            <CircularProgress color='primary' />
          </Styled.HelperContainer>
        ) : (items?.length ? (
          items.map((item) => (
            <StatisticsQuizListItem
              key={item.id}
              onRedirect={() => (
                history.push(`${pathname}/${item.id}`, { title: item.name })
              )}
              {...item}
            />
          ))
        ) : (
          <Styled.NoItemsContainer>
            <Typography variant='h5' color='primary'>
              {t('common.noData')}
            </Typography>
          </Styled.NoItemsContainer>
        ))}
      </Styled.List>
    </>
  )
}

export { StatisticsQuizList }
