import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@material-ui/core'

const RootContainer = styled.div`
  ${({ theme }) => css`
    border-radius: 15px;
    background-color: ${theme.palette.backgroundAccents.blueish};
  `}
`

const Accordion = styled(MuiAccordion)`
  box-shadow: none;
  background-color: inherit;
  padding: 10px
`

const AccordionSummary = styled(MuiAccordionSummary)`
  display: flex;
  flex-direction: column;
`

const SummaryContainer = styled.div`
  display: flex;
  align-items: center;
`

const AccordionDetails = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
`

export {
  RootContainer,
  Accordion,
  AccordionSummary,
  SummaryContainer,
  AccordionDetails,
}
