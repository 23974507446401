import { useTranslation } from 'react-i18next'

import { useIsMobile } from 'hooks/useIsMobile'
import { formatSeconds } from 'utils/formatSeconds'
import { AnalysisItem } from 'api/types'
import { ListItemContainer, AnalysisUserDataContainer } from './AnalysisListItem.styles'
import * as Styled from '../AnalysisList.styles'

interface AnalysisListItemProps extends AnalysisItem {}

const AnalysisListItem = ({
  averageCorrect,
  quizName,
  step,
  time
}: AnalysisListItemProps): JSX.Element => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    <ListItemContainer $isMobile={isMobile}>
      <AnalysisUserDataContainer>
        <Styled.TrainingIdContainer>
          {quizName}
        </Styled.TrainingIdContainer>
        {isMobile ? (
          <>
            <Styled.ResultContainer>
              {`${t('analysis.stepId')}: ${step}`}
            </Styled.ResultContainer>
            <Styled.ResultContainer>
              {`${t('analysis.averageCorrectAnswers')}: ${averageCorrect}`}
            </Styled.ResultContainer>
            <Styled.ResultContainer>
              {`${t('analysis.averageUsedTime')}: ${formatSeconds(Math.round(time))} min`}
            </Styled.ResultContainer>
          </>
        ) : (
          <>
            <Styled.StepIdContainer>
              {step}
            </Styled.StepIdContainer>
            <Styled.ResultContainer>
              {averageCorrect}
            </Styled.ResultContainer>
            <Styled.ResultContainer>
              {`${formatSeconds(Math.round(time))} min`}
            </Styled.ResultContainer>
          </>
        )}
      </AnalysisUserDataContainer>
    </ListItemContainer>
  )
}

export { AnalysisListItem }
