import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { SortSelect } from 'components/data/SortSelect/SortSelect'

const ActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 20px;
`

const StyledSortSelect = styled(SortSelect)`
  ${({ theme }) => css`
    flex: 1;
    min-width: 200px;
    margin-right: 20px;

    ${theme.breakpoints.down('sm')} {
      margin-right: 0;
    }
  `}
`

const SearchFilterContainer = styled.div`
  ${({ theme }) => css`
    flex: 3;
    min-width: 200px;
    margin-left: 20px;

    ${theme.breakpoints.down('sm')} {
      margin-left: 0;
    }
  `}
`

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
`

export {
  ActionsContainer,
  StyledSortSelect as SortSelect,
  SearchFilterContainer,
  LoadMoreContainer,
}
