import { KnowledgeBaseEntityResponse } from 'api/queries/knowledgeBase/types'
import { MIN_MARK_WEIGHT } from 'constants/knowledgeBaseContent'
import { KnowledgeBaseContentFormFields } from 'components/forms/KnowledgeBaseContentForm/KnowledgeBaseContentForm'
import { parseContentFields } from 'components/forms/KnowledgeBaseContentForm/utils'
import { EditKnowledgeBaseObjectVariables } from 'api/mutations/knowledgeBase/types'
import { KnowledgeBaseContentType } from 'types/KnowledgeBaseContentType'

const getFormInitValues = (
  data?: KnowledgeBaseEntityResponse
): KnowledgeBaseContentFormFields | undefined => {
  if (data) {
    const { dataType } = data
    if (dataType === KnowledgeBaseContentType.PRESENTATION) {
      return {
        type: KnowledgeBaseContentType.PRESENTATION,
        name: data.name,
        nameTranslations: data.nameTranslations,
        description: data.description,
        descriptionTranslations: data.descriptionTranslations,
        destination: data.parent?.id || null,
        knowledgeBaseFilesIds: data.knowledgeBaseFiles?.map((file) => file.id) || [],
        image: data.image?.miniaturePath || null,
        visibility: data.visibility,
        duration: data.duration ? data.duration / 60 : 0,
        subForm: {
          difficulty: data.difficulty,
          scene: data.scene,
          timeline: data.timeline,
        }
      }
    }
    if (dataType === KnowledgeBaseContentType.TEST) {
      return {
        type: KnowledgeBaseContentType.TEST,
        name: data.name,
        nameTranslations: data.nameTranslations,
        description: data.description,
        descriptionTranslations: data.descriptionTranslations,
        destination: data.parent?.id || null,
        knowledgeBaseFilesIds: data.knowledgeBaseFiles?.map((file) => file.id) || [],
        image: data.image?.miniaturePath || null,
        visibility: data.visibility,
        duration: data.duration ? data.duration / 60 : 0,
        subForm: {
          difficulty: data.difficulty,
          scene: data.scene,
          timeline: data.timeline,
        }
      }
    }
    if (dataType === KnowledgeBaseContentType.SANDBOX_TEST) {
      return {
        type: KnowledgeBaseContentType.SANDBOX_TEST,
        name: data.name,
        nameTranslations: data.nameTranslations,
        description: data.description,
        descriptionTranslations: data.descriptionTranslations,
        destination: data.parent?.id || null,
        knowledgeBaseFilesIds: data.knowledgeBaseFiles?.map((file) => file.id) || [],
        image: data.image?.miniaturePath || null,
        visibility: data.visibility,
        duration: data.duration ? data.duration / 60 : 0,
        subForm: {
          difficulty: data.difficulty,
          scene: data.scene,
          timeline: data.timeline,
        }
      }
    }
    if (dataType === KnowledgeBaseContentType.QUIZ) {
      return {
        type: KnowledgeBaseContentType.QUIZ,
        name: data.name,
        nameTranslations: data.nameTranslations,
        description: data.description,
        descriptionTranslations: data.descriptionTranslations,
        destination: data.parent?.id || null,
        knowledgeBaseFilesIds: data.knowledgeBaseFiles?.map((file) => file.id),
        image: data.image?.miniaturePath || null,
        visibility: data.visibility,
        duration: data.duration && data.duration / 60,
        subForm: {
          canGoBack: data.canGoBack || false,
          markWeight: data.markWeight || MIN_MARK_WEIGHT,
          randomQuestionOrder: data.randomQuestionOrder || false,
          showAnswers: data.showAnswers || false,
          quantityOfRandomQuestions: data.quantityOfRandomQuestions || null,
          questions: data.questions,
          quizDuration: !!data.duration,
        }
      }
    }
  }
  return undefined
}

const parseEditContentFields = (
  variables: KnowledgeBaseContentFormFields,
  languageCode: string
): EditKnowledgeBaseObjectVariables['dto'] => {
  const {
    image,
  } = variables

  return {
    ...parseContentFields(variables, languageCode),
    ...(typeof image !== 'string' && image !== null ? { image } : {}),
  }
}

export { getFormInitValues, parseEditContentFields }
