enum EducationLevel {
  UNKNOWN = 'UNKNOWN',
  PRIMARY = 'PRIMARY',
  LOWER_SECONDARY = 'LOWER_SECONDARY',
  VOCATIONAL = 'VOCATIONAL',
  SECONDARY = 'SECONDARY',
  HIGHER = 'HIGHER',
}

export { EducationLevel }
