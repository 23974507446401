import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import { PreviewAppointmentHeader } from 'components/data/Appointment/PreviewAppointmentHeader/PreviewAppointmentHeader'
import { AppointmentContent } from 'components/data/Appointment/AppointmentContent/AppointmentContent'
import { ForumContainer } from 'components/data/Appointment/ForumContainer/ForumContainer'
import { AppointmentFloatingContent } from 'components/data/Appointment/AppointmentFloatingContent/AppointmentFloatingContent'
import { useGetAppointmentQuery } from 'api/queries/appointments/appointments'
import { mapFromAppointmentResponse } from './utils'
import * as Styled from './details.styles'

interface RouteParams {
  id: string
}

const ParticipantPreviewAppointmentScreen: React.FC = () => {
  const { id } = useParams<RouteParams>()
  const { t } = useTranslation()

  const {
    data: appointmentData,
    isLoading,
    isError,
  } = useGetAppointmentQuery(+id)

  const appointment = useMemo(() => (
    mapFromAppointmentResponse(appointmentData)
  ), [appointmentData])

  return (
    <RouteContainer $fullHeight>
      <PreviewAppointmentHeader />
      <Styled.ContentFooterContainer>
        {isError
          ? <Styled.ErrorMessage>{t('common.errors.generic')}</Styled.ErrorMessage>
          : isLoading
            ? <Styled.CircularProgress />
            : (
              <>
                <AppointmentContent {...appointment} />
                <AppointmentFloatingContent>
                  <ForumContainer appointmentId={+id} />
                </AppointmentFloatingContent>
              </>
            )}
      </Styled.ContentFooterContainer>
    </RouteContainer>
  )
}

export default ParticipantPreviewAppointmentScreen
