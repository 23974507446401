import { useTranslation } from 'react-i18next'
import * as Styled from './ModalActions.styles'

interface ModalActionsProps {
  onCancel: () => void
  onSave: () => void
  isSaveDisabled?: boolean
  isLoading?: boolean
  cancelLabel?: string
  confirmLabel?: string
}

const ModalActions: React.FC<ModalActionsProps> = ({
  onCancel: handleCancel,
  onSave: handleSave,
  isSaveDisabled,
  isLoading,
  cancelLabel,
  confirmLabel,
}) => {
  const { t } = useTranslation()

  return (
    <Styled.ActionsContainer>
      <Styled.CancelButton
        isLoading={isLoading}
        variant='outlined'
        onClick={handleCancel}
      >
        {cancelLabel || t('common.cancel')}
      </Styled.CancelButton>
      <Styled.SaveButton
        isLoading={isLoading}
        disabled={isSaveDisabled}
        onClick={handleSave}
      >
        {confirmLabel || t('common.save')}
      </Styled.SaveButton>
    </Styled.ActionsContainer>
  )
}

export { ModalActions }
