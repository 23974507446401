import { useQuery, UseQueryResult } from 'react-query'
import { AxiosResponse } from 'axios'

import { useFetch } from 'providers/FetchProvider'
import { MaterialsResponse } from './types'

const useGetAllMaterials = ()
: UseQueryResult<AxiosResponse<MaterialsResponse>, unknown> => {
  const { fetch } = useFetch()

  return useQuery(
    'didacticMaterials',
    () => fetch.get('/material/company')
  )
}

const useGetMaterialPublicUrl = (id: number)
: UseQueryResult<AxiosResponse<string>, unknown> => {
  const { fetch } = useFetch()

  return useQuery(['materialPublicUrl', id], () => fetch.get(`/material/${id}/public-url`))
}

export { useGetAllMaterials, useGetMaterialPublicUrl }
