import { useTranslation } from 'react-i18next'
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded'
import { AppointmentList } from 'components/data/AppointmentList/AppointmentList'

import type { AppointmentListItemType } from 'components/data/AppointmentList/AppointmentList'
import { ARCHIVED_APPOINTMENTS, NEXT_APPOINTMENTS } from 'constants/appointmentLists'
import * as Styled from './Appointments.styles'

interface AppointmentsProps {
  nextAppointmentsList: AppointmentListItemType[]
  archivedAppointmentsList: AppointmentListItemType[]
  isLoading?: boolean
}

const Appointments: React.FC<AppointmentsProps> = ({
  nextAppointmentsList,
  archivedAppointmentsList,
  isLoading,
}) => {
  const { t } = useTranslation()

  return (
    <Styled.RootContainer>
      <Styled.UpperContainer>
        <Styled.ListHeader>
          <Styled.ListTitle variant='h3'>
            {t('appointments.nextAppointments')}
          </Styled.ListTitle>
          <Styled.SeeAllLink to={`appointments/detailed/${NEXT_APPOINTMENTS}`}>
            {t('common.seeAll')}
            <KeyboardArrowRightRoundedIcon />
          </Styled.SeeAllLink>
        </Styled.ListHeader>
        <AppointmentList
          appointments={nextAppointmentsList}
          appointmentsBasePath='appointments'
          isLoading={isLoading}
          emptyListSubtext={t('common.emptySubtext')}
        />
      </Styled.UpperContainer>
      <Styled.LowerContainer>
        <Styled.ListHeader>
          <Styled.ListTitle variant='h3'>
            {t('appointments.archivedAppointments')}
          </Styled.ListTitle>
          <Styled.SeeAllLink to={`appointments/detailed/${ARCHIVED_APPOINTMENTS}`}>
            {t('common.seeAll')}
            <KeyboardArrowRightRoundedIcon />
          </Styled.SeeAllLink>
        </Styled.ListHeader>
        <AppointmentList
          appointments={archivedAppointmentsList}
          appointmentsBasePath='appointments'
          isLoading={isLoading}
          isArchived
        />
      </Styled.LowerContainer>
    </Styled.RootContainer>
  )
}

export { Appointments }
