import styled from '@emotion/styled'

const List = styled.div`
  display: flex;
  flex-direction: column;
`

export {
  List,
}
