import { css, SerializedStyles, Theme } from '@emotion/react'
import { lighten, darken } from '@material-ui/core'

const textDeleteButtonStyles = (theme: Theme): SerializedStyles => css`
  color: ${theme.palette.error.main};
`

const containedDeleteButtonStyles = (theme: Theme): SerializedStyles => css`
  background-color: ${theme.palette.error.main};

  &:hover {
    background-color: ${lighten(theme.palette.error.main, 0.2)};
  }
  &:focus {
    background-color: ${darken(theme.palette.error.main, 0.2)};
  }
  &:disabled {
    color: white;
    background-color: ${theme.palette.button.disabled};
  }
`

const outlinedDeleteButtonStyles = (theme: Theme): SerializedStyles => css`
  color: ${theme.palette.error.main};
  border: 3px solid ${theme.palette.error.main};

  &:hover {
    background-image: ${theme.palette.errorGradient.main};
    color: ${theme.palette.secondary.contrastText};
  }
`

export {
  textDeleteButtonStyles,
  containedDeleteButtonStyles,
  outlinedDeleteButtonStyles
}
