import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import PresentationIcon from 'assets/icons/PresentationIcon'
import QuizIcon from 'assets/icons/QuizIcon'
import TestIcon from 'assets/icons/TestIcon'

const MIN_CONTENT_DURATION = 1
const MIN_MARK_WEIGHT = 1
const MAX_MARK_WEIGHT = 3

const GROUP_ICON = FolderOpenIcon
const PRESENTATION_ICON = PresentationIcon
const TEST_ICON = TestIcon
const QUIZ_ICON = QuizIcon

export {
  MIN_CONTENT_DURATION,
  MIN_MARK_WEIGHT,
  MAX_MARK_WEIGHT,
  GROUP_ICON,
  PRESENTATION_ICON,
  TEST_ICON,
  QUIZ_ICON,
}
