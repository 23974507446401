import styled from '@emotion/styled'
import {
  IconButton,
  IconButtonProps,
  Typography,
  TypographyProps,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

const NestedList = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => `
    margin-left: ${theme.spacing(8)}px;

    ${theme.breakpoints.down('sm')} {
      margin: ${theme.spacing(0, 4)};
    }
  `}
`

interface ListItemProps {
  $expanded?: boolean
}

const ListItem = styled.div<ListItemProps>`
  ${({ theme, $expanded }) => `
    display: flex;
    align-items: center;
    margin: ${theme.spacing(1, 0)};
    padding: ${theme.spacing(1)}px;
    border: 3px solid rgba(0, 0, 0, 0.25);
    ${$expanded && `
      border-color: ${theme.palette.primary.main};
    `};
    border-radius: 10px;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: center;
      padding: ${theme.spacing(0.5)}px;
    }
  `}
`

const DataContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: center;
    }
  `}
`

const DataValueContainer = styled((props: TypographyProps) => (
  <Typography {...props} />
))`
  ${({ theme }) => `
    flex: 1;
    margin: 0 ${theme.spacing(1)}px;

    ${theme.breakpoints.down('sm')} {
      margin: ${theme.spacing(1)}px 0;
    }
  `}
`

interface ExpandButtonProps extends IconButtonProps {
  $expanded?: boolean
}

const ExpandButton = styled((props: ExpandButtonProps) => (
  <IconButton {...props}>
    <ExpandMoreIcon />
  </IconButton>
), { shouldForwardProp: (prop) => prop !== '$expanded' })`
  ${({ theme, $expanded }) => `
    margin: ${theme.spacing(0, 2)};
    & .MuiSvgIcon-root {
      transition: transform .2s ease-in-out;
      transform: rotate(${$expanded ? '180' : '0'}deg);
    }
  `}
`

const ForwardButton = styled((props: IconButtonProps) => (
  <IconButton {...props}>
    <ArrowForwardIcon />
  </IconButton>
), { shouldForwardProp: (prop) => prop !== '$expanded' })`
  ${({ theme }) => `
    margin: ${theme.spacing(0, 2)};
  `}
`

const NestedListContainer = styled.div`
  ${({ theme }) => `
    margin-left: ${theme.spacing(2)}px;
  `}
`

export {
  NestedList,
  ListItem,
  DataContainer,
  DataValueContainer,
  ExpandButton,
  ForwardButton,
  NestedListContainer,
}
