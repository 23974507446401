import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Container, ContainerProps } from '@material-ui/core'

interface StyledContainerProps extends ContainerProps {
  $fullHeight?: boolean
}

const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== '$fullHeight'
})<StyledContainerProps>`
  ${({ theme, $fullHeight }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(3)}px;
    height: ${$fullHeight ? '100%' : 'auto'};
  `}
`

export { StyledContainer as Container }
export type { StyledContainerProps as ContainerProps }
