import React, { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { addSeconds } from 'date-fns/esm'
import { formatISO } from 'date-fns'
import { Save } from '@material-ui/icons'

import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import { AppointmentHeader } from 'components/data/Appointment/AppointmentHeader/AppointmentHeader'
import { AppointmentForm, AppointmentFormFields } from 'components/forms/AppointmentForm/AppointmentForm'
import { useGetAppointmentQuery } from 'api/queries/appointments/appointments'
import { useEditAppointmentMutation } from 'api/mutations/appointments/appointments'
import { useAdditionalMaterialsHandler } from 'hooks/useAdditionalMaterialsHandler'
import { useIsMobile } from 'hooks/useIsMobile'
import { AppointmentVisibilityType } from 'types/AppointmentVisibilityType'
import { AUTHOR, COMPANY } from 'constants/visibilityTypes'

import { mapFromAppointmentResponse } from './utils'
import * as Styled from './details.styles'

interface RouteParams {
  id: string
}

const EditAppointmentScreen: React.FC = () => {
  const { id: appointmentId } = useParams<RouteParams>()
  const { t } = useTranslation()
  const history = useHistory()
  const queryClient = useQueryClient()
  const isMobile = useIsMobile()

  const {
    data: appointmentData,
    isLoading: isLoadingGet,
    isError: isErrorGet,
  } = useGetAppointmentQuery(+appointmentId)

  const { mutate, isLoading } = useEditAppointmentMutation({
    onSuccess: (data) => {
      const { id } = data
      queryClient.setQueryData(['appointment', { id }], data)
      history.push(`/appointments/${id}`)
    }
  })

  const { handleAdditionalMaterials, isNewMaterialsLoading } = useAdditionalMaterialsHandler()

  const initialValues: AppointmentFormFields | undefined = useMemo(() => {
    const appointment = mapFromAppointmentResponse(appointmentData)
    if (appointment) {
      const {
        startDate,
        duration,
        description,
      } = appointment
      const startDateTime = new Date(startDate)
      const endTime = new Date(addSeconds(startDateTime, duration)).toString()

      return {
        ...appointment,
        dateTime: {
          date: startDateTime.toString(),
          startTime: startDateTime.toString(),
          endTime,
        },
        description: description || '',
      }
    }
    return undefined
  }, [appointmentData])

  const handleSubmit = async ({
    dateTime: {
      date,
      startTime,
      endTime,
    },
    teacher,
    didacticMaterials,
    participants,
    knowledgeBaseScheduleRecords,
    visibility: appointmentVisibility,
    ...fields
  }: AppointmentFormFields) => {
    const startDate = new Date(date)
    const startTimeDate = new Date(startTime)
    const endTimeDate = new Date(endTime)
    startDate.setHours(startTimeDate.getHours(), startTimeDate.getMinutes(), 0, 0)
    const duration = (endTimeDate.getTime() - startTimeDate.getTime()) / 1000
    const parsedMaterials = await handleAdditionalMaterials(didacticMaterials)

    const visibility = appointmentVisibility === AppointmentVisibilityType.Private
      ? AUTHOR
      : COMPANY

    if (teacher) {
      mutate({
        id: appointmentId,
        editAppointment: {
          ...fields,
          startDate: formatISO(startDate),
          duration,
          teacherId: teacher.id,
          didacticMaterialIds: parsedMaterials.map((material) => material.id),
          participantsIds: participants.map((participant) => participant.id),
          languageCode: 'pl',
          knowledgeBaseScheduleRecords: knowledgeBaseScheduleRecords.map((recordId, index) => ({
            knowledgeBaseEntityId: recordId,
            position: index,
          })),
          visibility
        }
      })
    }
  }

  const renderActionButtons = () => (
    <Styled.ActionButton
      form='appointmentForm'
      startIcon={<Save />}
      $isMobile={isMobile}
    >
      {t('common.save')}
    </Styled.ActionButton>
  )

  return (
    <RouteContainer $fullHeight>
      <AppointmentHeader
        actionButtons={renderActionButtons()}
      />
      <Styled.ContentFooterContainer>
        {isErrorGet ? (
          <Styled.ErrorMessage>
            {t('common.errors.generic')}
          </Styled.ErrorMessage>
        ) : (
          isLoadingGet ? (
            <Styled.CircularProgress />
          ) : (
            <AppointmentForm
              onSubmit={handleSubmit}
              initialValues={initialValues}
              isLoading={isLoading || isNewMaterialsLoading}
              authorId={appointmentData?.author?.id}
            />
          )
        )}
      </Styled.ContentFooterContainer>
    </RouteContainer>
  )
}

export default EditAppointmentScreen
