import * as Styled from './ParticipantSection.styles'

interface ParticipantItemProps {
  firstName?: string
  lastName?: string
  email?: string
}

const ParticipantItem: React.FC<ParticipantItemProps> = ({
  firstName,
  lastName,
  email,
}) => (
  <Styled.ParticipantItem>
    <Styled.ParticipantText $bold>
      {`${firstName} ${lastName}`}
    </Styled.ParticipantText>
    <Styled.ParticipantText>
      {`[${email}]`}
    </Styled.ParticipantText>
  </Styled.ParticipantItem>
)

export { ParticipantItem }
