import {
  HomeRounded,
  Folder,
  AccountCircle,
  People,
  DateRange,
  FileCopy,
  Dvr,
  PieChart,
} from '@material-ui/icons'
import AddressBookIcon from 'assets/icons/AddressBookIcon'
import { NavigationTab } from 'types/NavigationTab'
import { PARTICIPANT_ROLE, TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE } from 'constants/roleGroups'

const NAVIGATION_TABS: Readonly<NavigationTab[]> = Object.freeze([
  {
    to: '/',
    name: 'home',
    translationKey: 'drawer.navigation.home',
    icon: <HomeRounded />,
    accessRoles: [PARTICIPANT_ROLE, TEACHER_ROLE, COMPANY_ROLE],
  },
  {
    to: '/knowledge-base',
    name: 'knowledge base',
    partial: 'knowledge-base',
    translationKey: 'drawer.navigation.knowledgeBase',
    icon: <Folder />,
    accessRoles: [PARTICIPANT_ROLE, TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE],
  },
  {
    to: '/users',
    name: 'users',
    translationKey: 'drawer.navigation.users',
    icon: <AddressBookIcon />,
    accessRoles: [COMPANY_ROLE, ADMIN_ROLE],
  },
  {
    to: '/appointments',
    name: 'appointments',
    partial: 'appointments',
    translationKey: 'drawer.navigation.appointments',
    icon: <DateRange />,
    accessRoles: [PARTICIPANT_ROLE, TEACHER_ROLE, COMPANY_ROLE],
  },
  {
    to: '/results',
    name: 'results',
    partial: 'results',
    translationKey: 'drawer.navigation.results',
    icon: <FileCopy />,
    accessRoles: [COMPANY_ROLE, ADMIN_ROLE],
  },
  {
    to: '/statistics',
    name: 'statistics',
    partial: 'statistics',
    translationKey: 'drawer.navigation.statistics',
    icon: <Dvr />,
    accessRoles: [COMPANY_ROLE, ADMIN_ROLE],
  },
  {
    to: '/analysis',
    name: 'analysis',
    partial: 'analysis',
    translationKey: 'drawer.navigation.analysis',
    icon: <PieChart />,
    accessRoles: [COMPANY_ROLE, ADMIN_ROLE],
  },
  {
    to: '/companies',
    name: 'companies',
    translationKey: 'drawer.navigation.companies',
    icon: <People />,
    accessRoles: [ADMIN_ROLE],
    divider: true,
  },
  {
    to: '/account',
    name: 'account',
    translationKey: 'drawer.navigation.account',
    icon: <AccountCircle />,
  },
])

export { NAVIGATION_TABS }
