import { useTranslation } from 'react-i18next'
import { Autocomplete, AutocompleteProps } from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { Addressable } from 'api/queries/companyAddressables/types'
import { TextFieldProps } from 'components/inputs/TextField/TextField'
import * as Styled from './AddressableSelect.styles'

interface AddressableSelectProps<T>
  extends Omit<AutocompleteProps<T, false, false, false>, 'renderInput' | 'options'> {
  className?: string,
  addressablesData: Addressable[]
  textFieldProps: TextFieldProps
}

const AddressableSelect = ({
  className,
  addressablesData,
  id,
  value,
  onChange: handleChange,
  onBlur: handleBlur,
  textFieldProps: {
    name,
    errorMessage,
    touched,
    ...restOfTextFieldProps
  },
  ...rest
}: AddressableSelectProps<Addressable>) => {
  const { t } = useTranslation()

  return (
    <Autocomplete
      {...rest}
      id={id}
      popupIcon={<ExpandMoreIcon />}
      value={value || undefined}
      getOptionSelected={(option, val) => option.id === val?.id}
      options={addressablesData}
      noOptionsText={t('common.noOptions')}
      getOptionLabel={({ address }) => `${address}`}
      onChange={handleChange}
      onBlur={handleBlur}
      loadingText={`${t('common.loading')}...`}
      renderInput={(params) => (
        <Styled.AutocompleteTextField
          {...params}
          placeholder='-'
          touched={touched}
          errorMessage={errorMessage}
          InputProps={{
            ...params.InputProps,
            className: '',
            disableUnderline: true,
          }}
          {...restOfTextFieldProps}
        />
      )}
    />
  )
}

export { AddressableSelect }
