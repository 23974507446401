import styled from '@emotion/styled'
import {
  IconButton,
  IconButtonProps,
  Typography,
  TypographyProps,
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import Send from '@material-ui/icons/Send'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { TextField } from 'components/inputs/TextField/TextField'
import { Button, ButtonProps } from 'components/inputs/Button/Button'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ForumHeader = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(1, 2)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    & .MuiSvgIcon-root {
      color: white;
    }
    background-color: ${theme.palette.primary.main};
  `}
`

const ForumTitle = styled((props: TypographyProps) => (
  <Typography variant='subtitle1' {...props} />
))``

const CloseButton = styled((props: IconButtonProps) => (
  <IconButton {...props}>
    <Close />
  </IconButton>
))``

const EntriesContainer = styled.div`
  ${({ theme }) => `
    flex: 1;
    padding: ${theme.spacing(0, 2, 2)};
    display: flex;
    flex-direction: column;
    overflow: auto;
  `}
`

const SelectedThreadContainer = styled.div`
  flex: 1;
`

const SelectedThreadRepliesContainer = styled.div`
  ${({ theme }) => `
    margin-left: ${theme.spacing(4)}px;
    padding: ${theme.spacing(0, 1)};
    background-color: ${theme.palette.backgroundAccents.blueish};
  `}
`

const BackButton = styled(({
  children,
  ...props
}: ButtonProps) => (
  <Button variant='text' {...props}>
    <ArrowBackIcon />
    {children}
  </Button>
))`
  ${({ theme }) => `
    font-size: ${theme.typography.subtitle1.fontSize};
    font-weight: ${theme.typography.subtitle1.fontWeight};
    padding: ${theme.spacing(1, 1, 1, 0)};
    color: white;
    & .MuiSvgIcon-root {
      margin-right: ${theme.spacing(1)}px;
    }
  `}
`

const CenteredContentContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ForumFooter = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(1, 1)};
    background-color: ${theme.palette.backgroundAccents.darkerBlueish};
  `}
`

const ForumFooterTitleContainer = styled.div`
  min-height: 40px;
  display: flex;
  justify-content: space-between;
`

const ForumFooterFormContainer = styled.div`
  display: flex;
`

const ForumEntryInput = styled(TextField)`
  margin: 0;
`

const PostEntryButton = styled((props: ButtonProps) => (
  <Button {...props} />
))`
  ${({ theme }) => `
    width: auto;
    margin-left: ${theme.spacing(1)}px;
  `}
`

const SendIcon = styled(Send)`
  ${({ theme }) => `
    margin-right: ${theme.spacing(1.5)}px;
  `}
`

export {
  Container,
  ForumHeader,
  ForumTitle,
  CloseButton,
  EntriesContainer,
  SelectedThreadContainer,
  SelectedThreadRepliesContainer,
  BackButton,
  CenteredContentContainer,
  ForumFooter,
  ForumFooterTitleContainer,
  ForumFooterFormContainer,
  ForumEntryInput,
  PostEntryButton,
  SendIcon,
}
