import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { UsersTable } from 'components/data/UsersTable/UsersTable'
import { useGetUsersFromCompanyId } from 'api/queries/users/users'
import { UserListItemType } from 'components/data/UserList/UserListItem/UserListItem'
import { useRegisterCompanyIdMemberMutation } from 'api/mutations/users/users'
import { useCompanyId } from 'providers/CompanyProvider'

const AdminUsersScreen: React.FC = () => {
  const { i18n } = useTranslation()
  const languageCode = useMemo(() => i18n.languages[0], [i18n])
  const companyId = useCompanyId() || -1

  const {
    data,
    isLoading,
    isError
  } = useGetUsersFromCompanyId(companyId)

  const users = useMemo(() => (data || []), [data])

  const { mutateAsync } = useRegisterCompanyIdMemberMutation()

  const handleRegister = async (user: UserListItemType) => {
    await mutateAsync({
      user: {
        languageCode,
        timeZone: user.timeZone ? user.timeZone : 'UTC',
        ...user
      },
      id: companyId
    })
  }

  return (
    <UsersTable
      usersData={users}
      isDataError={isError}
      isDataLoading={isLoading}
      userQueryKey='companyUsers'
      onRegister={handleRegister}
    />
  )
}

export { AdminUsersScreen }
