import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import SignInForm, { FormFields } from 'components/forms/SignInForm/SignInForm'
import { AuthLayout } from 'components/layout/AuthLayout/AuthLayout'
import { useSignInMutation } from 'api/mutations/authentication/authentication'
import { SignInErrorResponse, SignInVariables } from 'api/mutations/authentication/types'
import { POLICY_NOT_ACCEPTED } from 'constants/errorCodes'
import { useApiErrorHandler } from 'providers/ApiErrorHandlerProvider'

const SignInScreen: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { errorMessage, handleApiError } = useApiErrorHandler()

  const redirectToPolicyAccept = (
    error: SignInErrorResponse,
    variables: SignInVariables
  ) => {
    const privacyPolicyLocation = {
      pathname: '/privacy-policy/',
      state: {
        password: variables.password,
        username: variables.emailOrUsername,
        policyContent: error.response?.data.policyReturnDTO.content,
        policyId: error.response?.data.policyReturnDTO.id
      }
    }
    history.push(privacyPolicyLocation)
  }

  const { mutate, isLoading, isError } = useSignInMutation({
    onError: (error, variables) => {
      switch (error.response?.data.messageCode) {
        case POLICY_NOT_ACCEPTED:
          redirectToPolicyAccept(error, variables)
          break
        default:
          handleApiError(error, t('signIn.errors.wrongCredentials'))
          break
      }
    }
  })

  return (
    <AuthLayout>
      <SignInForm
        onSubmit={(values: FormFields) => mutate(values)}
        isLoading={isLoading}
        isError={isError}
        errorMessage={errorMessage}
      />
    </AuthLayout>
  )
}

export default SignInScreen
