import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { InputAdornment as MuiInputAdornment, Typography } from '@material-ui/core'

import { Button, ButtonProps } from 'components/inputs/Button/Button'
import { TextField } from 'components/inputs/TextField/TextField'
import { GroupSelect } from 'components/data/GroupSelect/GroupSelect'
import { KnowledgeBaseVisibilitySelect } from 'components/data/VisibilityTypeSelect/KnowledgeBaseVisibilitySelect/KnowledgeBaseVisibilitySelect'
import { UploadImageField } from 'components/inputs/UploadImageField/UploadImageField'
import { MultipleValuesField } from 'components/inputs/MultipleValuesField/MultipleValuesField'
import { ContentSection } from 'components/data/ContentSection/ContentSection'
import { LanguagesSelect } from 'components/data/LanguagesSelect/LanguagesSelect'

const selectStyles = css`
  min-width: 200px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 800px;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const InfoSection = styled(ContentSection)`
  flex: 1;
  margin-top: ${({ theme }) => theme.spacing(2.5)}px;
  min-width: 200px;
`

const StyledGroupSelect = styled(GroupSelect)`
  ${selectStyles}
`

const StyledKnowledgeBaseVisibilitySelect = styled(KnowledgeBaseVisibilitySelect)`
  ${selectStyles}
`

const DestinationValue = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`

const StyledTextField = styled(TextField)`
  width: 100%;
`

const StyledTextFieldShort = styled(TextField)`
  max-width: 200px;
`

const StyledUploadImageField = styled(UploadImageField)`
  width: 200px;
`

const StyledMultipleValuesField = styled(MultipleValuesField)`
  max-height: 240px;
`

const RowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-column-gap: 20px;
`

interface SaveButtonProps extends ButtonProps {
  $isMobile?: boolean
}

const SaveButton = styled((props: SaveButtonProps) => (
  <Button {...props} />
), { shouldForwardProp: (prop) => prop !== '$isMobile' })`
  ${({ theme, $isMobile }) => css`
    width: auto;
    z-index: 999;
    ${$isMobile ? css`
      margin-left: ${theme.spacing(2.5)}px;
    ` : css`
      position: absolute;
      bottom: 50px;
      right: 50px;
    `}
  `}
`

const DifficultySliderContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(5)}px;
`

const InputAdornment = styled(MuiInputAdornment)`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`

const QuestionsListButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing(1)}px;
  max-width: 250px;
`

const TranslationLanguagesSelect = styled(LanguagesSelect)`
  ${selectStyles}
`

export {
  Form,
  InfoSection,
  SaveButton,
  StyledGroupSelect as GroupSelect,
  StyledTextField as TextField,
  RowContainer,
  StyledKnowledgeBaseVisibilitySelect as KnowledgeBaseVisibilitySelect,
  DestinationValue,
  HeaderContainer,
  StyledUploadImageField as UploadImageField,
  StyledMultipleValuesField as MultipleValuesField,
  DifficultySliderContainer,
  StyledTextFieldShort as TextFieldShort,
  InputAdornment,
  QuestionsListButton,
  TranslationLanguagesSelect
}
