import styled from '@emotion/styled'

import { Button } from 'components/inputs/Button/Button'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledButton = styled(Button)`
  width: auto;
`

export { StyledButton as Button, ButtonContainer }
