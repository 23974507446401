import React from 'react'

import {
  useGetOwnNextAppointmentsQuery,
  useGetOwnArchivedAppointmentsQuery
} from 'api/queries/appointments/appointments'
import { TEACHER_ROLE, PARTICIPANT_ROLE } from 'constants/roleGroups'
import { useHasRole } from 'hooks/useHasRoles'
import { AppointmentsBase } from './AppointmentsBase'

const UserAppointmentsScreen: React.FC = () => {
  const isAllowed = useHasRole([PARTICIPANT_ROLE, TEACHER_ROLE])

  const {
    data: nextAppointmentsData,
    isLoading: isLoadingNext
  } = useGetOwnNextAppointmentsQuery({
    forPrincipal: isAllowed
  })

  const {
    data: archivedAppointmentsData,
    isLoading: isLoadingArchived
  } = useGetOwnArchivedAppointmentsQuery({
    forPrincipal: isAllowed
  })

  const nextAppointmentsList = nextAppointmentsData?.pages[0].content.slice(0, 3) || []
  const archivedAppointmentsList = archivedAppointmentsData?.pages[0].content.slice(0, 3) || []
  const isLoading = isLoadingNext || isLoadingArchived

  return (
    <AppointmentsBase
      nextAppointmentsList={nextAppointmentsList}
      archivedAppointmentsList={archivedAppointmentsList}
      isLoading={isLoading}
    />
  )
}

export { UserAppointmentsScreen }
