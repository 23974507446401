import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'

import { Checkbox } from 'components/inputs/Checkbox/Checkbox'
import * as Styled from './EmailNotificationsForm.styles'
import * as FormsStyled from '../forms.styles'

interface EmailNotificationsFormProps {
  initialValue?: boolean
  onChange?: (value: boolean) => void
  isLoading?: boolean
  isError?: boolean
}

const EmailNotificationsForm = ({
  initialValue = false,
  onChange: handleChange = () => {},
  isLoading = false,
  isError = false,
}: EmailNotificationsFormProps): JSX.Element => {
  const { t } = useTranslation()
  const [emailNotifications, setEmailNotifications] = useState<boolean>(initialValue)

  const handleEmailNotificationsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailNotifications(event.target.checked)
    handleChange?.(event.target.checked)
  }

  useEffect(() => {
    setEmailNotifications(initialValue)
  }, [initialValue])

  return (
    <FormsStyled.Form>
      <Styled.FormSectionWrapper>
        {isError && (
          <FormsStyled.ErrorMessage>
            {t('common.errors.generic')}
          </FormsStyled.ErrorMessage>
        )}
        {isLoading ? (
          <Styled.CenteredContainer>
            <CircularProgress />
          </Styled.CenteredContainer>
        ) : (
          <Checkbox
            id='aboutNewContentOnEmail'
            name='aboutNewContentOnEmail'
            label={t('notifications.labels.email')}
            checked={emailNotifications}
            value={emailNotifications}
            onChange={handleEmailNotificationsChange}
          />
        )}
      </Styled.FormSectionWrapper>
    </FormsStyled.Form>
  )
}

export { EmailNotificationsForm }
