import styled from '@emotion/styled'
import { Button } from 'components/inputs/Button/Button'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const AddQuestionButton = styled(Button)`
  max-width: 300px;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      align-self: center;
    }
  `}
`

export {
  Container,
  AddQuestionButton,
}
