import React from 'react'
import { Hidden } from '@material-ui/core'
import { useDrawerState, useDrawerToggle } from 'providers/DrawerStateProvider'
import { NavigationDrawer } from './NavigationDrawer'

const NavigationDrawerContainer: React.FC = () => {
  const open = useDrawerState()
  const handleDrawerToggle = useDrawerToggle()

  return (
    <>
      <Hidden smUp>
        <NavigationDrawer
          variant='temporary'
          anchor='left'
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
        />
      </Hidden>
      <Hidden xsDown>
        <NavigationDrawer variant='permanent' anchor='left' open />
      </Hidden>
    </>
  )
}

export { NavigationDrawerContainer }
