import React from 'react'
import { useTranslation } from 'react-i18next'

import HomeTitleAccentIcon from 'assets/icons/HomeTitleAccentIcon'
import * as Styled from './WelcomeTitle.styles'

interface WelcomeTitleProps {
  title?: string
}

const WelcomeTitle = ({ title }: WelcomeTitleProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Styled.TitleContainer>
      <Styled.Title variant='h2' color='primary'>
        {title || t('home.title')}
        <HomeTitleAccentIcon />
      </Styled.Title>
    </Styled.TitleContainer>
  )
}

export { WelcomeTitle }
