import { ReactNode } from 'react'

import * as Styled from './AppointmentFloatingContent.styled'

interface AppointmentFloatingContentProps {
  children?: ReactNode
}

const AppointmentFloatingContent = ({ children }: AppointmentFloatingContentProps) => (
  <Styled.FloatingRightContainer>{children}</Styled.FloatingRightContainer>
)

export { AppointmentFloatingContent }
