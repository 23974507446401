import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { useAcceptPrivacyPolicy } from 'api/mutations/privacyPolicy/privacyPolicy'
import { useSignInMutation } from 'api/mutations/authentication/authentication'
import { AcceptPolicyFields } from 'types/AcceptPolicyType'
import { AuthLayout } from 'components/layout/AuthLayout/AuthLayout'
import * as Styled from './AcceptPrivacyPolicyScreen.styles'
import './AcceptPrivacyPolicyScreen.css'

const AcceptPrivacyPolicyScreen: React.FC = () => {
  const location = useLocation<AcceptPolicyFields>()
  const history = useHistory()
  const { t } = useTranslation()

  const { mutate: signInMutate, isLoading, isError: signInError } = useSignInMutation({
    onSuccess: () => {
      history.push('/')
    },
  })

  const { mutate, isError } = useAcceptPrivacyPolicy({
    onSuccess: () => {
      signInMutate({
        emailOrUsername: location.state.username,
        password: location.state.password
      })
    }
  })

  const handleClick = () => {
    mutate({
      password: location.state.password,
      username: location.state.username,
      id: location.state.policyId
    })
  }

  return (
    <AuthLayout>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <Markdown rehypePlugins={[rehypeRaw as any]} className='markdown'>
        {location.state.policyContent}
      </Markdown>
      <Styled.ButtonContainer>
        <Styled.Button onClick={() => history.push('/')}>
          {t('common.decline')}
        </Styled.Button>
        <Styled.Button
          onClick={handleClick}
          disabled={isLoading}
          isLoading={isLoading}
        >
          {t('common.agree')}
        </Styled.Button>
        {(isError || signInError) && (
          <Styled.ErrorText variant='subtitle1'>
            {t('common.errors.generic')}
          </Styled.ErrorText>
        )}
      </Styled.ButtonContainer>
    </AuthLayout>
  )
}

export default AcceptPrivacyPolicyScreen
