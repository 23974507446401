import styled from '@emotion/styled'
import {
  ListItemText,
  MenuItem as MuiMenuItem,
} from '@material-ui/core'

const MenuItem = styled(MuiMenuItem)`
  &.MuiMenuItem-root {
    white-space: normal;
  }
`

const NotificationText = styled(ListItemText)`
  flex: 1;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`

const PrimarySpan = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
`

interface SeenIndicatorProps {
  seen: boolean
}

const SeenIndicator = styled.div<SeenIndicatorProps>`
  ${({ seen, theme }) => `
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${seen ? theme.palette.disabled.main : theme.palette.primary.main};
  `};
`

export {
  MenuItem,
  NotificationText,
  PrimarySpan,
  SeenIndicator,
}
