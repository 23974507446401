import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Collapse, Divider, Typography } from '@material-ui/core'
import uniqueId from 'lodash/uniqueId'

import { Modal, ModalProps } from 'components/layout/Modal/Modal'
import { DidacticMaterial } from 'types/DidacticMaterial'
import * as Styled from './AddMaterialsModal.styles'
import { PdfFile, PdfTab } from './tabs/PdfTab'
import { Url, UrlTab } from './tabs/UrlTab'

interface AddMaterialsModalProps extends Omit<ModalProps, 'children' | 'onClose'> {
  onClose: () => void
  onSave: (materials: DidacticMaterial[]) => void
  didacticMaterials?: DidacticMaterial[]
  isLoading?: boolean
}

const AddMaterialsModal = ({
  onClose: handleClose = () => null,
  onSave: handleSave,
  didacticMaterials = [],
  isLoading,
  ...props
}: AddMaterialsModalProps): JSX.Element => {
  const { t } = useTranslation()

  const [modalTabValue, setModalTabValue] = useState<number>(0)
  const [materials, setMaterials] = useState<DidacticMaterial[]>(didacticMaterials)

  const handleTabChange = (_: unknown, newValue: number) => {
    setModalTabValue(newValue)
  }

  const handleDeleteButton = useCallback((materialId: number) => () => {
    const filteredMaterials = materials.filter((m) => m.id !== materialId)
    setMaterials(filteredMaterials)
  }, [materials])

  const handleFileAdd = (file: PdfFile) => {
    const newMaterial = {
      id: -uniqueId(),
      name: file.name,
      content: file.file,
      type: 'FILE' as const,
      isNew: true,
    }
    setMaterials((prevMaterials) => ([...prevMaterials, newMaterial]))
  }

  const handleUrlAdd = (url: Url) => {
    const newMaterial = {
      id: -uniqueId(),
      name: url.name,
      content: url.link,
      type: 'LINK' as const,
      isNew: true,
    }
    setMaterials((prevMaterials) => ([...prevMaterials, newMaterial]))
  }

  const handleModalSave = useCallback(() => {
    handleSave(materials)
  }, [handleSave, materials])

  const handleModalCancel = useCallback(() => {
    handleClose()
    setMaterials(didacticMaterials)
  }, [didacticMaterials, handleClose])

  useEffect(() => {
    setMaterials(didacticMaterials)
  }, [didacticMaterials])

  return (
    <Modal onClose={handleModalCancel} {...props}>
      <Modal.Title>
        {t('modals.addMaterials.title')}
      </Modal.Title>
      <Modal.Content>
        <Modal.Tabs
          variant='fullWidth'
          value={modalTabValue}
          onChange={handleTabChange}
        >
          <Modal.Tab label={t('modals.addMaterials.tabs.pdf')} />
          <Modal.Tab label={t('modals.addMaterials.tabs.url')} />
        </Modal.Tabs>
      </Modal.Content>
      <Styled.ModalContent>
        <div>
          <Collapse in={modalTabValue === 0}>
            <Modal.TabPanel value={modalTabValue} index={0}>
              <PdfTab onFileAdd={(file) => handleFileAdd(file)} />
            </Modal.TabPanel>
          </Collapse>
          <Collapse in={modalTabValue === 1}>
            <Modal.TabPanel value={modalTabValue} index={1}>
              <UrlTab onUrlAdd={(url) => handleUrlAdd(url)} />
            </Modal.TabPanel>
          </Collapse>
        </div>
        <div>
          <Divider />
          <Styled.DidacticMaterialsContainer>
            {materials?.length ? (
              materials.map((material) => (
                <Styled.Chip
                  key={material.id}
                  label={material.name}
                  onDelete={handleDeleteButton(material.id)}
                />
              ))
            ) : (
              <Typography>
                {t('appointment.noAdditionalMaterials')}
              </Typography>
            )}
          </Styled.DidacticMaterialsContainer>
        </div>
      </Styled.ModalContent>
      <Modal.Actions
        isLoading={isLoading}
        onCancel={handleModalCancel}
        onSave={handleModalSave}
        confirmLabel={t('appointment.addChosen')}
      />
    </Modal>
  )
}

export { AddMaterialsModal }
