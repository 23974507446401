import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import { useIsMobile } from 'hooks/useIsMobile'
import * as Styled from './AppointmentHeader.styles'

interface AppointmentHeaderProps {
  actionButtons?: JSX.Element | null
}

const AppointmentHeader: React.FC<AppointmentHeaderProps> = ({
  actionButtons
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    isMobile ? (
      <Styled.AppointmentMobileHeaderContainer>
        <Styled.MobileHeaderRow>
          <Typography variant='h2'>
            {t('appointment.title')}
          </Typography>
          <div>
            {actionButtons}
          </div>
        </Styled.MobileHeaderRow>
      </Styled.AppointmentMobileHeaderContainer>
    ) : null
  )
}

export { AppointmentHeader }
