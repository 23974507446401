import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { NOTIFICATIONS_QUERY_KEY } from 'api/queriesKeys'
import { useFetch } from 'providers/FetchProvider'

const useSetSeenNotificationsMutation = (
  options?: UseMutationOptions<AxiosResponse, Error, number[]>
): UseMutationResult<AxiosResponse, Error, number[]> => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  return useMutation(
    (ids) => fetch.patch(`/notification?ids=${ids.join(',')}`),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEY)
      },
      onError: () => {
        enqueueSnackbar(t('notifications.action.error.setSeen'), { variant: 'error' })
      },
    },
  )
}

export { useSetSeenNotificationsMutation }
