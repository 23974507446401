import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  Typography,
  TypographyProps,
  IconButton,
  IconButtonProps
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Alert, { AlertProps } from '@material-ui/lab/Alert'

import { Button } from 'components/inputs/Button/Button'
import { AppointmentVisibilitySelect } from 'components/data/VisibilityTypeSelect/AppointmentVisibilitySelect/AppointmentVisibilitySelect'

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  > * {
    padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`

interface RowProps {
  $justifyContent?: 'flex-start' | 'flex-end' | 'center' |
  'space-between' | 'space-around' | 'space-evenly'
  $smallScreenMaxWidth?: string
}

const Row = styled.div<RowProps>`
  ${({ $justifyContent, theme, $smallScreenMaxWidth }) => css`
    display: flex;
    justify-content: ${$justifyContent || 'flex-start'};
    ${theme.breakpoints.down('sm')} {
      max-width: ${$smallScreenMaxWidth || 'none'};
    }
    :last-child {
      padding-bottom: ${theme.spacing(3)}px;
    }
  `}
`

const ColumnsRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: 0 -${theme.spacing(1)}px;
    > * + * {
      padding: 0 ${theme.spacing(1)}px;
    }
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      padding: -${theme.spacing(1)}px 0;
      > * + * {
        padding: ${theme.spacing(1)}px 0;
      }
    }
  `}
`

const LeftLabel = styled((props: TypographyProps) => (
  <Typography variant='subtitle1' {...props} />
))`
  margin-right: ${({ theme }) => theme.spacing(3)}px;
  margin-top: ${({ theme }) => theme.spacing(1.5)}px;
`

const TopLabel = styled((props: TypographyProps) => (
  <Typography variant='subtitle1' {...props} />
))`
  margin-bottom: 6px;
`

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

interface ItemsContainerProps {
  $touched?: boolean
}

const ItemsContainer = styled.div<ItemsContainerProps>`
  ${({ theme, $touched }) => css`
    background-color: ${$touched ? 'white' : theme.palette.backgroundAccents.blueish};
    padding: 0 ${theme.spacing(2)}px;
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(2)}px 0;
    }
  `}
`

const SectionTitle = styled((props: TypographyProps) => (
  <Typography variant='subtitle1' {...props} />
))``

const ItemsHeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

const ItemsListContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(2)}px 0 12px;
`

const ItemsListRow = styled.div`
  margin: 2px;
`

const RemoveButton = styled((props: IconButtonProps) => (
  <IconButton size='small' {...props}><CloseIcon fontSize='inherit' /></IconButton>
))`
  ${({ theme }) => css`
    color: ${theme.palette.text.primary};
  `}
`

const AddButton = styled(Button)`
  width: auto;
`

const SaveButton = styled(Button)`
  position: absolute;
  right: 50px;
  bottom: 50px;
  width: auto;
`

const StyledVisibilityTypeSelect = styled(AppointmentVisibilitySelect)`
  ${({ theme, touched }) => css`
    margin-top: 0;

    .MuiInputBase-root {
      flex-wrap: wrap;
      background-color: ${touched ? 'white' : theme.palette.backgroundAccents.blueish};
    }
  `}
`

const VisibilityAlert = styled((props: AlertProps) => (
  <Alert severity='info' {...props} />
))`
  ${({ theme }) => `
    background: ${theme.palette.backgroundAccents.darkerBlueish};

    & .MuiAlert-icon {
      color: ${theme.palette.primary.main}
    }
  `}
`

export {
  Form,
  LeftLabel,
  TopLabel,
  Row,
  ColumnsRow,
  Column,
  ItemsContainer,
  SectionTitle,
  ItemsHeaderContainer,
  ItemsListContainer,
  ItemsListRow,
  RemoveButton,
  AddButton,
  SaveButton,
  StyledVisibilityTypeSelect as VisibilityTypeSelect,
  VisibilityAlert
}
