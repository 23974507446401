import styled from '@emotion/styled'
import { IconButton, IconButtonProps } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckIcon from '@material-ui/icons/Check'

import { TextField, TextFieldProps } from 'components/inputs/TextField/TextField'
import { Button, ButtonProps } from 'components/inputs/Button/Button'

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(1, 0)};
`

const TitleContainer = styled.div`
  min-height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Row = styled.div`
  display: flex;
`

const CenteredContentContainer = styled.div`
  display: flex;
  justify-content: center;
`

const EditConfirmButton = styled((props: IconButtonProps) => (
  <IconButton color='primary' {...props}>
    <CheckIcon />
  </IconButton>
))`
  ${({ theme }) => `
    & .MuiSvgIcon-root {
      font-size: ${theme.typography.subtitle1.fontSize}px;
    }
  `}
`

interface EditButtonProps extends IconButtonProps {
  $isActive?: boolean
}

const EditButton = styled(({ $isActive, ...props }: EditButtonProps) => (
  <IconButton {...props}>
    {$isActive ? (
      <CancelIcon />
    ) : (
      <EditIcon />
    )}
  </IconButton>
))`
  & .MuiSvgIcon-root {
    font-size: ${({ theme }) => theme.typography.subtitle1.fontSize}px;
  }
`

const AdditionalInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const RepliesContainer = styled.div`
  ${({ theme }) => `
    margin-left: ${theme.spacing(4)}px;
    padding: ${theme.spacing(0, 1)};
    background-color: ${theme.palette.backgroundAccents.blueish};
    display: flex;
    flex-direction: column;
  `}
`

const ViewThreadButton = styled((props: ButtonProps) => (
  <Button variant='text' {...props} />
))`
  ${({ theme }) => `
    margin: ${theme.spacing(0.5, 0)};
  `}
  padding: 0;
`

const MoreRepliesIndicator = styled.div`
  ${({ theme }) => `
    width: 8px;
    height: 8px;
    margin: ${theme.spacing(1, 0.5)};
    border-radius: 50%;
    background-color: ${theme.palette.primary.main};
  `}
`

const PostReplyInput = styled((props: TextFieldProps) => (
  <TextField {...props} />
))`
  ${({ theme }) => `
    margin: ${theme.spacing(0.5, 0)};
    & .MuiInput-input {
      padding: ${theme.spacing(1)}px;
    }
  `}
`

const PostReplyButton = styled(Button)`
  ${({ theme }) => `
    width: auto;
    height: 35px;
    padding: 0;
    margin: auto 0 auto ${theme.spacing(1)}px;

    & .MuiSvgIcon-root {
      height: 24px;
      width: 24px;
    }

    & .MuiCircularProgress-root {
      color: white;
    }
  `}
`

export {
  Container,
  TitleContainer,
  Row,
  EditButton,
  CenteredContentContainer,
  EditConfirmButton,
  AdditionalInfoContainer,
  RepliesContainer,
  MoreRepliesIndicator,
  ViewThreadButton,
  PostReplyInput,
  PostReplyButton,
}
