import { ReactNode } from 'react'

import * as Styled from './PageHeader.styles'

interface PageHeaderProps {
  title?: string
  actions?: ReactNode
}

const PageHeader = ({
  title,
  actions,
}: PageHeaderProps) => (
  <Styled.Header>
    {title && (
      <Styled.HeaderTitle>
        {title}
      </Styled.HeaderTitle>
    )}
    <Styled.ActionsContainer>
      {actions}
    </Styled.ActionsContainer>
  </Styled.Header>
)

export { PageHeader }
