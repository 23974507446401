import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

import { ReactComponent as TestSVG } from 'assets/images/test-icon.svg'

const TestIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <TestSVG />
  </SvgIcon>
)

export default TestIcon
