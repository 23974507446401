import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  Badge,
  BadgeProps,
  Chip,
  Paper,
  PaperProps,
  Typography
} from '@material-ui/core'

interface ExpandableItemProps {
  // any type because Keyframes type is not exported from emotion/styled-components
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $animation?: any
  $expandedWidth?: number
}

const animationProperties = css`
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
`

interface StyledPaperProps extends PaperProps, ExpandableItemProps {}

const StyledPaper = styled(
  (props: StyledPaperProps) => <Paper {...props} />
)`${({ theme, $animation, $expandedWidth }) => css`
    max-width:  ${$expandedWidth || 650}px;
    transform-origin: left center;
    animation-name: ${$animation};
    ${animationProperties}

    &.MuiPaper-root {
      box-shadow: 0 0 25px ${theme.palette.backgroundAccents.shadow};
      border-radius: 15px;
    }

    ${theme.breakpoints.down('sm')} {
      max-width: ${$expandedWidth ? $expandedWidth / 2 : 330}px;
    }

    .ReactCollapse--collapse {
      transition: height 500ms;
    }

    .ReactCollapse--content {
      max-height: 200px;
      overflow-y: auto;
      transition: height 500ms;
    }
  `}
`

const Content = styled('div')<ExpandableItemProps>`
  ${({ theme, $animation }) => css`
    display: flex;
    height: 100%;
    overflow-y: auto ;
    transform-origin: left center;
    animation-name: ${$animation};
    ${animationProperties}

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      overflow: hidden;
    }
  `}
`

const CalendarContainer = styled('div')`
  min-width: 330px;
  min-height: 330px;
  max-height: 500px; 
  width: 100%;
  padding: 10px;
  overflow: hidden;
`

const AppointmentsListContainer = styled('div')<ExpandableItemProps>`
  ${({ theme, $animation }) => css`
    width: 100%;
    height: 100%;
    padding: ${theme.spacing(1)}px;
    padding-top: ${theme.spacing(3)}px;
    animation-name: ${$animation};
    ${animationProperties}

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(1, 4)};
    }
  `}
`

const CalendarAppointmentsList = styled('div')`
  display: flex;
  max-height: 280px;
  flex-direction: column;
  overflow-y: auto;
`

const AppointmentTitle = styled(Typography)`
  font-weight: bold;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const CalendarAppointmentsListItem = styled('div')`
  padding: 5px 0;
  display: flex;
  align-items: center;
`

const AppointmentDetailsContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`

const OpenChip = styled(Chip)`
  ${({ theme }) => css`
    color: #fff;
    background-image: ${theme.palette.gradient.main};
    pointer-events: none;

    ${theme.breakpoints.down('sm')} {
      align-self: flex-start;
    }
  `}
`

interface AppointmentsBadgeProps extends BadgeProps {
  $isCurrent?: boolean
}

const AppointmentsBadge = styled((props: AppointmentsBadgeProps) => (
  <Badge overlap='circular' color='primary' {...props} />
))`
  ${({ theme, $isCurrent }) => css`
    & .MuiBadge-badge {
      color: #fff;
    }
    
    ${$isCurrent ? css`
      & .MuiButtonBase-root {
        background-color: #fff;
        border: 1px solid ${theme.palette.primary.main};
      }
      & .MuiTypography-root {
        color: ${theme.palette.primary.main};
      }
    ` : null};
  `}
`

export {
  StyledPaper as Paper,
  Content,
  CalendarContainer,
  AppointmentsListContainer,
  CalendarAppointmentsList,
  CalendarAppointmentsListItem,
  AppointmentTitle,
  AppointmentDetailsContainer,
  OpenChip,
  AppointmentsBadge
}
