import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { useFetch } from 'providers/FetchProvider'
import { ChangeLanguageResponse, ChangeLanguageVariables } from './types'

const useChangeLanguageMutation = (
  options?: UseMutationOptions<ChangeLanguageResponse, Error, ChangeLanguageVariables>
): UseMutationResult<ChangeLanguageResponse, Error, ChangeLanguageVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ browserCode }) => fetch.patch(`/user/edit-language/${browserCode}`)
      .then((response) => response.data),
    {
      ...options,
      onSuccess: (data, ...rest) => {
        onSuccess?.(data, ...rest)
        const { defaultLanguage: { browserCode } } = data
        enqueueSnackbar(
          t('language.action.success.change', { browserCode }),
          { variant: 'success' }
        )
      },
      onError: () => {
        enqueueSnackbar(t('language.action.error.change'), { variant: 'error' })
      },
    },
  )
}

export { useChangeLanguageMutation }
