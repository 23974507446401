import React, { useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import { useUserRole } from 'providers/UserContextProvider'
import { PARTICIPANT_ROLE, TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE } from 'constants/roleGroups'
import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import ParticipantTeacherHomeScreen from 'screens/home/Home/ParticipantTeacherHomeScreen/ParticipantTeacherHomeScreen'
import CompanyHomeScreen from 'screens/home/Home/CompanyHomeScreen/CompanyHomeScreen'

const HomeScreen: React.FC = () => {
  const userRole = useUserRole()
  const screenToRender = useMemo(() => {
    switch (userRole) {
      case COMPANY_ROLE:
        return <CompanyHomeScreen />
      case TEACHER_ROLE:
      case PARTICIPANT_ROLE:
        return <ParticipantTeacherHomeScreen />
      case ADMIN_ROLE:
        return <Redirect to='/knowledge-base' />
      default:
        return <Redirect to='/sign-in' />
    }
  }, [userRole])

  return <RouteContainer>{screenToRender}</RouteContainer>
}

export default HomeScreen
