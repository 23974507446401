import styled from '@emotion/styled'
import { IconButton, Typography } from '@material-ui/core'

const Item = styled.div`
display: flex;
flex: 50%;
align-items: center;
`

const ItemName = styled(Typography)`
max-width: 650px;
margin: 5px 0;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
`

const DeleteButton = styled(IconButton)`
margin-left: ${({ theme }) => theme.spacing(2)}px;
`

export {
  Item,
  ItemName,
  DeleteButton,
}
