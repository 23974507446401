import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

import { RankingItem } from 'api/types'
import { useFetch } from 'providers/FetchProvider'
import { GET_RANKING_QUERY_KEY } from 'api/queriesKeys'

type UseGetRankingQueryKeyType = Array<string | { date: string | undefined }>

const NUMBER_OF_BEST_RESULTS = 10

const useGetRankingQuery = (
  date?: string,
  options?: UseQueryOptions<RankingItem[], Error, RankingItem[], UseGetRankingQueryKeyType>
): UseQueryResult<RankingItem[], Error> => {
  const { fetch } = useFetch()

  return useQuery(
    [GET_RANKING_QUERY_KEY, { date }],
    async () => {
      const { data } = await fetch.get('/results', {
        params: {
          count: NUMBER_OF_BEST_RESULTS,
          date
        },
      })
      return data
    },
    {
      enabled: !!date,
      ...options,
    }
  )
}

export {
  useGetRankingQuery,
}
