import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Typography } from '@material-ui/core'

import { Modal } from 'components/layout/Modal/Modal'

const StyledModal = styled(Modal)`
  ${({ theme }) => css`
    & .MuiPaper-root {
      min-width: 500px;
      max-height: 700px;

      ${theme.breakpoints.down('sm')} {
        min-width: 100px;
      }
    }
  `}
`

const ErrorText = styled(Typography)`
  ${({ theme }) => css`
    margin: 10px 0;
    color: ${theme.palette.error.main}
  `}
`

export {
  StyledModal as Modal,
  ErrorText
}
