import styled from '@emotion/styled'
import { Link as RouterLink } from 'react-router-dom'

const ContentContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    width: calc(100% + ${theme.spacing(2)}px);
    margin-left: -${theme.spacing(4)}px;

    & > * {
      margin-left: ${theme.spacing(4)}px;
    }
  
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `}
`

const ColumnContainer = styled.div`
  flex: 1;
  max-width: 600px;
  display: flex;
  flex-direction: column;
`

const FormWrapper = styled.div`
  max-width: 600px;
`

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  vertical-align: middle;
`

const RedirectLink = styled(RouterLink)`
  display: flex;
  flex-wrap: wrap;
  margin: 10px auto;
  line-height: 1;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.main};
`

export {
  ContentContainer,
  ColumnContainer,
  FormWrapper,
  LinkContainer,
  RedirectLink,
}
