import { useEffect, useRef, useState } from 'react'

const useIntersectionObserver = (
  element: HTMLDivElement | null,
  options?: IntersectionObserverInit
): boolean => {
  const [isIntersecting, setIsIntersecting] = useState(false)
  const observer = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting)
    }, options)
    const { current: currentObserver } = observer
    if (element) currentObserver.observe(element)

    return () => currentObserver.disconnect()
  }, [element, options])

  return isIntersecting
}

export { useIntersectionObserver }
