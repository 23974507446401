import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import { AppointmentList } from 'components/data/AppointmentList/AppointmentList'
import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import { SearchFilterInput } from 'components/inputs/SearchFilterInput/SearchFilterInput'
import { ARCHIVED_APPOINTMENTS, NEXT_APPOINTMENTS } from 'constants/appointmentLists'
import { PARTICIPANT_ROLE, TEACHER_ROLE } from 'constants/roleGroups'
import { NEWEST, OLDEST } from 'constants/sortTypes'
import { useAppointmentsDetailedData } from 'hooks/useAppointmentsDetailedData'
import { SortType } from 'types/SortType'
import { AppointmentListType } from 'types/AppointmentListType'
import { useHasRole } from 'hooks/useHasRoles'
import { useCompanyId } from 'providers/CompanyProvider'
import * as Styled from './AppointmentDetailedListScreen.styles'

interface ParamTypes {
  type: AppointmentListType
}

const AppointmentDetailedListScreen: React.FC = () => {
  const { t } = useTranslation()
  const { type } = useParams<ParamTypes>()
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [sortType, setSortType] = useState<SortType>(type === NEXT_APPOINTMENTS ? OLDEST : NEWEST)
  const isAllowed = useHasRole([PARTICIPANT_ROLE, TEACHER_ROLE])
  const companyId = useCompanyId() || 0
  const [loaderElement, setLoaderElement] = useState<HTMLDivElement | null>(null)

  const loader = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setLoaderElement(node)
    }
  }, [])

  const isIntersecting = useIntersectionObserver(loaderElement)

  const handleSortSelectChange = (event: React.ChangeEvent<{
    name?: string | undefined
    value: unknown
  }>) => {
    setSortType(event.target.value as SortType)
  }

  const {
    data: appointmentsDetailedData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useAppointmentsDetailedData(
    companyId,
    type,
    sortType,
    searchFilter,
    isAllowed,
  )

  const appointmentsListToShow = useMemo(() => {
    if (appointmentsDetailedData?.pages) {
      const { pages } = appointmentsDetailedData
      return pages
        .map((page) => page.content)
        .reduce((prev, cur) => [...prev, ...cur])
        .filter((item, pos, arr) => arr.findIndex((i) => i.id === item.id) === pos)
    }
    return []
  }, [appointmentsDetailedData])

  useEffect(() => {
    if (isIntersecting && hasNextPage) fetchNextPage()
  }, [isIntersecting, hasNextPage, fetchNextPage, appointmentsDetailedData])

  return (
    <RouteContainer>
      <Typography variant='h2'>
        {type === NEXT_APPOINTMENTS
          ? t('appointments.nextAppointments')
          : t('appointments.archivedAppointments')}
      </Typography>
      <Styled.ActionsContainer>
        <Styled.SortSelect
          withoutNone
          value={sortType}
          onChange={handleSortSelectChange}
        />
        <Styled.SearchFilterContainer>
          <SearchFilterInput
            placeholder={t('common.startWrite')}
            searchFilterValue={searchFilter}
            onChangeSearchFilter={setSearchFilter}
          />
        </Styled.SearchFilterContainer>
      </Styled.ActionsContainer>
      <AppointmentList
        appointments={appointmentsListToShow}
        appointmentsBasePath='appointments'
        isLoading={isLoading}
        isArchived={type === ARCHIVED_APPOINTMENTS}
      />
      <Styled.LoadMoreContainer ref={loader}>
        {isFetchingNextPage && <Typography>{t('common.loading')}...</Typography>}
      </Styled.LoadMoreContainer>
    </RouteContainer>
  )
}

export { AppointmentDetailedListScreen }
