import React, { useCallback, useState, MouseEvent } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { useSendResetPasswordEmailMutation } from 'api/mutations/users/users'
import SendResetPasswordEmailForm, { FormFields } from 'components/forms/SendResetPasswordEmailForm/SendResetPasswordEmailForm'
import { AuthLayout } from 'components/layout/AuthLayout/AuthLayout'
import * as Styled from './auth.styles'

const SendResetEmailScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const [emailAddress, setEmailAddress] = useState('')

  const { mutate, isLoading, isSuccess, isError } = useSendResetPasswordEmailMutation({
    onSuccess: ({ data }) => setEmailAddress(data),
  })

  const handleRedirect = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      history.push('/sign-in')
    },
    [history]
  )

  return (
    <AuthLayout>
      <Styled.SmallBlueCircle />
      <SendResetPasswordEmailForm
        onSubmit={(values: FormFields) => mutate(values.emailOrUsername)}
        onRedirect={handleRedirect}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        emailAddress={emailAddress}
      />
    </AuthLayout>
  )
}

export default withRouter(SendResetEmailScreen)
