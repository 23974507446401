import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseQueryResult,
  UseInfiniteQueryResult,
} from 'react-query'
import { useFetch } from 'providers/FetchProvider'
import {
  AppointmentsQueryOptions,
  AppointmentsResponse,
  AppointmentResponse,
} from './types'

const useGetHomeAppointmentsQuery = (
  queryOptions?: AppointmentsQueryOptions,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<AppointmentsResponse, Error>, 'queryKey'>
)
: UseInfiniteQueryResult<AppointmentsResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    ['home-appointments', { queryOptions }],
    async () => {
      const { data } = await fetch.get('/appointment', {
        params: {
          archivedOptions: 'FUTURE',
          sortOption: 'OLDEST',
          forPrincipal: true,
          ...queryOptions,
        }
      })
      return data
    },
    useQueryOptions
  )
}

const useGetOwnNextAppointmentsQuery = (
  queryOptions?: AppointmentsQueryOptions,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<AppointmentsResponse, Error>, 'queryKey'>
)
: UseInfiniteQueryResult<AppointmentsResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    ['next-own-appointments', { queryOptions }],
    async ({ pageParam = 0 }) => {
      const { data } = await fetch.get('/appointment', {
        params: {
          sortOption: 'OLDEST',
          forPrincipal: true,
          ...queryOptions,
          archivedOptions: 'FUTURE',
          page: pageParam,
        }
      })
      return data
    },
    useQueryOptions
  )
}

const useGetOwnArchivedAppointmentsQuery = (
  queryOptions?: AppointmentsQueryOptions,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<AppointmentsResponse, Error>, 'queryKey'>
)
: UseInfiniteQueryResult<AppointmentsResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    ['archived-own-appointments', { queryOptions }],
    async ({ pageParam = 0 }) => {
      const { data } = await fetch.get('/appointment', {
        params: {
          sortOption: 'NEWEST',
          forPrincipal: true,
          ...queryOptions,
          archivedOptions: 'ARCHIVED',
          page: pageParam,
        }
      })
      return data
    },
    useQueryOptions
  )
}

const useGetCompanyNextAppointmentsQuery = (
  queryOptions?: AppointmentsQueryOptions,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<AppointmentsResponse, Error>, 'queryKey'>
)
: UseInfiniteQueryResult<AppointmentsResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    ['next-company-appointments', { queryOptions }],
    async ({ pageParam = 0 }) => {
      const { data } = await fetch.get(`/appointment/company/${queryOptions?.companyId}`, {
        params: {
          sortOption: 'OLDEST',
          forPrincipal: true,
          ...queryOptions,
          archivedOptions: 'FUTURE',
          page: pageParam,
        }
      })
      return data
    },
    useQueryOptions
  )
}

const useGetCompanyArchivedAppointmentsQuery = (
  queryOptions?: AppointmentsQueryOptions,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<AppointmentsResponse, Error>, 'queryKey'>
)
: UseInfiniteQueryResult<AppointmentsResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    ['archived-company-appointments', { queryOptions }],
    async ({ pageParam = 0 }) => {
      const { data } = await fetch.get(`/appointment/company/${queryOptions?.companyId}`, {
        params: {
          sortOption: 'NEWEST',
          forPrincipal: true,
          ...queryOptions,
          archivedOptions: 'ARCHIVED',
          page: pageParam,
        }
      })
      return data
    },
    useQueryOptions
  )
}

const useGetAppointmentQuery = (
  id: number,
  options?: Omit<UseQueryOptions<AppointmentResponse, Error>, 'queryKey'>
): UseQueryResult<AppointmentResponse, Error> => {
  const { fetch } = useFetch()

  return useQuery(
    ['appointment', { id }],
    () => fetch.get(`/appointment/${id}`).then((response) => response.data),
    options,
  )
}

export {
  useGetHomeAppointmentsQuery,
  useGetOwnNextAppointmentsQuery,
  useGetOwnArchivedAppointmentsQuery,
  useGetCompanyNextAppointmentsQuery,
  useGetCompanyArchivedAppointmentsQuery,
  useGetAppointmentQuery,
}
