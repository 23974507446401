import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/inputs/Button/Button'

interface UploadImageButtonProps {
  className?: string
  onFileChange?: (file: File) => void
  children?: ReactNode
}

const UploadImageButton = ({
  onFileChange: handleFileChange,
  className,
  children,
}: UploadImageButtonProps): JSX.Element => {
  const { t } = useTranslation()

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.currentTarget.files && handleFileChange) {
      handleFileChange(e.currentTarget.files[0])
    }
  }

  return (
    <>
      <input
        id='raised-button-file'
        accept='image/*'
        style={{ display: 'none' }}
        type='file'
        onChange={handleImageChange}
      />
      <label htmlFor='raised-button-file'>
        <Button component='span' className={className}>
          {children || t('common.chooseImage')}
        </Button>
      </label>
    </>
  )
}

export { UploadImageButton }
