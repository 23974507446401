import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { IconButton, IconButtonProps } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

import { TextField, Props as TextFieldProps } from 'components/inputs/TextField/TextField'

interface HighlightedTextFieldProps extends TextFieldProps {
  $marginBottom?: string
  $withHighlight?: boolean
}

const HighlightedTextField = styled(({
  $marginBottom,
  $withHighlight = true,
  ...props
}: HighlightedTextFieldProps) => (
  <TextField withHighlight={$withHighlight} {...props} />
))`
  ${({ $marginBottom }) => css`
    margin-top: 0;
    margin-bottom: ${$marginBottom || '12px'};
  `}
`

export interface DateTimeTextFieldProps extends HighlightedTextFieldProps {
  $maxInputWidth?: string
  $active?: boolean
  $border?: boolean
}

const DateTimeTextField = styled(HighlightedTextField, {
  shouldForwardProp: (prop) => prop !== '$maxInputWidth'
})<DateTimeTextFieldProps>`
  ${({ theme, $maxInputWidth, $active, $border }) => `
    width: ${$maxInputWidth ? 'auto' : '100%'};
    .MuiInputBase-root {
      max-width: ${$maxInputWidth || '100%'};
      color: ${$active ? theme?.palette.text.primary : theme?.palette.disabled.main};
      border-width: ${$border ? '1px' : '0'};
      font-size: 14px;
      > input {
        ${theme?.breakpoints.down('sm')} {
          text-align: center;
        }
      }
    }
  `}
`

const ClearIconButton = styled((props: IconButtonProps) => (
  <IconButton
    {...props}
    onClick={(event) => {
      event.stopPropagation()
      props.onClick?.(event)
    }}
  >
    <ClearIcon />
  </IconButton>
))``

export {
  DateTimeTextField,
  HighlightedTextField,
  ClearIconButton
}
