import CheckboxTree, { CheckboxProps, Node, OnCheckNode } from 'react-checkbox-tree'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded'
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded'
import IndeterminateCheckBoxRoundedIcon from '@material-ui/icons/IndeterminateCheckBoxRounded'
import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded'
import CheckIcon from '@material-ui/icons/Check'

import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import './TreeView.css'

interface TreeViewProps extends CheckboxProps {
  isSingleSelect?: boolean
}

const TreeView = ({
  isSingleSelect,
  onlyLeafCheckboxes = true,
  ...props
}: TreeViewProps): JSX.Element => (
  <CheckboxTree
    onlyLeafCheckboxes={onlyLeafCheckboxes}
    icons={{
      check: !isSingleSelect
        ? <CheckBoxRoundedIcon color='primary' />
        : <CheckIcon color='primary' />,
      uncheck: (
        <CheckBoxOutlineBlankRoundedIcon
          color='primary'
          opacity={isSingleSelect ? 0 : 1}
        />
      ),
      halfCheck: <IndeterminateCheckBoxRoundedIcon color='primary' />,
      expandClose: <AddRoundedIcon color='primary' />,
      expandOpen: <RemoveRoundedIcon color='primary' />,
    }}
    {...props}
  />
)

export { TreeView }
export type { Node as TreeViewNode, OnCheckNode as OnCheckTreeViewNode }
