import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDrawerToggle } from 'providers/DrawerStateProvider'
import { NAVIGATION_TABS } from 'constants/tabs'
import { RestrictedContent } from 'components/auth/RestrictedContent/RestrictedContent'
import { useNavigationTabs } from 'hooks/useNavigationTabs'
import { NavigationListItem } from './NavigationListItem/NavigationListItem'
import * as Styled from './NavigationList.styles'

const NavigationList: React.FC = () => {
  const { t } = useTranslation()
  const tabs = useNavigationTabs(NAVIGATION_TABS)
  const handleDrawerToggle = useDrawerToggle()

  return (
    <Styled.List>
      {tabs.map(({ to, name, translationKey, selected, icon, accessRoles, divider }) => (
        <RestrictedContent key={name} accessRoles={accessRoles}>
          <NavigationListItem
            key={name}
            to={to}
            selected={selected}
            primary={t(translationKey)}
            icon={icon}
            onClick={handleDrawerToggle}
            divider={divider}
          />
        </RestrictedContent>
      ))}
    </Styled.List>
  )
}
export { NavigationList }
