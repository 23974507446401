import { AccordionProps, CircularProgress, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { ExpandMoreIcon } from 'components/data/Card/Card.styles'
import * as Styled from './FirstStepsCardAccordion.styles'

interface FirstStepsCardAccordionProps extends Omit<AccordionProps, 'children'> {
  summary: string
  content: string
  redirectPath?: string
  redirectState?: unknown
  checked?: boolean
  isLoading?: boolean
}

const FirstStepsCardAccordion = ({
  summary,
  content,
  redirectPath,
  redirectState,
  checked,
  isLoading,
  ...props
}: FirstStepsCardAccordionProps): JSX.Element => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleRedirectButton = () => {
    if (redirectPath) {
      history.push({ pathname: redirectPath, state: redirectState })
    }
  }

  return (
    <Styled.Accordion {...props}>
      <Styled.AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Styled.SummaryContainer>
          {isLoading ? (
            <CircularProgress size={37} />
          ) : (
            checked && <Styled.Check color='primary' />
          )}
          <Typography variant='subtitle1'>
            {summary}
          </Typography>
        </Styled.SummaryContainer>
      </Styled.AccordionSummary>
      <Styled.AccordionDetails>
        <Typography variant='body1'>
          {content}
        </Typography>
        {redirectPath && (
          <Styled.RedirectButton backgroundGradient onClick={handleRedirectButton}>
            {t('common.go')}
          </Styled.RedirectButton>
        )}
      </Styled.AccordionDetails>
    </Styled.Accordion>
  )
}

export { FirstStepsCardAccordion }
