import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import { PageHeader } from 'components/layout/PageHeader/PageHeader'
import { AnalysisScopeType } from 'types/AnalysisScopeType'
import { AnalysisList } from 'components/data/AnalysisList/AnalysisList'
import { useAnalysisData } from 'hooks/useAnalysisData'
import { useCompanyId } from 'providers/CompanyProvider'
import * as Styled from './Analysis.styled'

const AnalysisScreen = () => {
  const { t } = useTranslation()
  const companyId = useCompanyId() || 0
  const [displayFrom, setDisplayFrom] = useState<AnalysisScopeType>(
    AnalysisScopeType.CORRECTNESS
  )

  const { data: analysisData, isLoading, isError } = useAnalysisData(companyId, displayFrom)

  return (
    <RouteContainer>
      <PageHeader
        title={t('analysis.title')}
        actions={(
          <Styled.HeaderContainer>
            <Styled.AnalysisScopeSelectLabel>
              {t('analysis.sortBy')}
            </Styled.AnalysisScopeSelectLabel>
            <Styled.Select
              value={displayFrom}
              onChange={(event) => setDisplayFrom(event.target.value as AnalysisScopeType)}
            />
          </Styled.HeaderContainer>
        )}
      />
      <AnalysisList
        analysisData={analysisData}
        isLoading={isLoading}
        isError={isError}
      />
    </RouteContainer>
  )
}

export default AnalysisScreen
