import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { KnowledgeBaseEntityResponse } from 'api/queries/knowledgeBase/types'
import { useFetch } from 'providers/FetchProvider'
import { AddQuestionObjectVariables, EditQuestionObjectVariables, MoveQuestionVariables } from './types'

const useEditQuestionMutation = (
  options: UseMutationOptions<KnowledgeBaseEntityResponse, Error, EditQuestionObjectVariables>
) : UseMutationResult<KnowledgeBaseEntityResponse, Error, EditQuestionObjectVariables> => {
  const { fetch } = useFetch()
  const { onSuccess } = options || {}
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    ({ id, dto }: EditQuestionObjectVariables) =>
      fetch.patch(`/quiz/question/${id}`, dto),
    {
      ...options,
      onSuccess: (...args) => {
        onSuccess?.(...args)
        enqueueSnackbar(t('quiz.action.success.edit'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('quiz.action.error.edit'), { variant: 'error' })
      },
    },
  )
}

const useCopyQuizMutation = (
  options?: UseMutationOptions<AxiosResponse<KnowledgeBaseEntityResponse>, Error, number>
): UseMutationResult<AxiosResponse<KnowledgeBaseEntityResponse>, Error, number> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()
  const { onSuccess } = options || {}

  return useMutation(
    (id: number) =>
      fetch.post(`/quiz/${id}/copy`),
    {
      ...options,
      onSuccess: (...args) => {
        onSuccess?.(...args)
        enqueueSnackbar(t('quiz.action.success.copy'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('quiz.action.error.copy'), { variant: 'error' })
      },
    },
  )
}

const useAddQuestionMutation = (
  options: UseMutationOptions<KnowledgeBaseEntityResponse, Error, AddQuestionObjectVariables>
) : UseMutationResult<KnowledgeBaseEntityResponse, Error, AddQuestionObjectVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ id, dto }: AddQuestionObjectVariables) => (
      fetch.post(`/quiz/${id}`, dto).then((response) => response.data)
    ),
    {
      ...options,
      onSuccess: (...args) => {
        onSuccess?.(...args)
        enqueueSnackbar(t('quiz.action.success.add'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('quiz.action.error.add'), { variant: 'error' })
      },
    },
  )
}

const useDeleteQuestionMutation = (
  options?: UseMutationOptions<KnowledgeBaseEntityResponse, Error, number>
): UseMutationResult<KnowledgeBaseEntityResponse, Error, number> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()
  const { onSuccess } = options || {}

  return useMutation(
    (id: number) =>
      fetch.delete(`/quiz/question/${id}`),
    {
      ...options,
      onSuccess: (...args) => {
        onSuccess?.(...args)
        enqueueSnackbar(t('quiz.action.success.delete'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('quiz.action.error.delete'), { variant: 'error' })
      },
    },
  )
}

const useMoveQuestionMutation = (
  options?: UseMutationOptions<KnowledgeBaseEntityResponse, Error, MoveQuestionVariables>
): UseMutationResult<KnowledgeBaseEntityResponse, Error, MoveQuestionVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ id, position }: MoveQuestionVariables) => (
      fetch.patch(`/quiz/question/${id}/move?position=${position}`)),
    {
      ...options,
      onSuccess: (...args) => {
        onSuccess?.(...args)
        enqueueSnackbar(t('quiz.action.success.moveQuestion'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('quiz.action.error.moveQuestion'), { variant: 'error' })
      },
    },
  )
}

export {
  useEditQuestionMutation,
  useAddQuestionMutation,
  useCopyQuizMutation,
  useDeleteQuestionMutation,
  useMoveQuestionMutation
}
