import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cleanDeep from 'clean-deep'

import { Modal, ModalProps } from 'components/layout/Modal/Modal'
import { TreeView, TreeViewNode } from 'components/inputs/TreeView/TreeView'
import { useTreeViewData } from 'hooks/useTreeViewData'
import { CircularProgress } from 'screens/KnowledgeBase/KnowledgeBaseScreen/KnowledgeBaseScreen.styles'
import { useCompanyId } from 'providers/CompanyProvider'
import { KnowledgeBaseVisibilityType } from 'types/KnowledgeBaseVisibilityType'
import { KnowledgeBaseContentType } from 'types/KnowledgeBaseContentType'
import { GROUP_ICON, PRESENTATION_ICON, QUIZ_ICON, TEST_ICON } from 'constants/knowledgeBaseContent'
import { KnowledgeBaseTreeViewNode } from 'api/queries/knowledgeBase/types'
import * as Styled from './ContentModal.styles'

interface ContentModalProps extends Omit<ModalProps, 'children' | 'onClose'> {
  open: boolean
  onClose: () => void
  onSave: (values: number[]) => void
  initialValues?: number[]
  isSaving?: boolean
  includeFolders?: boolean
  isLoadingInitialValues?: boolean
  isPresentation?: boolean
  knowledgeBaseVisibilityType?: KnowledgeBaseVisibilityType
}

function getNodeIcon(type: KnowledgeBaseContentType) {
  switch (type) {
    case KnowledgeBaseContentType.PRESENTATION:
      return PRESENTATION_ICON
    case KnowledgeBaseContentType.TEST:
    case KnowledgeBaseContentType.SANDBOX_TEST:
      return TEST_ICON
    case KnowledgeBaseContentType.QUIZ:
      return QUIZ_ICON
    default:
      return GROUP_ICON
  }
}

function mapKBTreeViewNodeToNode(kbTreeViewNode: KnowledgeBaseTreeViewNode): TreeViewNode {
  const { children, dataType, value } = kbTreeViewNode
  const Icon = getNodeIcon(dataType)
  const isGroup = dataType === 'GROUP'

  return {
    ...kbTreeViewNode,
    value: value.toString(),
    icon: <Icon
      width={!isGroup ? 24 : 0}
      height={24}
      viewBox={`${!isGroup ? '0 0 120 120' : '0 0 0 0'}`}
    />,
    children: children?.map(mapKBTreeViewNodeToNode),
  }
}

function filterUndefined<T>(value: T | null | undefined): value is T {
  return value != null
}

const ContentModal = ({
  open,
  onClose: handleClose,
  onSave: handleSave,
  initialValues = [],
  isSaving,
  includeFolders = false,
  isLoadingInitialValues,
  isPresentation,
  knowledgeBaseVisibilityType
}: ContentModalProps): JSX.Element => {
  const { t } = useTranslation()
  const companyId = useCompanyId() || 0

  const [expanded, setExpanded] = useState<string[]>([])
  const [checked, setChecked] = useState<string[]>(initialValues.map((value) => value?.toString()))

  useEffect(() => {
    setChecked(initialValues.map((value) => value?.toString()))
  }, [initialValues])

  const {
    data: treeViewData,
    isLoading: isTreeViewLoading,
    isError
  } = useTreeViewData(companyId, isPresentation, knowledgeBaseVisibilityType)

  const treeViewNodes: TreeViewNode[] = useMemo(() => (
    treeViewData ? (
      cleanDeep(treeViewData).filter(filterUndefined).map(mapKBTreeViewNodeToNode)
    ) : []
  ), [treeViewData])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nodeSorter: any = (nodes: TreeViewNode[]) => (
    nodes.map((node) => (node.children
      ? { ...node, children: nodeSorter(node.children) }
      : node))
      .sort((a, b) => ((a.children && !b.children) ? -1 : 1))
  )

  const handleCloseModal = () => {
    setExpanded([])
    setChecked(initialValues.map((value) => value?.toString()))
    handleClose()
  }

  return (
    <Styled.Modal
      open={open}
      onClose={handleCloseModal}
    >
      <Modal.Title variant='h3'>
        {t(`modals.${isPresentation ? 'presentation' : 'content'}.title`)}
      </Modal.Title>
      <Modal.Content>
        <Styled.ContentContainer>
          {!isPresentation && (
            <Styled.Typography>
              {t('modals.content.select')}
            </Styled.Typography>
          )}
          <Styled.HelpersContainer>
            {isError
            && <Styled.Typography color='error'>{t('common.errors.generic')}</Styled.Typography>}
            {treeViewNodes.length === 0
            && <Styled.Typography>{t('knowledgeBase.empty')}</Styled.Typography>}
            {(isTreeViewLoading || isLoadingInitialValues) && <CircularProgress color='primary' />}
          </Styled.HelpersContainer>
          <Styled.TreeViewContainer>
            <TreeView
              isSingleSelect={isPresentation}
              onlyLeafCheckboxes={!includeFolders}
              nodes={nodeSorter(treeViewNodes)}
              checked={checked}
              expanded={expanded}
              onCheck={(value) => (isPresentation
                ? setChecked(value.filter((node) => !checked.includes(node)))
                : setChecked(value))}
              onExpand={(value) => setExpanded(value)}
            />
          </Styled.TreeViewContainer>
        </Styled.ContentContainer>
      </Modal.Content>
      <Modal.Actions
        onCancel={handleCloseModal}
        onSave={() => handleSave(checked.map((check) => parseInt(check, 10)))}
        isLoading={isSaving}
        confirmLabel={t('common.choose')}
      />
    </Styled.Modal>
  )
}

export { ContentModal }
