import { useMemo } from 'react'

import {
  textDeleteButtonStyles,
  containedDeleteButtonStyles,
  outlinedDeleteButtonStyles
} from './DeleteButton.styles'
import { Button, ButtonProps } from '../Button/Button'

interface DeleteButtonProps extends ButtonProps {}

const DeleteButton = ({ variant = 'contained', ...props }: DeleteButtonProps) => {
  const styles = useMemo(() => {
    if (variant === 'outlined') return outlinedDeleteButtonStyles
    if (variant === 'contained') {
      return containedDeleteButtonStyles
    }
    return textDeleteButtonStyles
  }, [variant])

  return (
    <Button variant={variant} {...props} styles={styles} />
  )
}

export { DeleteButton }
export type { DeleteButtonProps }
