import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import { add } from 'date-fns'
import SaveIcon from '@material-ui/icons/Save'

import { CreateOrEditAppointmentSchema } from 'validations/CreateOrEditAppointmentSchema'
import { useIsMobile } from 'hooks/useIsMobile'
import { Participant } from 'types/Participant'
import { useUserId } from 'providers/UserContextProvider'
import { HighlightedTextField } from 'components/inputs/DatePicker/DatePicker.styles'
import { AppointmentVisibilityType } from 'types/AppointmentVisibilityType'
import { DateTimeSection } from './DateTimeSection/DateTimeSection'
import { LecturersSection, Teacher } from './LecturersSection/LecturersSection'
import { AdditionalMaterialsSection, DidacticMaterial } from './AdditionalMaterialsSection/AdditionalMaterialsSection'
import { ParticipantsSection } from './ParticipantsSection/ParticipantsSection'
import { KnowledgeBaseScheduleSection } from './KnowledgeBaseScheduleSection/KnowledgeBaseScheduleSection'
import * as Styled from './AppointmentForm.styles'

interface AppointmentFormFields {
  name: string
  dateTime: {
    date: string
    startTime: string
    endTime: string
  }
  teacher?: Teacher
  description: string
  didacticMaterials: DidacticMaterial[]
  participants: Participant[]
  visibility: AppointmentVisibilityType
  knowledgeBaseScheduleRecords: number[]
  open: boolean
}

interface AppointmentFormProps {
  initialValues?: AppointmentFormFields
  onSubmit?: (values: AppointmentFormFields) => void
  isLoading?: boolean
  formId?: string
  authorId?: number | null
}

const defaultValues = {
  name: '',
  dateTime: {
    date: new Date().toString(),
    startTime: add(new Date(), { minutes: 60 - new Date().getMinutes() }).toString(),
    endTime: add(new Date(), { hours: 1, minutes: 60 - new Date().getMinutes() }).toString()
  },
  teacher: undefined,
  description: '',
  didacticMaterials: [],
  participants: [],
  visibility: AppointmentVisibilityType.Invitational,
  open: false,
  knowledgeBaseScheduleRecords: [],
}

const AppointmentForm: React.FC<AppointmentFormProps> = ({
  initialValues = defaultValues,
  onSubmit = () => {},
  isLoading,
  formId,
  authorId,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const userId = useUserId()

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    validateField,
  } = useFormik<AppointmentFormFields>({
    initialValues,
    validate: (currentValues) => {
      try {
        validateYupSchema(currentValues, CreateOrEditAppointmentSchema, true, currentValues)
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
    onSubmit,
    enableReinitialize: true,
  })

  const handleMaterialsChange = async (materials: DidacticMaterial[]) => {
    await setFieldValue('didacticMaterials', materials)
    await setFieldTouched('didacticMaterials', true)
  }

  const handleScheduleChange = (records: number[]) => {
    setFieldValue('knowledgeBaseScheduleRecords', records)
    setFieldTouched('knowledgeBaseScheduleRecords', true)
  }

  const changeVisibilityRelatedFields = (visibility: AppointmentVisibilityType, open: boolean) => {
    setFieldValue('visibility', visibility)
    setFieldTouched('visibility', true)
    setFieldValue('open', open)
    setFieldTouched('open', true)
  }

  const handleVisibilityTypeChange = (event: ChangeEvent<{ name?: string, value: unknown }>) => {
    const visibility = event.target.value as AppointmentVisibilityType
    if (
      [AppointmentVisibilityType.Invitational,
        AppointmentVisibilityType.Private].includes(visibility)
    ) {
      changeVisibilityRelatedFields(visibility, false)
    } else if (visibility === AppointmentVisibilityType.Public) {
      changeVisibilityRelatedFields(visibility, true)
    }
  }

  return (
    <Styled.Form id={formId} onSubmit={handleSubmit}>
      <Styled.Row>
        <Styled.Column>
          <Styled.TopLabel>{t('common.visibility')}</Styled.TopLabel>
          <Styled.VisibilityTypeSelect
            id='visibility'
            name='visibility'
            label=''
            value={values.visibility}
            onChange={handleVisibilityTypeChange}
            onBlur={handleBlur}
            touched={!!touched.visibility}
            errorMessage={t(errors.visibility as unknown as string || '')}
            disabled={authorId !== userId}
          />
          <Styled.VisibilityAlert>
            {t(`appointment.visibility.info.${values.visibility}`)}
          </Styled.VisibilityAlert>
        </Styled.Column>
      </Styled.Row>
      <Styled.Row>
        <Styled.Column>
          <Styled.TopLabel>
            {t('common.name')}
          </Styled.TopLabel>
          <HighlightedTextField
            id='name'
            name='name'
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.name}
            errorMessage={t(errors.name || '')}
          />
        </Styled.Column>
      </Styled.Row>
      <Styled.ColumnsRow>
        <Styled.Column>
          <DateTimeSection
            values={values.dateTime}
            onValueChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            validateField={validateField}
            touched={touched.dateTime}
            errorMessage={{
              date: errors.dateTime?.date,
              startTime: errors.dateTime?.startTime,
              endTime: errors.dateTime?.endTime,
            }}
          />
          <LecturersSection
            value={values.teacher}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            touched={!!touched.teacher}
            errorMessage={t(errors.teacher as string || '')}
          />
          <Styled.TopLabel>{t('common.description')}</Styled.TopLabel>
          <HighlightedTextField
            id='description'
            name='description'
            placeholder={t('appointment.addDescriptionPlaceholder')}
            multiline
            minRows={3}
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.description}
            errorMessage={t(errors.description || '')}
          />
          <AdditionalMaterialsSection
            didacticMaterials={values.didacticMaterials}
            onMaterialsChange={handleMaterialsChange}
          />
        </Styled.Column>
        <Styled.Column>
          <KnowledgeBaseScheduleSection
            value={values.knowledgeBaseScheduleRecords}
            onValueChange={handleScheduleChange}
            touched={!!touched.knowledgeBaseScheduleRecords}
          />
        </Styled.Column>
        <Styled.Column>
          <ParticipantsSection
            participants={values.participants}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            touched={!!touched.participants}
            errors={errors.participants as string[]}
            selectedTeacherId={values.teacher?.id}
          />
        </Styled.Column>
      </Styled.ColumnsRow>
      {!isMobile && (
        <Styled.SaveButton
          isLoading={isLoading}
          disabled={isLoading}
          startIcon={<SaveIcon />}
        >
          {t('common.save')}
        </Styled.SaveButton>
      )}
    </Styled.Form>
  )
}

export type { AppointmentFormFields }
export { AppointmentForm }
