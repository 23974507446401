import styled from '@emotion/styled'
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@material-ui/core'

const Accordion = styled(MuiAccordion)`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(4)}px;
    padding: ${theme.spacing(2)}px;
    border-radius: 15px;
    background-color: ${theme.palette.backgroundAccents.blueish};
    box-shadow: none;
    ::before {
      height: 0;
    }
    &.Mui-expanded {
      margin-bottom: ${theme.spacing(4)}px;
    }
  `}
`

const AccordionSummary = styled(MuiAccordionSummary)`
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.spacing(2.5)}px;
  .MuiAccordionSummary-content {
    width: 100%;
  }
  &.Mui-expanded {
    min-height: 0;
  }
  .MuiButtonBase-root {
    margin: 0;
    padding: ${({ theme }) => theme.spacing(0.5)}px;
  }
`

const AccordionDetails = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
`

export {
  Accordion,
  AccordionSummary,
  AccordionDetails,
}
