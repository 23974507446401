import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { useFetch } from 'providers/FetchProvider'
import { CompanyAddressables } from './types'

const useGetOwnCompanyAddressables = (
  options?: Omit<UseQueryOptions<CompanyAddressables, Error>, 'queryKey'>
) : UseQueryResult<CompanyAddressables, Error> => {
  const { fetch } = useFetch()

  return useQuery(
    'ownCompanyAddressables',
    () => fetch.get('/addressable').then((response) => response.data),
    options
  )
}

const useGetCompanyIdAddressables = (
  id: number,
  options?: Omit<UseQueryOptions<CompanyAddressables, Error>, 'queryKey'>
): UseQueryResult<CompanyAddressables, Error> => {
  const { fetch } = useFetch()

  return useQuery(
    ['companyAddressables', { id }],
    () => fetch.get(`/addressable/company/${id}`).then((response) => response.data),
    options
  )
}

export {
  useGetOwnCompanyAddressables,
  useGetCompanyIdAddressables
}
