import { css } from '@emotion/react'
import styled from '@emotion/styled'

interface ListItemContainerProps {
  $isMobile?: boolean
}

const ListItemContainer = styled.div<ListItemContainerProps>`
  display: flex;
`

const AnalysisUserDataContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ${theme.spacing(3, 1)};
    margin: ${theme.spacing(1)}px;
    border: ${theme.palette.border.borderLineWidth} solid ${theme.palette.border.light};
    border-radius: ${theme.palette.border.borderRadius};
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      padding: ${theme.spacing(2, 0)};
    }
  `}
`

export {
  ListItemContainer,
  AnalysisUserDataContainer
}
