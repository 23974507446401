import { useTranslation } from 'react-i18next'

import { QuizEntryStatisticsDTO } from 'api/queries/results/types'
import { formatSeconds } from 'utils/formatSeconds'
import * as Styled from './AnswersStatistics.styles'

interface AnswersStatisticsProps {
  stats?: QuizEntryStatisticsDTO
}

const AnswersStatistics = ({
  stats
}: AnswersStatisticsProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Styled.Container>
      <Styled.Header>{t('results.receivedAnswers')}</Styled.Header>
      <Styled.Typography>
        {`${t('results.correctAnswers')}: ${stats?.correctAnswers}`}
      </Styled.Typography>
      <Styled.Typography>
        {`${t('results.wrongAnswers')}: ${stats?.incorrectAnswers}`}
      </Styled.Typography>
      <Styled.Typography>
        {`${t('results.algorithmRating')}: ${stats?.algorithmGrade}`}
      </Styled.Typography>
      <Styled.Typography>
        {`${t('results.accuracy')}: ${stats?.correctness && stats.correctness * 100}%`}
      </Styled.Typography>
      {stats?.time && (
        <Styled.Typography>
          {`${t('results.timeUsed')}: ${formatSeconds(stats.time)} min`}
        </Styled.Typography>
      )}
    </Styled.Container>
  )
}

export { AnswersStatistics }
