import { useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { Grid, Typography, CircularProgress } from '@material-ui/core'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import { formatISO } from 'date-fns'
import { format } from 'date-fns-tz'

import { useSetActiveMutation } from 'api/mutations/users/users'
import { UserList } from 'components/data/UserList/UserList'
import { UserListItem, UserListItemType } from 'components/data/UserList/UserListItem/UserListItem'
import { PageHeader } from 'components/layout/PageHeader/PageHeader'
import * as LayoutStyled from 'components/layout/Layout.styles'
import { UsersTableHeader } from './UsersTableHeader'
import * as Styled from './UsersTable.styles'

interface UserTableProps {
  usersData: UserListItemType[]
  isDataLoading: boolean
  isDataError: boolean
  userQueryKey: string
  onRegister: (user: UserListItemType) => Promise<void>
  newUserVisible?: boolean
}

const UsersTable: React.FC<UserTableProps> = ({
  usersData,
  isDataLoading,
  isDataError,
  userQueryKey,
  onRegister,
  newUserVisible,
}) => {
  const [isNewUserVisible, setIsNewUserVisible] = useState<boolean>(!!newUserVisible)
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { mutate: setActive } = useSetActiveMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(userQueryKey)
    }
  })

  const userTimeZone = format(new Date(), 'OOOO', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  })

  const handleSubmitNewUser = async (user: UserListItemType) => {
    const { expirationDate: expirationDateString } = user
    try {
      await onRegister({
        ...user,
        username: user.username !== '' ? user.username : null,
        phoneNumber: user.phoneNumber !== '' ? user.phoneNumber : null,
        educationLevel: user.educationLevel !== '' ? user.educationLevel : null,
        timeZone: userTimeZone !== 'GMT+00:00' ? userTimeZone : 'UTC',
        expirationDate: expirationDateString ? formatISO(new Date(expirationDateString)) : null
      })
    } finally {
      queryClient.invalidateQueries(userQueryKey)
      setIsNewUserVisible(false)
    }
  }

  const handleCancelNewUser = () => {
    setIsNewUserVisible(false)
  }

  const handleActivate = (id: number, isActive: boolean) => {
    setActive({ id, isActive })
  }

  const sortedUsersData = useMemo(() => (
    [...usersData].sort((a, b) => (+b.technicalUser - +a.technicalUser))
  ), [usersData])

  return (
    <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
      <PageHeader
        title={t('common.users')}
        actions={
          <Styled.Button
            backgroundGradient
            onClick={() => setIsNewUserVisible(true)}
            endIcon={<AddRoundedIcon />}
          >
            {t('users.addNew')}
          </Styled.Button>
        }
      />
      <LayoutStyled.HelperContainer item xs={12}>
        {isDataError && <Typography color='error'>{t('common.errors.generic')}</Typography>}
        {isDataLoading && <CircularProgress color='primary' />}
      </LayoutStyled.HelperContainer>
      <LayoutStyled.ListContainer item xs={12}>
        {!isDataLoading && (
          <UsersTableHeader />
        )}
        {isNewUserVisible && (
          <UserListItem isNew onSubmit={handleSubmitNewUser} onCancel={handleCancelNewUser} />
        )}
        {usersData.length ? (
          <UserList
            users={sortedUsersData}
            onActivate={handleActivate}
            userQueryKey={userQueryKey}
          />
        ) : (
          !isNewUserVisible
          && !isDataLoading && (
            <Styled.NoUsersContainer item>
              <Typography variant='h5' color='primary'>
                {t('users.noUsers')}
              </Typography>
            </Styled.NoUsersContainer>
          )
        )}
      </LayoutStyled.ListContainer>
    </Grid>
  )
}

export { UsersTable }
