import { SortType } from 'types/SortType'

const NONE: SortType = 'NONE'
const ALPHABETICAL: SortType = 'ALPHABETICAL'
const ALPHABETICAL_REVERSE: SortType = 'ALPHABETICAL_REVERSE'
const NEWEST: SortType = 'NEWEST'
const OLDEST: SortType = 'OLDEST'

export {
  NONE,
  ALPHABETICAL,
  ALPHABETICAL_REVERSE,
  NEWEST,
  OLDEST
}
