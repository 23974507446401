import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseQueryResult,
  UseInfiniteQueryResult,
} from 'react-query'
import { useFetch } from 'providers/FetchProvider'
import { NOTIFICATIONS_QUERY_KEY } from 'api/queriesKeys'
import { NotificationsResponse } from './types'

const NOTIFICATIONS_PAGE_SIZE = 12

const useGetNotificationsQuery = (
  useQueryOptions?: Omit<UseInfiniteQueryOptions<NotificationsResponse, Error>, 'queryKey'>
) : UseInfiniteQueryResult<NotificationsResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    NOTIFICATIONS_QUERY_KEY,
    ({ pageParam }) => fetch.get('/notification', {
      params: {
        page: pageParam,
        size: NOTIFICATIONS_PAGE_SIZE,
      },
    }).then((response) => response.data),
    useQueryOptions,
  )
}

const useGetNotificationsCountQuery = (
  useQueryOptions?: Omit<UseQueryOptions<number, Error>, 'queryKey'>
) : UseQueryResult<number, Error> => {
  const { fetch } = useFetch()

  return useQuery(
    [NOTIFICATIONS_QUERY_KEY, 'count'],
    () => fetch.get('/notification/count').then((response) => response.data),
    useQueryOptions,
  )
}

export {
  useGetNotificationsQuery,
  useGetNotificationsCountQuery,
}
