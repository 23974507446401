import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Typography } from '@material-ui/core'

import { Button } from 'components/inputs/Button/Button'

const AddMaterialContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`

const AddText = styled(Typography)`
  flex: 1;
  font-weight: bold;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`

const AddButton = styled(Button)`
  min-width: 50px;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`

const InputContainer = styled.div`
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
`

const SelectFileButton = styled(Button)`
  width: auto;
  min-width: 150px;
  padding: 5px;
  margin-right: 10px;
`

const FileName = styled(Typography)`
  min-width: 100px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 10px 0;
`

const InputLabel = styled.label`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    align-items: center;
    margin-bottom: ${theme.spacing(2)}px;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: flex-start;
    }
  `}
`

export {
  AddMaterialContainer,
  AddText,
  InputContainer,
  InputLabel,
  SelectFileButton,
  FileName,
  AddButton,
}
