import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'

const imageBase = css`
  width: 100%;
  height: 100%;
  border-radius: 20px;
`

const ImagePreview = styled.img`
  ${imageBase}
  object-fit: cover;
`

const ImagePlaceholder = styled.div`
  ${({ theme }) => css`
    position: relative;
    ${imageBase}
    background-color: ${theme.palette.disabled.main}
  `}
`

const PlaceholderText = styled(Typography)`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -${theme.spacing(5)}px;
    margin-top: -10px;
    color: ${theme.palette.secondary.contrastText};
  `}
`

export {
  ImagePreview,
  ImagePlaceholder,
  PlaceholderText,
}
