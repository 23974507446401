import { ReactNode, useState } from 'react'
import { Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import * as Styled from './AccountAccordion.styles'

interface AccountAccordionProps {
  title?: string
  details?: ReactNode
  initialExpanded?: boolean
}

const AccountAccordion = ({ title, details, initialExpanded = false }: AccountAccordionProps) => {
  const [expanded, setExpanded] = useState(initialExpanded)

  const handleChange = () => setExpanded((prevState) => !prevState)

  return (
    <Styled.Accordion expanded={expanded} onChange={handleChange}>
      <Styled.AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='h2'>{title}</Typography>
      </Styled.AccordionSummary>
      <Styled.AccordionDetails>
        {details}
      </Styled.AccordionDetails>
    </Styled.Accordion>
  )
}

export { AccountAccordion }
