import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Breadcrumbs as MuiBreadcrumbs, BreadcrumbsProps, Typography } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

const KnowledgeBaseContentItem = styled.div`
  align-items: baseline;
  margin: ${({ theme }) => theme.spacing(0.5, 0)};
`

const StyledTypography = styled(Typography)`
  font-weight: bold;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`

const Breadcrumbs = styled((props: BreadcrumbsProps) => (
  <MuiBreadcrumbs separator={<NavigateNextIcon fontSize='small' />} {...props} />
))`
  ${({ theme }) => css`
    color: ${theme.palette.text.primary};
    font-weight: normal;
  `}
`

export {
  KnowledgeBaseContentItem,
  StyledTypography as Typography,
  Breadcrumbs
}
