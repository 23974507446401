import { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Fade from '@material-ui/core/Fade'

import { ForumEntry } from 'api/types'
import { useIsMobile } from 'hooks/useIsMobile'
import { Forum } from './Forum/Forum'
import * as Styled from './ForumContainer.styles'

interface RouteParams {
  id: string
  openForum?: string
}

interface ForumContainerProps extends Pick<ForumEntry, 'appointmentId'> {}

const ForumContainer = ({ appointmentId }: ForumContainerProps) => {
  const { openForum } = useParams<RouteParams>()
  const history = useHistory()
  const location = useLocation()
  const isMobile = useIsMobile()
  const [isOpen, setIsOpen] = useState(false)
  const anchorElRef = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (anchorElRef.current && openForum) {
      setIsOpen(true)
      history.replace(location.pathname.substring(0, location.pathname.lastIndexOf('/')))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorElRef])

  return (
    <>
      <Styled.ForumPopper
        anchorEl={anchorElRef.current}
        open={isOpen}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={500}>
            <Styled.ForumPaper>
              <Forum
                onClose={() => setIsOpen(false)}
                appointmentId={appointmentId}
              />
            </Styled.ForumPaper>
          </Fade>
        )}
      </Styled.ForumPopper>
      <Styled.OpenButton
        innerRef={anchorElRef}
        color='primary'
        $isMobile={isMobile}
        $isOpen={isOpen}
        onClick={() => (
          setIsOpen((prev) => !prev)
        )}
      />
    </>
  )
}

export { ForumContainer }
