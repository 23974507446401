import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Button } from 'components/inputs/Button/Button'

const StyledButton = styled(Button)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing(2)}px;
    max-width: 250px;
    font-size: 15px;
    white-space: nowrap;

    ${theme.breakpoints.down('sm')} {
      margin-top: 10px;
    }
  `}
`

export { StyledButton as Button }
