import styled from '@emotion/styled'
import { Typography, TypographyProps } from '@material-ui/core'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`

const Header = styled((props: TypographyProps) => (
  <Typography variant='subtitle1' {...props} />
))``

const StyledTypography = styled((props: TypographyProps) => (
  <Typography {...props} />
))`
  flex: 1;
  margin: ${({ theme }) => theme.spacing(1, 0)};
`

export {
  Container,
  Header,
  StyledTypography as Typography,
}
