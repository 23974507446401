import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Modal } from 'components/layout/Modal/Modal'

const StyledModal = styled(Modal)`
  ${({ theme }) => css`
  & .MuiPaper-root {
      min-width: 650px;
      max-height: 700px;

      ${theme.breakpoints.down('sm')} {
        min-width: 100px;
      }
    }
  `}
`

export {
  StyledModal as Modal,
}
