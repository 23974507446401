import { FormControl, FormHelperText } from '@material-ui/core'
import { useMemo } from 'react'

import { ButtonProps } from 'components/inputs/Button/Button'

import * as Styled from './UploadImageField.styles'

interface UploadImageFieldProps extends ButtonProps {
  className?: string
  onFileChange: (file: File) => void
  file?: File | string | null
  error?: boolean
  errorMessage?: string
  touched?: boolean
}

const UploadImageField = ({
  onFileChange,
  className,
  file,
  error,
  errorMessage,
  touched,
}: UploadImageFieldProps): JSX.Element => {
  const image = useMemo(() => {
    if (typeof file === 'string') {
      return file
    }
    return file ? URL.createObjectURL(file) : ''
  }, [file])

  return (
    <FormControl error={error && touched} className={className}>
      <Styled.ImagePreview imageSrc={image} />
      {error && touched && (
        <FormHelperText color='error'>
          {errorMessage}
        </FormHelperText>
      )}
      <Styled.UploadFileButton onFileChange={onFileChange} />
    </FormControl>
  )
}

export { UploadImageField }
