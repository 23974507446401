import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Typography } from '@material-ui/core'

import { useGetQuizAnalysisQuery } from 'api/queries/statistics/statistics'
import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import { PageHeader } from 'components/layout/PageHeader/PageHeader'
import { StatisticsQuestionDetails } from 'components/data/Statistics/Details/StatisticsQuestionDetails'
import { Chart } from 'components/data/Statistics/Details/Chart'
import { parseData } from './utils'
import * as Styled from './StatisticsDetailQuestion.styled'
import * as CommonStyled from './Statistics.styled'

interface RouteParams {
  quizId: string
  questionId: string
}

const StatisticsDetailQuestionScreen = () => {
  const { quizId, questionId } = useParams<RouteParams>()
  const { t } = useTranslation()

  const {
    data: quizDetailsData,
    isLoading,
    isError
  } = useGetQuizAnalysisQuery(+quizId)

  const questionData = quizDetailsData?.find((question) => question.questionDTO.id === +questionId)

  const {
    navgTime,
    nstdDevTime,
    navgCorrect,
    navgIncorrect,
  } = questionData?.quizEvalData || {}

  const {
    time,
    correct,
  } = parseData(questionData?.quizEvalData)

  return (
    <RouteContainer>
      {isLoading ? (
        <CommonStyled.LoaderContainer>
          <CircularProgress color='primary' />
        </CommonStyled.LoaderContainer>
      ) : isError ? (
        <Styled.HelperContainer>
          <Typography color='error'>
            {t('common.errors.generic')}
          </Typography>
        </Styled.HelperContainer>
      ) : (
        <>
          <PageHeader title={questionData?.questionDTO.question} />
          <Styled.Container>
            <StatisticsQuestionDetails
              averageTime={navgTime}
              standardDeviation={nstdDevTime}
              averageCorrectness={navgCorrect}
              averageIncorrect={navgIncorrect}
            />
            <Styled.ChartsContainer>
              <Styled.ChartContainer $spacing>
                <Chart
                  title={t('statistics.details.averageTimeOfTaskSolution')}
                  label={t('statistics.details.averageTimeInPercentage')}
                  data={time}
                />
              </Styled.ChartContainer>
              <Styled.ChartContainer>
                <Chart
                  title={t('statistics.details.averageResponseCorrectness')}
                  label={t('statistics.details.averageCorrectnessInPercentage')}
                  data={correct}
                />
              </Styled.ChartContainer>
            </Styled.ChartsContainer>
          </Styled.Container>
        </>
      )}
    </RouteContainer>
  )
}

export default StatisticsDetailQuestionScreen
