import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { FORUM_ENTRIES_QUERY_KEY } from 'api/queriesKeys'
import { ForumEntry } from 'api/types'
import { useFetch } from 'providers/FetchProvider'
import {
  PostForumEntryVariables,
  ModifyForumEntryVariables,
  PostForumEntryReplyVariables,
} from './types'

const usePostEntryForAppointmentMutation = (
  options?: UseMutationOptions<ForumEntry, Error, PostForumEntryVariables>
): UseMutationResult<ForumEntry, Error, PostForumEntryVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ appointmentId, content }) =>
      fetch.post(`/forum-entry/appointment/${appointmentId}`, { content })
        .then((response) => response.data),
    {
      ...options,
      onSuccess: (...args) => {
        onSuccess?.(...args)
        queryClient.invalidateQueries(FORUM_ENTRIES_QUERY_KEY)
        enqueueSnackbar(t('appointment.forum.action.success.add'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('appointment.forum.action.error.add'), { variant: 'error' })
      },
    },
  )
}

const useModifyEntryForAppointmentMutation = (
  options?: UseMutationOptions<ForumEntry, Error, ModifyForumEntryVariables>
): UseMutationResult<ForumEntry, Error, ModifyForumEntryVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ id, content }) =>
      fetch.put(`/forum-entry/${id}`, { content })
        .then((response) => response.data),
    {
      ...options,
      onSuccess: (...args) => {
        onSuccess?.(...args)
        queryClient.invalidateQueries(FORUM_ENTRIES_QUERY_KEY)
        enqueueSnackbar(t('appointment.forum.action.success.edit'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('appointment.forum.action.error.edit'), { variant: 'error' })
      },
    },
  )
}

const usePostReplyForAppointmentEntryMutation = (
  options?: UseMutationOptions<ForumEntry, Error, PostForumEntryReplyVariables>
): UseMutationResult<ForumEntry, Error, PostForumEntryReplyVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ id, content }) =>
      fetch.post(`/forum-entry/${id}`, { content })
        .then((response) => response.data),
    {
      ...options,
      onSuccess: (...args) => {
        onSuccess?.(...args)
        queryClient.invalidateQueries(FORUM_ENTRIES_QUERY_KEY)
        enqueueSnackbar(t('appointment.forum.action.success.add'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('appointment.forum.action.error.add'), { variant: 'error' })
      },
    },
  )
}

export {
  usePostEntryForAppointmentMutation,
  useModifyEntryForAppointmentMutation,
  usePostReplyForAppointmentEntryMutation,
}
