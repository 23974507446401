import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { CircularProgress, Collapse, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { HistoryQuizQuestionDTO } from 'api/queries/results/types'
import { useGetHistoryQuestionQuery } from 'api/queries/results/results'
import { useIsMobile } from 'hooks/useIsMobile'
import { QuestionAnswerDetails } from './QuestionAnswerDetails/QuestionAnswerDetails'
import * as Styled from './QuestionAnswerItem.styles'

interface RouteParams {
  id: string
}

interface QuestionAnswerItemProps extends HistoryQuizQuestionDTO {
  index: number
}

const QuestionAnswerItem = ({
  index,
  questionId,
  correctness,
  question,
  warning,
  userAnswers,
  correctAnswers
}: QuestionAnswerItemProps) => {
  const { t } = useTranslation()
  const { id: quizId } = useParams<RouteParams>()
  const [expanded, setExpanded] = useState<boolean>(false)
  const isMobile = useIsMobile()

  const { data: questionDetails, isLoading, isError } = useGetHistoryQuestionQuery(
    +quizId,
    questionId,
    {
      enabled: expanded
    }
  )

  return (
    <>
      <Styled.QuestionAnswerItemContainer
        $correct={correctness === 1}
      >
        <Styled.TextContainer>
          <Styled.QuestionText>
            {`${isMobile ? `${t('results.content')}: ` : ''} 
            ${index}. ${question}${warning ? '⚠️' : ''}`}
          </Styled.QuestionText>
          <Styled.Typography>
            {`${isMobile ? `${t('results.answer')}: ` : ''} ${userAnswers.join(', ')}`}
          </Styled.Typography>
          <Styled.Typography>
            {`${isMobile ? `${t('results.correctAnswer')}: ` : ''} ${correctAnswers.join(', ')}`}
          </Styled.Typography>
          <Styled.Typography>
            {`${isMobile ? `${t('results.accuracy')}: ` : ''} ${correctness * 100}%`}
          </Styled.Typography>
        </Styled.TextContainer>
        <Styled.ExpandButton
          $expanded={expanded}
          onClick={() => setExpanded((prev) => !prev)}
        />
      </Styled.QuestionAnswerItemContainer>
      <Collapse in={expanded} unmountOnExit>
        {isError ? (
          <Styled.HelperContainer>
            <Typography color='error'>
              {t('common.errors.generic')}
            </Typography>
          </Styled.HelperContainer>
        ) : isLoading ? (
          <Styled.HelperContainer>
            <CircularProgress color='primary' />
          </Styled.HelperContainer>
        ) : (
          <QuestionAnswerDetails
            questionData={questionDetails}
          />
        )}
      </Collapse>
    </>
  )
}

export { QuestionAnswerItem }
