import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

import { ReactComponent as PresentationSVG } from 'assets/images/presentation-icon.svg'

const PresentationIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <PresentationSVG />
  </SvgIcon>
)

export default PresentationIcon
