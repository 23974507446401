import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Grid } from '@material-ui/core'

const RootGrid = styled(Grid)`
  height: 100%;
`

interface LeftImageGridProps {
  $image: string
}

const LeftImageGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== '$image'
})<LeftImageGridProps>`
  ${({ theme, $image }) => css`
    background-image: url(${$image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% 65%;
    background-origin: border-box;
    margin-top: -${theme.spacing(5)}px;
    ${theme.breakpoints.down('md')} {
      padding: 0;
    }
  `}
`

const RightGrid = styled(Grid)`
  ${({ theme }) => css`
    max-height: 100vh;
    position: relative;
    overflow-x: hidden;
    padding: ${theme.spacing(5)}px;
    box-shadow: -5px 0px 15px ${theme.palette.backgroundAccents.shadow};

    ${theme.breakpoints.down('md')}  {
      padding: 10px 20px;
    }
  `}
`

const UpperRightGrid = styled(Grid)`
  padding-bottom: ${({ theme }) => theme.spacing(2.5)}px;
`

const BellVrLogo = styled.img`
  max-width: 420px;
  width: 100%;
  height: auto;
`

const LowerRightGrid = styled(Grid)`
  max-width: 640px;
`

export {
  RootGrid,
  LeftImageGrid,
  RightGrid,
  UpperRightGrid,
  BellVrLogo,
  LowerRightGrid,
}
