import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Button, ButtonProps } from 'components/inputs/Button/Button'
import { ImagePreview } from 'components/data/ImagePreview/ImagePreview'
import { ContentSection } from 'components/data/ContentSection/ContentSection'
import { Checkbox } from 'components/inputs/Checkbox/Checkbox'

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(3)}px;
  max-width: 800px;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const InfoSection = styled(ContentSection)`
  margin-top: ${({ theme }) => theme.spacing(2.5)}px;
  flex: 1;
`

const HelpersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`

const RowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-column-gap: 20px;
`

const FlexColumnContainer = styled.div`
  flex: 1;
`

interface ExitButtonProps extends ButtonProps {
  $isMobile?: boolean
}

const EditButton = styled((props: ExitButtonProps) => (
  <Button {...props} />
), { shouldForwardProp: (prop) => prop !== '$isMobile' })`
  ${({ theme, $isMobile }) => css`
    width: auto;
    ${$isMobile ? css`
      margin-left: ${theme.spacing(2.5)}px;
    ` : css`
      position: absolute;
      bottom: 50px;
      right: 50px;
      z-index: 1;
    `}
  `}
`

const StyledImagePreview = styled(ImagePreview)`
  max-width: 250px;
  height: 150px;
`

const DifficultySliderContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(1, 5)};
`

const CheckboxSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`

const StyledCheckbox = styled(Checkbox)`
  margin: 5px 0;
`

export {
  RootContainer,
  HeaderContainer,
  ContentContainer,
  InfoSection,
  HelpersContainer,
  EditButton,
  StyledImagePreview as ImagePreview,
  RowContainer,
  FlexColumnContainer,
  DifficultySliderContainer,
  CheckboxSection,
  StyledCheckbox as Checkbox,
}
