import { useTranslation } from 'react-i18next'
import { IconButton } from '@material-ui/core'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'

import { TextField, TextFieldProps } from 'components/inputs/TextField/TextField'

interface SearchInputProps extends Omit<TextFieldProps, 'value' | 'onChange' | 'endIcon'> {
  searchFilterValue: string
  onChangeSearchFilter: (value: string) => void
}

const SearchFilterInput = ({
  label,
  searchFilterValue,
  onChangeSearchFilter: handleChangeSearchFilter,
  ...props
}: SearchInputProps) => {
  const { t } = useTranslation()

  const renderEndAdornment = searchFilterValue ? (
    <IconButton onClick={() => handleChangeSearchFilter('')}>
      <CloseRoundedIcon />
    </IconButton>
  ) : <SearchRoundedIcon />

  return (
    <TextField
      label={label ?? t('common.search')}
      value={searchFilterValue}
      onChange={({ target: { value } }) => handleChangeSearchFilter(value)}
      endIcon={renderEndAdornment}
      {...props}
    />
  )
}

export { SearchFilterInput }
