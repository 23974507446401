/* eslint-disable @typescript-eslint/no-explicit-any */
import { keyframes } from '@emotion/react'
import { useMemo } from 'react'

import { createExpandOnXAnimationKeyframes } from './utils'

// any type because Keyframes type is not exported from emotion/styled-components
interface UseCalendarAnimationsReturnType {
  paperExpandAnimation: any
  paperCollapseAnimation: any
  contentExpandAnimation: any
  contentCollapseAnimation: any
  contentTextAnimation: any
  contentTextBackwardAnimation: any
}

const useCalendarAnimations = (
  width: number,
  expandedWidth: number
): UseCalendarAnimationsReturnType => {
  const {
    forwardAnimation,
    inverseForwardAnimation,
    backwardAnimation,
    inverseBackwardAnimation,
  } = useMemo(() => (
    createExpandOnXAnimationKeyframes(width, expandedWidth)
  ), [expandedWidth, width])

  const paperExpandAnimation = keyframes`
    ${forwardAnimation}
  `
  const paperCollapseAnimation = keyframes`
    ${backwardAnimation}
  `

  const contentExpandAnimation = keyframes`
    ${inverseForwardAnimation} 
  `

  const contentCollapseAnimation = keyframes`
    ${inverseBackwardAnimation} 
  `

  const contentTextAnimation = keyframes`
    0% {
      opacity: 0;
    } 
    100% {
      opacity: 1;
    }
  `

  const contentTextBackwardAnimation = keyframes`
    0% {
      opacity: 1;
    } 
    100% {
      opacity: 0;
    }
  `

  return {
    paperExpandAnimation,
    paperCollapseAnimation,
    contentExpandAnimation,
    contentCollapseAnimation,
    contentTextAnimation,
    contentTextBackwardAnimation,
  }
}

export { useCalendarAnimations }
