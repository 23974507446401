import { KnowledgeBaseScheduleProvider } from './KnowledgeBaseSchedule.context'
import { KnowledgeBaseScheduleList } from './KnowledgeBaseScheduleList/KnowledgeBaseScheduleList'

interface KnowledgeBaseScheduleProps {
  scheduleIds?: number[]
}

const KnowledgeBaseSchedule = ({
  scheduleIds = []
}: KnowledgeBaseScheduleProps): JSX.Element => (
  <KnowledgeBaseScheduleProvider>
    <KnowledgeBaseScheduleList scheduleIds={scheduleIds} />
  </KnowledgeBaseScheduleProvider>
)

export { KnowledgeBaseSchedule }
