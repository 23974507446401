import format from 'date-fns/format'

const formatDateWithTime = (dateTimeText: string, dateHoursSeparator = ''): string => (
  format(
    new Date(dateTimeText),
    `dd/MM/yyyy ${dateHoursSeparator ? `'${dateHoursSeparator}'` : ''} kk:mm`,
  )
)

export { formatDateWithTime }
