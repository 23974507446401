import { CSSProperties, ElementType, ReactElement, Ref, useMemo } from 'react'
import { ButtonProps } from '@material-ui/core'

import * as Styled from './Button.styles'

interface Props extends ButtonProps {
  backgroundGradient?: boolean
  isLoading?: boolean
  styles?: CSSProperties
}

type CastedForwardRefButtonType = <C extends ElementType>(
  props: ButtonProps<C, {
    component?: C;
    backgroundGradient?: boolean,
    isLoading?: boolean,
  }>,
  ref?: Ref<HTMLButtonElement>
) => ReactElement

const Button: CastedForwardRefButtonType = ({
  children,
  variant = 'contained',
  backgroundGradient,
  type = 'submit',
  isLoading,
  styles,
  ...rest
}) => {
  const ButtonComponent = useMemo(() => {
    if (variant === 'outlined') return Styled.OutlinedButton
    if (variant === 'contained') {
      return backgroundGradient ? Styled.GradientButton : Styled.ContainedButton
    }
    return Styled.TextButton
  }, [variant, backgroundGradient])

  return (
    <ButtonComponent
      variant={variant}
      type={type}
      $styles={styles}
      {...rest}
    >
      {isLoading ? (
        <Styled.LoaderRoot>
          <Styled.CircularProgress
            size={28}
            thickness={5}
          />
        </Styled.LoaderRoot>
      ) : null}
      {children}
    </ButtonComponent>
  )
}

export { Button }
export type { Props as ButtonProps }
