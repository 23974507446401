import { ReactNode } from 'react'
import { Typography } from '@material-ui/core'

import * as Styled from './ContentSection.styles'

interface ContentSectionProps {
  containerDirection?: 'horizontal' | 'vertical'
  label: string
  value?: ReactNode | string
  editValueComponent?: ReactNode
  className?: string
  children?: ReactNode
}

const ContentSection = ({
  containerDirection = 'vertical',
  label,
  value,
  editValueComponent,
  className,
  children,
}: ContentSectionProps): JSX.Element => (
  <Styled.Container
    $horizontal={containerDirection === 'horizontal'}
    className={className}
  >
    <Typography variant='subtitle1'>
      {label}
    </Typography>
    <Styled.ContentContainer>
      {editValueComponent || (!!value && (
        <Styled.Text variant='body1'>
          {value}
        </Styled.Text>
      ))}
    </Styled.ContentContainer>
    {children}
  </Styled.Container>
)

export { ContentSection }
