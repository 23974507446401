import * as yup from 'yup'

import { MAX_IMAGE_SIZE, SUPPORTED_IMAGE_TYPES } from 'constants/files'

const ImageSchema = yup.mixed()
  .test(
    'fileType',
    'common.errors.fileType',
    (value) => value === null || SUPPORTED_IMAGE_TYPES.includes(value?.type)
  )
  .test(
    'fileSize',
    'common.errors.imageSize',
    (value) => value === null || value?.size <= MAX_IMAGE_SIZE
  )
  .test(
    'imageDimensions',
    'common.errors.imageDimensions',
    (value) => {
      if (value) {
        return new Promise((resolve) => {
          const reader = new FileReader()
          reader.readAsDataURL(value)
          reader.onload = (readerValue) => {
            const img = new Image()
            if (typeof readerValue.target?.result === 'string') {
              img.src = readerValue.target?.result
              img.onload = () => {
                resolve(img.width >= 500 && img.height >= 300)
              }
            }
          }
        })
      }
      return value === null
    }
  )

export { ImageSchema }
