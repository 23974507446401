const ease = (v: number, pow = 4): number => 1 - (1 - v) ** pow

interface ExpandOnXAnimationKeyframes {
  forwardAnimation: string
  backwardAnimation: string
  inverseForwardAnimation: string
  inverseBackwardAnimation: string
}

const createExpandOnXAnimationKeyframes = (
  collapsedSize: number,
  expandedSize: number
): ExpandOnXAnimationKeyframes => {
  const x = collapsedSize / expandedSize
  let forwardAnimation = ''
  let inverseForwardAnimation = ''
  let backwardAnimation = ''
  let inverseBackwardAnimation = ''

  for (let step = 0; step <= 100; step += 1) {
    const easedStep = ease(step / 100)

    const xScale = x + (1 - x) * easedStep

    forwardAnimation += `${step}% {
      transform: scaleX(${xScale});
    }`

    backwardAnimation += `${100 - step}% {
      transform: scaleX(${xScale});
    }`

    const invXScale = 1 / xScale
    inverseForwardAnimation += `${step}% {
      transform: scaleX(${invXScale});
    }`

    inverseBackwardAnimation += `${100 - step}% {
      transform: scaleX(${invXScale});
    }`
  }
  return {
    forwardAnimation,
    backwardAnimation,
    inverseForwardAnimation,
    inverseBackwardAnimation
  }
}

export { createExpandOnXAnimationKeyframes }
