import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'

import { useFetch } from 'providers/FetchProvider'
import { useLogin } from 'providers/UserContextProvider'
import { SignInResponse, SignInVariables, SignInErrorResponse } from 'api/mutations/authentication/types'

const useSignInMutation = (
  options: UseMutationOptions<SignInResponse, SignInErrorResponse, SignInVariables>
) : UseMutationResult<SignInResponse, SignInErrorResponse, SignInVariables> => {
  const { fetch } = useFetch()
  const login = useLogin()
  const { onSuccess } = options

  return useMutation(
    ({ emailOrUsername, password }: SignInVariables) =>
      fetch.post('/login', {
        username: emailOrUsername,
        password,
      }).then((response) => response.data),
    {
      ...options,
      onSuccess: (data, ...rest) => {
        const { accessToken, userId, userRole, languageDTO } = data
        login(accessToken, userRole, userId, languageDTO)
        onSuccess?.(data, ...rest)
      }
    }
  )
}

const useSignOutMutation = (
  options: UseMutationOptions<AxiosResponse, Error, undefined>
) : UseMutationResult<AxiosResponse, Error, undefined> => {
  const { fetch } = useFetch()

  return useMutation(
    () =>
      fetch.patch('/token/logout'),
    options
  )
}

export { useSignInMutation, useSignOutMutation }
