import { useTranslation } from 'react-i18next'
import { CircularProgress, Typography } from '@material-ui/core'

import { AnalysisItem } from 'api/types'
import { AnalysisListItem } from './AnalysisListItem/AnalysisListItem'
import * as Styled from './AnalysisList.styles'

interface AnalysisListProps {
  isLoading: boolean
  isError: boolean
  analysisData?: AnalysisItem[]
}

const AnalysisList = ({ analysisData, isLoading, isError }: AnalysisListProps): JSX.Element => {
  const { t } = useTranslation()

  if (isLoading || isError) {
    return (
      <Styled.CenterElementContainer>
        {isLoading && <CircularProgress />}
        {isError && <Typography>{t('common.errors.generic')}</Typography>}
      </Styled.CenterElementContainer>
    )
  }

  if (!analysisData?.length) {
    return (
      <Styled.CenterElementContainer>
        <Typography>
          {t('results.ranking.noData')}
        </Typography>
      </Styled.CenterElementContainer>
    )
  }

  return (
    <Styled.ListContainer>
      <Styled.ListHeaderContainer>
        <Styled.TrainingIdContainer>
          {t('analysis.trainingId')}
        </Styled.TrainingIdContainer>
        <Styled.ResultContainer>
          {t('analysis.stepId')}
        </Styled.ResultContainer>
        <Styled.ResultContainer>
          {t('analysis.averageCorrectAnswers')}
        </Styled.ResultContainer>
        <Styled.ResultContainer>
          {t('analysis.averageUsedTime')}
        </Styled.ResultContainer>
      </Styled.ListHeaderContainer>
      {
        analysisData && analysisData.map((item) => (
          <AnalysisListItem key={`${item.step}-${item.quizName}`} {...item} />
        ))
      }
    </Styled.ListContainer>
  )
}

export { AnalysisList }
