import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Drawer } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { NavigationListItem } from './NavigationList/NavigationListItem/NavigationListItem'

const drawerWidth = 265

const Toolbar = styled(Link)`
  margin: ${({ theme }) => theme.spacing(2.5, 0)};
`

const StyledDrawer = styled(Drawer)`
  ${({ theme }) => css`
    width: ${drawerWidth}px;
    flex-shrink: 0;
    justify-content: space-between;
    
    .MuiDrawer-paper {
      width: ${drawerWidth}px;
      background-color: ${theme.palette.backgroundAccents.darkerBlueish};
      border-right: 0;
    }
  `}
`

const LogoImage = styled.img`
  display: block;
  width: 76%;
  margin: 0 auto;
`

const ListContainer = styled.div`
  height: 100%;
  margin: 0 12%;
  display: flex;
  flex-direction: column;
  justify-content: 'space-between';
`

const StyledNavigationListItem = styled(NavigationListItem)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing(5)}px;
    
    &.MuiListItem-root {
      color: ${theme.palette.primary.main};
    }
  `}
`

export {
  Toolbar,
  StyledDrawer as Drawer,
  LogoImage,
  ListContainer,
  StyledNavigationListItem as NavigationListItem,
}
