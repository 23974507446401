import { UserDetails } from 'api/types'
import { AppointmentResponse } from 'api/queries/appointments/types'

interface NotificationUser extends Pick<UserDetails, 'id'> {
  fullNameInversed: string
}

enum NotificationReference {
  APPOINTMENT = 'APPOINTMENT',
  STRING = 'STRING'
}

enum NotificationType {
  USER_ADDED_TO_APPOINTMENT = 'USER_ADDED_TO_APPOINTMENT',
  APPOINTMENT_SOON = 'APPOINTMENT_SOON',
  APPOINTMENT_DATE_CHANGED = 'APPOINTMENT_DATE_CHANGED',
  APPOINTMENT_REMOVED = 'APPOINTMENT_REMOVED',
  CONTENT_UPDATED_ON_APPOINTMENT = 'CONTENT_UPDATED_ON_APPOINTMENT',
  NEW_CONTENT_ON_APPOINTMENT = 'NEW_CONTENT_ON_APPOINTMENT',
  NEW_TEST_ON_APPOINTMENT = 'NEW_TEST_ON_APPOINTMENT',
  TEST_UPDATED_ON_APPOINTMENT = 'TEST_UPDATED_ON_APPOINTMENT',
  NEW_QUIZ_ON_APPOINTMENT = 'NEW_QUIZ_ON_APPOINTMENT',
  QUIZ_UPDATED_ON_APPOINTMENT = 'QUIZ_UPDATED_ON_APPOINTMENT',
  NEW_MSG_ON_APPOINTMENT = 'NEW_MSG_ON_APPOINTMENT',
  NEW_REPLY_ON_APPOINTMENT = 'NEW_REPLY_ON_APPOINTMENT',
  USER_REMOVED_FROM_APPOINTMENT = 'USER_REMOVED_FROM_APPOINTMENT',
}

type NotificationBase = {
  creationDateTime: string
  id: number
  seen: boolean
  type: NotificationType
  updateDateTime: string
}

type Notification = NotificationBase & ({
  appointment?: Pick<AppointmentResponse, 'id' | 'name' | 'startDate'>
  owner: NotificationUser
  refers: NotificationReference.APPOINTMENT
  user: NotificationUser
} | {
  refers: NotificationReference.STRING
  value: string
})

export {
  NotificationType,
  NotificationReference,
}

export type {
  Notification,
}
