import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Tab, Tabs } from '@material-ui/core'

const StyledTab = styled(Tab)`
${({ theme }) => css`
    padding-left: 0;
    padding-right: 30px;
    width: 100%;

    & .MuiTab-wrapper {
      align-items: flex-start;
      color: ${theme.palette.grey[500]};
    }
  `}
`

const StyledTabs = styled(Tabs)`
  ${({ theme }) => css`
    box-shadow: inset 0 -3px 0 ${theme.palette.grey[500]};
    min-width: 300px;
  
    & .MuiTabs-indicator {
      background-color: ${theme.palette.primary.main};
      height: 3px;
    }
    
    & .MuiTab-root {
      text-transform: none;
    }

    & .Mui-selected > .MuiTab-wrapper {
      color: ${theme.palette.primary.main};
    }
  `}
`

export {
  StyledTabs as Tabs,
  StyledTab as Tab,
}
