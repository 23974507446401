import { VisibilityType } from 'types/VisibilityType'

const PUBLIC: VisibilityType = 'PUBLIC'
const COMPANY: VisibilityType = 'COMPANY'
const AUTHOR: VisibilityType = 'AUTHOR'

export {
  PUBLIC,
  COMPANY,
  AUTHOR,
}
