import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { IconButton, IconButtonProps, Popover } from '@material-ui/core'
import ColorizeIcon from '@material-ui/icons/Colorize'

import { TextField } from 'components/inputs/TextField/TextField'

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
`

const UserColorPopover = styled(Popover)`
  & .MuiPopover-paper {
    overflow: visible;
  }
  & .MuiPaper-rounded {
    border-radius: 8px;
  }
`

const UserColorTextField = styled(TextField)`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`

const UserColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
`

const UserColorPickerButton = styled((props: IconButtonProps) => (
  <IconButton {...props}>
    <ColorizeIcon />
  </IconButton>
))``

interface UserColorCircleProps {
  $backgroundColor?: string
}

const UserColorCircle = styled.div<UserColorCircleProps>`
  ${({ theme, $backgroundColor }) => css`
    margin-right: ${theme.spacing(1)}px;
    width: 32px;
    height: 32px;
    border: 1px solid ${theme.palette.text.primary};
    border-radius: 50%;
    background-color: ${$backgroundColor};
  `}
`

export {
  CenteredContainer,
  UserColorPopover,
  UserColorTextField,
  UserColorPickerContainer,
  UserColorPickerButton,
  UserColorCircle,
}
