import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseQueryResult,
  UseInfiniteQueryResult,
} from 'react-query'

import { useFetch } from 'providers/FetchProvider'
import {
  KNOWLEDGE_BASE_COMPANY_ID_TOP_QUERY_KEY,
  KNOWLEDGE_BASE_CONTENT_QUERY_KEY,
  KNOWLEDGE_BASE_ENTITY_QUERY_KEY,
  KNOWLEDGE_BASE_OWN_COMPANY_TOP_QUERY_KEY,
  USER_KNOWLEDGE_BASE_PERMISSIONS
} from '../../queriesKeys'
import {
  KnowledgeBaseQueryOptions,
  KnowledgeBaseEntityResponse,
  KnowledgeBaseContentResponse,
  KnowledgeBaseTreeViewNode,
  KnowledgeBaseTreeViewQueryOptions
} from './types'

const useGetKnowledgeBaseEntityQuery = (
  id: number,
  options?: Omit<UseQueryOptions<KnowledgeBaseEntityResponse, Error>, 'queryKey'>
): UseQueryResult<KnowledgeBaseEntityResponse, Error> => {
  const { fetch } = useFetch()

  return useQuery(
    [KNOWLEDGE_BASE_ENTITY_QUERY_KEY, { id }],
    () => fetch.get(`/knowledge-base/${id}`).then((response) => response.data),
    options,
  )
}

const useGetTopLevelOfOwnCompanyQuery = (
  queryOptions?: KnowledgeBaseQueryOptions,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<KnowledgeBaseContentResponse, Error>, 'queryKey'>
): UseInfiniteQueryResult<KnowledgeBaseContentResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    [KNOWLEDGE_BASE_OWN_COMPANY_TOP_QUERY_KEY, { queryOptions }],
    async ({ pageParam = 0 }) => {
      const { data } = await fetch.get('/knowledge-base/company', {
        params: {
          ...queryOptions,
          page: pageParam,
        },
      })
      return data
    },
    useQueryOptions
  )
}

const useGetTopLevelOfCompanyIdQuery = (
  queryOptions?: KnowledgeBaseQueryOptions,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<KnowledgeBaseContentResponse, Error>, 'queryKey'>
): UseInfiniteQueryResult<KnowledgeBaseContentResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    [KNOWLEDGE_BASE_COMPANY_ID_TOP_QUERY_KEY, { queryOptions }],
    async ({ pageParam = 0 }) => {
      const { data } = await fetch.get(`/knowledge-base/company/${queryOptions?.companyId}`, {
        params: {
          ...queryOptions,
          page: pageParam,
        },
      })
      return data
    },
    useQueryOptions
  )
}

const useGetKnowledgeBaseContentQuery = (
  id: number,
  queryOptions: KnowledgeBaseQueryOptions,
  useQueryOptions?: Omit<UseInfiniteQueryOptions<KnowledgeBaseContentResponse, Error>, 'queryKey'>
): UseInfiniteQueryResult<KnowledgeBaseContentResponse, Error> => {
  const { fetch } = useFetch()

  return useInfiniteQuery(
    [KNOWLEDGE_BASE_CONTENT_QUERY_KEY, id, queryOptions],
    async ({ pageParam = 0 }) => {
      const { data } = await fetch.get(`/knowledge-base/${id}/content`, {
        params: {
          ...queryOptions,
          page: pageParam,
        },
      })
      return data
    },
    useQueryOptions
  )
}

const useGetKnowledgeBaseOwnCompanyTreeView = (
  queryOptions?: KnowledgeBaseTreeViewQueryOptions,
  options?: Omit<UseQueryOptions<KnowledgeBaseTreeViewNode[], unknown>, 'queryKey'>,
)
: UseQueryResult<KnowledgeBaseTreeViewNode[], unknown> => {
  const { fetch } = useFetch()

  const { isPresentation, ...params } = queryOptions || {}

  return useQuery(
    'ownCompanyTreeView',
    () => fetch.get(
      `/knowledge-base/company/tree${isPresentation ? '/presentation' : ''}`,
      { params }
    ).then((response) => response.data),
    options
  )
}

const useGetKnowledgeBaseCompanyIdTreeView = (
  queryOptions?: KnowledgeBaseTreeViewQueryOptions,
  options?: Omit<UseQueryOptions<KnowledgeBaseTreeViewNode[], unknown>, 'queryKey'>,
)
: UseQueryResult<KnowledgeBaseTreeViewNode[], unknown> => {
  const { fetch } = useFetch()

  const { companyId, isPresentation, ...params } = queryOptions || {}

  return useQuery(
    'idCompanyTreeView',
    () => {
      if (!companyId) {
        throw new Error(
          'useGetKnowledgeBaseCompanyIdTreeView cannot be executed without companyId prop'
        )
      }

      return fetch.get(
        `/knowledge-base/company/${companyId}/tree${isPresentation ? '/presentation' : ''}`,
        { params }
      ).then((response) => response.data)
    },
    options
  )
}

const useGetUserKnowledgeBasePermissionsQuery = (
  userId: number | null,
  options?: Omit<UseQueryOptions<number[], Error, number[], string[]>, 'queryKey' | 'queryFn'>
) : UseQueryResult<number[], Error> => {
  const { fetch } = useFetch()

  return useQuery(
    [USER_KNOWLEDGE_BASE_PERMISSIONS, `${userId}`],
    () => {
      if (!userId) {
        throw new Error(
          'useGetUserKnowledgeBasePermissionsQuery hook cannot be used without userId prop'
        )
      }

      return fetch.get(
        `knowledge-base/user/${userId}`
      ).then((response) => response.data)
    },
    {
      enabled: !!userId,
      ...options
    }
  )
}

export {
  useGetKnowledgeBaseEntityQuery,
  useGetTopLevelOfOwnCompanyQuery,
  useGetTopLevelOfCompanyIdQuery,
  useGetKnowledgeBaseContentQuery,
  useGetKnowledgeBaseOwnCompanyTreeView,
  useGetKnowledgeBaseCompanyIdTreeView,
  useGetUserKnowledgeBasePermissionsQuery
}
