import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalProps } from 'components/layout/Modal/Modal'
import { MoveSelect } from 'components/inputs/MoveSelect/MoveSelect'
import * as Styled from './MoveElementModal.styles'

interface MoveElementModalProps extends Omit<ModalProps, 'children' | 'onClose'> {
  open: boolean
  movedElementId: number
  onClose: () => void
  onSave: (item? : number | null) => void,
  isLoading?: boolean
}

const MoveElementModal = ({
  open,
  movedElementId,
  onClose: handleClose,
  onSave: handleSave,
  isLoading,
}: MoveElementModalProps): JSX.Element => {
  const { t } = useTranslation()
  const [value, setValue] = useState<number | null>(null)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)

  const handleSelectedDestinationChange = (id: number | null) => {
    setValue(id)
    setIsDisabled(false)
  }

  const handleCloseModal = () => {
    handleClose()
    setIsDisabled(true)
  }

  return (
    <Styled.Modal
      open={open}
      onClose={handleCloseModal}
    >
      <Modal.Title variant='h3'>{t('modals.moveElement.title')}</Modal.Title>
      <Modal.Content>
        <MoveSelect
          movedElementId={movedElementId}
          onSelectedDestinationChange={(id) => handleSelectedDestinationChange(id)}
        />
      </Modal.Content>
      <Modal.Actions
        isLoading={isLoading}
        isSaveDisabled={isDisabled}
        onCancel={handleCloseModal}
        onSave={() => handleSave(value)}
        confirmLabel={t('common.move')}
      />
    </Styled.Modal>
  )
}

export { MoveElementModal }
