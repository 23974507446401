import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Typography, CircularProgress } from '@material-ui/core'

import { ForumEntry } from 'api/types'
import {
  useModifyEntryForAppointmentMutation,
} from 'api/mutations/forumEntries/forumEntries'
import { useUserId } from 'providers/UserContextProvider'
import { formatDateWithTime } from 'utils/formatDateWithTime'
import { TextField } from 'components/inputs/TextField/TextField'
import { FORUM_ENTRY_MAX_LENGTH } from '../constants'
import { CircularProgressWithLabel } from '../CircularProgressWithLabel'
import * as Styled from './ForumEntryItem.styles'

interface ForumEntryItemBaseProps extends ForumEntry {
  isViewOnly?: boolean
  isReply?: boolean
}

const ForumEntryItemBase = ({
  id,
  author,
  content,
  date,
  replies,
  isViewOnly,
  isReply,
}: ForumEntryItemBaseProps) => {
  const { t } = useTranslation()
  const userId = useUserId()
  const [editData, setEditData] = useState({
    isActive: false,
    content: '',
  })

  const { id: authorId, fullNameInversed } = author
  const isAuthor = userId === authorId

  const {
    mutate: modifyEntryMutate,
    isLoading: isEditing,
  } = useModifyEntryForAppointmentMutation({
    onSuccess: () => {
      setEditData({
        isActive: false,
        content: '',
      })
    }
  })

  return (
    <>
      <Styled.Container>
        <Styled.TitleContainer>
          <Typography
            variant='subtitle1'
            color={isAuthor ? 'primary' : 'initial'}
          >
            {fullNameInversed}
          </Typography>
          {isAuthor && !isViewOnly && (
            <Styled.Row>
              {editData.isActive && (
                <CircularProgressWithLabel
                  value={(editData.content.length / FORUM_ENTRY_MAX_LENGTH) * 100}
                />
              )}
              <Styled.EditButton
                $isActive={editData.isActive}
                onClick={() => setEditData(({ isActive: prevIsActive }) => (prevIsActive ? {
                  isActive: false,
                  content: '',
                } : {
                  isActive: true,
                  content,
                }))}
              />
              {editData.isActive && (
                <Styled.EditConfirmButton
                  onClick={() => (
                    modifyEntryMutate({ id, content: editData.content })
                  )}
                />
              )}
            </Styled.Row>
          )}
        </Styled.TitleContainer>
        {editData.isActive ? (
          <Styled.CenteredContentContainer>
            {isEditing ? (
              <CircularProgress />
            ) : (
              <TextField
                multiline
                minRows={2}
                maxRows={2}
                value={editData.content}
                onChange={({ target: { value } }) => (
                  setEditData((prevState) => ({
                    ...prevState,
                    content: value,
                  }))
                )}
                inputProps={{
                  maxLength: FORUM_ENTRY_MAX_LENGTH,
                }}
              />
            )}
          </Styled.CenteredContentContainer>
        ) : (
          <Typography align='justify'>
            {content}
          </Typography>
        )}
        <Styled.AdditionalInfoContainer>
          <Typography color='textSecondary'>
            {formatDateWithTime(date)}
          </Typography>
          {!isReply && (
            <Typography color='textSecondary'>
              {`${t('appointment.forum.replies')}: ${replies.length}`}
            </Typography>
          )}
        </Styled.AdditionalInfoContainer>
      </Styled.Container>
      <Divider />
    </>
  )
}

export type { ForumEntryItemBaseProps }
export { ForumEntryItemBase }
