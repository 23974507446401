import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import { Maybe } from 'types/Maybe'
import { formatDate } from 'utils/formatDate'
import { formatTimeRange } from 'utils/formatTimeRange'
import { AppointmentVisibilityType } from 'types/AppointmentVisibilityType'
import { RestrictedContent } from 'components/auth/RestrictedContent/RestrictedContent'
import { TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE } from 'constants/roleGroups'

import {
  DidacticMaterialsSection,
  DidacticMaterial,
} from './DidacticMaterialsSection/DidacticMaterialsSection'
import {
  ParticipantsSection,
  Participant,
} from './ParticipantsSection/ParticipantsSection'
import * as CommonStyled from '../Appointment.styles'
import * as Styled from './AppointmentContent.styles'
import { KnowledgeBaseScheduleSection } from './KnowledgeBaseScheduleSection/KnowledgeBaseScheduleSection'

interface Teacher {
  id: number
  firstName: string
  lastName: string
  phoneNumber: Maybe<string>
}

interface AppointmentContentProps {
  name?: string
  startDate?: string
  duration?: number
  teacher?: Teacher
  description?: Maybe<string>
  didacticMaterials?: DidacticMaterial[]
  knowledgeBaseScheduleRecords?: number[]
  participants?: Participant[]
  visibility?: AppointmentVisibilityType
}

const AppointmentContent: React.FC<AppointmentContentProps> = ({
  name,
  startDate = new Date().toString(),
  duration = 0,
  teacher,
  description,
  didacticMaterials = [],
  knowledgeBaseScheduleRecords = [],
  participants = [],
  visibility
}) => {
  const { t } = useTranslation()

  const { from, to } = formatTimeRange(startDate, duration)

  return (
    <>
      <RestrictedContent accessRoles={[ADMIN_ROLE, COMPANY_ROLE, TEACHER_ROLE]}>
        <CommonStyled.Row>
          <Styled.PropertyContainer>
            <Styled.TopLabel>
              {t('common.visibility')}
            </Styled.TopLabel>
            <Typography>
              {t(`appointment.visibility.${[visibility]}`)}
            </Typography>
          </Styled.PropertyContainer>
        </CommonStyled.Row>
      </RestrictedContent>
      <CommonStyled.Row>
        <Styled.PropertyContainer $flexDirection='row'>
          <Styled.Column>
            <Styled.TopLabel>{t('common.name')}</Styled.TopLabel>
            <Styled.NameValue>{name}</Styled.NameValue>
          </Styled.Column>
        </Styled.PropertyContainer>
      </CommonStyled.Row>
      <Styled.ColumnsRow>
        <Styled.Column>
          <Styled.PropertyContainer>
            <Styled.TopLabel>{t('appointment.date')}</Styled.TopLabel>
            <Typography>{formatDate(startDate)}</Typography>
          </Styled.PropertyContainer>
          <Styled.PropertyContainer>
            <Styled.TopLabel>{t('appointment.timeOfAppointment')}</Styled.TopLabel>
            <Styled.TimeRangeContainer>
              <Typography>{from}</Typography>
              <Styled.Dash>&#8212;</Styled.Dash>
              <Typography>{to}</Typography>
            </Styled.TimeRangeContainer>
          </Styled.PropertyContainer>
          <Styled.PropertyContainer>
            <Styled.TopLabel>
              {t('appointment.lecturer')}
            </Styled.TopLabel>
            <Typography>
              {`${teacher?.firstName} ${teacher?.lastName} 
              ${teacher?.phoneNumber ? `(tel. ${teacher?.phoneNumber})` : ''}`}
            </Typography>
          </Styled.PropertyContainer>
          <Styled.PropertyContainer>
            <Styled.TopLabel>{t('common.description')}</Styled.TopLabel>
            <Typography>{description || t('common.noData')}</Typography>
          </Styled.PropertyContainer>
          <Styled.PropertyContainer>
            <DidacticMaterialsSection didacticMaterials={didacticMaterials} />
          </Styled.PropertyContainer>
        </Styled.Column>
        <Styled.Column>
          <KnowledgeBaseScheduleSection scheduleIds={knowledgeBaseScheduleRecords} />
        </Styled.Column>
        <Styled.Column>
          <ParticipantsSection participants={participants} />
        </Styled.Column>
      </Styled.ColumnsRow>
    </>
  )
}

export { AppointmentContent }
