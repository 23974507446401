import {
  ResultsByQuizItem,
  ResultsByUserItem,
  ResultsByAppointmentItem,
  QuizEntryItem
} from 'api/queries/results/types'

enum ResultsItemType {
  APPOINTMENT = 'APPOINTMENT',
  QUIZ = 'QUIZ',
  USER = 'USER',
  ENTRY = 'ENTRY',
}

interface QuizResultsItem extends ResultsByQuizItem {
  type: ResultsItemType.QUIZ
}

interface UserResultsItem extends ResultsByUserItem {
  type: ResultsItemType.USER
}

interface AppointmentResultsItem extends ResultsByAppointmentItem {
  type: ResultsItemType.APPOINTMENT
}

interface EntryResultItem extends QuizEntryItem {
  type: ResultsItemType.ENTRY
}

type ResultsItemsUnion = QuizResultsItem |
UserResultsItem |
AppointmentResultsItem |
EntryResultItem

export {
  ResultsItemType,
}

export type {
  QuizResultsItem,
  UserResultsItem,
  AppointmentResultsItem,
  EntryResultItem,
  ResultsItemsUnion,
}
