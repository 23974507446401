import styled from '@emotion/styled'

const HelperContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  margin: ${({ theme }) => theme.spacing(2)}px 0;
`

interface ChartContainerProps {
  $spacing?: boolean
}

const ChartContainer = styled.div<ChartContainerProps>`
  flex: 1;
  max-width: 1000px;
`

const ChartsContainer = styled.div`
  display: flex;
  width: calc(100% - 64px);
  margin: ${({ theme }) => theme.spacing(2)}px 0;
  & > div {
    padding: 0 ${({ theme }) => theme.spacing(2)}px;
  }
  @media (max-width: 1600px) {
    flex-direction: column;
    & > div {
      padding: ${({ theme }) => theme.spacing(2)}px 0;
    }
  }
`

export {
  HelperContainer,
  Container,
  ChartsContainer,
  ChartContainer,
}
