import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  FormControl,
  FormHelperText
} from '@material-ui/core'
import { InputBaseStyle } from 'components/inputs/inputs.styles'

interface StyledFormControlProps {
  label?: React.ReactNode
}

const StyledFormControl = styled(FormControl)<StyledFormControlProps>`
  ${({ theme, label }) => css`
    ${InputBaseStyle(theme, label)}
    .MuiSelect-root {
      padding: ${theme.spacing(2)}px;
      padding-right: 46px;
    }
    .MuiInputBase-inputAdornedStart {
      padding-left: calc(2em + 1rem);
    }
    .MuiFormLabel-root {
      color: ${theme.palette.text.primary};
    }
    .MuiFormLabel-root.Mui-error {
      color: ${theme.palette.error.main};
    }
    .MuiInputBase-root.Mui-focused {
      background-color: rgba(0, 0, 0, 0.05);
    }
    .MuiSvgIcon-root {
      position: absolute;
      top: 10px;
      color: rgba(0, 0, 0, 0.54);
    }
    .MuiSelect-select:focus {
      background-color: transparent;
    }
  `}
`

const StyledFormHelperText = styled(FormHelperText)`
  color: ${({ theme }) => theme.palette.error.main};
`

export {
  StyledFormControl as FormControl,
  StyledFormHelperText as FormHelperText,
}
