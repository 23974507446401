import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik, FieldArray, FormikProvider, FieldArrayRenderProps } from 'formik'
import { FormControl, FormControlLabel, IconButton } from '@material-ui/core'
import uniqueId from 'lodash/uniqueId'

import { useGetKnowledgeBaseEntityQuery } from 'api/queries/knowledgeBase/knowledgeBase'
import { useIsMobile } from 'hooks/useIsMobile'
import { QuizContentSchema } from 'validations/QuizContentSchema'
import { QuizQuestionType } from 'types/QuestionType'
import { Checkbox } from 'components/inputs/Checkbox/Checkbox'
import { ContentModal } from 'components/modals/ContentModal/ContentModal'
import { QuizQuestionContentFormFields } from './types'
import * as Styled from './QuizQuestionForm.styles'
import { getDefaultValues, isStringArray } from './utils'

interface QuizQuestionContentFormProps {
  initialValues?: QuizQuestionContentFormFields
  isLoading?: boolean
  onSubmit: (values: QuizQuestionContentFormFields) => void
}

const QuizQuestionForm: React.FC<QuizQuestionContentFormProps> = ({
  initialValues,
  isLoading,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [radioButtonChecked, setRadioButtonChecked] = useState<number>()

  const formik = useFormik<QuizQuestionContentFormFields>({
    initialValues: {
      ...getDefaultValues(),
      ...initialValues,
    },
    validationSchema: QuizContentSchema,
    onSubmit,
    enableReinitialize: true,
  })

  const {
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
    setValues,
  } = formik

  const { data: presentationData } = useGetKnowledgeBaseEntityQuery(
    values.presentation?.id || 0,
    {
      enabled: !!values.presentation?.id
    }
  )

  const getErrorMessage = (index: number) => {
    if (
      errors.answers
      && !isStringArray(errors.answers)
      && typeof errors.answers !== 'string') {
      const errorAnswer = t((errors.answers[index])?.content || '')
      return errorAnswer
    }
    return ''
  }

  useEffect(() => {
    if (initialValues?.questionType === QuizQuestionType.SINGLE_CHOICE) {
      if (initialValues?.answers) {
        const initValueRadioButton = initialValues?.answers.find((answer) => answer.correct)
        setRadioButtonChecked(initValueRadioButton?.id)
      } else setRadioButtonChecked(0)
    }
  }, [initialValues])

  useEffect(() => {
    if (values.questionType === QuizQuestionType.SINGLE_CHOICE) {
      const newAnswers = values.answers.map((answer) => ({
        ...answer,
        correct: radioButtonChecked === answer.id,
      }))
      setValues({
        ...values,
        answers: newAnswers
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioButtonChecked])

  const handleDeleteAnswer = (
    arrayHelpers: FieldArrayRenderProps,
    index: number,
    answerId: number
  ) => {
    if (values.questionType === QuizQuestionType.SINGLE_CHOICE) {
      if (values.answers.length > 1) {
        const newAnswers = values.answers.filter((answer) => answer.id !== answerId)
        setFieldValue('answers', newAnswers)
        if (answerId === radioButtonChecked) {
          setRadioButtonChecked(newAnswers[0].id)
        }
      }
    } else if (values.answers.length > 1) {
      arrayHelpers.remove(index)
    }
  }

  const ErrorMessage = () => {
    let message = ''
    if (values.answers.length < 1) message = 'quiz.errors.answers.minFields'
    if (values.questionType === QuizQuestionType.SINGLE_CHOICE
      && !values.answers.some((answer) => answer.correct)) {
      message = 'quiz.errors.answers.minCorrect'
    }
    return message ? <Styled.ErrorMessage>{t(message)}</Styled.ErrorMessage> : null
  }

  useEffect(() => {
    if (!values.presentation) {
      setFieldValue('stepId', '')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.presentation])

  const handleModalSave = (presentation: number[]) => {
    setFieldValue('presentation', presentation.length !== 0 ? { id: presentation[0] } : null)
    setModalOpen(false)
  }

  return (
    <FormikProvider value={formik}>
      <Styled.Form onSubmit={handleSubmit}>
        <Styled.HeaderContainer>
          <Styled.SaveButton
            $isMobile={isMobile}
            type='submit'
            isLoading={isLoading}
          >
            {t('common.save')}
          </Styled.SaveButton>
        </Styled.HeaderContainer>
        <Styled.RowContainer>
          <Styled.InfoSection
            label={t('quiz.question')}
            editValueComponent={
              <Styled.TextField
                id='question'
                name='question'
                value={values.question}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.question}
                errorMessage={t(errors.question || '')}
              />
            }
          />
          <Styled.InfoSection
            label={t('quiz.presentation')}
            editValueComponent={
              <>
                <Styled.PresentationLabel>
                  {presentationData?.name}
                </Styled.PresentationLabel>
                <Styled.ModalButton
                  type='button'
                  onClick={() => setModalOpen(true)}
                >
                  {t('quiz.selectPresentation')}
                </Styled.ModalButton>
              </>
            }
          />
        </Styled.RowContainer>
        <Styled.RowContainer>
          <Styled.InfoSection
            label={t('quiz.stepId')}
            editValueComponent={
              <Styled.TextField
                disabled={!values.presentation}
                id='stepId'
                name='stepId'
                value={values.stepId}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.stepId}
                errorMessage={t(errors.stepId || '')}
              />
            }
          />
          <Styled.InfoSection
            label={t('quiz.weight')}
            editValueComponent={
              <Styled.TextField
                id='weight'
                name='weight'
                value={values.weight}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.weight}
                errorMessage={t(errors.weight || '')}
              />
            }
          />
        </Styled.RowContainer>
        <Styled.RowContainer>
          <Styled.InfoSection
            label={t('common.type')}
            editValueComponent={
              <Styled.QuestionTypeSelect
                id='questionType'
                name='questionType'
                label=''
                value={values.questionType}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={!!touched.questionType}
                errorMessage={t(errors.questionType as unknown as string || '')}
              />
            }
          />
        </Styled.RowContainer>
        <Styled.AnswerLabel>
          {t('quiz.labelAnswers')}
        </Styled.AnswerLabel>
        <FieldArray
          name='answers'
          render={(arrayHelpers) => (
            <div>
              {values.answers.map((answer, index) => (
                <Styled.AnswerRowContainer key={answer.id}>
                  <Styled.TextFieldMedium
                    id={`answers[${index}].content`}
                    name={`answers[${index}].content`}
                    value={answer.content}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.answers && touched.answers[index]?.content}
                    errorMessage={getErrorMessage(index)}
                  />
                  {values.questionType === QuizQuestionType.SINGLE_CHOICE ? (
                    <FormControl>
                      <FormControlLabel
                        value={answer.id}
                        control={<Styled.RadioButton
                          checked={radioButtonChecked === answer.id}
                          onChange={() => setRadioButtonChecked(answer.id)}
                        />}
                        label=''
                      />
                    </FormControl>
                  ) : (
                    <Checkbox
                      id={`answers[${index}].correct`}
                      name={`answers[${index}].correct`}
                      checked={answer.correct}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.answers && touched.answers[index]?.correct}
                    />
                  )}
                  <IconButton
                    onClick={() => handleDeleteAnswer(arrayHelpers, index, answer.id)}
                  >
                    <Styled.ClearIcon
                      type='button'
                    />
                  </IconButton>
                </Styled.AnswerRowContainer>
              ))}
              <Styled.AddAnswerButton
                type='button'
                onClick={() => arrayHelpers.push({
                  content: '',
                  correct: false,
                  id: -uniqueId()
                })}
              >
                {t('quiz.addAnswer')}
              </Styled.AddAnswerButton>
              {errors.answers
                && touched.answers
                && <ErrorMessage />}
            </div>
          )}
        />
      </Styled.Form>
      <ContentModal
        isPresentation
        initialValues={[values.presentation?.id as number]}
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleModalSave}
      />
    </FormikProvider>
  )
}

export type { QuizQuestionContentFormFields }
export { QuizQuestionForm }
