import styled from '@emotion/styled'
import { Typography, TypographyProps } from '@material-ui/core'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: space-between;
  padding-right: 63px;
`

const Header = styled((props: TypographyProps) => (
  <Typography variant='subtitle1' {...props} />
))`
  flex: 1;
  margin: ${({ theme }) => theme.spacing(1)}px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const QuestionHeader = styled(Header)`
  flex: 2;
`

export {
  Container,
  HeaderContainer,
  Header,
  QuestionHeader
}
