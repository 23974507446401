import React from 'react'
import * as Styled from './CompanyStatistics.styles'

interface CompanyStatisticsItemType {
  title: string
  amount: number
}

interface CompanyStatisticsProps {
  data: CompanyStatisticsItemType[]
}

const CompanyStatistics: React.FC<CompanyStatisticsProps> = ({
  data
}) => (
  <Styled.RootContainer>
    {data.map(({ title, amount }) => (
      <Styled.StatisticsItem
        key={title}
        title={title}
        amount={amount}
      />
    ))}
  </Styled.RootContainer>
)

export { CompanyStatistics }
