import React, { useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import { useUserRole } from 'providers/UserContextProvider'
import { TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE } from 'constants/roleGroups'
import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import { AdminCreateAppointmentScreen } from './AdminCreateAppointmentScreen'
import { CompanyCreateAppointmentScreen } from './CompanyCreateAppointmentScreen'

const CreateAppointmentScreen: React.FC = () => {
  const userRole = useUserRole()
  const screenToRender = useMemo(() => {
    switch (userRole) {
      case COMPANY_ROLE:
      case TEACHER_ROLE:
        return <CompanyCreateAppointmentScreen />
      case ADMIN_ROLE:
        return <AdminCreateAppointmentScreen />
      default:
        return <Redirect to='/sign-in' />
    }
  }, [userRole])

  return <RouteContainer>{screenToRender}</RouteContainer>
}

export default CreateAppointmentScreen
