import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { useFetch } from 'providers/FetchProvider'
import { CreateFileVariables, CreateMaterialResponse, CreateLinkVariables } from './types'

const useCreateFileMutation = (
  options?: UseMutationOptions<CreateMaterialResponse, Error, CreateFileVariables>
): UseMutationResult<CreateMaterialResponse, Error, CreateFileVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  return useMutation(
    ({ file, name }) => {
      const formData = new FormData()
      formData.append('file', file)
      return fetch.post(`/material/create-file?name=${name}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => response.data)
    },
    {
      ...options,
      onSuccess: ({ name }) => {
        enqueueSnackbar(
          t('appointment.action.success.createFile', { name }),
          { variant: 'success' }
        )
      },
      onError: () => {
        enqueueSnackbar(t('appointment.action.error.createFile'), { variant: 'error' })
      },
    },
  )
}

const useCreateLinkMutation = (
  options?: UseMutationOptions<CreateMaterialResponse, Error, CreateLinkVariables>
): UseMutationResult<CreateMaterialResponse, Error, CreateLinkVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  return useMutation(
    ({ link, name }) =>
      fetch
        .post(`/material/create-link?link=${link}&name=${name}`)
        .then((response) => response.data),
    {
      ...options,
      onSuccess: ({ name }) => {
        enqueueSnackbar(
          t('appointment.action.success.createLink', { name }),
          { variant: 'success' }
        )
      },
      onError: () => {
        enqueueSnackbar(t('appointment.action.error.createLink'), { variant: 'error' })
      },
    },
  )
}

export {
  useCreateFileMutation,
  useCreateLinkMutation
}
