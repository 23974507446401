import React, { useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import { useUserRole } from 'providers/UserContextProvider'
import { PARTICIPANT_ROLE, TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE } from 'constants/roleGroups'
import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import { UserAppointmentsScreen } from './Appointments/UserAppointmentsScreen'
import { AdminAppointmentsScreen } from './Appointments/AdminAppointmentsScreen'

const AppointmentsScreen: React.FC = () => {
  const userRole = useUserRole()
  const screenToRender = useMemo(() => {
    switch (userRole) {
      case COMPANY_ROLE:
      case TEACHER_ROLE:
      case PARTICIPANT_ROLE:
        return <UserAppointmentsScreen />
      case ADMIN_ROLE:
        return <AdminAppointmentsScreen />
      default:
        return <Redirect to='/' />
    }
  }, [userRole])

  return <RouteContainer>{screenToRender}</RouteContainer>
}

export default AppointmentsScreen
