import { useMemo } from 'react'
import { useHistory, useLocation, matchPath } from 'react-router-dom'
import { KB_CONTENT_ROUTES } from 'constants/knowledgeBase'

const screensOneSlashReturnRemoval = [
  '/appointments/create',
  '/appointments/:id/edit',
  '/appointments/:id',
  `/knowledge-base(/group/\\d+)*/:type${KB_CONTENT_ROUTES}/:id/edit`,
  '/knowledge-base(/group/\\d+)*/quiz/:id/edit/questions',
  '/knowledge-base(/group/\\d+)*/quiz/:id/edit/questions/:questionId',
  '/knowledge-base/create',
  '/knowledge-base(/group/\\d+)*/group/:id/create',
  '/courses/:id',
  '/courses/create',
  '/faq/contact',
  '/results/ranking',
  '/statistics/:quizId',
  '/statistics/:quizId/:questionId',
]

const screensTwoSlashesReturnRemoval = [
  '/appointments/detailed/:type',
  '/knowledge-base(/group/\\d+)*/group/:id',
  `/knowledge-base(/group/\\d+)*/:type${KB_CONTENT_ROUTES}/:id`,
  '/results/quiz/:id',
]

interface StateType {
  from?: string
}

const useLocationReturnFunction = () => {
  const history = useHistory()
  const location = useLocation<StateType | undefined>()

  const locationReturnFunction = useMemo(() => {
    const { pathname, state } = location
    if (state?.from) {
      return () => history.push(state.from || '/')
    }
    if (screensOneSlashReturnRemoval.some((screen) => (
      matchPath(pathname, {
        path: screen,
        exact: true,
      })
    ))) {
      return () => history.push(pathname.slice(0, pathname.lastIndexOf('/')))
    }
    if (screensTwoSlashesReturnRemoval.some((screen) => (
      matchPath(pathname, {
        path: screen,
        exact: true,
      })
    ))) {
      return () => history.push(pathname.split('/').slice(0, -2).join('/'))
    }
    return null
  }, [history, location])

  return locationReturnFunction
}

export { useLocationReturnFunction }
