import { useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { addSeconds } from 'date-fns/esm'
import { Add, Delete, Edit } from '@material-ui/icons'

import { RouteContainer } from 'components/layout/RouteContainer/RouteContainer'
import { AppointmentHeader } from 'components/data/Appointment/AppointmentHeader/AppointmentHeader'
import { AppointmentContent } from 'components/data/Appointment/AppointmentContent/AppointmentContent'
import { ForumContainer } from 'components/data/Appointment/ForumContainer/ForumContainer'
import { AppointmentFloatingContent } from 'components/data/Appointment/AppointmentFloatingContent/AppointmentFloatingContent'
import { DeleteModal } from 'components/modals/DeleteModal/DeleteModal'
import { useGetAppointmentQuery } from 'api/queries/appointments/appointments'
import { useDeleteAppointmentMutation } from 'api/mutations/appointments/appointments'
import { useIsMobile } from 'hooks/useIsMobile'
import { mapFromAppointmentResponse } from './utils'
import * as Styled from './details.styles'

interface StateType {
  from?: string
}

interface RouteParams {
  id: string
}

const RestrictedPreviewAppointmentScreen: React.FC = () => {
  const { id } = useParams<RouteParams>()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const history = useHistory()
  const { state } = useLocation<StateType | undefined>()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const {
    mutate: deleteAppointment,
    isLoading: isDeleteLoading,
    isError: isDeleteError
  } = useDeleteAppointmentMutation()

  const handleDeleteModalOpen = () => setIsDeleteModalOpen(true)

  const handleDeleteModalClose = () => setIsDeleteModalOpen(false)

  const appointmentId = +id

  const handleAppointmentDelete = () => {
    if (appointmentId) {
      deleteAppointment(appointmentId, {
        onSuccess: () => {
          handleDeleteModalClose()
          history.replace(state?.from || '/appointments')
        }
      })
    }
  }

  const {
    data: appointmentData,
    isLoading,
    isError,
  } = useGetAppointmentQuery(appointmentId)

  const appointment = useMemo(() => (
    mapFromAppointmentResponse(appointmentData)
  ), [appointmentData])

  const isArchived = new Date() > new Date(
    addSeconds(new Date(appointment?.startDate || ''), appointment?.duration || 0) || ''
  )

  const handleRedirect = () => {
    if (isArchived) {
      history.push('/appointments/create', { appointmentId: id, from: pathname })
    } else {
      history.push(`/appointments/${id}/edit`)
    }
  }

  const renderActionButtons = () => (
    <>
      <Styled.DeleteButton
        type='button'
        onClick={handleDeleteModalOpen}
        startIcon={<Delete />}
        $isMobile={isMobile}
      >
        {!isMobile ? t('common.delete') : null}
      </Styled.DeleteButton>
      <Styled.ActionButton
        type='button'
        onClick={handleRedirect}
        startIcon={isArchived ? <Add /> : <Edit />}
        $isMobile={isMobile}
      >
        {!isMobile ? t(isArchived ? 'common.createNew' : 'common.edit') : null}
      </Styled.ActionButton>
    </>
  )

  return (
    <RouteContainer $fullHeight>
      <AppointmentHeader
        actionButtons={renderActionButtons()}
      />
      <Styled.ContentFooterContainer>
        {isError
          ? <Styled.ErrorMessage>{t('common.errors.generic')}</Styled.ErrorMessage>
          : isLoading
            ? <Styled.CircularProgress />
            : (
              <>
                <AppointmentContent {...appointment} />
                <AppointmentFloatingContent>
                  <ForumContainer appointmentId={+id} />
                  {!isMobile && (
                    <Styled.Row $justifyContent='flex-end'>
                      {renderActionButtons()}
                    </Styled.Row>
                  )}
                </AppointmentFloatingContent>
              </>
            )}
      </Styled.ContentFooterContainer>
      <DeleteModal
        open={isDeleteModalOpen}
        errorText={isDeleteError ? t('appointment.action.error.delete') : ''}
        onClose={handleDeleteModalClose}
        onSave={handleAppointmentDelete}
        isLoading={isDeleteLoading}
      />
    </RouteContainer>
  )
}

export default RestrictedPreviewAppointmentScreen
