import {
  useGetTeachersFromOwnCompany,
  useGetTeachersFromCompanyId,
} from 'api/queries/users/users'

const useTeachersData = (companyId: number) => {
  const ownTeachers = useGetTeachersFromOwnCompany({
    enabled: !companyId
  })

  const idTeachers = useGetTeachersFromCompanyId(
    companyId,
    {
      enabled: !!companyId
    }
  )

  return companyId ? idTeachers : ownTeachers
}

export { useTeachersData }
