import styled from '@emotion/styled'
import {
  IconButton,
  IconButtonProps,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { iconFontSize } from 'constants/icons'

const CloseButton = styled((props: IconButtonProps) => (
  <IconButton color='inherit' {...props}>
    <CloseIcon fontSize='inherit' />
  </IconButton>
))`
  font-size: ${iconFontSize};
`

export { CloseButton }
