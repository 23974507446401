import { useTranslation } from 'react-i18next'

import { Select, SelectProps } from 'components/inputs/Select/Select'
import { AppointmentVisibilityType } from 'types/AppointmentVisibilityType'

import * as VisibilityTypeSelectStyles from 'components/data/VisibilityTypeSelect/VisibilityTypeSelect.styles'

interface AppointmentVisibilitySelectProps extends SelectProps {}

const AppointmentVisibilitySelect = (props: AppointmentVisibilitySelectProps) => {
  const { t } = useTranslation()

  return (
    <Select
      label={t('common.visibility')}
      {...props}
    >
      {Object.values(AppointmentVisibilityType).map((type) => (
        <VisibilityTypeSelectStyles.MenuItem key={type} value={type}>
          {t(`appointment.visibility.${[type]}`)}
        </VisibilityTypeSelectStyles.MenuItem>
      ))}
    </Select>
  )
}

export { AppointmentVisibilitySelect }
