import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Typography, TypographyProps, CircularProgress as MuiCircularProgress } from '@material-ui/core'

const Container = styled.div`
  ${({ theme }) => `
    margin-left: ${theme.spacing(2)}px;
    margin-right: ${theme.spacing(2)}px;

    ${theme.breakpoints.down('sm')} {
      margin-left: 0;
      margin-right: 0;
    }
  `}
`

const CircularProgress = styled(MuiCircularProgress)`
  margin: auto;
`

const ColumnsRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin: 0 -${theme.spacing(1)}px;
    > * {
      margin: 0 ${theme.spacing(1)}px;
    }
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      margin: -${theme.spacing(1)}px 0;
      > * {
        margin: ${theme.spacing(1)}px 0;
      }
    }
  `}
`

interface PropertyContainerProps {
  $flexDirection?: 'row' | 'column'
}

const PropertyContainer = styled.div<PropertyContainerProps>`
  ${({ theme, $flexDirection = 'column' }) => css`
    display: flex;
    flex-direction: ${$flexDirection};
    margin-bottom: ${theme.spacing(1.5)}px;
  `}
`

const LeftLabel = styled((props: TypographyProps) => (
  <Typography variant='subtitle1' {...props} />
))`
  margin-right: ${({ theme }) => theme.spacing(3)}px;
`

const NameValue = styled((props: TypographyProps) => (
  <Typography variant='subtitle2' {...props} />
))`
  line-height: 1.75;
  display: flex;
  align-items: center;
`

const TopLabel = styled((props: TypographyProps) => (
  <Typography variant='subtitle1' {...props} />
))`
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
`

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ContentLabel = styled((props: TypographyProps) => (
  <Typography variant='subtitle1' {...props} />
))`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  line-height: 1.5;
`

const TimeRangeContainer = styled.div`
  display: flex;
`

const Dash = styled(Typography)`
  margin: 0 ${({ theme }) => theme.spacing(1)}px;
`

const ErrorMessage = styled((props: TypographyProps) => (
  <Typography variant='subtitle2' {...props} />
))`
  ${({ theme }) => css`
    margin: 0 auto;
    color: ${theme.palette.error.main}
  `}
`

export {
  Container,
  CircularProgress,
  ColumnsRow,
  PropertyContainer,
  LeftLabel,
  NameValue,
  TopLabel,
  Column,
  ContentLabel,
  TimeRangeContainer,
  Dash,
  ErrorMessage,
}
