import { UseQueryResult } from 'react-query'

import { KnowledgeBaseTreeViewNode } from 'api/queries/knowledgeBase/types'
import {
  useGetKnowledgeBaseOwnCompanyTreeView,
  useGetKnowledgeBaseCompanyIdTreeView
} from 'api/queries/knowledgeBase/knowledgeBase'
import { KnowledgeBaseVisibilityType } from 'types/KnowledgeBaseVisibilityType'

const useTreeViewData = (
  companyId: number,
  isPresentation?: boolean,
  visibility?: KnowledgeBaseVisibilityType,
): UseQueryResult<KnowledgeBaseTreeViewNode[], unknown> => {
  const ownTreeView = useGetKnowledgeBaseOwnCompanyTreeView({ isPresentation, visibility }, {
    enabled: !companyId
  })

  const idTreeView = useGetKnowledgeBaseCompanyIdTreeView(
    { companyId, isPresentation, visibility },
    {
      enabled: !!companyId
    },
  )

  return companyId ? idTreeView : ownTreeView
}

export { useTreeViewData }
