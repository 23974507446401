import { useState } from 'react'
import constate from 'constate'
import { useTranslation } from 'react-i18next'

import { ApiError } from 'api/types'
import { parseErrorCodeToi18nKey } from 'utils/parseErrorCodeToi18nKey'

const [
  ApiErrorHandlerProvider,
  useApiErrorHandler
] = constate(
  () => {
    const { t, i18n } = useTranslation()
    const [errorMessage, setErrorMessage] = useState<string>('')

    function handleApiError<T>(error: ApiError<T>, errorNotFoundMessage?: string) {
      const errorCode = parseErrorCodeToi18nKey(error.response?.data.messageCode || '')
      if (i18n.exists(`apiErrors.${[errorCode]}`)) {
        setErrorMessage(t(`apiErrors.${[errorCode]}`))
      } else if (errorNotFoundMessage) {
        setErrorMessage(errorNotFoundMessage)
      } else {
        setErrorMessage(t('common.errors.generic'))
      }
    }

    return {
      errorMessage,
      handleApiError,
    }
  },
)

export { ApiErrorHandlerProvider, useApiErrorHandler }
