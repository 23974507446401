import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'

import { useGetKnowledgeBaseEntityQuery } from 'api/queries/knowledgeBase/knowledgeBase'
import { useSetScheduleLoading } from '../KnowledgeBaseSchedule.context'
import * as Styled from './KnowledgeBaseScheduleItem.styles'

interface KnowledgeBaseEntity {
  id: number
  name: string
  parent?: KnowledgeBaseEntity
}

interface KnowledgeBaseScheduleItemProps {
  itemId: number
}

const getBreadcrumbs = (e: KnowledgeBaseEntity, arr: KnowledgeBaseEntity[] = [])
: KnowledgeBaseEntity[] => {
  if (!e.parent) {
    return [e]
  }
  return [...getBreadcrumbs(e.parent, arr), e]
}

const KnowledgeBaseScheduleItem: React.FC<KnowledgeBaseScheduleItemProps> = ({ itemId }) => {
  const { data: entityData, isLoading } = useGetKnowledgeBaseEntityQuery(itemId)
  const setLoading = useSetScheduleLoading()

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading, setLoading])

  return (
    <Styled.KnowledgeBaseContentItem>
      <Styled.Typography variant='body1'>
        {`• ${entityData?.name}`}
      </Styled.Typography>
      <Styled.Breadcrumbs>
        {entityData?.parent && getBreadcrumbs(entityData?.parent).map(({ id, name }) => (
          <Typography key={`breadcrumb-${id}`}>
            {name}
          </Typography>
        ))}
      </Styled.Breadcrumbs>
    </Styled.KnowledgeBaseContentItem>
  )
}

export type { KnowledgeBaseEntity }
export { KnowledgeBaseScheduleItem }
