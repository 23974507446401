import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import { getKnowledgeBaseBreadcrumbsList } from 'utils/getKnowledgeBaseBreadcrumbsList'
import * as Styled from './KnowledgeBaseBreadcrumbs.styles'

interface KnowledgeBaseEntity {
  id: number
  name: string
  parent?: KnowledgeBaseEntity
}

interface KnowledgeBaseBreadcrumbsParams {
  knowledgeBaseEntity?: KnowledgeBaseEntity
  isError?: boolean
  isDisplayOnly?: boolean
}

const KnowledgeBaseBreadcrumbs: React.FC<KnowledgeBaseBreadcrumbsParams> = ({
  knowledgeBaseEntity,
  isError,
  isDisplayOnly = false
}) => {
  const { t } = useTranslation()

  const breadcrumbs = useMemo(() => (
    knowledgeBaseEntity ? (
      getKnowledgeBaseBreadcrumbsList(knowledgeBaseEntity).map((value, index, array) => ({
        ...value,
        url: array.slice(0, index + 1).reduce((acc, { id: groupId }) => (
          `${acc}/group/${groupId}`
        ), '')
      }))
    ) : []
  ), [knowledgeBaseEntity])

  const renderBreadcrumb = useCallback(
    (name: string, url = '', id?: number, index?: number) => (
      index === breadcrumbs.length - 1 || isDisplayOnly ? (
        <Typography key={id}>
          {name}
        </Typography>
      ) : (
        <Styled.BreadcrumbLink
          key={id}
          to={`/knowledge-base${url}`}
        >
          {name}
        </Styled.BreadcrumbLink>
      )
    ),
    [isDisplayOnly, breadcrumbs]
  )

  return (
    <>
      {isError ? (
        <Styled.ErrorMessage>{t('knowledgeBase.breadcrumbs.error')}</Styled.ErrorMessage>
      ) : (
        <Styled.Breadcrumbs>
          {breadcrumbs.length && (
            renderBreadcrumb(t('knowledgeBase.breadcrumbs.root'))
          )}
          {breadcrumbs.map(({ id: breadcrumbId, name, url }, index) => (
            renderBreadcrumb(name, url, breadcrumbId, index)
          ))}
        </Styled.Breadcrumbs>
      )}
    </>
  )
}

export { KnowledgeBaseBreadcrumbs }
