import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { AxiosResponse } from 'axios'
import { useSnackbar } from 'notistack'
import { formatISO } from 'date-fns'

import { useFetch } from 'providers/FetchProvider'
import {
  ChangePasswordVariables,
  ChangeEmailVariables,
  ResetPasswordVariables,
  SignUpVariables,
  EditUserDetailsVariables,
  EditUserDetailsResponse,
  EditUserNotificationsVariables,
  SetActiveVariables,
  EditRoleVariables,
  RegisterCompanyMemberVariables,
  ChangeEducationLevelVariables,
  EditUserVariables
} from 'api/mutations/users/types'
import { ApiError, UserDetails } from 'api/types'
import { COMPANY_USERS_QUERY_KEY, OWN_COMPANY_USERS_QUERY_KEY } from 'api/queriesKeys'
import { getFieldErrorMessages } from 'utils/getFieldErrorMessages'
import { SnackbarErrorMessage } from 'components/layout/Snackbar/SnackbarErrorMessage'

const useSignUpMutation = (options: UseMutationOptions<AxiosResponse, Error, SignUpVariables>)
: UseMutationResult<AxiosResponse, Error, SignUpVariables> => {
  const { fetch } = useFetch()

  return useMutation(
    (user: SignUpVariables) =>
      fetch.post('/user/register', user),
    options,
  )
}

const useSendResetPasswordEmailMutation = (
  options: UseMutationOptions<AxiosResponse<string>, Error, string>
): UseMutationResult<AxiosResponse<string>, Error, string> => {
  const { fetch } = useFetch()

  return useMutation(
    (emailOrUsername: string) => fetch.patch(`/user/send-reset-email/${emailOrUsername}`),
    options
  )
}

const useResetPasswordMutation = (
  options: UseMutationOptions<AxiosResponse<string>, Error, ResetPasswordVariables>
): UseMutationResult<AxiosResponse<string>, Error, ResetPasswordVariables> => {
  const { fetch } = useFetch()

  return useMutation(
    (resetPasswordObj: ResetPasswordVariables) =>
      fetch.patch('/user/reset-password', resetPasswordObj),
    options
  )
}

const useChangePasswordMutation = (
  options?: UseMutationOptions<AxiosResponse, Error, ChangePasswordVariables>
): UseMutationResult<AxiosResponse, Error, ChangePasswordVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  return useMutation(
    (changePassword: ChangePasswordVariables) =>
      fetch.patch('/user/change-password', changePassword),
    {
      ...options,
      onSuccess: () => {
        enqueueSnackbar(t('changePassword.action.success'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('changePassword.action.error'), { variant: 'error' })
      },
    },
  )
}

const useChangeEmailMutation = (
  options?: UseMutationOptions<AxiosResponse, ApiError, ChangeEmailVariables>
): UseMutationResult<AxiosResponse, ApiError, ChangeEmailVariables> => {
  const { t, i18n } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  return useMutation(
    (changeEmail: ChangeEmailVariables) =>
      fetch.patch('/user/email', changeEmail),
    {
      ...options,
      onSuccess: () => {
        enqueueSnackbar(t('changeEmail.action.success'), { variant: 'success' })
      },
      onError: (error) => {
        const fieldErrorMessages = getFieldErrorMessages(i18n, error)
        enqueueSnackbar(
          <SnackbarErrorMessage
            title={t('changeEmail.action.error')}
            fieldErrorMessages={fieldErrorMessages}
          />,
          { variant: 'error', persist: true }
        )
      },
    },
  )
}

const useConfirmEmailMutation = (
  options: UseMutationOptions<AxiosResponse, Error, ChangeEmailVariables>
): UseMutationResult<AxiosResponse, Error, ChangeEmailVariables> => {
  const { fetch } = useFetch()

  return useMutation(
    (token: ChangeEmailVariables) =>
      fetch.patch('/user/email/confirm', token),
    options
  )
}

const useEditUserDetailsMutation = (
  options?: UseMutationOptions<EditUserDetailsResponse, Error, EditUserDetailsVariables>
): UseMutationResult<EditUserDetailsResponse, Error, EditUserDetailsVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    (editUser: EditUserDetailsVariables) =>
      fetch.put('/user/edit-current', editUser).then((response) => response.data),
    {
      ...options,
      onSuccess: (...args) => {
        onSuccess?.(...args)
        enqueueSnackbar(t('changeUserDetails.action.success'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('changeUserDetails.action.error'), { variant: 'error' })
      },
    },
  )
}

const useEditUserNotificationsMutation = (
  options?: UseMutationOptions<AxiosResponse, Error, EditUserNotificationsVariables>
): UseMutationResult<AxiosResponse, Error, EditUserNotificationsVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  return useMutation(
    (editNotifications: EditUserNotificationsVariables) =>
      fetch.patch('/user/notifications', editNotifications),
    {
      ...options,
      onSuccess: () => {
        enqueueSnackbar(t('notifications.action.success'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('notifications.action.error'), { variant: 'error' })
      },
    },
  )
}

const useSetActiveMutation = (
  options?: UseMutationOptions<AxiosResponse, Error, SetActiveVariables>
): UseMutationResult<AxiosResponse, Error, SetActiveVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ id, isActive }: SetActiveVariables) => {
      const newFormData = new FormData()
      newFormData.append('isActive', JSON.stringify(isActive))
      return fetch.patch(`/user/${id}/set-active`, newFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    {
      ...options,
      onSuccess: (...args) => {
        onSuccess?.(...args)
        enqueueSnackbar(t('users.action.success.setActive'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('users.action.error.setActive'), { variant: 'error' })
      },
    },
  )
}

const useEditCompanyRoleMutation = (
  options?: UseMutationOptions<AxiosResponse, Error, EditRoleVariables>
): UseMutationResult<AxiosResponse, Error, EditRoleVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ id, authorityGroupName }: EditRoleVariables) => {
      const newFormData = new FormData()
      newFormData.append('authorityGroupName', authorityGroupName)
      return fetch.patch(`/user/${id}/authority-group`, newFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    {
      ...options,
      onSuccess: (...args) => {
        onSuccess?.(...args)
        enqueueSnackbar(t('users.action.success.editRole'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('users.action.error.editRole'), { variant: 'error' })
      },
    },
  )
}

const useRegisterOwnCompanyMemberMutation = (
  options?: UseMutationOptions<AxiosResponse, Error, RegisterCompanyMemberVariables>
): UseMutationResult<
AxiosResponse<RegisterCompanyMemberVariables>, Error, RegisterCompanyMemberVariables
> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  return useMutation(
    ({ user }: RegisterCompanyMemberVariables) =>
      fetch.post('/user/register-company-member', user),
    {
      ...options,
      onSuccess: () => {
        enqueueSnackbar(t('users.action.success.registerCompanyMember'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('users.action.error.registerCompanyMember'), { variant: 'error' })
      },
    },
  )
}

const useRegisterCompanyIdMemberMutation = (
  options?: UseMutationOptions<AxiosResponse, Error, RegisterCompanyMemberVariables>
): UseMutationResult<AxiosResponse, Error, RegisterCompanyMemberVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  return useMutation(
    ({ id, user }: RegisterCompanyMemberVariables) =>
      fetch.post(`/user/register-company-member/company/${id}`, user),
    {
      ...options,
      onSuccess: () => {
        enqueueSnackbar(t('users.action.success.registerCompanyMember'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('users.action.error.registerCompanyMember'), { variant: 'error' })
      },
    },
  )
}

const useChangeEducationLevelMutation = (
  options?: UseMutationOptions<UserDetails, Error, ChangeEducationLevelVariables>
): UseMutationResult<UserDetails, Error, ChangeEducationLevelVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ id, educationLevel }: ChangeEducationLevelVariables) => {
      const newFormData = new FormData()
      if (educationLevel) {
        newFormData.append('educationLevel', educationLevel)
      }
      return fetch.patch(`/user/${id}/education-level`, newFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    {
      ...options,
      onSuccess: (...args) => {
        onSuccess?.(...args)
        enqueueSnackbar(t('users.action.success.educationLevel'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('users.action.error.educationLevel'), { variant: 'error' })
      },
    },
  )
}

const useEditUserMutation = (
  options?: UseMutationOptions<UserDetails, Error, EditUserVariables>
): UseMutationResult<UserDetails, Error, EditUserVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ id, user }) => {
      const { expirationDate: expirationDateString } = user
      const expirationDate = expirationDateString ? formatISO(new Date(expirationDateString)) : null

      return fetch.put(`/user/edit/${id}`, {
        ...user,
        expirationDate
      })
    },
    {
      ...options,
      onSuccess: (...args) => {
        onSuccess?.(...args)
        enqueueSnackbar(t('users.action.success.edit'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('users.action.error.edit'), { variant: 'error' })
      },
    },
  )
}

const useDeleteUserMutation = (
  options?: UseMutationOptions<AxiosResponse, Error, number>
): UseMutationResult<AxiosResponse, Error, number> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    (id: number) => (
      fetch.delete(`/user/${id}`)
    ),
    {
      ...options,
      onSuccess: (...args) => {
        onSuccess?.(...args)
        queryClient.invalidateQueries(COMPANY_USERS_QUERY_KEY)
        queryClient.invalidateQueries(OWN_COMPANY_USERS_QUERY_KEY)
        enqueueSnackbar(t('users.action.success.delete'), { variant: 'success' })
      },
    },
  )
}

export {
  useSignUpMutation,
  useSendResetPasswordEmailMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useChangeEmailMutation,
  useConfirmEmailMutation,
  useEditUserDetailsMutation,
  useEditUserNotificationsMutation,
  useSetActiveMutation,
  useRegisterOwnCompanyMemberMutation,
  useEditCompanyRoleMutation,
  useRegisterCompanyIdMemberMutation,
  useChangeEducationLevelMutation,
  useEditUserMutation,
  useDeleteUserMutation
}
