import { Grid } from '@material-ui/core'

import leftImage from 'assets/images/auth.svg'
import logo from 'assets/images/logo@2x.png'
import * as Styled from './AuthLayout.styles'

const AuthLayout: React.FC = ({ children }) => (
  <Styled.RootGrid container>
    <Styled.LeftImageGrid item sm={false} md={7} $image={leftImage} />
    <Styled.RightGrid container item sm={12} md={5}>
      <Grid container item alignContent='center' justifyContent='center'>
        <Styled.UpperRightGrid container item xs={12} justifyContent='center'>
          <Styled.BellVrLogo src={logo} alt='BellVR logo' />
        </Styled.UpperRightGrid>
        <Styled.LowerRightGrid container item xs={12} justifyContent='center'>
          {children}
        </Styled.LowerRightGrid>
      </Grid>
    </Styled.RightGrid>
  </Styled.RootGrid>
)

export { AuthLayout }
