import { useTranslation } from 'react-i18next'

import { ConfirmModal, ConfirmModalProps } from '../ConfirmModal/ConfirmModal'

interface DeleteModalProps extends Omit<ConfirmModalProps, 'title'> {
  title?: string
}

const DeleteModal = (props: DeleteModalProps) => {
  const { t } = useTranslation()

  return (
    <ConfirmModal
      title={t('modals.confirmDeletion')}
      confirmLabel={t('common.delete')}
      {...props}
    />
  )
}

export { DeleteModal }
