import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { ListItem, ListItemText } from '@material-ui/core'

const StyledListItem = styled(({ ...props }) => <ListItem {...props} />)`
  ${({ theme }) => css`
    margin: 10px 0;
    padding: 10px 0;
    color: ${theme.palette.icon.expandMore};
    &.MuiListItem-root {
      background-color: inherit;
      &:hover {
        color: ${theme.palette.primary.main};
        -webkit-filter: drop-shadow(0 6px 12px ${theme.palette.primary.main});
        filter: drop-shadow(0 6px 12px ${theme.palette.primary.main});
        svg {
          color: ${theme.palette.primary.main};
        }
      }
    }

    &.MuiListItem-root.Mui-selected {
      background-color: white;
      border-radius: 8px;
      color: ${theme.palette.primary.main};
      svg {
        color: ${theme.palette.primary.main};
      }
      &:hover {
        filter: none;
      }
    }

    .MuiListItemIcon-root {
      justify-content: center;
    }

    .MuiListItemText-root {
      margin-right: 6px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .MuiTypography-body1 {
      font-size: 14px;
    }
  `}
`

const StyledListItemText = styled(ListItemText)`
  margin-left: 0.5em;
  .MuiListItemText-primary {
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export {
  StyledListItem as ListItem,
  StyledListItemText as ListItemText
}
