interface TimeZone {
  offset: string
  labelCode: string
  tzCode: string
}

export const timeZones: TimeZone[] = [
  { offset: 'GMT-11:00', labelCode: 'pagoPago', tzCode: 'Etc/GMT-11' },
  { offset: 'GMT-10:00', labelCode: 'aleutian', tzCode: 'America/Adak' },
  { offset: 'GMT-09:00', labelCode: 'alaska', tzCode: 'America/Anchorage' },
  { offset: 'GMT-08:00', labelCode: 'pacific', tzCode: 'America/Los_Angeles' },
  { offset: 'GMT-07:00', labelCode: 'mountain', tzCode: 'America/Denver' },
  { offset: 'GMT-06:00', labelCode: 'central', tzCode: 'America/Chicago' },
  { offset: 'GMT-05:00', labelCode: 'eastern', tzCode: 'America/New_York' },
  { offset: 'GMT-04:00', labelCode: 'halifax', tzCode: 'America/Glace_Bay' },
  { offset: 'GMT-03:00', labelCode: 'buenosAires', tzCode: 'America/Argentina/Buenos_Aires' },
  { offset: 'GMT-02:00', labelCode: 'saoPaulo', tzCode: 'America/Sao_Paulo' },
  { offset: 'GMT-01:00', labelCode: 'capeVerde', tzCode: 'Atlantic/Cape_Verde' },
  { offset: 'UTC', labelCode: 'london', tzCode: 'UTC' },
  { offset: 'GMT+01:00', labelCode: 'belgrade', tzCode: 'Europe/Belgrade' },
  { offset: 'GMT+02:00', labelCode: 'jerusalem', tzCode: 'Asia/Jerusalem' },
  { offset: 'GMT+03:00', labelCode: 'minsk', tzCode: 'Europe/Minsk' },
  { offset: 'GMT+04:00', labelCode: 'dubai', tzCode: 'Asia/Dubai' },
  { offset: 'GMT+04:30', labelCode: 'kabul', tzCode: 'Asia/Kabul' },
  { offset: 'GMT+05:00', labelCode: 'uzbekistan', tzCode: 'Asia/Tashkent' },
  { offset: 'GMT+05:30', labelCode: 'india', tzCode: 'Asia/Kolkata' },
  { offset: 'GMT+05:45', labelCode: 'kathmandu', tzCode: 'Asia/Kathmandu' },
  { offset: 'GMT+06:00', labelCode: 'dhaka', tzCode: 'Asia/Dhaka' },
  { offset: 'GMT+06:30', labelCode: 'myanmar', tzCode: 'Asia/Rangoon' },
  { offset: 'GMT+07:00', labelCode: 'bangkok', tzCode: 'Asia/Bangkok' },
  { offset: 'GMT+08:00', labelCode: 'hongkong', tzCode: 'Asia/Hong_Kong' },
  { offset: 'GMT+08:45', labelCode: 'eucla', tzCode: 'Australia/Eucla' },
  { offset: 'GMT+09:00', labelCode: 'tokyo', tzCode: 'Asia/Tokyo' },
  { offset: 'GMT+09:30', labelCode: 'darwin', tzCode: 'Australia/Darwin' },
  { offset: 'GMT+10:00', labelCode: 'brisbane', tzCode: 'Australia/Brisbane' },
  { offset: 'GMT+10:30', labelCode: 'adelaide', tzCode: 'Australia/Lord_Howe' },
  { offset: 'GMT+11:00', labelCode: 'vladivostok', tzCode: 'Asia/Vladivostok' },
  { offset: 'GMT+12:00', labelCode: 'newZealand', tzCode: 'Pacific/Auckland' },
  { offset: 'GMT+13:00', labelCode: 'tonga', tzCode: 'Pacific/Tongatapu' },
  { offset: 'GMT+14:00', labelCode: 'kiritimati', tzCode: 'Pacific/Kiritimati' }
]
