import React, { useEffect } from 'react'
import { useFormik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import { ChangePasswordSchema } from 'validations/ChangePasswordSchema'
import { PasswordField } from 'components/inputs/PasswordField/PasswordField'
import { FormSaveButton } from 'components/forms/FormSaveButton/FormSaveButton'
import * as FormsStyled from '../forms.styles'

export interface ChangePasswordFormFields {
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
}

export interface ChangePasswordFormProps {
  onSubmit?: (
    values: ChangePasswordFormFields,
    helpers: FormikHelpers<ChangePasswordFormFields>
  ) => void
  isLoading?: boolean
  isSuccess?: boolean
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  onSubmit = () => {},
  isLoading,
  isSuccess,
}) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    dirty,
    resetForm,
  } = useFormik<ChangePasswordFormFields>(
    {
      initialValues: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      },
      validationSchema: ChangePasswordSchema,
      onSubmit,
    }
  )

  useEffect(() => {
    if (isSuccess) {
      resetForm()
    }
  }, [isSuccess, resetForm])

  return (
    <FormsStyled.Form onSubmit={handleSubmit}>
      <PasswordField
        label={t('common.oldPassword')}
        errorMessage={t(errors.oldPassword || '')}
        id='oldPassword'
        name='oldPassword'
        value={values.oldPassword}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.oldPassword}
      />
      <PasswordField
        label={t('common.newPassword')}
        errorMessage={t(errors.newPassword || '')}
        id='newPassword'
        name='newPassword'
        value={values.newPassword}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.newPassword}
      />
      <PasswordField
        label={t('common.confirmNewPassword')}
        errorMessage={t(errors.confirmNewPassword || '')}
        id='confirmNewPassword'
        name='confirmNewPassword'
        value={values.confirmNewPassword}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.confirmNewPassword}
      />
      <FormSaveButton
        isActive={dirty}
        isSaving={isLoading}
        isSaved={isSuccess}
      />
    </FormsStyled.Form>
  )
}
export { ChangePasswordForm }
