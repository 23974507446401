import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { AxiosResponse } from 'axios'

import { NOTIFICATIONS_QUERY_KEY } from 'api/queriesKeys'
import { useFetch } from 'providers/FetchProvider'
import {
  CreateAppointmentVariables,
  CreateAppointmentResponse,
  EditAppointmentVariables,
  EditAppointmentResponse,
} from './types'

const useCreateOwnAppointmentMutation = (
  options?: UseMutationOptions<CreateAppointmentResponse, Error, CreateAppointmentVariables>
): UseMutationResult<CreateAppointmentResponse, Error, CreateAppointmentVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ appointment }: CreateAppointmentVariables) =>
      fetch.post('/appointment', appointment).then((response) => response.data),
    {
      ...options,
      onSuccess: (data, ...rest) => {
        const { name } = data
        onSuccess?.(data, ...rest)
        queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEY)
        enqueueSnackbar(t('appointment.action.success.add', { name }), { variant: 'success' })
      },
      onError: (_, { appointment: { name } }) => {
        enqueueSnackbar(t('appointment.action.error.add', { name }), { variant: 'error' })
      },
    },
  )
}

const useCreateCompanyAppointmentMutation = (
  options?: UseMutationOptions<CreateAppointmentResponse, Error, CreateAppointmentVariables>
): UseMutationResult<CreateAppointmentResponse, Error, CreateAppointmentVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ companyId, appointment }: CreateAppointmentVariables) =>
      fetch
        .post(`/appointment/company/${companyId}`, appointment)
        .then((response) => response.data),
    {
      ...options,
      onSuccess: (data, ...rest) => {
        const { name } = data
        onSuccess?.(data, ...rest)
        queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEY)
        enqueueSnackbar(t('appointment.action.success.add', { name }), { variant: 'success' })
      },
      onError: (_, { appointment: { name } }) => {
        enqueueSnackbar(t('appointment.action.error.add', { name }), { variant: 'error' })
      },
    },
  )
}

const useEditAppointmentMutation = (
  options?: UseMutationOptions<EditAppointmentResponse, Error, EditAppointmentVariables>
): UseMutationResult<EditAppointmentResponse, Error, EditAppointmentVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    ({ id, editAppointment }: EditAppointmentVariables) =>
      fetch.put(`/appointment/${id}`, editAppointment).then((response) => response.data),
    {
      ...options,
      onSuccess: (data, ...rest) => {
        onSuccess?.(data, ...rest)
        queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEY)
        enqueueSnackbar(
          t('appointment.action.success.edit', { name: data.name }),
          { variant: 'success' }
        )
      },
      onError: () => {
        enqueueSnackbar(t('appointment.action.error.edit'), { variant: 'error' })
      },
    },
  )
}

const useDeleteAppointmentMutation = (
  options?: UseMutationOptions<AxiosResponse, Error, number>
): UseMutationResult<AxiosResponse, Error, number> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { fetch } = useFetch()

  const { onSuccess } = options || {}

  return useMutation(
    (id: number) =>
      fetch.delete(`/appointment/${id}`).then((response) => response.data),
    {
      ...options,
      onSuccess: (...props) => {
        onSuccess?.(...props)
        queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEY)
        enqueueSnackbar(
          t('appointment.action.success.delete', { id: props[1] }),
          { variant: 'success' }
        )
      },
      onError: () => {
        enqueueSnackbar(t('appointment.action.error.delete'), { variant: 'error' })
      },
    },
  )
}

export {
  useCreateOwnAppointmentMutation,
  useCreateCompanyAppointmentMutation,
  useEditAppointmentMutation,
  useDeleteAppointmentMutation
}
