import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Link as RouterLink } from 'react-router-dom'

const Link = styled(RouterLink)`
  ${({ color, theme }) => css`
    margin-bottom: ${theme.spacing(0.5)}px;
    vertical-align: middle;
    cursor: pointer;
    text-decoration: underline;
    color: ${color || theme.palette.primary.main};
    opacity: 1;
    :hover,
    :focus {
      opacity: 0.75;
    }
  `}
`

export { Link }
