import { ChangeEvent, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { MenuItem, CircularProgress } from '@material-ui/core'

import { Select } from 'components/inputs/Select/Select'
import { useGetUserDetailsQuery } from 'api/queries/users/users'
import { useGetLanguagesQuery } from 'api/queries/languages/languages'
import { USER_DETAILS_QUERY_KEY } from 'api/queriesKeys'
import { useChangeLanguageMutation } from 'api/mutations/languages/languages'
import { UserDetails } from 'api/types'
import * as FormsStyled from '../forms.styles'
import * as Styled from './ChangeLanguageForm.styles'

export interface ChangeLanguageFormFields {
  language: string
}

const ChangeLanguageForm = () => {
  const { i18n, t } = useTranslation()
  const queryClient = useQueryClient()
  const [open, setOpen] = useState(false)
  const [languageBrowserCode, setLanguageBrowserCode] = useState<string | null>(null)

  const {
    data: languages,
    isError: isErrorLanguages,
  } = useGetLanguagesQuery()

  const {
    data: userDetails,
    isLoading: isLoadingUserDetails,
    isError: isErrorUserDetails,
  } = useGetUserDetailsQuery()

  const currentLanguage = userDetails?.defaultLanguage

  useEffect(() => {
    if (currentLanguage) {
      setLanguageBrowserCode(currentLanguage.browserCode)
    }
  }, [currentLanguage])

  const {
    mutate: languageMutate,
    isLoading: isLoadingLanguageEdit,
  } = useChangeLanguageMutation({
    onSuccess: (data) => {
      queryClient.setQueryData<UserDetails | undefined>(USER_DETAILS_QUERY_KEY, (oldData) => (
        oldData && {
          ...oldData,
          defaultLanguage: data.defaultLanguage,
        }
      ))
    }
  })

  const handleChange = (event: ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>) => {
    const browserCode = event.target.value as string
    if (browserCode) {
      setLanguageBrowserCode(browserCode)
      i18n.changeLanguage(browserCode)
      languageMutate({ browserCode })
    }
  }

  return (
    <FormsStyled.Form>
      <Styled.CenteredContainer>
        {isErrorUserDetails && (
          <FormsStyled.ErrorMessage>
            {t('common.errors.generic')}
          </FormsStyled.ErrorMessage>
        )}
        {isLoadingUserDetails ? (
          <CircularProgress />
        ) : (
          <Styled.SelectWrapper>
            {isLoadingLanguageEdit ? <Styled.CircularProgress /> : null}
            <Select
              id='language'
              name='language'
              defaultValue={currentLanguage?.browserCode}
              value={languageBrowserCode}
              onChange={handleChange}
              open={open}
              onOpen={isLoadingLanguageEdit ? () => {} : () => setOpen(true)}
              onClose={() => setOpen(false)}
            >
              {isErrorLanguages && (
                <FormsStyled.ErrorMessage>
                  {t('common.error')}
                </FormsStyled.ErrorMessage>
              )}
              {languages?.map(({ id, browserCode }) => (
                <MenuItem key={id} value={browserCode}>
                  {t('language.'.concat(browserCode))}
                </MenuItem>
              ))}
            </Select>
          </Styled.SelectWrapper>
        )}
      </Styled.CenteredContainer>
    </FormsStyled.Form>
  )
}

export { ChangeLanguageForm }
