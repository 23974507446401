import { CompaniesListItemType } from './CompaniesListItem/CompaniesListItem'
import * as Styled from './CompaniesList.styles'

interface CompaniesListProps {
  companies: CompaniesListItemType[]
  onSave?: () => void
  onCancel?: () => void
  onActivate: (id: number, isActive: boolean) => void
  onEdit: (company: CompaniesListItemType) => void
  className?: string
}

const CompaniesList: React.FC<CompaniesListProps> = ({
  companies,
  onSave: handleSubmit = () => null,
  onCancel: handleCancel = () => null,
  onActivate: handleActivate,
  onEdit: handleEdit,
  className
}) => (
  <Styled.RootContainer className={className}>
    {companies.map((company) => (
      <Styled.CompaniesListItem
        key={company.id}
        id={company.id}
        initialValues={company}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onActivate={handleActivate}
        onEdit={handleEdit}
      />
    ))}
  </Styled.RootContainer>
)

export { CompaniesList }
