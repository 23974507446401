import React, { useMemo, useState } from 'react'
import { IconButton } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { TextField, Props } from '../TextField/TextField'

const PasswordField: React.FC<Props> = (props) => {
  const [showPassword, setShowPassword] = useState(false)
  const VisibilityToggle = useMemo(() => (
    <IconButton onClick={() => setShowPassword((prevState) => !prevState)}>
      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
    </IconButton>), [showPassword])

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        disableUnderline: true,
        endAdornment: VisibilityToggle
      }}
      {...props}
    />
  )
}

export { PasswordField }
