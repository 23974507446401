import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'

const IdText = styled(Typography)`
  width: 52px;
  margin: 5px;
  padding: 16px;
  justify-self: center;
`

export { IdText }
