import { useState } from 'react'
import { UseInfiniteQueryResult } from 'react-query'
import { useDebounce } from 'react-use'

import {
  useGetResultsByQuizQuery,
  useGetCompanyResultsByQuizQuery,
  useGetResultsByUserQuery,
  useGetCompanyResultsByUserQuery,
  useGetResultsByAppointmentQuery,
  useGetCompanyResultsByAppointmentQuery,
} from 'api/queries/results/results'
import {
  ResultsByQuizItem,
  ResultsResponseUnion,
  ResultsByUserItem,
  ResultsByAppointmentItem,
} from 'api/queries/results/types'
import { Company } from 'api/queries/companies/types'
import { ResultsGroupingType } from 'constants/resultsGroupingType'
import { ResultsItemType } from 'components/data/ResultsList/types'

const mapResultsData = (
  resultsType: ResultsGroupingType,
  queryResult: UseInfiniteQueryResult<ResultsResponseUnion, Error>
) => ({
  ...queryResult,
  data: {
    ...queryResult.data,
    pages: queryResult.data?.pages.map((page) => page.content.map((item) => {
      switch (resultsType) {
        case ResultsGroupingType.BY_USERS:
          return ({
            type: ResultsItemType.USER as const,
            ...item as ResultsByUserItem
          })
        case ResultsGroupingType.BY_QUIZZES:
          return ({
            type: ResultsItemType.QUIZ as const,
            ...item as ResultsByQuizItem
          })
        default:
          return ({
            type: ResultsItemType.APPOINTMENT as const,
            ...item as ResultsByAppointmentItem
          })
      }
    })),
  }
})

const useResultsData = (
  companyId: Company['id'],
  resultsType: ResultsGroupingType,
  searchFilter?: string
) => {
  const pageOption = (lastPage: ResultsResponseUnion) => (
    lastPage.number < lastPage.totalPages - 1 ? lastPage.number + 1 : undefined
  )
  const [buffer, setBuffer] = useState(searchFilter)
  useDebounce(() => setBuffer(searchFilter), 500, [searchFilter])

  const ownQuizResults = useGetResultsByQuizQuery(
    buffer,
    {
      enabled: resultsType === ResultsGroupingType.BY_QUIZZES && !companyId,
      getNextPageParam: pageOption,
    }
  )

  const companyQuizResults = useGetCompanyResultsByQuizQuery(
    companyId,
    buffer,
    {
      enabled: resultsType === ResultsGroupingType.BY_QUIZZES && !!companyId,
      getNextPageParam: pageOption,
    }
  )

  const ownUserResults = useGetResultsByUserQuery(
    buffer,
    {
      enabled: resultsType === ResultsGroupingType.BY_USERS && !companyId,
      getNextPageParam: pageOption,
    }
  )

  const companyUserResults = useGetCompanyResultsByUserQuery(
    companyId,
    buffer,
    {
      enabled: resultsType === ResultsGroupingType.BY_USERS && !!companyId,
      getNextPageParam: pageOption,
    }
  )

  const ownAppointmentResults = useGetResultsByAppointmentQuery(
    buffer,
    {
      enabled: resultsType === ResultsGroupingType.BY_APPOINTMENTS && !companyId,
      getNextPageParam: pageOption,
    }
  )

  const companyAppointmentResults = useGetCompanyResultsByAppointmentQuery(
    companyId,
    buffer,
    {
      enabled: resultsType === ResultsGroupingType.BY_APPOINTMENTS && !!companyId,
      getNextPageParam: pageOption,
    }
  )

  return mapResultsData(
    resultsType,
    companyId ? (
      resultsType === ResultsGroupingType.BY_QUIZZES
        ? companyQuizResults : resultsType === ResultsGroupingType.BY_USERS
          ? companyUserResults : companyAppointmentResults
    ) : (
      resultsType === ResultsGroupingType.BY_QUIZZES
        ? ownQuizResults : resultsType === ResultsGroupingType.BY_USERS
          ? ownUserResults : ownAppointmentResults
    )
  )
}

export { useResultsData }
