import styled from '@emotion/styled'
import { Typography, TypographyProps } from '@material-ui/core'

const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

const HeaderTitle = styled((props: TypographyProps) => (
  <Typography variant='h2' {...props} />
))``

const ActionsContainer = styled.div``

export {
  Header,
  HeaderTitle,
  ActionsContainer,
}
