import styled from '@emotion/styled'
import { Fab } from '@material-ui/core'
import { Button } from 'components/inputs/Button/Button'

const AddAppointmentButton = styled(Button)`
  max-width: 350px;
  margin-bottom: 30px;
`

const AddAppointmentFab = styled(Fab)`
  ${({ theme }) => `
    padding: ${theme.spacing(1.5, 3.5)};
    position: fixed;
    bottom: 25px;
    right: 25px;
    color: white;
    z-index: 1;
    @media (max-width: 350px) {
      right: 0;
    }
  `}
`

const RootContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`

export {
  AddAppointmentButton,
  AddAppointmentFab,
  RootContainer,
  ActionsContainer
}
