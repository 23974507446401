import React, { useMemo, useState } from 'react'
import {
  Grid,
  IconButton
} from '@material-ui/core'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import { Autocomplete } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'

import { CardsList } from 'components/data/CardsList/CardsList'
import { TextField } from 'components/inputs/TextField/TextField'
import { SortSelect } from 'components/data/SortSelect/SortSelect'
import { OLDEST } from 'constants/sortTypes'
import { SortType } from 'types/SortType'
import { useSortCardItems } from 'hooks/useSortCardItems'
import * as Styled from './CoursesScreen.styles'

const KnowledgeBaseScreen: React.FC = () => {
  const { t } = useTranslation()
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [sortType, setSortType] = useState<SortType>(OLDEST)
  const [mockupData] = useState([
    {
      id: 1,
      name: 'Kurs operatora wózka widłowego',
      creationDateTime: '2017-04-19T07:09:40.744967',
    },
    {
      id: 2,
      name: 'Kombajn chodnikowy',
      creationDateTime: '2005-04-19T07:09:40.744967',
    },
    {
      id: 3,
      name: 'Kombajn uliczny',
      creationDateTime: '2000-05-19T07:09:40.744967',
    },
    {
      id: 4,
      name: 'Kombajn podłogowy',
      creationDateTime: '1991-04-19T07:09:40.744967',
    },
    {
      id: 5,
      name: 'Bardzo ważny kurs',
      creationDateTime: '1801-04-19T07:09:40.744967',
    },
  ])

  const sortTypeFunction = useSortCardItems(sortType)

  const handleSortSelectChange = (event: React.ChangeEvent<{
    name?: string | undefined
    value: unknown
  }>) => {
    setSortType(event.target.value as SortType)
  }

  const mockupDataList = useMemo(() => (
    mockupData
      .map((item) => ({
        id: item.id,
        name: item.name,
        creationDateTime: item.creationDateTime,
      }))
      .sort(sortTypeFunction) || []
  ), [mockupData, sortTypeFunction])

  const filteredMockupDataToShow = useMemo(() => (
    mockupDataList
      .filter(({ name }) => (
        searchFilter ? name.toLowerCase().includes(searchFilter?.toLowerCase()) : true
      ))
  ), [mockupDataList, searchFilter])

  const renderEndAdornment = searchFilter ? (
    <IconButton onClick={() => setSearchFilter('')}>
      <CloseRoundedIcon />
    </IconButton>
  ) : <SearchRoundedIcon />

  // TO DO: Add redirect to proper pages when they will be ready
  const handleAddNewCardButton = () => null

  return (
    <Styled.Grid container direction='column'>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={3}>
          <SortSelect
            withoutNone
            value={sortType}
            onChange={handleSortSelectChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            freeSolo
            onInputChange={
              (_, value) => setSearchFilter(value)
            }
            inputValue={searchFilter}
            options={filteredMockupDataToShow}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('common.search')}
                placeholder={t('knowledgeBase.startWrite')}
                margin='normal'
                InputLabelProps={{
                  ...params.InputLabelProps,
                  shrink: true,
                }}
                InputProps={{
                  ...params.InputProps,
                  className: '',
                  disableUnderline: true,
                  endAdornment: renderEndAdornment
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent='flex-end'>
        <Styled.Button
          backgroundGradient
          onClick={handleAddNewCardButton}
          endIcon={<AddRoundedIcon />}
        >
          {t('courses.createNew')}
        </Styled.Button>
      </Grid>
      <Styled.CardsListGrid item xs={12}>
        <CardsList
          data={filteredMockupDataToShow}
          emptyListInfoText={t('courses.empty')}
        />
      </Styled.CardsListGrid>
    </Styled.Grid>
  )
}

export default KnowledgeBaseScreen
