import styled from '@emotion/styled'
import { Typography, TypographyProps } from '@material-ui/core'

const Container = styled.div`
  display: table;
`

const RowsContainer = styled.div`
  display: table-row-group;
`

const Row = styled.div`
  display: table-row;
`

const Label = styled((props: TypographyProps) => (
  <Typography {...props} />
))`
  display: table-cell;
  padding-right: ${({ theme }) => theme.spacing(4)}px;
`

const Value = styled((props: TypographyProps) => (
  <Typography {...props} />
))`
  display: table-cell;
  font-weight: bold;
`

export {
  Container,
  RowsContainer,
  Row,
  Label,
  Value,
}
