import { useTranslation } from 'react-i18next'

import { UserResultsItem } from '../types'
import * as Styled from './ResultsListItem.styled'

const UserResultsListItemData = ({
  userName,
  entriesCount
}: UserResultsItem) => {
  const { t } = useTranslation()

  return (
    <>
      <Styled.DataValueContainer>
        {userName}
      </Styled.DataValueContainer>
      <Styled.DataValueContainer>
        {t('results.quizzes', { count: entriesCount })}
      </Styled.DataValueContainer>
      <Styled.DataValueContainer />
    </>
  )
}

export { UserResultsListItemData }
