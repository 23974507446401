import { VisibilityType } from 'api/mutations/knowledgeBase/types'
import { AppointmentResponse } from 'api/queries/appointments/types'
import { AUTHOR, COMPANY } from 'constants/visibilityTypes'
import { DidacticMaterial } from 'types/DidacticMaterial'
import { Maybe } from 'types/Maybe'
import { AppointmentVisibilityType } from 'types/AppointmentVisibilityType'

interface Appointment {
  name: string
  startDate: string
  duration: number
  teacher: {
    email: string
    firstName: string
    id: number
    lastName: string
    phoneNumber: Maybe<string>
  }
  description: Maybe<string>
  didacticMaterials: DidacticMaterial[]
  knowledgeBaseScheduleRecords: number[]
  participants: {
    downloadedPercent: number
    email: string
    firstName: string
    id: number
    lastName: string
  }[]
  visibility: AppointmentVisibilityType
  open: boolean
}

const convertVisibilityAndOpenToAppointmentVisibility = (
  visibility: Omit<VisibilityType, 'PUBLIC'>,
  open: boolean
) => {
  if (visibility === AUTHOR) {
    return {
      visibility: AppointmentVisibilityType.Private,
      open: false
    }
  }
  if (visibility === COMPANY) {
    if (open) {
      return {
        visibility: AppointmentVisibilityType.Public,
        open
      }
    }
    return {
      visibility: AppointmentVisibilityType.Invitational,
      open
    }
  }
  return {
    visibility: AppointmentVisibilityType.Invitational,
    open: false
  }
}

const mapFromAppointmentResponse = (
  data?: AppointmentResponse
) : Appointment | null => {
  if (data) {
    const {
      name,
      startDate,
      duration,
      appointmentTeacher,
      description,
      didacticMaterials,
      knowledgeBaseScheduleRecords,
      appointmentParticipants,
      visibility: initialVisibility,
      open: initialOpen
    } = data

    const {
      visibility,
      open
    } = convertVisibilityAndOpenToAppointmentVisibility(initialVisibility, initialOpen)

    return {
      name,
      startDate,
      duration,
      teacher: appointmentTeacher.user,
      description,
      didacticMaterials: didacticMaterials.map(({ link, ...material }) => (
        { ...material, content: link }
      )),
      visibility,
      open,
      knowledgeBaseScheduleRecords: knowledgeBaseScheduleRecords.map(
        (item) => item.knowledgeBaseEntity.id
      ),
      participants: appointmentParticipants.map(({ downloadedPercent, user }) => ({
        downloadedPercent,
        ...user,
      }))
    }
  }
  return null
}

export { convertVisibilityAndOpenToAppointmentVisibility, mapFromAppointmentResponse }
