import { useState, useCallback } from 'react'
import constate from 'constate'

const [DrawerStateProvider, useDrawerState, useDrawerToggle] = constate(
  () => {
    const [mobileOpen, setMobileOpen] = useState(false)
    const handleDrawerToggle = useCallback(() => {
      setMobileOpen((prevState) => !prevState)
    }, [])
    return {
      mobileOpen,
      handleDrawerToggle,
    }
  },
  (value) => value.mobileOpen,
  (value) => value.handleDrawerToggle
)

export { DrawerStateProvider, useDrawerState, useDrawerToggle }
