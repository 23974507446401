import { useParams, useHistory } from 'react-router'
import { CircularProgress } from '@material-ui/core'
import { useQueryClient } from 'react-query'
import differenceWith from 'lodash/differenceWith'
import differenceBy from 'lodash/differenceBy'
import isEqual from 'lodash/isEqual'

import { QuizQuestionForm } from 'components/forms/QuizQuestionForm/QuizQuestionForm'
import { useEditQuestionMutation } from 'api/mutations/quizes/quizes'
import { useGetQuestionQuery } from 'api/queries/quizes/quizes'
import { KNOWLEDGE_BASE_ENTITY_QUERY_KEY } from 'api/queriesKeys'
import { QuizQuestionContentFormFields } from '../../../components/forms/QuizQuestionForm/types'
import * as Styled from '../knowledgeBase.styles'

interface ParamTypes {
  questionId: string
}

const KnowledgeBaseQuestionEditScreen = (): JSX.Element => {
  const history = useHistory()
  const { questionId } = useParams<ParamTypes>()
  const queryClient = useQueryClient()

  const {
    data: questionData,
    isLoading,
  } = useGetQuestionQuery(+questionId, { enabled: !!questionId })

  const {
    mutate: editObjectMutate,
    isLoading: isEditObjectMutationLoading
  } = useEditQuestionMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(KNOWLEDGE_BASE_ENTITY_QUERY_KEY)
      history.goBack()
    },
  })

  const handleSubmit = ({
    ...values
  }: QuizQuestionContentFormFields) => {
    const { questionType, question, stepId, weight, answers, presentation } = values
    const newAnswersToAdd = answers.filter((answer) => answer.id < 0)
    const answersToEdit = differenceWith(answers, questionData?.answers || [], isEqual)
    const answersToDelete = differenceBy(questionData?.answers || [], answers, 'id')
    editObjectMutate({
      id: +questionId,
      dto: {
        question,
        questionType,
        stepId,
        weight,
        answersToAdd: newAnswersToAdd.map(({ id, ...rest }) => rest),
        answersToDelete: answersToDelete.map((answer) => answer.id),
        answersToEdit: answersToEdit.filter((answer) => answer.id > 0),
        presentationId: presentation?.id,
      }
    })
  }

  return (
    <Styled.RootContainer>
      {isLoading ? (
        <Styled.HelpersContainer>
          {isLoading && <CircularProgress color='primary' />}
        </Styled.HelpersContainer>
      ) : (
        <QuizQuestionForm
          initialValues={questionData}
          onSubmit={handleSubmit}
          isLoading={isEditObjectMutationLoading}
        />
      )}
    </Styled.RootContainer>
  )
}

export { KnowledgeBaseQuestionEditScreen }
