import { css } from '@emotion/react'
import styled from '@emotion/styled'

const Hr = styled.hr`
  ${({ theme }) => css`
    height: 2px;
    border-width: 0;
    margin: ${theme.spacing(2)}px 0;
    background: #cbcbcb;
  `}
`

const Form = styled.form`
  width: 100%;
  padding: 6px ${({ theme }) => theme.spacing(1)}px;
`

const FormFooter = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(2)}px
  `}
`

const ConfirmationMessage = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  text-align: center;
  font-weight: normal;
`

const ErrorMessage = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.error.main};
  `}
`

const FormHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(1.5, 0)};
`

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
`

export {
  Hr,
  Form,
  FormFooter,
  ConfirmationMessage,
  ErrorMessage,
  FormHeader,
  CenteredContainer
}
