import * as Styled from './SnackbarErrorMessage.styles'

interface SnackbarErrorMessageProps {
  title?: string
  fieldErrorMessages?: string[]
}

export const SnackbarErrorMessage = ({ title, fieldErrorMessages }: SnackbarErrorMessageProps) => (
  <div>
    <Styled.Title>{title}</Styled.Title>
    <div>
      {fieldErrorMessages?.map((fieldErrorMessage) => (
        <div key={fieldErrorMessage}>{fieldErrorMessage}</div>
      ))}
    </div>
  </div>
)
