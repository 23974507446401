import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import { formatTime } from 'utils/formatTime'
import { AppointmentListItemType } from 'components/data/AppointmentList/AppointmentList'
import * as Styled from './AppointmentCalendar.styles'

interface CalendarAppointmentsListProps {
  appointmentList: AppointmentListItemType[]
  onAppointmentClick?: (appointment: AppointmentListItemType) => void
}

const CalendarAppointmentsList = ({
  appointmentList,
  onAppointmentClick: handleAppointmentClick,
}: CalendarAppointmentsListProps) => {
  const { t } = useTranslation()

  return (
    <Styled.CalendarAppointmentsList>
      {appointmentList.map((appointment) => (
        <Styled.CalendarAppointmentsListItem key={appointment.id}>
          <Styled.AppointmentDetailsContainer>
            <Styled.AppointmentTitle
              variant='body1'
              onClick={() => handleAppointmentClick?.(appointment)}
            >
              {appointment.name}
            </Styled.AppointmentTitle>
            <Typography>
              {`${t('common.start')}: ${formatTime(appointment.startDate)}`}
            </Typography>
          </Styled.AppointmentDetailsContainer>
          {appointment.open
            ? <Styled.OpenChip label={t('appointment.open')} size='small' />
            : null}
        </Styled.CalendarAppointmentsListItem>
      ))}
    </Styled.CalendarAppointmentsList>
  )
}

export { CalendarAppointmentsList }
