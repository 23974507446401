import { useMemo } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

const useIsSmallScreen = () => {
  const theme = useTheme()

  const isSmallScreen = useMemo(() => theme.breakpoints.down('sm'), [theme])

  return useMediaQuery(isSmallScreen)
}

export { useIsSmallScreen }
