import React, { useMemo } from 'react'

import {
  useGetCompanyNextAppointmentsQuery,
  useGetCompanyArchivedAppointmentsQuery
} from 'api/queries/appointments/appointments'
import { useCompanyId } from 'providers/CompanyProvider'
import { AppointmentsBase } from './AppointmentsBase'

const AdminAppointmentsScreen: React.FC = () => {
  const companyId = useCompanyId() || -1

  const {
    data: nextAppointmentsData,
    isLoading: isLoadingNext
  } = useGetCompanyNextAppointmentsQuery({
    companyId,
    forPrincipal: false
  })

  const {
    data: archivedAppointmentsData,
    isLoading: isLoadingArchived
  } = useGetCompanyArchivedAppointmentsQuery({
    companyId,
    forPrincipal: false
  })

  const nextAppointmentsList = useMemo(() => (
    nextAppointmentsData?.pages[0].content.slice(0, 3) || []
  ), [nextAppointmentsData?.pages])
  const archivedAppointmentsList = useMemo(() => (
    archivedAppointmentsData?.pages[0].content.slice(0, 3) || []
  ), [archivedAppointmentsData?.pages])
  const isLoading = isLoadingNext || isLoadingArchived

  return (
    <AppointmentsBase
      nextAppointmentsList={nextAppointmentsList}
      archivedAppointmentsList={archivedAppointmentsList}
      isLoading={isLoading}
    />
  )
}

export { AdminAppointmentsScreen }
