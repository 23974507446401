import { useEffect, useMemo, useCallback } from 'react'
import Axios from 'axios'
import constate from 'constate'
import { useIsAuthScreen } from 'hooks/useIsAuthScreen'
import { useRefreshTokenQuery } from 'api/queries/token/token'
import { useAccessToken, useIsAuthenticated, useLogin, useLogout } from './UserContextProvider'

const [FetchProvider, useFetch] = constate(() => {
  const accessToken = useAccessToken()
  const isAuthenticated = useIsAuthenticated()
  const isAuthScreen = useIsAuthScreen()
  const login = useLogin()
  const logout = useLogout()
  const { refetch, isLoading: isRefreshLoading } = useRefreshTokenQuery({
    enabled: !!isAuthenticated,
    // refresh every 3 minutes (keep up to date with backend configuration)
    refetchInterval: 1000 * 60 * 3,
    onSuccess: (data) => {
      const { accessToken: newAccessToken, userId: id, userRole, languageDTO } = data
      login(newAccessToken, userRole, id, languageDTO)
    },
    onError: () => {
      logout()
    },
    retry: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: true,
  })

  const fetch = useMemo(() => {
    const instance = Axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      withCredentials: true
    })
    return instance
  }, [])

  const refresh = useCallback(async () => {
    await refetch()
    return Promise.resolve()
  }, [refetch])

  useEffect(() => {
    if (isAuthScreen) {
      return () => {}
    }

    if (!accessToken) {
      refresh()
      return () => {}
    }

    const requestInterceptor = fetch.interceptors.request.use((request) => {
      request.headers.Authorization = `Bearer ${accessToken}`
      return request
    })

    const responseInterceptor = fetch.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response: { status } } = error
        if (status === 401) {
          refresh()
        }
        return Promise.reject(error)
      }
    )

    return () => {
      fetch.interceptors.request.eject(requestInterceptor)
      fetch.interceptors.response.eject(responseInterceptor)
    }
  }, [
    accessToken,
    fetch.interceptors.response,
    fetch.interceptors.request,
    refresh,
    isAuthScreen
  ])

  return { fetch, isRefreshLoading }
})

export { FetchProvider, useFetch }
