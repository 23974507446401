/** @jsxImportSource @emotion/react */
import { useLocation, useHistory } from 'react-router-dom'
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded'
import { useTranslation } from 'react-i18next'

import * as Styled from './AppointmentListItem.styles'

interface AppointmentListItemProps {
  redirectPath: string,
  title: string,
  date: string,
  time: string,
  gradientBackground?: boolean
  isArchived?: boolean
  className?: string
  isOpen: boolean
}

const AppointmentListItem: React.FC<AppointmentListItemProps> = ({
  redirectPath,
  title,
  date,
  time,
  gradientBackground,
  isArchived,
  className,
  isOpen
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const history = useHistory()

  return (
    <Styled.RootLink
      onClick={() => {
        history.push(redirectPath, { from: pathname })
      }}
    >
      <Styled.RootContainer
        $gradientBackground={gradientBackground}
        $isArchived={isArchived}
        className={className}
      >
        <Styled.TopContainer>
          <Styled.Title variant='subtitle1'>{title}</Styled.Title>
          {(isOpen && !isArchived)
            ? <Styled.OpenChip label={t('appointment.open')} />
            : null}
        </Styled.TopContainer>
        <Styled.BottomContainer>
          <Styled.DateTimeContainer>
            <Styled.DateText variant='body1'>{date}</Styled.DateText>
            <Styled.TimeText variant='body1'>{time}</Styled.TimeText>
          </Styled.DateTimeContainer>
          <Styled.RedirectLink
            to={{
              pathname: redirectPath,
              state: {
                from: pathname,
              }
            }}
          >
            {t('appointmentList.seeDetails')}
            <KeyboardArrowRightRoundedIcon />
          </Styled.RedirectLink>
        </Styled.BottomContainer>
      </Styled.RootContainer>
    </Styled.RootLink>
  )
}

export { AppointmentListItem }
export type { AppointmentListItemProps }
