import { ReactNode } from 'react'
import * as Styled from './ModalContent.styles'

interface ModalContentProps {
  className?: string
  children: ReactNode
}

const ModalContent = ({ children, className }: ModalContentProps): JSX.Element => (
  <Styled.ContentContainer className={className}>
    {children}
  </Styled.ContentContainer>
)

export { ModalContent }
